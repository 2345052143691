import React from "react";
import { useParams } from "react-router-dom";
import EmailTemplateForm from "../forms/EmailTemplateForm";

export default function EditEmailTemplate() {
  const { id } = useParams();

  return (
    <>
      <EmailTemplateForm onEdit editId={id} />
    </>
  );
}
