import React, { useEffect, useState } from "react";

import MainWrapper from "./MainWrapper";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useQuoteQuery } from "../queries/quotes";
import Loading from "../components/Loading";
import {
  airConditionerOptions,
  electric_car_Options,
  formatMysqlDate,
  getPercentageByType,
  getT,
  inductionOptions,
  translationKeys,
} from "../admin/utils";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useAuth } from "../authContext";
import {
  floorsOptions,
  getInfoOfListValue,
  homeTypes,
  inclinationTypes,
  personTypes,
  riscaldamentoOptions,
} from "../utils";
import Accordion from "react-bootstrap/Accordion";
import ListGroup from "react-bootstrap/ListGroup";
import { FaBuilding, FaCar, FaHome, FaHouseUser } from "react-icons/fa";
import { BsBuildingsFill, BsFillPersonFill } from "react-icons/bs";
import { MdLandslide, MdOutlineRoofing, MdSoupKitchen } from "react-icons/md";
import { SiLevelsdotfyi } from "react-icons/si";
import { FaPeopleRoof } from "react-icons/fa6";
import { LiaSnowflakeSolid } from "react-icons/lia";
import { PiThermometerColdBold } from "react-icons/pi";
import useScreenSize from "../components/useScreenSize";
import paths from "../config/paths";
import Navigation from "../components/Navigation";
import QuoteStatusWidget from "./widgets/quoteStatusWidget";

export default function QuoteInfo() {
  const [editData, setEditData] = useState();
  const isMobile = useScreenSize(768);
  const { id } = useParams();
  const { panelSettings } = useAuth();
  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useQuoteQuery(
    id,
    id ? true : false
  );

  useEffect(() => {
    if (data && !isLoading) {
      setEditData(data?.quote);
    }
  }, [isLoading, data]);

  const getBatteryCount = (r) => {
    let f4 = getFtvData(r);
    let bat = 0;
    if (panelSettings && panelSettings.bat_capacity) {
      let batCapacity = parseFloat(panelSettings.bat_capacity);
      if (f4 >= batCapacity) {
        bat = f4 / batCapacity;
        if (bat < 1) {
          bat = 0;
        }
      }
    }

    // manuvally set the battery size
    if (r?.bat && parseFloat(r?.bat) > 0) {
      bat = parseFloat(r?.bat);
    }

    return bat;
  };

  const getFinalPrice = (r) => {
    let f4 = getFtvData(r);
    let bat = getBatteryCount(r);
    let price = 0;
    let price2 = 0;
    if (panelSettings && panelSettings.ftv_price && panelSettings.bat_price) {
      let ftvPrice = parseFloat(panelSettings.ftv_price);
      let batPrice = parseFloat(panelSettings.bat_price);
      if (f4 > 0 && ftvPrice > 0) {
        price = f4 * ftvPrice;
      }
      if (batPrice > 0) {
        price2 = price + batPrice * bat;
      }
    }

    // extra price
    let extraPrice = price2;

    let items = [
      {
        name: "peopleCount",
        val: r?.people_count,
      },
      {
        name: "heatPump",
        val: r?.heat_pump,
      },
      {
        name: "inductionHub",
        val: r?.induction_hub,
      },
      {
        name: "ac",
        val: r?.air_conditioners_pump,
      },
      {
        name: "electricCar",
        val: r?.electric_car,
      },
      {
        name: "col",
        val: r?.col,
      },
    ];

    items.forEach((item) => {
      let subPrice = getPercentageByType(item.val, item.name);

      if (subPrice > 1) {
        extraPrice = extraPrice + extraPrice * (subPrice / 100);
      }
    });

    // global discount
    if (r?.discount && parseFloat(r?.discount) > 0) {
      let disCount = parseFloat(r?.discount) / 100;
      extraPrice = extraPrice - extraPrice * disCount;
    } else {
      extraPrice = extraPrice - extraPrice * 0.1;
    }

    return Math.round(extraPrice).toFixed(2);
  };

  const getBatteryKw = (r) => {
    let bat = getBatteryCount(r);
    let batkw = 0;
    if (panelSettings && panelSettings.bat_capacity) {
      let bc = parseFloat(panelSettings.bat_capacity);
      if (bc > 0 && bat > 0) {
        batkw = batkw + bat * bc;
      }
    }
    return batkw;
  };

  const getFtvData = (r) => {
    let f4 = 0;
    let f1 = r.f1 ? parseFloat(r.f1) : 1;
    let f2 = r.f2 ? parseFloat(r.f2) : 1;
    let f3 = r.f3 ? parseFloat(r.f3) : 1;
    let total = f1 + f2 + f3;
    let subTotal = total - total * 0.2;
    f4 = subTotal / 365;
    return f4 > 0 ? f4.toFixed(3) : 0;
  };

  const getIconByType = (key, val = false) => {
    let final = "";
    switch (key) {
      case "single":
        final = <FaHome />;
        break;
      case "shed":
        final = <FaBuilding />;
        break;
      case "condominium":
        final = <BsBuildingsFill />;
        break;
      case "ground":
        final = <MdLandslide />;
        break;
      case 1:
        final = !val ? (
          <img src={"/assets/piano.png"} alt="1" />
        ) : (
          <img src={"/assets/piano_hov.png"} alt="1" />
        );
        break;
      case 2:
        final = !val ? (
          <img src={"/assets/1falda.png"} alt="1" />
        ) : (
          <img src={"/assets/1falda_hov.png"} alt="1" />
        );
        break;
      case 3:
        final = !val ? (
          <img src={"/assets/2falde.png"} alt="1" />
        ) : (
          <img src={"/assets/2falde_hov.png"} alt="1" />
        );
        break;
      case 4:
        final = !val ? (
          <img src={"/assets/2falde_b.png"} alt="1" />
        ) : (
          <img src={"/assets/2falde_b_hov.png"} alt="1" />
        );
        break;
      default:
        break;
    }
    return final;
  };

  const generatePDF = async () => {
    window.print();
  };

  return (
    <>
      <MainWrapper
        mainClass="dynamicPage"
        topBar={false}
        showFooter={isMobile ? false : true}
        showChatBtn={isMobile ? false : true}
      >
        <div className="infoWrapper quote_info_page">
          <div className="d-flex p-2 justify-content-between gap-2 align-items-center">
            <Button
              onClick={() => {
                // eslint-disable-next-line no-lone-blocks
                {
                  isMobile
                    ? navigate(paths.myQuotesPath)
                    : navigate(paths.profilePath);
                }
              }}
              variant="success"
              className=" no-print"
            >
              {getT(translationKeys.back)}
            </Button>

            <Button
              onClick={() => {
                generatePDF();
              }}
              variant="success "
              className=" no-print"
            >
              {getT(translationKeys.download)}
            </Button>
          </div>

          {!isLoading ? (
            <>
              {editData && editData.id > 0 ? (
                <>
                  <div className="estimate_panel_info">
                    <div className="user_info_section">
                      <div className="user_info_section_inner ">
                        <div className="address">
                          <div className="title">
                            {editData.name
                              ? editData.name
                              : editData.company_name}
                          </div>
                          <div className="title">{editData.email}</div>
                          <div className="title">{editData.address}</div>
                          <div className="s_title">
                            {editData &&
                              editData.id +
                                "-" +
                                formatMysqlDate(editData?.created_at)}
                          </div>
                        </div>
                        <div className="address">
                          <div className="title">Borromeo Impianti S.r.l.</div>
                          <div className="title">info@borromeoimpianti.com</div>
                          <div className="title"> Tel: +39 02 80898363</div>
                          <div className="title">Via Liguria, 34/7,</div>
                          <div className="">20068 Peschiera Borromeo MI</div>
                          <a href="https://borromeoimpianti.com">
                            borromeoimpianti
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="commonboxes">
                      <div className="commonbox">
                        <div className="commonbox_item">
                          <span className="commonbox_item_sub">
                            <FaHouseUser />
                            {getT(translationKeys.owner_label)}
                          </span>
                          <span>
                            {editData.is_owner
                              ? getT(translationKeys.yes)
                              : getT(translationKeys.no)}
                          </span>
                        </div>
                        <div className="commonbox_item">
                          <span className="commonbox_item_sub">
                            <BsBuildingsFill />
                            {getT(translationKeys.constructionType)}
                          </span>
                          <span>
                            {getT(
                              translationKeys[
                                getInfoOfListValue(
                                  homeTypes,
                                  editData.home_type
                                ).name
                              ]
                            )}
                          </span>
                        </div>
                        <div className="commonbox_item">
                          <span className="commonbox_item_sub">
                            <BsFillPersonFill />
                            {getT(translationKeys.personType)}
                          </span>
                          <span>
                            {getT(
                              translationKeys[
                                getInfoOfListValue(
                                  personTypes,
                                  editData.person_type
                                ).name
                              ]
                            )}
                          </span>
                        </div>
                        <div className="commonbox_item">
                          <span className="commonbox_item_sub">
                            <MdOutlineRoofing />
                            {getT(translationKeys.inclinationFormLabel)}
                          </span>
                          <span className="extraIcon">
                            {!editData.floor_status
                              ? getIconByType(editData.inclination_type, true)
                              : getT(translationKeys.yes)}
                          </span>
                        </div>
                        <div className="commonbox_item">
                          <span className="commonbox_item_sub">
                            <SiLevelsdotfyi />
                            {getT(translationKeys.floorslabel)}
                          </span>
                          <span>
                            {!editData.floor_count_status
                              ? getT(
                                  translationKeys[
                                    getInfoOfListValue(
                                      floorsOptions,
                                      editData.floor_count
                                    ).name
                                  ]
                                )
                              : getT(translationKeys.yes)}
                          </span>
                        </div>
                      </div>
                      <div className="commonbox">
                        <div className="commonbox_item">
                          <span className="commonbox_item_sub">
                            {getT(translationKeys.mqt)}
                          </span>
                          <span>
                            {editData.area
                              ? editData.area
                              : editData.inclination}
                          </span>
                        </div>
                        <div className="commonbox_item">
                          <span className="commonbox_item_sub">
                            {getT(translationKeys.mqi)}
                          </span>
                          <span>
                            {editData.full_area
                              ? editData.full_area
                              : editData.tilt}
                          </span>
                        </div>
                        <div className="commonbox_item">
                          <span className="commonbox_item_sub">
                            {getT(translationKeys.energy_title_label)}
                          </span>
                          <span>
                            {editData.f_status ? getT(translationKeys.yes) : ""}
                          </span>
                        </div>
                        {!editData.f_status && (
                          <>
                            <div className="commonbox_item">
                              <span className="commonbox_item_sub">
                                {getT(translationKeys.f1)}
                              </span>
                              <span>{editData.f1}</span>
                            </div>
                            <div className="commonbox_item">
                              <span className="commonbox_item_sub">
                                {getT(translationKeys.f2)}
                              </span>
                              <span>{editData.f2}</span>
                            </div>
                            <div className="commonbox_item">
                              <span className="commonbox_item_sub">
                                {getT(translationKeys.f3)}
                              </span>
                              <span>{editData.f3}</span>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="commonbox">
                        <div className="commonbox_item">
                          <span className="commonbox_item_sub">
                            <FaCar />
                            {getT(
                              translationKeys.selection_electric_placeholder
                            )}
                          </span>
                          <span>
                            {getT(
                              translationKeys[
                                getInfoOfListValue(
                                  electric_car_Options,
                                  editData.electric_car
                                ).name
                              ]
                            )}
                          </span>
                        </div>
                        <div className="commonbox_item">
                          <span className="commonbox_item_sub">
                            <PiThermometerColdBold />
                            {getT(translationKeys.selection_cold_placeholder)}
                          </span>
                          <span>
                            {getT(
                              translationKeys[
                                getInfoOfListValue(
                                  inductionOptions,
                                  editData.induction_hub
                                ).name
                              ]
                            )}
                          </span>
                        </div>
                        <div className="commonbox_item">
                          <span className="commonbox_item_sub">
                            <MdSoupKitchen />
                            {getT(
                              translationKeys.selection_induction_placeholder
                            )}
                          </span>
                          <span>
                            {getT(
                              translationKeys[
                                getInfoOfListValue(
                                  airConditionerOptions,
                                  editData.air_conditioners_pump
                                ).name
                              ]
                            )}
                          </span>
                        </div>
                        <div className="commonbox_item">
                          <span className="commonbox_item_sub">
                            <LiaSnowflakeSolid />
                            {getT(translationKeys.selectionLabel_riscaldamento)}
                          </span>
                          <span>
                            {getT(
                              translationKeys[
                                getInfoOfListValue(
                                  riscaldamentoOptions,
                                  editData.heat_pump
                                ).name
                              ]
                            )}
                          </span>
                        </div>
                        <div className="commonbox_item">
                          <span className="commonbox_item_sub">
                            <FaPeopleRoof />
                            {getT(translationKeys.selection_people_placeholder)}
                          </span>
                          <span>
                            {getT(
                              translationKeys[
                                getInfoOfListValue(
                                  riscaldamentoOptions,
                                  editData.people_count
                                ).name
                              ]
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="commonbox">
                        <div className="commonbox_item">
                          <span className="commonbox_item_sub">
                            {getT(translationKeys.ftv)}
                          </span>
                          <span>{getFtvData(editData) + " KW"}</span>
                        </div>

                        <div className="commonbox_item">
                          <span className="commonbox_item_sub">
                            {getT(translationKeys.bat)}
                          </span>
                          <span>{getBatteryKw(editData) + " KW"}</span>
                        </div>
                        <div className="commonbox_item">
                          <span className="commonbox_item_sub">
                            {getT(translationKeys.discount)}
                          </span>
                          <span>
                            {editData?.discount &&
                            parseFloat(editData.discount) > 0
                              ? "-" + parseFloat(editData.discount) + "%"
                              : "-10%"}
                          </span>
                        </div>
                        <div className="commonbox_item">
                          <span className="commonbox_item_sub">
                            {getT(translationKeys.finalPrice)}
                          </span>
                          <span>{"€" + getFinalPrice(editData)}</span>
                        </div>
                      </div>
                    </div>
                    <div class="page-break"></div>
                    <div className="widgets_container">
                      <div className="widget_item m-2">
                        <QuoteStatusWidget editData={editData} />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div>{getT(translationKeys.no_data_label)}</div>
              )}
            </>
          ) : (
            <p>{getT(translationKeys.pleaseWait)}</p>
          )}
        </div>
        {isMobile && <Navigation />}
      </MainWrapper>
    </>
  );
}
