import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";
import {
  useCreateEventMutation,
  useEventQuery,
  useUpdateEventMutation,
} from "../../queries/events";
import { useUserQuery } from "../../queries/users";
import { useQuoteQuery } from "../../queries/quotes";
import {
  CUSTOMERROLE,
  OPERATORROLE,
  eventStatusList,
  formatMysqlDate,
  getT,
  translationKeys,
} from "../utils";
import { useAuth } from "../../authContext";
import Select from "react-select";
import CustomSelect from "./utils/CustomSelect";
import CustomInput from "./utils/CustomInput";
import CustomTextArea from "./utils/CustomTextArea";
import moment from "moment";

const AppointmentUpdateForm = ({
  onEdit = false,
  editId = null,
  onClose = () => {},
  givenDate = null,
}) => {
  const [submittable, setSubmittable] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [quotesData, setQuotesData] = useState([]);
  const [editData, setEditData] = useState();
  const { authUserId, userRoleName, customerInfo } = useAuth();
  const formattedDefaultDate = moment(givenDate).format("YYYY-MM-DDTHH:mm");

  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useEventQuery(
    editId,
    editId ? true : false
  );

  const {
    data: userData,
    isIdle: userIdle,
    isLoading: userLoading,
    isError: userError,
  } = useUserQuery();

  const {
    data: qData,
    isIdle: qIdle,
    isLoading: qLoading,
    isError: qError,
  } = useQuoteQuery();

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdateEventMutation)
    : (mutationHook = useCreateEventMutation);
  const mutation = mutationHook();

  const newSchema = {
    start: Yup.string().required(getT(translationKeys.startDateRequired)),
    end: Yup.string().required(getT(translationKeys.endDateRequired)),
  };

  const editSchema = {
    notes: Yup.string().required(getT(translationKeys.notes)),
  };

  const formSchema = Yup.object().shape(onEdit ? editSchema : newSchema);

  useEffect(() => {
    if (data && !isLoading) {
      setEditData(data?.event);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (userData && !userLoading && userData.users) {
      setUsersData(userData?.users);
    }
  }, [userLoading, userData]);

  useEffect(() => {
    if (qData && !qLoading && qData.quotes) {
      setQuotesData(qData?.quotes);
    }
  }, [qLoading, qData]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.event &&
        mutation.data.data.event.id > 0
      ) {
        onEdit
          ? toast.success(getT(translationKeys.updated))
          : toast.success(getT(translationKeys.created));
        onClose();
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error(getT(translationKeys.generalError));
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const finalData = {
      start_date: values.start,
      end_date: values.end,
      customer_notes: values.notes,
      user_id: customerInfo?.id,
    };

    toast.warning(getT(translationKeys.pleaseWait));

    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  if (isLoading || userLoading || userIdle || qLoading || qIdle) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">{getT(translationKeys.generalError)}</div>;
  }

  return (
    <Container fluid className="EventForm">
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              start: editData?.start_date
                ? editData.start_date
                : formattedDefaultDate,
              end: editData?.end_date,
              notes: editData?.customer_notes,
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Row>
                  <Col sm={12} md={6}>
                    <CustomInput
                      name="start"
                      label={getT(translationKeys.startDate)}
                      type="datetime-local"
                      defaultValue={values.start}
                      placeholder=""
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <CustomInput
                      name="end"
                      label={getT(translationKeys.endDate)}
                      type="datetime-local"
                      defaultValue={values.end}
                      placeholder=""
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={12}>
                    <CustomTextArea
                      name="notes"
                      label={getT(translationKeys.notes)}
                      defaultValue={values.notes}
                      placeholder=""
                    />
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      {getT(translationKeys.submit)}
                    </button>
                    {/* <button
                      type="button"
                      onClick={() => handleClear(formik)}
                      className="btn btn-secondary"
                    >
                      cancel
                        </button> */}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default AppointmentUpdateForm;
