import React from "react";
import ProductForm from "../forms/ProductForm";

export default function NewProduct() {
  return (
    <>
      <ProductForm />
    </>
  );
}
