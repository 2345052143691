import React from "react";
import PageForm from "../forms/PageForm";

export default function NewPage() {
  return (
    <>
      <PageForm />
    </>
  );
}
