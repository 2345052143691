import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths, { apiUrl } from "../../config/paths";
import Loading from "../../components/Loading";
import {
  airConditionerOptions,
  electric_car_Options,
  getT,
  inductionOptions,
  translationKeys,
} from "../utils";
import CustomInput from "./utils/CustomInput";
import { Button, ProgressBar } from "react-bootstrap";
import { BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";
import {
  floorsOptions,
  getCustomerToken,
  homeTypes,
  inclinationTypes,
  personTypes,
  riscaldamentoOptions,
  updateQuoteData,
} from "../../utils";
import { MdLandslide, MdOutlineLandslide } from "react-icons/md";

import { FaBuilding, FaHome } from "react-icons/fa";
import { BsBuildingsFill, BsFillPersonFill } from "react-icons/bs";
import CustomSelect2 from "./utils/CustomSelect2";
import axios from "axios";
import { useAuth } from "../../authContext";

const MobileForm = ({
  onEdit = false,
  editId = null,
  paramId = null,
  editData = {},
  onBack = () => {},
  address = {},
  refCode = "",
  loc = {},
}) => {
  const [submittable, setSubmittable] = useState(false);
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const { customerInfo, toggleLoginModal } = useAuth();
  const formikRef = useRef(null);

  const newSchema = {
    address: Yup.string().required(getT(translationKeys.addressRequired)),
    electric_car: Yup.string().required(
      getT(translationKeys.electric_car_required)
    ),
    induction_hub: Yup.string().required(
      getT(translationKeys.induction_hub_required)
    ),
    air_conditioners_pump: Yup.string().required(
      getT(translationKeys.air_conditioners_pump_required)
    ),
    heat_pump: Yup.string().required(getT(translationKeys.heat_pump_required)),
    people_count: Yup.string().required(
      getT(translationKeys.people_count_required)
    ),
  };

  const formSchema = Yup.object().shape(newSchema);

  useEffect(() => {
    if (customerInfo?.email) {
      if (formikRef && formikRef.current) {
        formikRef.current.setFieldValue("email", customerInfo?.email);
        formikRef.current.setFieldValue("user_id", customerInfo?.id);
      }
    }
  }, [customerInfo]);

  const handleSubmit = async (values, { setSubmitting }) => {
    let finalValues = {
      //0
      address: values.address,
      cap: values.cap,
      country: values.country,
      city: values.city,
      province: values.prov,
      //1
      person_type: values.person_type,
      company_name: values.company_name,
      //2
      home_type: values.home_type,
      is_owner: values.is_owner,
      //3
      floor_status: values.inclination_status,
      inclination_type: values.inclination_type,
      floor_count_status: values?.floor_count_status,
      floor_count: values?.floor_count,

      //4
      area: values?.area,
      full_area: values?.full_area,

      //5
      f1: values?.f1,
      f2: values?.f2,
      f3: values?.f3,
      f_status: values.fidontknow,
      //6
      electric_car: values?.electric_car,
      induction_hub: values?.induction_hub,
      air_conditioners_pump: values?.air_conditioners_pump,
      heat_pump: values?.heat_pump,
      people_count: values?.people_count,

      //extra
      status: 2,
      col: "",
      //old columns
      //energy_price: values?.energy_price,
      //energy_increase: values?.energy_increase,
      email: values.email,
      mobile: values.phone,
      name: values.name,
      sanitary_water: values.sanitary_water,
      is_air_conditioners_pump: values.is_air_conditioners_pump,
      user_id: values.user_id,
    };

    if (!paramId) {
      finalValues["ref_code"] = refCode;
    }

    let upateId = editId ? editId : localStorage.getItem("quoteId");
    const customerToken = getCustomerToken();
    if (!customerToken) {
      toggleLoginModal(true);
      //toast.error(getT(translationKeys.loginError));
      return;
    }

    if (upateId > 0) {
      let url = "/api/cusQuote/" + upateId;
      if (!paramId) {
        url = "/api/cusQuote/" + upateId + "/complete";
      }
      try {
        // Make Axios POST request to save data
        const response = await axios.put(apiUrl + url, finalValues);
        if (
          response.status === 200 &&
          response.data &&
          response.data.quote &&
          response.data.quote.id > 0
        ) {
          if (!paramId) {
            localStorage.removeItem("quoteId");
            toast.success(getT(translationKeys.request_sent_success_label), {
              position: "top-right",
            });
            navigate(paths.homePath);
            window.location.reload();
          } else if (paramId && step === 6) {
            toast.success(getT(translationKeys.request_update_success_label), {
              position: "top-right",
            });
          }
        } else {
          //console.log(response.status, response);
          toast.error(getT(translationKeys.generalError), {
            position: "top-right",
          });
        }
        setSubmitting(false);
      } catch (error) {
        console.error("Error saving data:", error);
        setSubmitting(false);
      }
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    navigate(paths.categoriesPath);
  };

  const getIconByType = (key, val = false) => {
    let final = "";
    switch (key) {
      case "single":
        final = <FaHome />;
        break;
      case "shed":
        final = <FaBuilding />;
        break;
      case "condominium":
        final = <BsBuildingsFill />;
        break;
      case "ground":
        final = <MdLandslide />;
        break;
      case 1:
        final = !val ? (
          <img src={"/assets/piano.png"} alt="1" />
        ) : (
          <img src={"/assets/piano_hov.png"} alt="1" />
        );
        break;
      case 2:
        final = !val ? (
          <img src={"/assets/1falda.png"} alt="1" />
        ) : (
          <img src={"/assets/1falda_hov.png"} alt="1" />
        );
        break;
      case 3:
        final = !val ? (
          <img src={"/assets/2falde.png"} alt="1" />
        ) : (
          <img src={"/assets/2falde_hov.png"} alt="1" />
        );
        break;
      case 4:
        final = !val ? (
          <img src={"/assets/2falde_b.png"} alt="1" />
        ) : (
          <img src={"/assets/2falde_b_hov.png"} alt="1" />
        );
        break;
      default:
        break;
    }
    return final;
  };

  const onNext = () => {
    if (step > -1 && step <= 5) {
      setStep((prev) => prev + 1);
    } else {
      setStep(6);
    }
  };

  const onPrev = () => {
    if (step === 1) {
      onBack();
    } else {
      setStep((prev) => prev - 1);
    }
  };

  const onUpdateValues = async (values, payload) => {
    toast.loading(getT(translationKeys.pleaseWait));
    let res1 = await updateQuoteData(payload, editId);
    if (res1.status) {
      toast.dismiss();
      onNext();
    } else {
      console.log("update failed");
    }
  };

  const getPercent = () => {
    return (step / 6) * 100;
  };

  return (
    <div className="mobile_form_wrapper">
      <div className="mobile_form_pre_box"></div>
      <div className="mobile_form_wrapper_inner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              //step0
              address: address?.address ?? "",
              lat: loc?.lat ?? "",
              lng: loc?.lng ?? "",
              city: address?.city ?? "",
              province: address.province
                ? address.province
                : address.country_state_code
                ? address.country_state_code
                : "",
              prov: address.province
                ? address.province
                : address.country_state_code
                ? address.country_state_code
                : "",
              cap: address.cap ? address.cap : address.zip ? address.zip : "",
              country: address?.country ?? "",
              //step1
              person_type: editData?.person_type ?? "",
              company_name: editData?.company_name ?? "",
              //step2
              is_owner: editData?.is_owner && editData?.is_owner ? true : false,
              home_type: editData?.home_type ? editData.home_type : "",
              //step3
              inclination_type: editData?.inclination_type ?? "",
              inclination_status: editData?.floor_status ? true : false,
              floor_count_status:
                editData?.floor_count_status && editData?.floor_count_status
                  ? true
                  : false,
              floor_count: editData?.floor_count ?? "",
              //step4
              area: editData?.area
                ? editData.area
                : editData?.inclination
                ? editData?.inclination
                : "",
              full_area: editData?.full_area
                ? editData.full_area
                : editData?.tilt
                ? editData?.tilt
                : "",
              //step5
              f1: editData?.f1 ?? "",
              f2: editData?.f2 ?? "",
              f3: editData?.f3 ?? "",
              fidontknow:
                editData?.f_status && editData?.f_status ? true : false,
              //step6
              electric_car: editData?.electric_car ?? "",
              induction_hub: editData?.induction_hub ?? "",
              air_conditioners_pump: editData?.air_conditioners_pump ?? "",
              heat_pump: editData?.heat_pump ?? "",
              people_count: editData?.people_count ?? "",
              user_id:
                customerInfo?.id && customerInfo.id > 0
                  ? customerInfo?.id
                  : null,
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
            innerRef={formikRef}
          >
            {({
              values,
              setFieldValue,
              formik,
              handleChange,
              isSubmitting,
            }) => (
              <Form>
                <div className="progressBar">
                  <ProgressBar variant="success" now={getPercent()} />
                </div>
                {step === 0 && (
                  <div className="mobile_form_step">
                    <div className="mobile_form_step_inner">
                      {/* <div className="b-title">
                        {getT(translationKeys.locationBigTitle)} <br />{" "}
                        <span>{getT(translationKeys.locationBigTitle2)}</span>
                      </div> */}
                      <div className="s-title">
                        {getT(translationKeys.locationSmallTitle)}
                      </div>
                      <CustomInput
                        defaultValue={values.address}
                        name="address"
                        placeholder={getT(translationKeys.address)}
                        isIcon={true}
                        labelStatus={false}
                        fieldType="auto"
                        onAutoFieldSelected={(data) => {
                          console.log(data);
                          //setStep(1);
                          setFieldValue("address", data.address);
                        }}
                      />
                    </div>
                  </div>
                )}
                {step === 1 && (
                  <div className="mobile_form_step d-flex flex-column">
                    <div className="mobile_form_step_inner">
                      <div className="b-title">
                        {getT(translationKeys.personType)}
                      </div>
                      <div className="home-types">
                        {personTypes &&
                          personTypes.map((h) => {
                            return (
                              <div
                                onClick={() => {
                                  if (h.value === values.person_type) {
                                    setFieldValue("person_type", "");
                                  } else {
                                    setFieldValue("person_type", h.value);
                                  }
                                }}
                                className={
                                  values.person_type === h.value
                                    ? "home-type active"
                                    : "home-type "
                                }
                              >
                                {h.value === "company" ? (
                                  <BsBuildingsFill className="me-1" />
                                ) : (
                                  <BsFillPersonFill className="me-1" />
                                )}
                                <span>{getT(translationKeys[h.name])}</span>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="mobile_form_step_inner mt-1">
                      {values.person_type === "company" && (
                        <CustomInput
                          onEdit={false}
                          defaultValue={values.company_name}
                          name="company_name"
                          labelStatus={false}
                          placeholder={getT(translationKeys.company_name)}
                          label={getT(translationKeys.company_name)}
                        />
                      )}
                    </div>
                  </div>
                )}
                {step === 2 && (
                  <div className="mobile_form_step d-flex flex-column">
                    <div className="mobile_form_step_inner ">
                      <div className="b-title">
                        {getT(translationKeys.constructionType)}
                      </div>
                      <div className="home-types">
                        {homeTypes &&
                          homeTypes.map((h) => {
                            return (
                              <div
                                onClick={() => {
                                  if (h.value === values.home_type) {
                                    setFieldValue("home_type", "");
                                  } else {
                                    setFieldValue("home_type", h.value);
                                  }
                                }}
                                className={
                                  values.home_type === h.value
                                    ? "home-type active"
                                    : "home-type "
                                }
                              >
                                {getIconByType(h.value)}
                                <span className="mx-1">
                                  {getT(translationKeys[h.name])}
                                </span>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="mobile_form_step_inner">
                      <div className="d-flex checkBox">
                        <Field
                          type="checkbox"
                          className="form-check-input me-2"
                          name="is_owner"
                        />
                        <label>{getT(translationKeys.is_owner)}</label>
                      </div>
                    </div>
                  </div>
                )}
                {step === 3 && (
                  <div className="mobile_form_step d-flex flex-column">
                    <div className="mobile_form_step_inner">
                      <div className=" b-title">
                        {getT(translationKeys.inclinationFormLabel)}
                      </div>
                      <div
                        className={
                          values.inclination_status
                            ? "home-roof-types disabled"
                            : "home-roof-types"
                        }
                      >
                        {inclinationTypes.map((i) => {
                          return (
                            <div
                              onClick={() => {
                                if (!values.inclination_status) {
                                  if (i.value === values.inclination_type) {
                                    setFieldValue("inclination_type", "");
                                  } else {
                                    setFieldValue("inclination_type", i.value);
                                  }
                                }
                              }}
                              className={
                                values.inclination_type === i.value
                                  ? "home-roof-type active"
                                  : "home-roof-type "
                              }
                            >
                              {getIconByType(
                                i.value,
                                values.inclination_type === i.value
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div className="d-flex  checkBox mb-2">
                        <Field
                          type="checkbox"
                          id="inclination_status"
                          className="form-check-input me-2"
                          name="inclination_status"
                          onChange={(event) => {
                            handleChange(event);
                            if (event.target.value) {
                              setFieldValue("inclination_type", "");
                            }
                          }}
                        />
                        <label className="me-2">
                          {getT(translationKeys.idontknow)}
                        </label>
                      </div>
                    </div>
                    <div className="mobile_form_step_inner mt-1">
                      <CustomSelect2
                        label={getT(translationKeys.floorslabel)}
                        name="floor_count"
                        options={floorsOptions}
                        defaultValue={values.floor_count}
                        defaultSelectVal={getT(translationKeys.selectionLabel)}
                        boxVisible={true}
                        boxIcon={"stairs"}
                        disabled={values.floor_count_status ? true : false}
                      />
                      <div className="d-flex  checkBox">
                        <Field
                          type="checkbox"
                          id="floor_count_status"
                          className="form-check-input me-2"
                          name="floor_count_status"
                          onChange={(event) => {
                            handleChange(event);
                            if (event.target.value) {
                              setFieldValue("floor_count", "");
                            }
                          }}
                        />
                        <label className="me-2">
                          {getT(translationKeys.idontknowTerraLabel)}
                        </label>
                      </div>
                    </div>
                  </div>
                )}
                {step === 4 && (
                  <div className="mobile_form_step d-flex flex-column">
                    <div className="mobile_form_step_inner">
                      <div className="b-title">
                        {getT(translationKeys.selection_roof_label)}
                      </div>
                      <p className="m-title">
                        {getT(translationKeys.selection_roof_sub_label)}
                      </p>
                      <div className="meters">
                        <div className="mqt meter">
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.area}
                            name="area"
                            type="number"
                            classList="form-control placeholder_center placeholder_light"
                            label={getT(translationKeys.mqt)}
                            placeholder="0"
                          />
                        </div>
                        <div className="mqi meter">
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.full_area}
                            classList="form-control placeholder_center placeholder_light"
                            name="full_area"
                            minNumber={0}
                            type="number"
                            label={getT(translationKeys.mqi)}
                            placeholder="0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {step === 5 && (
                  <div className="mobile_form_step d-flex flex-column">
                    <div className="mobile_form_step_inner ">
                      <div className="b-title  step2">
                        {getT(translationKeys.energy_title_label)}
                      </div>
                      <p>{getT(translationKeys.energy_sub_label)}</p>
                      <div className="fvalues">
                        <CustomInput
                          onEdit={false}
                          placeholder={"F1"}
                          defaultValue={values.f1}
                          name="f1"
                          type="number"
                          min={0}
                          disabled={values.fidontknow ? true : false}
                          classList="form-control placeholder_center placeholder_light fval"
                          labelStatus={false}
                        />
                        <CustomInput
                          onEdit={false}
                          placeholder={"F2"}
                          defaultValue={values.f2}
                          name="f2"
                          min={0}
                          type="number"
                          disabled={values.fidontknow ? true : false}
                          classList="form-control placeholder_center placeholder_light fval"
                          labelStatus={false}
                        />
                        <CustomInput
                          onEdit={false}
                          placeholder={"F3"}
                          defaultValue={values.f3}
                          name="f3"
                          type="number"
                          min={0}
                          disabled={values.fidontknow ? true : false}
                          classList="form-control placeholder_center placeholder_light fval"
                          labelStatus={false}
                        />
                      </div>
                    </div>
                    <div className="mobile_form_step_inner">
                      <div className="d-flex checkBox">
                        <Field
                          type="checkbox"
                          className="form-check-input me-2"
                          name="fidontknow"
                          checked={values.fidontknow}
                          onChange={(event) => {
                            handleChange(event);
                            if (event.target.value) {
                              setFieldValue("f1", "");
                              setFieldValue("f2", "");
                              setFieldValue("f3", "");
                            }
                          }}
                        />
                        <label className="me-2">
                          {getT(translationKeys.fidontknow)}
                        </label>
                      </div>
                      {values.fidontknow && (
                        <>
                          <div className="energyInfo">
                            <div className="energyInfoTop">
                              <p>{getT(translationKeys.energy_note_label)}</p>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
                {step === 6 && (
                  <div className="mobile_form_step d-flex flex-column">
                    <div className="mobile_form_step_inner step5">
                      <div className="b-title">
                        {getT(translationKeys.profile_consumption_title)}
                      </div>
                      <CustomSelect2
                        label={getT(translationKeys.electricCarLabel)}
                        name="electric_car"
                        options={electric_car_Options}
                        defaultValue={values.electric_car}
                        defaultSelectVal={getT(
                          translationKeys.selection_electric_placeholder
                        )}
                        labelStatus={false}
                        boxVisible={true}
                        boxIcon={"car"}
                      />
                      <CustomSelect2
                        label={getT(translationKeys.kichenLabel)}
                        name="induction_hub"
                        options={inductionOptions}
                        defaultValue={values.induction_hub}
                        labelStatus={false}
                        defaultSelectVal={getT(
                          translationKeys.selection_induction_placeholder
                        )}
                        boxVisible={true}
                        boxIcon={"kichen"}
                      />
                      <CustomSelect2
                        label={getT(translationKeys.acLabel2)}
                        name="air_conditioners_pump"
                        options={airConditionerOptions}
                        defaultValue={values.air_conditioners_pump}
                        defaultSelectVal={getT(
                          translationKeys.selection_cold_placeholder
                        )}
                        labelStatus={false}
                        boxVisible={true}
                        boxIcon={"cold"}
                      />
                      <CustomSelect2
                        label={getT(translationKeys.personsLabel)}
                        name="heat_pump"
                        labelStatus={false}
                        options={riscaldamentoOptions}
                        defaultValue={values.air_conditioners_pump}
                        defaultSelectVal={getT(
                          translationKeys.selectionLabel_riscaldamento
                        )}
                        boxVisible={true}
                        boxIcon={"flakes"}
                      />
                      <CustomSelect2
                        label={getT(translationKeys.personsLabel)}
                        name="people_count"
                        options={riscaldamentoOptions}
                        labelStatus={false}
                        defaultValue={values.people_count}
                        defaultSelectVal={getT(
                          translationKeys.selection_people_placeholder
                        )}
                        boxIcon={"people"}
                        boxVisible={true}
                      />
                    </div>
                  </div>
                )}
                {/* step === 7 && (
                  <div className="mobile_form_step d-flex flex-column">
                    <div className=" b-title">
                      {getT(translationKeys.quote_holder_name)}
                    </div>
                    {values.person_type === "company" && (
                      <div className="mobile_form_step_inner">
                        <div className="mb-2">
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.company_name}
                            name="company_name"
                            labelStatus={false}
                            placeholder={getT(translationKeys.company_name)}
                            label={getT(translationKeys.company_name)}
                          />
                        </div>
                      </div>
                    )}
                    <div className="mobile_form_step_inner">
                      <CustomInput
                        onEdit={false}
                        defaultValue={values.address}
                        name="address"
                        placeholder={getT(translationKeys.address)}
                        labelStatus={false}
                        fieldType="auto"
                        isIcon={true}
                      />
                      <CustomInput
                        onEdit={false}
                        defaultValue={values.city}
                        name="city"
                        placeholder={getT(translationKeys.comune)}
                        labelStatus={false}
                      />
                      <CustomInput
                        onEdit={false}
                        defaultValue={values.prov}
                        name="prov"
                        placeholder={getT(translationKeys.province)}
                        labelStatus={false}
                      />
                    </div>
                  </div>
                ) */}
                <div className={"action_btns " + (step === 6 ? "last" : "")}>
                  <Button
                    disabled={step === 0}
                    variant={"primary"}
                    className={step <= 5 ? "last" : ""}
                    type="button"
                    onClick={() => {
                      onPrev();
                    }}
                  >
                    <BsChevronDoubleLeft />
                    {step <= 5 && <span>{getT(translationKeys.previous)}</span>}
                  </Button>

                  {step <= 5 && (
                    <Button
                      variant={"primary"}
                      type="button"
                      onClick={async () => {
                        // step0
                        if (
                          !values.address ||
                          !values.lat ||
                          !values.lng ||
                          !values.city ||
                          !values.prov
                        ) {
                          console.log(values, "initial values");
                          toast.error(getT(translationKeys.addressRequired));
                          return;
                        }

                        //step1
                        if (step === 1) {
                          if (!values.person_type) {
                            toast.error(
                              getT(translationKeys.person_type_required)
                            );
                            return;
                          }
                          if (
                            values.person_type === "company" &&
                            !values.company_name
                          ) {
                            toast.error(
                              getT(translationKeys.company_name_required)
                            );
                            return;
                          }
                          // update values
                          onUpdateValues(values, {
                            person_type: values.person_type,
                            company_name: values.company_name,
                            lat: values.lat,
                            lng: values.lng,
                          });
                        }

                        //step2
                        if (step === 2) {
                          if (!values.home_type) {
                            toast.error(
                              getT(translationKeys.home_type_required)
                            );
                            return;
                          }
                          // update values
                          onUpdateValues(values, {
                            is_owner: values.is_owner,
                            home_type: values.home_type,
                          });
                        }
                        //step3
                        if (step === 3) {
                          // inclination
                          if (
                            !values.inclination_status &&
                            values.inclination_type === ""
                          ) {
                            toast.error(
                              getT(translationKeys.fillInclinationlabel)
                            );
                            return;
                          }

                          if (
                            !values.floor_count_status &&
                            values.floor_count == ""
                          ) {
                            toast.error(
                              getT(translationKeys.fillPianifouriterralabel)
                            );
                            return;
                          }

                          if (
                            !values.floor_count == "" &&
                            values.floor_count_status
                          ) {
                            toast.error(
                              getT(translationKeys.fillPianifouriterralabel)
                            );
                            return;
                          }
                          // update values
                          onUpdateValues(values, {
                            inclination_type: values.inclination_type,
                            floor_status: values.inclination_status,
                            floor_count_status: values?.floor_count_status,
                            floor_count: values?.floor_count,
                          });
                        }

                        //step4
                        if (step === 4) {
                          if (!values.area) {
                            toast.error(getT(translationKeys.mqt_required));
                            return;
                          }
                          if (values.area <= 0) {
                            toast.error(
                              getT(translationKeys.mqt_minimum_one_required)
                            );
                            return;
                          }
                          if (!values.full_area) {
                            toast.error(getT(translationKeys.mqi_required));
                            return;
                          }
                          if (values.full_area <= 0) {
                            toast.error(
                              getT(translationKeys.mqi_minimum_one_required)
                            );
                            return;
                          }
                          // update values
                          onUpdateValues(values, {
                            area: values.area,
                            full_area: values.full_area,
                          });
                        }

                        //step5
                        if (step === 5) {
                          if (
                            (!values.f1 && !values.fidontknow) ||
                            (!values.f2 && !values.fidontknow) ||
                            (!values.f3 && !values.fidontknow)
                          ) {
                            toast.error(
                              getT(translationKeys.energy_info_required)
                            );
                            return;
                          }
                          if (
                            (values.f1 <= 0 && !values.fidontknow) ||
                            (values.f2 <= 0 && !values.fidontknow) ||
                            (values.f3 <= 0 && !values.fidontknow)
                          ) {
                            toast.error(
                              getT(
                                translationKeys.energy_info_should_be_minimum_one
                              )
                            );
                            return;
                          }
                          onUpdateValues(values, {
                            f1: values.f1,
                            f2: values.f2,
                            f3: values.f3,
                            f_status: values.fidontknow,
                          });
                        }
                      }}
                    >
                      <span>{getT(translationKeys.next)}</span>
                      <BsChevronDoubleRight />
                    </Button>
                  )}
                  {step === 6 && (
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant={"success"}
                    >
                      {!paramId
                        ? getT(translationKeys.quoteBtn)
                        : getT(translationKeys.update)}
                    </Button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default MobileForm;
