import React, { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import {
  BsArrowsCollapse,
  BsArrowsExpand,
  BsCopy,
  BsFillTrash3Fill,
  BsImage,
  BsPlusCircle,
} from "react-icons/bs";
import Swal from "sweetalert2";
import Form from "react-bootstrap/Form";
import WrapperModal from "../../components/ModalWrapper";
import { getT, translationKeys } from "../utils";
import MediaPicker from "../MediaPicker";
import CreatableSelect from "react-select/creatable";

export default function ComponentForm({
  info = {},
  index,
  cid,
  onDelete = () => {},
  handleDynamicChange = () => {},
  handleStatusChange = () => {},
  onCreateInnerItem = () => {},
  onDeleteInnerItem = () => {},
  onCloneItem = () => {},
}) {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(null);
  const [selectedFile, setSelectedFile] = useState("");
  const [updateStatus, setUpdateStatus] = useState(false);

  const getShowPlus = () => {
    return (
      info.type === "gallery" ||
      info.type === "accordion" ||
      info.type === "customform"
    );
  };

  const getComponentTitle = () => {
    return info.type === "gallery"
      ? index + 1 + "-" + info?.type + "-" + info?.content?.contentType
      : index +
          1 +
          "-" +
          info?.type +
          "-" +
          (info?.content?.gridSize === "half" ? "half" : "full");
  };

  const onImageUpate = (val) => {
    if (val !== "") {
      //console.log(editModal);
      let parts = editModal.toString().split("_");
      if (parts.length >= 2) {
        handleDynamicChange(parts[0], "image", val, parts[1]);
      } else {
        handleDynamicChange(parts[0], "image", val);
      }

      setEditModal(null);
      setUpdateStatus(true);
      setTimeout(() => {
        setUpdateStatus(false);
      }, 10);
      console.log("test", editModal);
    }
    setShowModal(!showModal);
  };

  const getTypeStatus = (type) => {
    return type === "radio" || type === "select" ? true : false;
  };

  return (
    <>
      <WrapperModal
        show={showModal}
        title={getT(translationKeys.media)}
        handleClose={() => {
          setShowModal(!showModal);
        }}
      >
        <MediaPicker onSelected={onImageUpate} sFile={selectedFile} />
      </WrapperModal>
      {!updateStatus && (
        <div className="componentListItem">
          <div className="titleInfo mb-1">
            <div className="title cursor" onClick={() => setShow(!show)}>
              {getComponentTitle()}
            </div>
            <div>
              {getShowPlus() && (
                <BsPlusCircle
                  className="cursor"
                  onClick={() => onCreateInnerItem(index)}
                />
              )}
              {!show && (
                <BsArrowsExpand
                  className="cursor mx-2"
                  onClick={() => {
                    setShow(!show);
                  }}
                />
              )}
              {show && (
                <BsArrowsCollapse
                  className="cursor mx-2"
                  onClick={() => {
                    setShow(!show);
                  }}
                />
              )}
              {cid > 1 && (
                <BsCopy
                  className="cursor"
                  onClick={() => {
                    Swal.fire({
                      title: getT("are_you_sure_clone_component_label"),
                      text: "",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: getT(translationKeys.yes),
                      cancelButtonText: getT(translationKeys.no),
                    }).then((result) => {
                      if (result.isConfirmed) {
                        onCloneItem(index, cid);
                      }
                    });
                  }}
                />
              )}
              <BsFillTrash3Fill
                className="cursor mx-2"
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure ?",
                    text: "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      onDelete(index, cid);
                    }
                  });
                }}
              />
            </div>
          </div>
          {/* common elements */}
          {show && (
            <Row>
              <Col md={12}>
                <Form.Check // prettier-ignore
                  type={"checkbox"}
                  label={`status`}
                  onChange={(e) =>
                    handleStatusChange(index, "status", !info.status)
                  }
                  defaultChecked={info.status}
                />
              </Col>
              <Col md={6}>
                <label>{getT("grid_size_title_label")}</label>
                <select
                  name="gridSize"
                  onChange={(e) =>
                    handleDynamicChange(index, "gridSize", e.target.value)
                  }
                  defaultValue={info.content.gridSize}
                  className="form-select"
                >
                  <option value={""} disabled>
                    {getT("grid_size_selection_label")}
                  </option>
                  <option value={"half"}>Half</option>
                  <option value={"full"}>Full</option>
                </select>
              </Col>
              <Col md={6}>
                <label>{getT("alignment_title_label")}</label>
                <select
                  name="contentPosition"
                  onChange={(e) =>
                    handleDynamicChange(
                      index,
                      "contentPosition",
                      e.target.value
                    )
                  }
                  defaultValue={info.content.contentPosition}
                  className="form-select"
                >
                  <option value={""} disabled>
                    {getT("alignment_selection_label")}
                  </option>
                  <option value={"left"}>left</option>
                  <option value={"center"}>center</option>
                  <option value={"right"}>right</option>
                </select>
              </Col>

              {info.type === "gallery" && (
                <Col md={12}>
                  <label>{getT("choose_gallery_type_label")}</label>
                  <select
                    name="contentType"
                    onChange={(e) =>
                      handleDynamicChange(index, "contentType", e.target.value)
                    }
                    defaultValue={info.content.contentType}
                    className="form-select"
                  >
                    <option value={""} disabled>
                      {getT("choose_gallery_selection_label")}
                    </option>
                    <option value={"slider"}>slider</option>
                    <option value={"grid"}>grid</option>
                    <option value={"line"}>line</option>
                    <option value={"gallery"}>gallery</option>
                  </select>
                </Col>
              )}
            </Row>
          )}
          {/* end of common elements */}
          {info.type === "imagewithtext" && show && (
            <Row>
              <Col md={12}>
                <label>
                  <span> {getT("image_source_label")}</span>
                  <span>
                    <BsImage
                      onClick={() => {
                        setSelectedFile(info.content.image);
                        setShowModal(!showModal);
                        setEditModal(index);
                      }}
                      fontSize={25}
                      className="p-1 cursor"
                    />
                  </span>
                </label>
                <input
                  type="text"
                  onChange={(e) =>
                    handleDynamicChange(index, "image", e.target.value)
                  }
                  defaultValue={info.content.image}
                  placeholder="image path"
                  className="form-control my-2"
                  name="image"
                />
              </Col>
              <Col md={12}>
                <label>{getT("image_position_label")}</label>
                <select
                  onChange={(e) =>
                    handleDynamicChange(index, "imagePosition", e.target.value)
                  }
                  defaultValue={info.content.imagePosition}
                  className="form-select"
                  name="imagePosition"
                >
                  <option value={""} disabled>
                    {getT("image_position_selection_label")}
                  </option>
                  <option value={"left"}>left</option>
                  <option value={"right"}>right</option>
                </select>
              </Col>
            </Row>
          )}
          {(info.type === "header" ||
            info.type === "imagewithtext" ||
            info.type === "form") &&
            show && (
              <Row>
                <Col md={12}>
                  <label> {getT("page_component_title")} </label>
                  <input
                    type="text"
                    onChange={(e) =>
                      handleDynamicChange(index, "title", e.target.value)
                    }
                    defaultValue={info.content.title}
                    placeholder="title"
                    className="form-control my-2"
                    name="title"
                  />
                </Col>

                <Col>
                  <label>{getT("page_component_description")} </label>
                  <textarea
                    onChange={(e) =>
                      handleDynamicChange(index, "description", e.target.value)
                    }
                    defaultValue={info.content.description}
                    className="form-control"
                    cols="30"
                    placeholder="description"
                    rows="10"
                    name="description"
                  ></textarea>
                </Col>
              </Row>
            )}

          {info.type === "gallery" && show && (
            <>
              {info.content.items && info.content.items.length > 0 && (
                <div className="galleryList">
                  {info.content.items &&
                    info.content.items.map((i, innerIndex) => {
                      return (
                        <>
                          <div className="galleryListItem">
                            <div className="header">
                              <span>{innerIndex + 1 + ".galleryItem"}</span>
                              <BsFillTrash3Fill
                                className="cursor mx-2"
                                onClick={() => {
                                  Swal.fire({
                                    title: getT(
                                      translationKeys.are_you_sure_label
                                    ),
                                    text: "",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: getT(
                                      translationKeys.yes
                                    ),
                                    cancelButtonText: getT(translationKeys.no),
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      onDeleteInnerItem(index, innerIndex);
                                    }
                                  });
                                }}
                              />
                            </div>
                            <label>
                              <span>{getT("image_source_title")}</span>
                              <span>
                                <BsImage
                                  onClick={() => {
                                    setSelectedFile(
                                      info.content.items[innerIndex].image
                                    );
                                    setShowModal(!showModal);
                                    setEditModal(index + "_" + innerIndex);
                                  }}
                                  fontSize={25}
                                  className="p-1 cursor"
                                />
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) =>
                                handleDynamicChange(
                                  index,
                                  "image",
                                  e.target.value,
                                  innerIndex
                                )
                              }
                              defaultValue={
                                info.content.items[innerIndex].image
                              }
                              name={"innerItem" + index + "_" + innerIndex}
                            />
                            <label>{getT("page_component_extra_title")}</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) =>
                                handleDynamicChange(
                                  index,
                                  "title",
                                  e.target.value,
                                  innerIndex
                                )
                              }
                              defaultValue={
                                info.content.items[innerIndex].title
                              }
                              name={"innerItem" + index + "_" + innerIndex}
                            />
                          </div>
                        </>
                      );
                    })}
                </div>
              )}
            </>
          )}

          {info.type === "accordion" && show && (
            <>
              {info.content.items && info.content.items.length > 0 && (
                <div className="accordionList">
                  {info.content.items &&
                    info.content.items.map((i, innerIndex) => {
                      return (
                        <>
                          <div className="accordionListItem">
                            <div className="header">
                              <span>{innerIndex + 1 + ".accordionItem"}</span>
                              <BsFillTrash3Fill
                                className="cursor mx-2"
                                onClick={() => {
                                  Swal.fire({
                                    title: getT(
                                      translationKeys.are_you_sure_label
                                    ),
                                    text: "",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: getT(
                                      translationKeys.yes
                                    ),
                                    cancelButtonText: getT(translationKeys.no),
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      onDeleteInnerItem(index, innerIndex);
                                    }
                                  });
                                }}
                              />
                            </div>

                            <label>{getT("page_component_title")}</label>

                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) =>
                                handleDynamicChange(
                                  index,
                                  "title",
                                  e.target.value,
                                  innerIndex
                                )
                              }
                              defaultValue={
                                info.content.items[innerIndex].title
                              }
                              name={"innerItem" + index + "_" + innerIndex}
                            />
                            <label>{getT("page_component_description")}</label>

                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) =>
                                handleDynamicChange(
                                  index,
                                  "des",
                                  e.target.value,
                                  innerIndex
                                )
                              }
                              defaultValue={info.content.items[innerIndex].des}
                              name={"innerItem" + index + "_" + innerIndex}
                            />
                          </div>
                        </>
                      );
                    })}
                </div>
              )}
            </>
          )}

          {info.type === "form" && show && (
            <Row>
              <Col md={12}>
                <label>{getT("page_component_form_type_label")}</label>
                <select
                  onChange={(e) =>
                    handleDynamicChange(index, "type", e.target.value)
                  }
                  defaultValue={info.content.type}
                  className="form-select"
                  name="type"
                >
                  <option value={""}>
                    {getT("page_component_form_selection_label")}
                  </option>
                  <option value={"map"}>map</option>
                  <option value={"mapwithform"}>map with form</option>
                  <option value={"form"}>form</option>
                </select>
              </Col>
            </Row>
          )}

          {info.type === "customform" && show && (
            <>
              {info.content.items && info.content.items.length > 0 && (
                <div className="galleryList">
                  {info.content.items &&
                    info.content.items.map((i, innerIndex) => {
                      return (
                        <>
                          <div className="galleryListItem">
                            <div className="header">
                              <span>{innerIndex + 1 + ".FormElement"}</span>
                              <BsFillTrash3Fill
                                className="cursor mx-2"
                                onClick={() => {
                                  Swal.fire({
                                    title: getT(
                                      translationKeys.are_you_sure_label
                                    ),
                                    text: "",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: getT(
                                      translationKeys.yes
                                    ),
                                    cancelButtonText: getT(translationKeys.no),
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      onDeleteInnerItem(index, innerIndex);
                                    }
                                  });
                                }}
                              />
                            </div>
                            <div>
                              <Form.Check
                                type={"checkbox"}
                                label={"is required"}
                                name={"innerItem" + index + "_" + innerIndex}
                                onChange={(e) =>
                                  handleDynamicChange(
                                    index,
                                    "important",
                                    !info.content.items[innerIndex].important,
                                    innerIndex
                                  )
                                }
                                defaultChecked={
                                  info.content.items[innerIndex]?.important
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            {info.content.items[innerIndex].important && (
                              <div>
                                <label>
                                  {getT("page_component_form_message_label")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    handleDynamicChange(
                                      index,
                                      "message",
                                      e.target.value,
                                      innerIndex
                                    )
                                  }
                                  defaultValue={
                                    info.content.items[innerIndex].message
                                  }
                                  name={"innerItem" + index + "_" + innerIndex}
                                />
                              </div>
                            )}
                            <div className="type">
                              <label>
                                {" "}
                                {getT("page_component_form_type_label")}
                              </label>
                              <select
                                name={"innerItem" + index + "_" + innerIndex}
                                onChange={(e) =>
                                  handleDynamicChange(
                                    index,
                                    "input_type",
                                    e.target.value,
                                    innerIndex
                                  )
                                }
                                defaultValue={
                                  info.content.items[innerIndex].input_type
                                }
                                className="form-select"
                              >
                                <option value={""}>
                                  {getT(
                                    "page_component_form_type_selection_label"
                                  )}
                                </option>
                                <option value={"text_input"}>text input</option>
                                <option value={"checkbox"}>checkbox</option>
                                <option value={"radio"}>radio</option>
                                <option value={"text_area"}>text area</option>
                                <option value={"date"}>date</option>
                                <option value={"date_time"}>date time</option>
                                <option value={"select"}>select</option>

                                {/* <option value={"file"}>file</option> */}
                              </select>
                            </div>

                            {getTypeStatus(
                              info.content.items[innerIndex].input_type
                            ) && (
                              <>
                                <div>
                                  <label>
                                    {getT(
                                      "page_component_form_type_selection_optiions_label"
                                    )}
                                  </label>
                                  <CreatableSelect
                                    isMulti
                                    onChange={(newValue) => {
                                      handleDynamicChange(
                                        index,
                                        "options_titles",
                                        newValue,
                                        innerIndex
                                      );
                                    }}
                                    value={
                                      info.content.items[innerIndex]
                                        .options_titles &&
                                      info.content.items[innerIndex]
                                        .options_titles.length > 0
                                        ? info.content.items[innerIndex]
                                            .options_titles
                                        : []
                                    }
                                    onCreateOption={(inputValue) => {
                                      const newOption = {
                                        label: inputValue,
                                        value: inputValue,
                                      };
                                      if (
                                        info.content.items[innerIndex]
                                          .options_titles &&
                                        info.content.items[innerIndex]
                                          .options_titles.length > 0
                                      ) {
                                        handleDynamicChange(
                                          index,
                                          "options_titles",
                                          [
                                            ...info.content.items[innerIndex]
                                              .options_titles,
                                            newOption,
                                          ],
                                          innerIndex
                                        );
                                      } else {
                                        handleDynamicChange(
                                          index,
                                          "options_titles",
                                          [newOption],
                                          innerIndex
                                        );
                                      }
                                    }}
                                    name={
                                      "innerItem" + index + "_" + innerIndex
                                    }
                                    options={[]}
                                  />
                                  {/* <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) =>
                                      handleDynamicChange(
                                        index,
                                        "options_titles",
                                        e.target.value,
                                        innerIndex
                                      )
                                    }
                                    defaultValue={
                                      info.content.items[innerIndex]
                                        .options_titles
                                    }
                                    name={
                                      "innerItem" + index + "_" + innerIndex
                                    }
                                  /> */}
                                </div>
                                <div>
                                  <label>
                                    {getT(
                                      "page_component_form_type_selection_optiions_values_label"
                                    )}
                                  </label>
                                  {/* <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) =>
                                      handleDynamicChange(
                                        index,
                                        "options_values",
                                        e.target.value,
                                        innerIndex
                                      )
                                    }
                                    defaultValue={
                                      info.content.items[innerIndex]
                                        .options_values
                                    }
                                    name={
                                      "innerItem" + index + "_" + innerIndex
                                    }
                                  /> */}
                                  <CreatableSelect
                                    isMulti
                                    onChange={(newValue) => {
                                      handleDynamicChange(
                                        index,
                                        "options_values",
                                        newValue,
                                        innerIndex
                                      );
                                    }}
                                    value={
                                      info.content.items[innerIndex]
                                        .options_values &&
                                      info.content.items[innerIndex]
                                        .options_values.length > 0
                                        ? info.content.items[innerIndex]
                                            .options_values
                                        : []
                                    }
                                    onCreateOption={(inputValue) => {
                                      const newOption = {
                                        label: inputValue,
                                        value: inputValue,
                                      };
                                      if (
                                        info.content.items[innerIndex]
                                          .options_values &&
                                        info.content.items[innerIndex]
                                          .options_values.length > 0
                                      ) {
                                        handleDynamicChange(
                                          index,
                                          "options_values",
                                          [
                                            ...info.content.items[innerIndex]
                                              .options_values,
                                            newOption,
                                          ],
                                          innerIndex
                                        );
                                      } else {
                                        handleDynamicChange(
                                          index,
                                          "options_values",
                                          [newOption],
                                          innerIndex
                                        );
                                      }
                                    }}
                                    name={
                                      "innerItem" + index + "_" + innerIndex
                                    }
                                    options={[]}
                                  />
                                </div>
                              </>
                            )}

                            <label>{getT("page_component_form_title")}</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) =>
                                handleDynamicChange(
                                  index,
                                  "title",
                                  e.target.value,
                                  innerIndex
                                )
                              }
                              defaultValue={
                                info.content.items[innerIndex].title
                              }
                              name={"innerItem" + index + "_" + innerIndex}
                            />
                          </div>
                        </>
                      );
                    })}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}
