import React, { useEffect, useState } from "react";
import Filters from "../Filters";
import CustomTable from "../CustomTable";
import { BsFillInfoCircleFill, BsPencilSquare } from "react-icons/bs";
import moment from "moment";
import { contactColumns, getT, isDateBetween, translationKeys } from "../utils";
import { useNavigate } from "react-router-dom";
import paths, { adminWord, apiUrl } from "../../config/paths";
import { BsCopy } from "react-icons/bs";
import axios from "axios";
import { getFromLocalStorage } from "../../utils/helpers";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import { useAuth } from "../../authContext";
import { useContactQuery } from "../../queries/contacts";

export default function Contacts() {
  const [list, setList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const { TData } = useAuth();

  const navigate = useNavigate();

  const { data, isIdle, isLoading, isError, error } = useContactQuery();

  useEffect(() => {
    if (data && !isLoading && data.leads) {
      let apiList = data.leads.map((r) => {
        return {
          id: r?.id,
          date: moment(r?.created_at, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY"
          ),
          name: r?.name + " " + r?.surname,
          add: r?.address,
          comune: r?.city,
          phone: r?.mobile,
          email: r?.email,
          type: r?.referal_type,
          source: r?.source,
          action: (
            <>
              {/* <BsPencilSquare
                data-id={r.id}
                fontSize={25}
                className="cursor me-2"
                onClick={(event) => {
                  let id = event.target.getAttribute("data-id");
                  if (id > 0) {
                    navigate("/contacts/edit/" + id);
                  }
                }}
              /> */}
              <span
                className="cursor"
                data-id={r.id}
                onClick={(event) => {
                  let id =
                    event.target.parentNode.getAttribute("data-id") ||
                    event.target.getAttribute("data-id");
                  if (id > 0) {
                    navigate(adminWord + "/contacts/view/" + id);
                  }
                }}
              >
                <BsFillInfoCircleFill fontSize={25} data-id={r.id} />
              </span>
            </>
          ),
        };
      });

      setFilteredData(apiList);
      setList(apiList);
    }
  }, [isLoading, data]);

  const onSearch = (values) => {
    var options = list;

    if (options && options.length > 0) {
      if (values.name !== "") {
        options = options.filter((s) => {
          let cString = s.name.toLowerCase();
          return cString.includes(values.name.toLowerCase());
        });
      }

      if (values.address !== "") {
        options = options.filter((s) => {
          let cString = s.add.toLowerCase();
          return cString.includes(values.address.toLowerCase());
        });
      }

      if (values.comune !== "") {
        options = options.filter((s) => {
          let cString = s.comune.toLowerCase();
          return cString.includes(values.comune.toLowerCase());
        });
      }

      if (values.type !== "") {
        options = options.filter((s) => {
          return s.type === values.type;
        });
      }

      if (values.from !== "" && values.to !== "") {
        options = options.filter((s) => {
          let date = moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD");
          return isDateBetween(values.from, values.to, date);
        });
      }
    }

    setFilteredData(options);
  };

  const onClear = () => {
    setFilteredData(list);
  };

  return (
    <>
      <Filters
        onAdd={() => navigate(paths.newLeadPath)}
        onFilter={onSearch}
        onClear={onClear}
        type="contacts"
      />
      <div className="customTable">
        {isIdle || isLoading ? (
          getT(translationKeys.pleaseWait)
        ) : (
          <CustomTable
            columns={contactColumns}
            tData={TData}
            data={filteredData}
          />
        )}
      </div>
    </>
  );
}
