import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";
import CustomInput from "./utils/CustomInput";
import {
  CUSTOMERROLE,
  getT,
  ticketCategoryOptions,
  ticketStatusOptions,
  translationKeys,
} from "../utils";

import { useAuth } from "../../authContext";
import {
  useCreateTicketMutation,
  useTicketQuery,
  useUpdateTicketMutation,
} from "../../queries/tickets";
import CustomSelect2 from "./utils/CustomSelect2";
import { useUserQuery } from "../../queries/users";
import CustomTextArea from "./utils/CustomTextArea";

const TicketForm = ({ onEdit = false, editId = null }) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useTicketQuery(
    editId,
    editId ? true : false
  );
  const { userRoleName } = useAuth();

  const [usersData, setUsersData] = useState([]);

  const {
    data: userData,
    isIdle: userIdle,
    isLoading: userLoading,
    isError: userError,
  } = useUserQuery();

  const { authUserId } = useAuth();

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdateTicketMutation)
    : (mutationHook = useCreateTicketMutation);
  const mutation = mutationHook();

  const newSchema = {
    name: Yup.string().required(getT(translationKeys.nameRequired)),
    message: Yup.string().required(getT(translationKeys.messageRequired)),
  };

  const formSchema = Yup.object().shape(newSchema);

  useEffect(() => {
    if (userData && !userLoading && userData.users) {
      setUsersData(userData?.users);
    }
  }, [userLoading, userData]);

  useEffect(() => {
    if (data && !isLoading && data.item) {
      setEditData(data?.item);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.item &&
        mutation.data.data.item.id > 0
      ) {
        onEdit
          ? toast.success(getT(translationKeys.updated))
          : toast.success(getT(translationKeys.created));
        navigate(paths.ticketsPath);
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error(getT(translationKeys.generalError));
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const finalData = {
      name: values.name,
      status: values.status,
      create_user_id: values.create_user_id,
      operator_user_id: values.operator_user_id,
      message: values.message,
      category: values.category,
    };

    if (onEdit) {
      delete finalData.name;
    }

    toast.warning(getT(translationKeys.pleaseWait));
    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    navigate(paths.ticketsPath);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">{getT(translationKeys.generalError)}</div>;
  }

  return (
    <Container fluid className="leadForm">
      <button
        onClick={() => {
          navigate(paths.ticketsPath);
        }}
        className="btn btn-secondary "
      >
        {getT(translationKeys.back)}
      </button>
      <h4 className="my-2">
        {onEdit ? getT(translationKeys.history) : getT(translationKeys.create)}
      </h4>
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              name: editData?.name ?? "",
              status: editData?.value?.status ?? "1",
              create_user_id: editData?.create_user_id
                ? editData?.create_user_id
                : authUserId,
              operator_user_id: "",
              message: "",
              category: editData?.category ?? "practices",
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, formik }) => (
              <Form>
                <Row>
                  <Col sm={12} md={6}>
                    <CustomInput
                      onEdit={onEdit}
                      defaultValue={values.name}
                      name="name"
                      label={getT(translationKeys.subject)}
                    />
                  </Col>
                  <Col md={6}>
                    <CustomSelect2
                      label={getT(translationKeys.ticket_category_label)}
                      name="category"
                      options={ticketCategoryOptions}
                      defaultValue={values.category}
                      defaultSelectVal={getT(
                        translationKeys.ticket_select_category_placeholder_label
                      )}
                    />
                  </Col>
                  <Col sm={12} md={12}>
                    <CustomTextArea
                      name={"message"}
                      label={getT(translationKeys.message)}
                      placeholder=""
                    />
                  </Col>

                  {userRoleName !== CUSTOMERROLE && (
                    <>
                      {" "}
                      <Col sm={12} md={4}>
                        <CustomSelect2
                          label={getT(translationKeys.ticketStatusLabel)}
                          name="status"
                          options={ticketStatusOptions}
                          defaultValue={values.status}
                        />
                      </Col>
                      <Col sm={12} md={4}>
                        <CustomSelect2
                          label={getT(translationKeys.createdByLabel)}
                          name="create_user_id"
                          options={usersData.map((u) => {
                            return {
                              name: u.name + " " + u.lastName,
                              value: u.id,
                            };
                          })}
                          defaultValue={values.create_user_id}
                        />
                      </Col>
                      <Col sm={12} md={4}>
                        <CustomSelect2
                          label={getT(translationKeys.respondByLabel)}
                          name="operator_user_id"
                          options={usersData.filter((u) => {
                            return {
                              name: u.name + " " + u.lastName,
                              value: u.id,
                            };
                          })}
                          defaultValue={values.operator_user_id}
                        />
                      </Col>
                    </>
                  )}
                </Row>

                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      {getT(translationKeys.submit)}
                    </button>
                    <button
                      type="button"
                      onClick={() => handleClear(formik)}
                      className="btn btn-secondary"
                    >
                      {getT(translationKeys.back)}
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default TicketForm;
