import React from "react";
import { useParams } from "react-router-dom";
import DynamicSelectForm from "../forms/DynamicSelectForm";

export default function EditDynamicSelect() {
  const { id } = useParams();

  return (
    <>
      <DynamicSelectForm onEdit editId={id} />
    </>
  );
}
