import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { calculateQueryString, getFromLocalStorage } from "../utils/helpers";
import { apiUrl } from "../config/paths";

/*** Queries **********************/
const _fetcher = (baseUrl, Id = null, query = {}) => {
  const uri = Id ? `/${Id}` : "";
  let queryStr = calculateQueryString(query);
  return axios
    .get(`${baseUrl}${uri}${queryStr}`, {
      headers: {
        Authorization: getFromLocalStorage("userToken")
          ? getFromLocalStorage("userToken")
          : getFromLocalStorage("customerToken"),
      },
    })
    .then((res) => {
      return res.data;
    });
};

export const useTicketQuery = (
  Id = null,
  enabled = true,
  query = {},
  fetcher = _fetcher
) => {
  return useQuery({
    queryKey: ["tickets", Id, query],
    queryFn: () => fetcher(apiUrl + "/api/ticket", Id, query),
    option: {
      refetchInterval: 100,
      enabled: enabled,
    },
  });
};

// muations
const createTicketMutator = (params) => {
  return axios
    .post(apiUrl + `/api/ticket`, params, {
      headers: {
        Authorization: getFromLocalStorage("userToken")
          ? getFromLocalStorage("userToken")
          : getFromLocalStorage("customerToken"),
      },
    })
    .then((res) => res);
};

export const useCreateTicketMutation = (mutator = createTicketMutator) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => mutator(params),
    onSuccess: () => queryClient.invalidateQueries("tickets"),
  });
};

export const updateTicketMutation = (params) => {
  const { id, data } = params;
  return axios
    .put(apiUrl + `/api/ticket/${id}`, data, {
      headers: {
        Authorization: getFromLocalStorage("userToken")
          ? getFromLocalStorage("userToken")
          : getFromLocalStorage("customerToken"),
      },
    })
    .then((res) => res);
};

export const useUpdateTicketMutation = (mutator = updateTicketMutation) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => mutator(params),
    onSuccess: () => queryClient.invalidateQueries("tickets"),
  });
};

export const deleteTicketMutation = (params) => {
  const { id } = params;
  return axios
    .delete(apiUrl + `/api/ticket/${id}`, {
      headers: {
        Authorization: getFromLocalStorage("userToken")
          ? getFromLocalStorage("userToken")
          : getFromLocalStorage("customerToken"),
      },
    })
    .then((res) => res);
};

export const useDeleteTicketMutation = (mutator = deleteTicketMutation) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => mutator(params),
    onSuccess: () => queryClient.invalidateQueries("tickets"),
  });
};
