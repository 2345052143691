import React, { useEffect, useState } from "react";
import MainWrapper from "./pages/MainWrapper";
import MobileForm from "./admin/forms/MobileForm";
import MobileMap from "./pages/MobileMap";
import { toast } from "react-toastify";
import { getT, translationKeys } from "./admin/utils";
import axios from "axios";
import { apiUrl } from "./config/paths";
import { useLocation } from "react-router-dom";
import { updateQuoteData } from "./utils";
import Navigation from "./components/Navigation";
import { useAuth } from "./authContext";

export default function Mapp({ showHeader = true, prevId = null }) {
  const [step, setStep] = useState(0);
  const [zoom, setZoom] = useState(20);
  const [loc, setLoc] = useState(null);
  const [editData, setEditData] = useState({});
  const [address, setAddress] = useState({});
  const [loading, setLoading] = useState(true);
  const locationInfo = useLocation();
  const queryParams = new URLSearchParams(locationInfo.search);
  const idParam = prevId ? prevId : queryParams.get("id");
  const ref_code = queryParams.get("ref");
  const [refCode] = useState(ref_code);
  const [editId, setEditId] = useState(
    idParam > 0
      ? idParam
      : localStorage.getItem("quoteId")
      ? localStorage.getItem("quoteId")
      : null
  );
  const { isCustomerAuthenticated } = useAuth();
  const onUpdateLoc = (data) => {
    setLoc(data);
    if (data.zoom) {
      setZoom(data.zoom);
    }
  };

  const onUpdateAdd = (data) => {
    setLoc({
      lat: data.lat,
      lng: data.lng,
    });
    setAddress(data);
  };

  useEffect(() => {
    if (idParam > 0) {
      localStorage.setItem("quoteId", idParam);
    }
  }, [idParam]);

  // getting previous data
  useEffect(() => {
    // Function to create a row and get the ID
    if (editId > 1) {
      readRequestData(editId);
    } else {
      setLoading(false);
    }
  }, [editId]);

  const createRowAndFetchId = async () => {
    toast.loading(getT(translationKeys.pleaseWait));
    try {
      const response = await axios.post(apiUrl + "/api/cusQuote", {
        status: 0,
        lat: loc.lat,
        lng: loc.lng,
        address: address.address,
        city: address.city,
        country: address.country,
        province: address.country_state_code
          ? address.country_state_code
          : address.city,
        cap: address.zip ? address.zip : address.cap,
      });
      if (
        response.status === 201 &&
        response.data &&
        response.data.quote &&
        response.data.quote.id > 0
      ) {
        localStorage.setItem("quoteId", response.data.quote.id);
        setEditId(response.data.quote.id);
        toast.dismiss();
        return {
          id: response.data.quote.id,
          status: true,
        };
      } else {
        console.log(response.status, response.statusText);
        toast.dismiss();
        return {
          id: -1,
          status: false,
        };
      }
    } catch (error) {
      console.error("Error creating row:", error);
      toast.error(getT(translationKeys.generalError));
      return {
        id: -1,
        status: false,
      };
    }
  };

  const onEditDataChange = async (val, type) => {
    setEditData((prevTechInfo) => ({
      ...prevTechInfo,
      [type]: val,
    }));
  };

  const readRequestData = async (id) => {
    try {
      const response = await axios.get(apiUrl + "/api/cusQuote/" + id);
      if (
        response.status === 200 &&
        response.data &&
        response.data.quote &&
        response.data.quote.id > 0
      ) {
        // do the initial setup
        let initialData = response.data.quote;
        setEditData(initialData);
        setEditId(initialData.id);
        setLoc({
          lat: parseFloat(response.data.quote.lat),
          lng: parseFloat(response.data.quote.lng),
        });
        setAddress({
          address: response.data.quote.address,
          city: response.data.quote.city,
          province: response.data.quote.province,
          country: response.data.quote.country,
          cap: response.data.quote.cap,
          country_state_code: response.data.quote.province,
        });
        // hiding email modal when its there
        /*if (response.data.email === "") {
          setShowModal(true);
        }*/
      } else {
        console.log(response.status, response.statusText);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error creating row:", error);
      toast.error("try again later");
      setLoading(false);
    }
  };

  const onUpdateValues = async (values, payload) => {
    toast.loading(getT(translationKeys.pleaseWait));
    let res1 = await updateQuoteData(payload, editId);
    if (res1.status) {
      toast.dismiss();
    } else {
      console.log("update failed");
    }
  };

  return (
    <>
      <MainWrapper
        showHeader={showHeader}
        onBack={() => {}}
        showFooter={false}
        showChatBtn={false}
      >
        {step === 0 && !loading && (
          <MobileMap
            loc={loc}
            zoom={zoom}
            setLoc={onUpdateLoc}
            onNext={async () => {
              // create an id with address info
              //setStep(1);
              if (!editId) {
                const { id, status } = await createRowAndFetchId();
                if (!status) {
                  return;
                }
                if (id > 1) {
                  setStep(1);
                }
              } else if (
                loc &&
                loc.lat &&
                loc.lng &&
                address &&
                address.city &&
                address.address &&
                editId > 1
              ) {
                //console.log(address);
                onUpdateValues(null, loc);
                setStep(1);
              } else {
                console.log("something went wrong");
                toast.error(getT(translationKeys.address_not_valid_label));
              }
            }}
            setAdd={onUpdateAdd}
            address={address}
          />
        )}
        {step === 1 &&
          !loading &&
          editData &&
          editData.address &&
          loc &&
          loc.lat &&
          address &&
          address?.address && (
            <MobileForm
              onBack={() => {
                setStep(0);
              }}
              editData={editData}
              editId={editId}
              loc={loc}
              paramId={idParam}
              address={address}
              refCode={refCode}
            />
          )}
        {isCustomerAuthenticated && <Navigation />}
      </MainWrapper>
    </>
  );
}
