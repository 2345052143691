import Modal from "react-bootstrap/Modal";

export default function WrapperModal({
  show = false,
  handleClose = () => {},
  children,
  title = "new",
  modalType = "lg",
}) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={modalType ? modalType : "xl"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
}
