import React, { useEffect, useState } from "react";
import Filters from "../Filters";
import CustomTable from "../CustomTable";
import {
  BsDownload,
  BsEyeFill,
  BsPencilSquare,
  BsUpload,
} from "react-icons/bs";
import moment from "moment";
import { getT, isDateBetween, orderColumns, translationKeys } from "../utils";
import { useNavigate } from "react-router-dom";
import paths, { adminWord, apiUrl } from "../../config/paths";
import { BsCopy } from "react-icons/bs";
import axios from "axios";
import { getFromLocalStorage } from "../../utils/helpers";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useAuth } from "../../authContext";
import { useOrderQuery } from "../../queries/orders";
import { useQueryClient } from "@tanstack/react-query";

export default function Orders({ roleType = "", cusId = null }) {
  const [list, setList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [copyDisabled, setCopyDisabled] = useState(false);
  const { TData } = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const getCustomQuery = () => {
    let item = { customerId: cusId };
    if (roleType === "customer") {
      item["type"] = roleType;
    }
    return item;
  };

  const { data, isIdle, isLoading, isError, error } = useOrderQuery(
    null,
    true,
    getCustomQuery()
  );

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const id = event.target.id.split("file-upload")[1];

    let finalData = {
      id: id,
    };

    let formData = new FormData();
    formData.append("file", file);

    // Append additional form fields
    Object.keys(finalData).forEach((key) => {
      formData.append(key, finalData[key]);
    });

    toast.warning(getT(translationKeys.pleaseWait));

    try {
      const response = await axios.post(
        apiUrl + "/api/orderFileUpload/" + id,
        formData,
        {
          headers: {
            Authorization: getFromLocalStorage("userToken"),
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data && response.data.status) {
        toast.dismiss();
        // Reset the file input
        toast.success(getT(translationKeys.updated));
        queryClient.invalidateQueries("orders");
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("try again later");
      }
    } finally {
    }
    // You can perform additional actions like uploading the file to a server here
  };

  useEffect(() => {
    if (data && !isLoading && data.items) {
      let apiList = data.items.map((r) => {
        return {
          id: r?.id,
          date: moment(r?.created_at, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY"
          ),
          name: r?.product?.name,
          quantity: r?.quantity,
          status: r?.status,
          userName: r?.user?.name,
          action: (
            <>
              <span
                className="cursor me-2"
                data-id={r.id}
                onClick={(event) => {
                  let id =
                    event.target.parentNode.getAttribute("data-id") ||
                    event.target.getAttribute("data-id");
                  if (id > 0) {
                    roleType !== "customer"
                      ? navigate(adminWord + "/orders/view/" + id)
                      : navigate("/myOrders/view/" + id);
                  }
                  console.log(event);
                }}
              >
                <BsEyeFill data-id={r.id} fontSize={25} />
              </span>{" "}
              {roleType !== "customer" && (
                <>
                  <label htmlFor={"file-upload" + r.id}>
                    <BsUpload
                      data-id={r.id}
                      fontSize={20}
                      className="cursor me-2"
                    />
                  </label>
                  <input
                    id={"file-upload" + r.id}
                    type="file"
                    accept="image/*" // Accept only image files, you can modify it based on your needs
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </>
              )}
              {r?.invoice && r.invoice?.id && (
                <BsDownload
                  data-id={r?.invoice?.id}
                  fontSize={20}
                  className="cursor me-2"
                  onClick={(event) => {
                    let id = event.target.getAttribute("data-id");
                    if (id > 0) {
                      //navigate("/orders/view/" + id);
                      window.open(
                        apiUrl + `/api/order/attachment/${id}`,
                        "_self"
                      );
                    }
                  }}
                />
              )}
              {/*<BsCopy
                data-id={r.id}
                fontSize={25}
                className="cursor"
                disabled={copyDisabled}
                onClick={(event) => {
                  let id = event.target.getAttribute("data-id");
                  if (id > 0) {
                    setCopyDisabled(true);

                    Swal.fire({
                      title:
                        "Sei sicuro di voler convertire i lead in preventivi?",
                      text: "",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes",
                    }).then((result) => {
                       Read more about isConfirmed, isDenied below 
                      if (result.isConfirmed) {
                        toast.warning("please wait..");

                        axios
                          .post(
                            apiUrl + "/api/leadCopy",
                            { id: id },
                            {
                              headers: {
                                Authorization: getFromLocalStorage("userToken"),
                              },
                            }
                          )
                          .then((response) => {
                            if (
                              response.data &&
                              response.data.quote &&
                              response.data.quote.id > 0
                            ) {
                              toast.success(
                                "successfully created quote from lead"
                              );
                              navigate(
                                "/preventivi/edit/" + response.data.quote.id
                              );
                            } else {
                              toast.error("try again later");
                            }
                            setCopyDisabled(false);
                            // Handle the success response
                            console.log("Response:", response.data);
                          })
                          .catch((error) => {
                            // Handle errors
                            console.error("Error:", error);
                            toast.error("try again later");
                            setCopyDisabled(false);
                          });
                      }
                    });
                  }
                }}
              />*/}
            </>
          ),
        };
      });

      setFilteredData(apiList);
      setList(apiList);
    }
  }, [isLoading, data]);

  const onSearch = (values) => {
    var options = list;

    if (options && options.length > 0) {
      if (values.name !== "") {
        options = options.filter((s) => {
          let cString = s.name.toLowerCase();
          return cString.includes(values.name.toLowerCase());
        });
      }

      if (values.address !== "") {
        options = options.filter((s) => {
          let cString = s.add.toLowerCase();
          return cString.includes(values.address.toLowerCase());
        });
      }

      if (values.comune !== "") {
        options = options.filter((s) => {
          let cString = s.comune.toLowerCase();
          return cString.includes(values.comune.toLowerCase());
        });
      }

      if (values.type !== "") {
        options = options.filter((s) => {
          return s.type === values.type;
        });
      }

      if (values.from !== "" && values.to !== "") {
        options = options.filter((s) => {
          let date = moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD");
          return isDateBetween(values.from, values.to, date);
        });
      }
    }

    setFilteredData(options);
  };

  const onClear = () => {
    setFilteredData(list);
  };

  return (
    <>
      <Filters
        type="orders"
        onAdd={() => navigate(paths.newOrdersPath)}
        onFilter={onSearch}
        onClear={onClear}
      />
      <div className="customTable">
        {isIdle || isLoading ? (
          getT(translationKeys.pleaseWait)
        ) : (
          <CustomTable
            columns={orderColumns}
            tData={TData}
            data={filteredData}
          />
        )}
      </div>
    </>
  );
}
