import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";
import { useAuth } from "../../authContext";
import { getT, translationKeys } from "../utils";
import CustomInput from "./utils/CustomInput";
import {
  useCreateMenuMutation,
  useMenuQuery,
  useUpdateMenuMutation,
} from "../../queries/Menus";
import { usePagesQuery } from "../../queries/pages";
import CustomSelect2 from "./utils/CustomSelect2";

const MenuForm = ({ onEdit = false, editId = null }) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useMenuQuery(
    editId,
    false
  );

  const [pages, setPages] = useState([]);

  const {
    data: pagesData,
    isIdle: pagesIdle,
    isLoading: pagesLoading,
    isError: pagesError,
  } = usePagesQuery(null, true);

  const { TData } = useAuth();

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdateMenuMutation)
    : (mutationHook = useCreateMenuMutation);
  const mutation = mutationHook();

  const formSchema = Yup.object().shape({
    link_type: Yup.string().required(getT(translationKeys.link_type_required)),
  });

  useEffect(() => {
    if (pagesData && !pagesLoading && pagesData?.items) {
      let items = pagesData.items.filter((p) => !p.parent_id && p);
      setPages(items);
    }
  }, [pagesLoading, pagesData]);

  useEffect(() => {
    if (data && !isLoading && data?.item) {
      setEditData(data.item);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.item &&
        mutation.data.data.item.id > 0
      ) {
        onEdit
          ? toast.success(getT(translationKeys.updated))
          : toast.success(getT(translationKeys.created));
        navigate(paths.menusPath);
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error(getT(translationKeys.generalError));
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    //console.log(values);
    const finalData = {
      page_id: values.page_id,
      name: values.name,
      status: values.status,
      type: values.type,
      link_type: values.link_type,
      link: values.link,
    };

    toast.warning(getT(translationKeys.pleaseWait));

    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    navigate(paths.menusPath);
  };

  const getPages = () => {
    let items = [];
    pages.forEach((p) => {
      items.push({
        name: p?.name,
        value: p?.id,
      });
    });
    return items;
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">{getT(translationKeys.generalError)}</div>;
  }

  return (
    <Container fluid className="leadForm">
      <button
        onClick={() => {
          navigate(paths.menusPath);
        }}
        className="btn btn-secondary "
      >
        {getT(translationKeys.back)}
      </button>
      <h4 className="my-2">
        {onEdit ? getT(translationKeys.update) : getT(translationKeys.create)}
      </h4>
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              name: editData?.name ?? "",
              page_id: editData?.page_id ?? "",
              type: editData?.type ? editData.type : "header",
              status: editData?.status && editData.status ? true : false,
              link_type: editData?.link_type ?? "",
              link: editData?.link,
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, formik }) => (
              <Form>
                <Row>
                  <Col md={12}>
                    <div className="d-flex">
                      <Field
                        type="checkbox"
                        id="status"
                        className="form-check-input me-2"
                        name="status"
                      />
                      <label className="me-2">{getT("status")}</label>
                    </div>
                  </Col>
                  <Col sm={12} md={6}>
                    <CustomSelect2
                      label={getT(translationKeys.type)}
                      name="type"
                      options={[
                        {
                          name: "Header",
                          value: "header",
                        },
                        {
                          name: "Footer",
                          value: "footer",
                        },
                      ]}
                      defaultValue={values.type}
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <CustomSelect2
                      label={getT(translationKeys.link_type)}
                      name="link_type"
                      options={[
                        {
                          name: "Internal",
                          value: "internal",
                        },
                        {
                          name: "External",
                          value: "external",
                        },
                      ]}
                      defaultValue={values.link_type}
                    />
                  </Col>
                  {values.link_type === "internal" && (
                    <>
                      <Col sm={12} md={12}>
                        <CustomSelect2
                          label={getT(translationKeys.page)}
                          name="page_id"
                          options={getPages()}
                          defaultValue={values.page_id}
                        />
                      </Col>
                      <Col sm={12} md={12}>
                        <CustomInput
                          onEdit={false}
                          defaultValue={values.name}
                          name="name"
                          label={getT(translationKeys.alias)}
                        />
                      </Col>{" "}
                    </>
                  )}
                  {values.link_type === "external" && (
                    <>
                      <Col sm={12} md={12}>
                        <CustomInput
                          onEdit={false}
                          defaultValue={values.name}
                          name="name"
                          label={getT(translationKeys.name)}
                        />
                      </Col>
                      <Col sm={12} md={12}>
                        <CustomInput
                          onEdit={false}
                          defaultValue={values.link}
                          name="link"
                          label={getT(translationKeys.link)}
                        />
                      </Col>{" "}
                    </>
                  )}
                </Row>
                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      {getT(translationKeys.submit)}
                    </button>
                    <button
                      type="button"
                      onClick={() => handleClear(formik)}
                      className="btn btn-secondary"
                    >
                      {getT(translationKeys.back)}
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default MenuForm;
