import React, { useEffect, useState } from "react";
import {
  useCreateOrderMutation,
  useOrderQuery,
  useUpdateOrderMutation,
} from "../../queries/orders";
import { toast } from "react-toastify";
import { getT, translationKeys } from "../utils";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";
import Container from "react-bootstrap/esm/Container";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import CustomInput from "./utils/CustomInput";
import { useProductQuery } from "../../queries/products";
import CustomSelect from "./utils/CustomSelect";
import { useProductVariationQuery } from "../../queries/productVariations";
import CustomTextArea from "./utils/CustomTextArea";
import { useUserQuery } from "../../queries/users";

export default function OrderForm({ onEdit = false, editId = null }) {
  const [editData, setEditData] = useState();
  const [prods, setProds] = useState();
  const [variations, setVariations] = useState();
  const [users, setUsers] = useState();

  const [submittable, setSubmittable] = useState(false);
  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useOrderQuery(
    editId,
    editId ? true : false
  );

  const {
    data: prodData,
    isIdle: prodIdle,
    isLoading: prodLoading,
    isError: prodError,
  } = useProductQuery();

  const {
    data: varData,
    isIdle: varIdle,
    isLoading: varLoading,
    isError: varError,
  } = useProductVariationQuery();

  const {
    data: userData,
    isIdle: userIdle,
    isLoading: userLoading,
    isError: userError,
  } = useUserQuery();

  let mutationHook;

  const newUserSchema = {};

  const editUserSchema = {};

  const formSchema = Yup.object().shape(
    onEdit ? editUserSchema : newUserSchema
  );

  onEdit
    ? (mutationHook = useUpdateOrderMutation)
    : (mutationHook = useCreateOrderMutation);
  const mutation = mutationHook();

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.user &&
        mutation.data.data.user.id > 0
      ) {
        onEdit
          ? toast.success(getT(translationKeys.updated))
          : toast.success(getT(translationKeys.created));
        navigate(paths.ordersPath);
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error(getT(translationKeys.generalError));
      }
      mutation.reset();
    }
  }, [mutation]);

  useEffect(() => {
    if (data && !isLoading && data.item) {
      setEditData(data?.item);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (varData && !varLoading && varData.items) {
      setVariations(varData?.items);
    }
  }, [varLoading, varData]);

  useEffect(() => {
    if (prodData && !prodLoading && prodData.items) {
      setProds(prodData?.items);
    }
  }, [prodLoading, prodData]);

  useEffect(() => {
    if (userData && !userLoading && userData.users) {
      setUsers(userData?.users);
    }
  }, [userLoading, userData]);

  const handleSubmit = async (values, { setSubmitting }) => {
    //console.log(values);
    const finalData = {
      email: values.email,
      name: values.name,
      lastName: values.surname,
      password: values.pwd,
      company: values.company,
      role_id: values.role_id,
      status: values.status,
    };
    if (onEdit) {
      delete finalData.email;
    }

    toast.warning(getT(translationKeys.pleaseWait));
    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    navigate(paths.ordersPath);
  };

  if (isLoading || prodLoading) {
    return <Loading />;
  }

  return (
    <Container fluid className="leadForm">
      <button
        onClick={() => {
          navigate(paths.ordersPath);
        }}
        className="btn btn-secondary "
      >
        {getT(translationKeys.back)}
      </button>
      <h4 className="my-2">
        {onEdit ? getT(translationKeys.update) : getT(translationKeys.create)}
      </h4>
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              product_id: "",
              variations: [],
              name: editData?.billing?.name,
              surname: editData?.billing?.surname,
              socialSecurity: editData?.billing?.socialSecurity,
              zip: editData?.billing?.zip,
              city: editData?.billing?.city,
              state: editData?.billing?.state,
              phone: editData?.billing?.phone,
              country: editData?.billing?.country,
              company: editData?.billing?.company,
              iva: editData?.billing?.iva,
              shippingName: editData?.shipping?.name,
              shippingSurname: editData?.shipping?.surname,
              shippingCity: editData?.shipping?.city,
              shippingCodiceFiscale: editData?.shipping?.socialSecurity,
              shippingCompany: editData?.shipping?.company,
              shippingCountry: editData?.shipping?.country,
              shippingPhone: editData?.shipping?.phone,
              shippingState: editData?.shipping?.state,
              shippingZip: editData?.shipping?.zip,
              quantity: 1,
              user_id: "",
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, formik }) => (
              <Form>
                <Row>
                  <Col sm={12} md={6}>
                    <CustomSelect
                      label={getT(translationKeys.products)}
                      name="product_id"
                      defaultValue={values.product_id}
                      options={
                        prods &&
                        prods?.map((option, index) => ({
                          label: option?.name + option?.surname,
                          value: option.id,
                        }))
                      }
                    />
                  </Col>
                  <Col sm={12} md={4}>
                    <CustomSelect
                      label={getT(translationKeys.users)}
                      name="user_id"
                      defaultValue={values.user_id}
                      options={
                        users &&
                        users?.map((option, index) => ({
                          label: option.name,
                          value: option.id,
                        }))
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.quantity}
                      name="quantity"
                      type="number"
                      label={getT(translationKeys.quantity)}
                    />
                  </Col>

                  <Col sm={12} md={12}>
                    <CustomSelect
                      label={getT(translationKeys.variations)}
                      name="variations"
                      isMulti={true}
                      defaultValue={values.variations}
                      options={
                        variations &&
                        variations?.map((option, index) => ({
                          label: option.name + " - " + option?.category?.name,
                          value: option.id,
                        }))
                      }
                    />
                  </Col>
                  <Col sm={12} md={12}>
                    <CustomTextArea
                      name="note"
                      label={getT(translationKeys.notes)}
                      defaultValue={values.note}
                      placeholder=""
                    />
                  </Col>
                  <Row>
                    <Col sm={12} md={6}>
                      <h2>Billing address</h2>
                      <Row>
                        <Col md={6}>
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.name}
                            name="name"
                            label={getT(translationKeys.name)}
                          />
                        </Col>
                        <Col md={6}>
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.surname}
                            name="surname"
                            label={getT(translationKeys.surname)}
                          />
                        </Col>
                        <Col md={12}>
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.socialSecurity}
                            name="socialSecurity"
                            label={getT(translationKeys.socialSecurity)}
                          />
                        </Col>
                        <Col md={4}>
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.zip}
                            name="zip"
                            label={getT(translationKeys.zip)}
                          />
                        </Col>
                        <Col md={4}>
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.city}
                            name="city"
                            label={getT(translationKeys.city)}
                          />
                        </Col>
                        <Col md={4}>
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.state}
                            name="state"
                            label={getT(translationKeys.state)}
                          />
                        </Col>
                        <Row>
                          <Col md={6}>
                            <CustomInput
                              onEdit={false}
                              defaultValue={values.country}
                              name="country"
                              label={getT(translationKeys.country)}
                            />
                          </Col>
                          <Col md={6}>
                            <CustomInput
                              onEdit={false}
                              defaultValue={values.phone}
                              name="phone"
                              label={getT(translationKeys.phone)}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <p>
                            Se vuoi intestare la tua fattura ad un azienda
                            comila i dati seguito
                          </p>
                          <Col md={6}>
                            <CustomInput
                              onEdit={false}
                              defaultValue={values.company}
                              name="company"
                              label={getT(translationKeys.company)}
                            />
                          </Col>
                          <Col md={6}>
                            <CustomInput
                              onEdit={false}
                              defaultValue={values.iva}
                              name="iva"
                              label={getT(translationKeys.iva)}
                            />
                          </Col>
                        </Row>
                      </Row>
                    </Col>
                    <Col sm={12} md={6}>
                      <h2>Shipping address</h2>

                      <Row>
                        <Col md={6}>
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.shippingName}
                            name="shippingName"
                            label={getT(translationKeys.name)}
                          />
                        </Col>
                        <Col md={6}>
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.shippingSurname}
                            name="shippingSurname"
                            label={getT(translationKeys.surname)}
                          />
                        </Col>
                        <Col md={12}>
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.shippingCodiceFiscale}
                            name="shippingCodiceFiscale"
                            label={getT(translationKeys.socialSecurity)}
                          />
                        </Col>
                        <Col md={4}>
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.shippingZip}
                            name="shippingZip"
                            label={getT(translationKeys.zip)}
                          />
                        </Col>
                        <Col md={4}>
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.shippingCity}
                            name="shippingCity"
                            label={getT(translationKeys.city)}
                          />
                        </Col>
                        <Col md={4}>
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.shippingState}
                            name="shippingState"
                            label={getT(translationKeys.state)}
                          />
                        </Col>
                        <Col md={12}>
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.shippingCompany}
                            name="shippingCompany"
                            label={getT(translationKeys.company)}
                          />
                        </Col>
                        <Row>
                          <Col md={6}>
                            <CustomInput
                              onEdit={false}
                              defaultValue={values.shippingCountry}
                              name="shippingCountry"
                              label={getT(translationKeys.country)}
                            />
                          </Col>
                          <Col md={6}>
                            <CustomInput
                              onEdit={false}
                              defaultValue={values.shippingPhone}
                              name="shippingPhone"
                              label={getT(translationKeys.phone)}
                            />
                          </Col>
                        </Row>
                      </Row>
                    </Col>
                  </Row>

                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success mt-2 me-2"
                    >
                      {getT(translationKeys.submit)}
                    </button>
                    <button
                      type="button"
                      onClick={() => handleClear(formik)}
                      className="btn btn-secondary"
                    >
                      {getT(translationKeys.back)}
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
}
