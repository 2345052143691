import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Main from "./Main";
import { Auth0Provider } from "@auth0/auth0-react";
import { auth0clientid, auth0domain } from "./config/paths";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { google_auth_id } from "./config/api";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={google_auth_id}>
      {/* <Auth0Provider
        domain={auth0domain}
        clientId={auth0clientid}
        authorizationParams={{
          redirect_uri: window.location.origin,
          //audience: `https://${auth0domain}/api/v2/`,
          //scope: "read:current_user",
        }}
      >*/}
      <Main />
      {/* </Auth0Provider> */}
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
