import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import MainWrapper from "./MainWrapper";
import { getT, translationKeys } from "../admin/utils";
import ProfileForm from "../admin/forms/ProfileForm";
import UpdatePasswordForm from "../admin/forms/UpdatePasswordForm";
import Appointmentswidget from "./widgets/appointmentswidget";
import TicketsWidget from "./widgets/ticketswidget";
import QuotesWidget from "./widgets/quoteswidget";
import CalenderWidget from "./widgets/calenderWidget";
import QuoteStatusWidget from "./widgets/quoteStatusWidget";
import Navigation from "../components/Navigation";
import useScreenSize from "../components/useScreenSize";
import { useAuth } from "../authContext";
import { siteUrl } from "../config/paths";
import { FaShareAltSquare } from "react-icons/fa";
import CustomOverLay from "../components/CustomOverLay";
import { BsFillInfoSquareFill } from "react-icons/bs";

export default function Profile() {
  const { isCustomerAuthenticated, customerInfo } = useAuth();
  const isMobile = useScreenSize(768);

  return (
    <>
      <MainWrapper
        mainClass="dynamicPage profile_page"
        showFooter={isMobile ? false : true}
        showChatBtn={true}
      >
        {isCustomerAuthenticated && customerInfo && customerInfo?.id > 0 ? (
          <div className="infoWrapper">
            <Container fluid>
              <Row>
                <Col md={12}>
                  <div className="referral_wrapper">
                    <div className="referral_top">
                      <h4>{getT(translationKeys.referral_system_label)}</h4>
                      <div className="referral_wrapper_inner">
                        <div className="points">
                          {customerInfo && customerInfo?.points
                            ? customerInfo?.points
                            : 0}
                        </div>
                        <CustomOverLay
                          placement="bottom"
                          message={getT(translationKeys.referral_share_label)}
                          iconElement={
                            <FaShareAltSquare
                              fontSize={25}
                              onClick={() => {
                                let shareUrl =
                                  siteUrl +
                                  "/#/?ref=" +
                                  customerInfo?.referral_code;
                                if (navigator.share) {
                                  navigator
                                    .share({
                                      title: getT(
                                        translationKeys.referral_system_label_title
                                      ),
                                      text: getT(
                                        translationKeys.referral_system_label_text
                                      ),
                                      url: shareUrl,
                                    })
                                    .then(() =>
                                      console.log("Successfully shared!")
                                    )
                                    .catch((error) =>
                                      console.error("Error sharing:", error)
                                    );
                                } else {
                                  alert(`Copy this link to share: ${shareUrl}`);
                                }
                              }}
                              className={"cursor"}
                            />
                          }
                        ></CustomOverLay>
                        <CustomOverLay
                          placement="bottom"
                          message={getT(
                            translationKeys.referral_share_info_label
                          )}
                          iconElement={
                            <BsFillInfoSquareFill
                              fontSize={23}
                              className={"cursor"}
                            />
                          }
                        ></CustomOverLay>
                      </div>
                    </div>
                    <div className="referral_bottom">
                      {customerInfo && customerInfo.referrals && (
                        <>
                          <div className="referral_bottom_list">
                            {customerInfo.referrals.map((rf) => {
                              return (
                                <div className="referral_bottom_list_item">
                                  <span>
                                    {rf?.ref_user && rf?.ref_user?.email
                                      ? rf.ref_user.email
                                      : ""}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md={6} lg={4}>
                  <div className="profileForm">
                    <ProfileForm onEdit editId={customerInfo.id} />
                  </div>
                  <div className="updatePasswordForm">
                    <UpdatePasswordForm onEdit editId={customerInfo.id} />
                  </div>
                  {!isMobile && (
                    <div className="calenderForm front">
                      <CalenderWidget userId={customerInfo.id} />
                    </div>
                  )}
                </Col>
                {!isMobile && (
                  <>
                    <Col md={6} lg={8}>
                      <div className="widgets_container">
                        <div className="widget_item">
                          <QuotesWidget userId={customerInfo.id} />
                        </div>

                        <div className="widget_item">
                          <TicketsWidget userId={customerInfo.id} />
                        </div>
                        <div className="widget_item">
                          <Appointmentswidget userId={customerInfo.id} />
                        </div>
                      </div>
                    </Col>
                  </>
                )}
              </Row>
            </Container>
          </div>
        ) : (
          <div className="infoWrapper">
            {getT(translationKeys.notAutherized)}
          </div>
        )}
        {isMobile && isCustomerAuthenticated && <Navigation />}
      </MainWrapper>
    </>
  );
}
