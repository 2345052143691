import React from "react";
import ContactForm from "../../admin/forms/ContactForm";
import LocationMap from "./LocationMap";

export default function FormComponent({ info = {} }) {
  console.log(info);
  return (
    <>
      <div className="contactForm">
        {(info?.content?.type === "map" ||
          info?.content?.type === "mapwithform") && (
          <div
            style={{ height: "calc(100vh - 220px)", width: "100%" }}
            className="map"
          >
            <LocationMap />
          </div>
        )}
        {(info?.content?.type === "form" ||
          info?.content?.type === "mapwithform") && (
          <ContactForm position={"front"} />
        )}
      </div>
    </>
  );
}
