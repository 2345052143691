import React, { useEffect, useState } from "react";
import {
  BsChevronCompactRight,
  BsFillCartFill,
  BsFillXSquareFill,
} from "react-icons/bs";
import Order from "./Order";
import Shipping from "./Shipping";
import { updateOrderData } from "../../utils/helpers";
import { apiUrl, stripePublishableUrl } from "../../config/paths";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import axios from "axios";
import CartProduct from "./cartProduct";
import {
  currencyType,
  getT,
  getTotalPrice,
  getVariations,
} from "../../admin/utils";

export default function Cart({ order = {}, onUpdateQuantity = () => {} }) {
  const [step, setStep] = useState(0);
  const [show, setShow] = useState(false);

  const onCheckOut = async () => {
    //let qEle = document.getElementById("quantity");
    let nEle = document.getElementById("note");
    let taxCountry = document.getElementById("taxCountry");

    let res = await updateOrderData({
      //quantity: qEle?.value,
      note: nEle?.value,
      status: 1,
      user_id: JSON.parse(localStorage.getItem("customerData"))?.id,
      taxCountry: taxCountry?.value,
    });

    if (res.status) {
      // do something
      createStripeCheckoutSession();
    } else {
      console.log("update failed");
    }
  };

  const getInfoByType = (type, product) => {
    let items = [];
    if (product && product.infos && product.infos.length > 0) {
      product.infos.forEach((i) => {
        if (i.type === type) {
          items.push(i);
        }
      });
    }
    return items;
  };

  const createStripeCheckoutSession = async () => {
    const orderId = localStorage.getItem("orderId"); // Generate a custom order ID

    let taxCountry = document.getElementById("taxCountry");
    let ivaVal = 0.22;

    if (taxCountry?.value === "italy" && order?.company !== "") {
      ivaVal = 0.22;
    }
    if (taxCountry?.value === "others" && order?.company !== "") {
      ivaVal = 0;
    }
    if (order?.company === "") {
      ivaVal = 0.22;
    }
    const amount = getTotalPrice(true, order, ivaVal) * 100; // Amount in cents

    const response = await fetch(
      apiUrl + "/api/payment/create-checkout-session",
      {
        // Change the API endpoint to create a Checkout session
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderId: orderId, // Pass the order ID in the request
          amount: amount,
          currency: "EUR",
          name: "TEST",
          //email: user?.email,
        }),
      }
    );

    const data = await response.json();

    // Call function to handle Stripe Checkout with the received session ID
    handleStripeCheckout(data.sessionId); // Use session ID instead of client secret
  };

  const handleStripeCheckout = async (clientSecret) => {
    const stripe = await loadStripe(stripePublishableUrl);
    const { error } = await stripe.redirectToCheckout({
      sessionId: clientSecret,
    });
    if (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {show && (
        <div className="closeBtn">
          <BsChevronCompactRight onClick={() => setShow(!show)} fontSize={30} />
        </div>
      )}
      {show && (
        <div className="cartInfo">
          <div className="header">
            <h2>{getT("cart_info_title")}</h2>
            <BsFillXSquareFill
              className="cursor"
              fontSize={25}
              onClick={() => setShow(!show)}
            />
          </div>
          <div className="cartInfoBody">
            {step === 0 && (
              <>
                <div className="cart-product-list">
                  {order?.products &&
                    order.products.length > 0 &&
                    order.products.map((item, index) => (
                      <CartProduct
                        item={item}
                        index={index}
                        order={order}
                        onUpdateQuantity={onUpdateQuantity}
                      />
                    ))}
                </div>
                <div className="cart-product-total">
                  <span>{getT("cart_total_label")}</span>
                  <span>{getTotalPrice(false, order) + currencyType}</span>
                </div>
              </>
            )}
            {step === 1 && (
              <Shipping
                onMove={() => {
                  setStep(2);
                }}
                order={order}
              />
            )}
            {step === 2 && (
              <Order order={order} onUpdateQuantity={onUpdateQuantity} />
            )}
          </div>
          <div className="actions">
            {step > 0 && (
              <div
                onClick={() => setStep((prev) => (prev = prev - 1))}
                className="previous actionBtn cursor"
              >
                {getT("previous")}
              </div>
            )}
            {step !== 2 && (
              <div
                onClick={() => setStep((prev) => (prev = prev + 1))}
                className="next actionBtn cursor"
              >
                {getT("next")}
              </div>
            )}
            {step === 2 && (
              <div
                onClick={() => {
                  onCheckOut();
                }}
                className="next actionBtn cursor"
              >
                {getT("pay_now")}
              </div>
            )}
          </div>
        </div>
      )}
      {!show && order && (
        <div className="cartBtn cursor" onClick={() => setShow(!show)}>
          <span className="count">{order?.products?.length}</span>
          <BsFillCartFill fontSize={25} color="white" />
        </div>
      )}
    </>
  );
}
