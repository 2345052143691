import React, { useState } from "react";
import HeaderPage from "./Header";
import AiChatBox from "./AiChatBox";
import Footer from "./Footer";
import WrapperModal from "../components/ModalWrapper";
import AuthenticationModal from "./AuthenticationModal";
import { useAuth } from "../authContext";
import MobileVerifyModal from "./MobileVerifyModal";
import { getT, translationKeys } from "../admin/utils";

export default function MainWrapper({
  children,
  mainClass = "",
  topBar = true,
  showHeader = true,
  showChatBtn = true,
  showFooter = true,
  onBack = () => {},
}) {
  const {
    showLoginModal,
    toggleLoginModal,
    showMobileModal,
    toggleMobileModal,
  } = useAuth();
  return (
    <>
      <div className={"main-container " + mainClass}>
        <WrapperModal
          show={showLoginModal}
          title={getT(translationKeys.login_or_signup_modal_title)}
          handleClose={() => {
            toggleLoginModal(false);
          }}
        >
          <AuthenticationModal
            onClose={() => {
              toggleLoginModal(false);
            }}
          />
        </WrapperModal>
        <WrapperModal
          show={showMobileModal}
          title={getT(translationKeys.mobile_verification_modal_title)}
          handleClose={() => {
            toggleMobileModal(false);
          }}
        >
          <MobileVerifyModal
            onClose={() => {
              toggleMobileModal(false);
            }}
          />
        </WrapperModal>
        {showHeader && <HeaderPage onBack={onBack} />}
        {children}
        {showHeader && showChatBtn && <AiChatBox />}
        {showHeader && showFooter && <Footer noTopBar={topBar} />}
      </div>
    </>
  );
}
