import React, { useState } from "react";
import WrapperModal from "../../components/ModalWrapper";
import { eventStatusList, getT, translationKeys } from "../../admin/utils";
import AppointmentUpdateForm from "../../admin/forms/AppointmentUpdateForm";

export default function AppointmentUpdateModal({
  editId = -1,
  showModal,
  setShowModal = () => {},
}) {
  return (
    <>
      <WrapperModal
        show={showModal}
        title={getT(translationKeys.appointment_modify_request_label)}
        handleClose={() => {
          setShowModal(!showModal);
        }}
      >
        <AppointmentUpdateForm
          onEdit
          editId={editId}
          onClose={() => {
            setShowModal(!showModal, "update");
          }}
        />
      </WrapperModal>
    </>
  );
}
