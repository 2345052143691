import React, { useEffect } from "react";

export default function LocationMap() {
  useEffect(() => {
    if (
      window &&
      window.google &&
      window.google.maps &&
      window.google.maps.places
    ) {
      showMap();
    }
  }, []);

  const showMap = async () => {
    const mapEle = document.getElementById("roadMap");
    if (mapEle) {
      // eslint-disable-next-line no-undef
      let map = new google.maps.Map(mapEle, {
        zoom: 7,
        center: { lat: 45.4303378, lng: 9.3227076 },
        //minZoom: 18,
      });
    }
  };

  return (
    <>
      <div id="roadMap" className="mt-1 mb-1" style={{ height: "100%" }}></div>
    </>
  );
}
