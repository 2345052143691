import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import HeaderPage from "../Header";
import { useGeneralProductQuery } from "../../queries/products";
import { toast } from "react-toastify";
import axios from "axios";
import { apiUrl } from "../../config/paths";
import { updateOrderProductsData } from "../../utils/helpers";
import Product from "./Product";
import { useCategoryQuery } from "../../queries/categories";
import Cart from "./cart";
import { getCustomerToken } from "../../utils";
import { getT, translationKeys } from "../../admin/utils";
import WrapperModal from "../../components/ModalWrapper";
import { useAuth } from "../../authContext";
import AuthenticationModal from "../AuthenticationModal";
import MainWrapper from "../MainWrapper";

export default function Shop() {
  const [products, setProducts] = useState([]);
  const [cats, setCats] = useState([]);
  const { data, isIdle, isLoading, isError, error } = useGeneralProductQuery();
  const [initialId, setInitialId] = useState(
    localStorage.getItem("orderId") ? localStorage.getItem("orderId") : null
  );
  const [selectedCats, setSelectedCats] = useState([]);
  const [order, setOrder] = useState({});

  const {
    data: catData,
    isIdle: catIdle,
    isLoading: catLoading,
    isError: catError,
  } = useCategoryQuery();

  useEffect(() => {
    if (data && !isLoading && data.items) {
      setProducts(data.items);
    }
  }, [isLoading, data]);

  const itemExists = (product) => {
    return order?.products && order.products.length > 0
      ? order.products.find((item) => item?.product_id === product.id)
      : false;
  };

  useEffect(() => {
    if (catData && !catLoading && catData.items) {
      setCats(catData?.items);
    }
  }, [catLoading, catData]);

  // getting previous data
  useEffect(() => {
    if (!initialId) {
      createRowAndFetchId();
      //console.log("create a id for to track ");
    } else {
      readRequestData();
    }
  }, [initialId]);

  const readRequestData = async () => {
    try {
      const response = await axios.get(apiUrl + "/api/cusOrder/" + initialId);
      if (
        response.status === 200 &&
        response.data &&
        response.data.order &&
        response.data.order.id > 0
      ) {
        // do the initial setup
        let initialData = response.data.order;
        setOrder(initialData);
      } else {
        console.log(response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error creating row:", error);
      toast.error("try again later");
    }
  };

  // Function to create a row and get the ID
  const createRowAndFetchId = async () => {
    try {
      const response = await axios.post(apiUrl + "/api/cusOrder", {
        status: 0,
      });
      if (
        response.status === 201 &&
        response.data &&
        response.data.order &&
        response.data.order.id > 0
      ) {
        localStorage.setItem("orderId", response.data.order.id);
        setInitialId(response.data.order.id);
      } else {
        console.log(response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error creating row:", error);
      toast.error("try again later");
    }
  };

  const onAdd = async (product) => {
    if (getCustomerToken()) {
      let prodInputEle = document.getElementById("product_" + product.id);
      if (itemExists(product)) {
        setOrder((prevCart) => ({
          ...prevCart,
          products: prevCart.products.filter(
            (p) => p.product_id !== product.id
          ),
        }));
        prodInputEle.value = 1;
      } else {
        setOrder((prevCart) => ({
          ...prevCart,
          products: [
            ...prevCart.products,
            {
              order_id: order.id,
              product_id: product.id,
              quantity: prodInputEle.value >= 1 ? prodInputEle.value : 1,
              product: product,
            },
          ],
        }));
      }
    } else {
      alert("you have to login ");
    }
  };

  const onQuanityChange = (q, product) => {
    let prodInputEle = document.getElementById("product_" + product.id);
    if (q >= 1) {
      if (itemExists(product)) {
        setOrder((prevCart) => ({
          ...prevCart,
          products: prevCart.products.map((p) => {
            if (p.product_id === product.id) {
              p.quantity = q;
            }
            return p;
          }),
        }));
      }
    } else {
      setOrder((prevCart) => ({
        ...prevCart,
        products: prevCart.products.filter((p) => p.product_id !== product.id),
      }));
      prodInputEle.value = 1;
      //alert("quantity must be greater than 1");
    }
  };

  const onOrderChange = async () => {
    let ids = [];

    if (order?.products && order.products.length > 0) {
      order.products.forEach((p) => {
        ids.push({ id: p.product_id, q: p.quantity });
      });
    }

    let res = await updateOrderProductsData({
      product_ids: ids,
    });

    if (res.status) {
      // do something
    } else {
      console.log("update failed");
    }
  };

  useEffect(() => {
    if (order && order.products) {
      onOrderChange();
    }
  }, [order]);

  const toggleSelect = (cat) => {
    if (selectedCats.includes(cat)) {
      setSelectedCats(
        selectedCats.filter((selectedCat) => selectedCat !== cat)
      );
    } else {
      setSelectedCats([...selectedCats, cat]);
    }
  };

  return (
    <>
      <Cart order={order} onUpdateQuantity={onQuanityChange} />
      <MainWrapper mainClass="cartContainer" topBar={false}>
        {!catLoading ? (
          <div className="catList">
            {cats &&
              cats.map((c) => {
                return (
                  <div
                    className={
                      "catListItem " +
                      (selectedCats.includes(c.id) ? "active" : "")
                    }
                    onClick={() => {
                      toggleSelect(c.id);
                    }}
                  >
                    {c.name}
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="p-4">{getT(translationKeys.pleaseWait)}</div>
        )}
        {!isLoading ? (
          <div className="e_prod_list">
            {products &&
              order &&
              products.map((p) => {
                return (
                  <Product
                    info={p}
                    onAdd={onAdd}
                    itemExists={itemExists}
                    onQuanityChange={onQuanityChange}
                    order={order}
                    selectedCats={selectedCats}
                  />
                );
              })}
          </div>
        ) : (
          <div className="p-4">{getT(translationKeys.pleaseWait)}</div>
        )}
      </MainWrapper>
    </>
  );
}
