import React from "react";
import { useParams } from "react-router-dom";
import RequestForm from "../forms/RequestForm";

export default function EditRequest() {
  const { id } = useParams();

  return (
    <>
      <RequestForm onEdit editId={id} />
    </>
  );
}
