import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";
import { useAuth } from "../../authContext";
import { getT, templateOptions, translationKeys } from "../utils";
import CustomInput from "./utils/CustomInput";
import {
  useCreateEmailTempalteMutation,
  useEmailTemplateQuery,
  useUpdateEmailTempalteMutation,
} from "../../queries/emailTemplates";
import CustomEditor from "./utils/CustomEditor";
import CustomSelect2 from "./utils/CustomSelect2";

const EmailTemplateForm = ({
  onEdit = false,
  editId = null,
  position = "",
}) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useEmailTemplateQuery(
    editId,
    editId ? true : false
  );
  const { TData } = useAuth();
  const formikRef = useRef();

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdateEmailTempalteMutation)
    : (mutationHook = useCreateEmailTempalteMutation);
  const mutation = mutationHook();

  const formSchema = Yup.object().shape({
    name: Yup.string().required(getT(translationKeys.nameRequired)),
    content: Yup.string().required(getT(translationKeys.contentRequired)),
    //subject: Yup.string().required(getT(translationKeys.subjectRequired)),
  });

  useEffect(() => {
    if (data && !isLoading && data?.item) {
      setEditData(data.item);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.item &&
        mutation.data.data.item.id > 0
      ) {
        onEdit
          ? toast.success(getT(translationKeys.updated))
          : toast.success(getT(translationKeys.created));
        navigate(paths.emailTemplatesPath);
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error(getT(translationKeys.generalError));
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    //console.log(values);
    const finalData = {
      name: values.name,
      content: values.content,
      subject: values.subject,
    };

    toast.warning(getT(translationKeys.pleaseWait));

    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const handleClear = () => {
    const { current } = formikRef;
    if (current) {
      current.resetForm();
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">{getT(translationKeys.generalError)}</div>;
  }

  return (
    <Container fluid>
      {position === "" && (
        <button
          onClick={() => {
            navigate(paths.emailTemplatesPath);
          }}
          className="btn btn-secondary my-2"
        >
          {getT(translationKeys.back)}
        </button>
      )}
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              name: editData?.name ?? "",
              content: editData?.content ?? "",
              subject: editData?.subject ?? "",
            }}
            innerRef={formikRef}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, formik, resetForm }) => (
              <Form>
                <Row>
                  <Col sm={12} md={12}>
                    <CustomSelect2
                      label={getT(translationKeys.template)}
                      name="name"
                      options={templateOptions}
                      defaultValue={values.name}
                    />
                  </Col>
                  <Col sm={12} md={12}>
                    <CustomInput
                      defaultValue={values.subject}
                      name="subject"
                      label={getT(translationKeys.subject)}
                    />
                  </Col>
                  <Col sm={12} md={12} className="my-2">
                    <CustomEditor
                      name={"content"}
                      label={getT(translationKeys.body)}
                    />
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      {getT(translationKeys.submit)}
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default EmailTemplateForm;
