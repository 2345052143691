import React from "react";
import DynamicSelectForm from "../forms/DynamicSelectForm";

export default function NewDynamicSelect() {
  return (
    <>
      <DynamicSelectForm />
    </>
  );
}
