// App.js
import React from "react";
import App from "./App";
import Mapp from "./Mapp";
import useScreenSize from "./components/useScreenSize";

const SwitchApp = () => {
  const isMobile = useScreenSize(768); // Set breakpoint at 768px
  return <>{isMobile ? <Mapp /> : <App />}</>;
};

export default SwitchApp;
