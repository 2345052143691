import React, { useEffect, useState } from "react";
import Filters from "../Filters";
import CustomTable from "../CustomTable";
import { BsPencilSquare } from "react-icons/bs";
import moment from "moment";
import { clientsColumns, getT, isDateBetween, translationKeys } from "../utils";
import { useNavigate } from "react-router-dom";
import paths, { adminWord } from "../../config/paths";
import { useUserQuery } from "../../queries/users";
import { useRoleQuery } from "../../queries/roles";

export default function Clients() {
  const [list, setList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();

  const { data, isIdle, isLoading, isError, error } = useUserQuery();

  const {
    data: roleData,
    isIdle: roleIdle,
    isLoading: roleLoading,
    isError: roleError,
  } = useRoleQuery();

  useEffect(() => {
    if (
      data &&
      !roleLoading &&
      !isLoading &&
      data.users &&
      data.users.length > 0
    ) {
      let apiList = data.users.map((r) => {
        return {
          id: "CL" + r?.id,
          date: moment(r?.created_at, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY"
          ),
          name: r?.name + " " + r?.lastName,
          email: r?.email,
          company: r?.company ? "si" : "no",
          role: r?.role?.name,
          status: r.status === 1 ? "si" : "no",
          role_id: r?.role_id,
          companyInfo: r?.company,
          action: (
            <span
              className="cursor me-2"
              onClick={(event) => {
                let id =
                  event.target.parentNode.getAttribute("data-id") ||
                  event.target.getAttribute("data-id");
                if (id > 0) {
                  navigate(adminWord + "/clienti/edit/" + id);
                }
              }}
              data-id={r.id}
            >
              <BsPencilSquare data-id={r.id} fontSize={25} />
            </span>
          ),
        };
      });

      setFilteredData(apiList);
      setList(apiList);
    }
  }, [isLoading, data, roleLoading]);

  useEffect(() => {
    if (roleData && !isLoading && roleData.roles) {
      setRoles(roleData.roles);
    }
  }, [roleLoading, roleData]);

  const onSearch = (values) => {
    var options = list;

    if (options && options.length > 0) {
      if (values.name !== "") {
        options = options.filter((s) => {
          let cString = s.name.toLowerCase();
          return cString.includes(values.name.toLowerCase());
        });
      }

      if (values.address !== "") {
        options = options.filter((s) => {
          let cString = s.add.toLowerCase();
          return cString.includes(values.address.toLowerCase());
        });
      }

      if (values.comune !== "") {
        options = options.filter((s) => {
          let cString = s.comune.toLowerCase();
          return cString.includes(values.comune.toLowerCase());
        });
      }

      if (values.from !== "" && values.to !== "") {
        options = options.filter((s) => {
          let date = moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD");
          return isDateBetween(values.from, values.to, date);
        });
      }

      if (parseInt(values.role_id) !== -1) {
        options = options.filter((s) => {
          return parseInt(s.role_id) === parseInt(values.role_id);
        });
      }

      options = options.filter((s) => {
        return parseInt(s.companyInfo) === parseInt(values.company);
      });
    }

    setFilteredData(options);
  };

  const onClear = () => {
    setFilteredData(list);
  };

  return (
    <>
      <Filters
        type="clients"
        onAdd={() => navigate(paths.newClientPath)}
        onFilter={onSearch}
        onClear={onClear}
        rolesData={roles}
      />
      <div className="customTable">
        {isIdle || isLoading || roleLoading || roleIdle ? (
          getT(translationKeys.pleaseWait)
        ) : (
          <CustomTable columns={clientsColumns} data={filteredData} />
        )}
      </div>
    </>
  );
}
