import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { IoMdEye } from "react-icons/io";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { FaFilePdf } from "react-icons/fa6";
import { RiMoneyEuroBoxFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import paths, { apiUrl, siteUrl, stripePublishableUrl } from "../config/paths";
import { loadStripe } from "@stripe/stripe-js";
import Swal from "sweetalert2";
import { getT, translationKeys } from "../admin/utils";

export default function CustomListTable({
  title = "",
  btnTitle = "add new",
  lengendOptions = [],
  listItems = [],
  bottomLeft = false,
  addBtn = true,
  bottomLeftData = "",
  onNew = () => {},
  onEdit = () => {},
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = listItems.slice(indexOfFirstItem, indexOfLastItem);
  const navigate = useNavigate();

  const totalPages = Math.ceil(listItems.length / itemsPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const createStripeCheckoutSession = async (id) => {
    localStorage.setItem("quotePaymentId", id);
    const orderId = localStorage.getItem("quotePaymentId")
      ? localStorage.getItem("quotePaymentId")
      : id; // Generate a custom order ID
    const amount = 200 * 100; // Amount in cents
    const response = await fetch(
      apiUrl + "/api/quotePayment/create-checkout-session",
      {
        // Change the API endpoint to create a Checkout session
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderId: orderId, // Pass the order ID in the request
          amount: amount,
          currency: "EUR",
          name: "quote_" + orderId,
          //email: user?.email,
        }),
      }
    );

    const data = await response.json();

    // Call function to handle Stripe Checkout with the received session ID
    handleStripeCheckout(data.sessionId); // Use session ID instead of client secret
  };

  const handleStripeCheckout = async (clientSecret) => {
    const stripe = await loadStripe(stripePublishableUrl);
    const { error } = await stripe.redirectToCheckout({
      sessionId: clientSecret,
    });
    if (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="CustomTableList d-flex flex-column">
        <div className="top d-flex  justify-content-between text-uppercase">
          <div className="title">{title}</div>
          {addBtn && (
            <button onClick={onNew} className="text-uppercase btn btn-success">
              {btnTitle}
            </button>
          )}
        </div>
        <div className="middle">
          <div className="legend">
            {lengendOptions &&
              lengendOptions.map((i) => {
                return (
                  <div className="legendItem text-capitalize">{i.name}</div>
                );
              })}
          </div>
          <div className="columns">
            {currentItems &&
              currentItems.map((item) => {
                return (
                  <div className="column">
                    {lengendOptions.map((l) => {
                      if (l?.type && l.type === "actions") {
                        return (
                          <div className="columnInnerItem">
                            <IoMdEye
                              fontSize={30}
                              className="cursor me-2"
                              onClick={() => {
                                navigate("/quoteInfo/" + item.id);
                              }}
                            />

                            <HiOutlinePencilAlt
                              fontSize={25}
                              className="cursor me-2"
                              onClick={() => {
                                navigate(paths.homePath + "?id=" + item.id);
                              }}
                            />
                            {/* <FaFilePdf
                              onClick={() => {
                                window.print();
                              }}
                              fontSize={22}
                              className="cursor me-2"
                            /> */}
                            {item.payment_status == "paid" &&
                            item.payment_id &&
                            item.status == 4 ? (
                              <RiMoneyEuroBoxFill
                                fontSize={25}
                                color="green"
                                className={"cursor me-2"}
                              />
                            ) : (
                              <RiMoneyEuroBoxFill
                                fontSize={25}
                                onClick={() => {
                                  Swal.fire({
                                    title: getT(
                                      translationKeys.do_you_want_to_pay_deposit_label
                                    ),
                                    text: "",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: getT(
                                      translationKeys.yes
                                    ),
                                    cancelButtonText: getT(translationKeys.no),
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      createStripeCheckoutSession(item.id);
                                    }
                                  });
                                }}
                                className={"cursor me-2"}
                              />
                            )}
                          </div>
                        );
                      }
                      return (
                        <div
                          className={"columnInnerItem " + l?.extraClass}
                          onClick={() => {
                            if (l.link) {
                              onEdit(item);
                            }
                          }}
                        >
                          {item[l.value]}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
          </div>
        </div>
        <div className="bottom d-flex justify-content-between">
          <div className="bottom_left">{bottomLeftData}</div>
          <div className="bottom_right">
            <IoIosArrowBack
              onClick={handlePreviousPage}
              className={
                currentPage === 1 ? "mx-3 cursor disabled" : "mx-3 cursor"
              }
              fontSize={18}
            />
            <IoIosArrowForward
              onClick={handleNextPage}
              className={
                currentPage === totalPages
                  ? "mx-3 cursor disabled"
                  : "mx-3 cursor"
              }
              fontSize={18}
            />
          </div>
        </div>
      </div>
    </>
  );
}
