import React, { useEffect, useState } from "react";
import CustomListTable from "../../components/CustomListTable";
import paths, { apiUrl } from "../../config/paths";
import { getCustomerToken } from "../../utils";
import Loading from "../../components/Loading";
import { formatMysqlDate, getT, translationKeys } from "../../admin/utils";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import TicketWidgetModal from "./TicketWidgetModal";
import { useAuth } from "../../authContext";

export default function TicketsWidget({ userId = -1 }) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState(null);
  const token = getCustomerToken();
  const navigate = useNavigate();
  const locationInfo = useLocation();
  const queryParams = new URLSearchParams(locationInfo.search);
  const idParam = queryParams.get("ticket");
  const { ticketModalStatus, setTicketModalStatus } = useAuth();

  const loadTickets = async () => {
    fetch(apiUrl + "/api/getTickets/" + userId, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((res) => {
        if (res && res.items && res.items.length > 0) {
          setList(
            res.items.map((i) => {
              i.created_at = formatMysqlDate(i.created_at);
              return i;
            })
          );
        }
      })
      .catch((error) => {
        console.error("Error checking token status:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (userId && userId > 0) {
      if (token) {
        loadTickets();
      }
    }
  }, [userId]);

  useEffect(() => {
    if (idParam > 0 && ticketModalStatus) {
      list.forEach((l) => {
        if (l.id == idParam) {
          setId(l.id);
          setShowModal(true);
          setTicketModalStatus(false);
        }
      });
    }
  }, [idParam, list, ticketModalStatus]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {showModal && (
        <TicketWidgetModal
          id={id}
          showModal={showModal}
          setShowModal={(val) => {
            setId(null);
            setShowModal(val);
            loadTickets();
          }}
        />
      )}
      <CustomListTable
        title={getT(translationKeys.tickets_table_header_title)}
        btnTitle={getT(translationKeys.tickets_table_add_new_button_title)}
        lengendOptions={[
          { name: getT(translationKeys.tickets_table_id), value: "id" },
          {
            name: getT(translationKeys.tickets_table_date),
            value: "created_at",
          },
          {
            name: getT(translationKeys.tickets_table_category),
            value: "category",
          },
          {
            name: getT(translationKeys.tickets_table_title),
            value: "name",
            link: true,
            extraClass: "extra_link",
          },
        ]}
        listItems={list}
        onNew={() => {
          setId(null);
          setShowModal(true);
          //navigate(paths.newMyTicketsPath);
        }}
        onEdit={(data) => {
          if (data && data.id > 0) {
            setId(data?.id);
            setShowModal(true);
            //navigate("/myTickets/edit/" + data?.id);
          }
        }}
      />
    </>
  );
}
