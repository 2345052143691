import React, { useEffect, useState } from "react";
import HeaderPage from "../Header";
import Cart from "./cart";
import Footer from "../Footer";
import Slider from "./Slider";
import { useProductQuery } from "../../queries/products";
import { useParams } from "react-router-dom";
import { updateOrderData, updateOrderProductsData } from "../../utils/helpers";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import axios from "axios";
import { apiUrl } from "../../config/paths";
import { currencyType, getT, translationKeys } from "../../admin/utils";
import Loading from "../../components/Loading";
import { getFolderPath } from "../../utils/api";

export default function ProductDetail() {
  const { id } = useParams();
  const [editData, setEditData] = useState();
  const { data, isIdle, isLoading, isError, error } = useProductQuery(
    null,
    id ? true : false,
    {
      permalink: id,
    }
  );
  const [initialId, setInitialId] = useState(
    localStorage.getItem("orderId") ? localStorage.getItem("orderId") : null
  );
  const [order, setOrder] = useState({});
  const [cats, setCatData] = useState([]);

  useEffect(() => {
    if (initialId) {
      readRequestData();
    }
  }, [initialId]);

  useEffect(() => {
    if (order && order.products) {
      onOrderChange();
    }
  }, [order]);

  const onOrderChange = async () => {
    let ids = [];

    if (order?.products && order.products.length > 0) {
      order.products.forEach((p) => {
        ids.push({ id: p.product_id, q: p.quantity });
      });
    }

    let res = await updateOrderProductsData({
      product_ids: ids,
    });

    if (res.status) {
      // do something
    } else {
      console.log("update failed");
    }
  };

  const itemExists = (product) => {
    return order && order?.products && order.products.length > 0
      ? order.products.find((item) => item?.product_id === product?.id)
      : false;
  };

  useEffect(() => {
    if (data && !isLoading && data?.item && data.item) {
      setEditData(data.item);
      setCatData(data.item.categories);
    }
  }, [isLoading, data]);

  const readRequestData = async () => {
    try {
      const response = await axios.get(apiUrl + "/api/cusOrder/" + initialId);
      if (
        response.status === 200 &&
        response.data &&
        response.data.order &&
        response.data.order.id > 0
      ) {
        // do the initial setup
        let initialData = response.data.order;
        setOrder(initialData);
      } else {
        console.log(response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error creating row:", error);
      toast.error("try again later");
    }
  };

  const getInfoByType = (type) => {
    let items = [];
    if (editData && editData.infos && editData.infos.length > 0) {
      editData.infos.forEach((i) => {
        if (i.type === type) {
          items.push(i);
        }
      });
    }
    return items;
  };

  const onAdd = async (product) => {
    let prodInputEle = document.getElementById("product_" + product.id);
    if (itemExists(product)) {
      setOrder((prevCart) => ({
        ...prevCart,
        products: prevCart.products.filter((p) => p.product_id !== product.id),
      }));
      prodInputEle.value = 1;
    } else {
      setOrder((prevCart) => ({
        ...prevCart,
        products: [
          ...prevCart.products,
          {
            order_id: order.id,
            product_id: product.id,
            quantity: prodInputEle.value >= 1 ? prodInputEle.value : 1,
            product: product,
          },
        ],
      }));
    }
  };

  const getVariationByType = (id) => {
    let items = [];
    if (editData && editData.variations && editData.variations.length > 0) {
      editData.variations.forEach((i) => {
        if (i.product_category_id === id) {
          items.push(i);
        }
      });
    }
    return items;
  };

  const onVariationChange = async (id) => {
    let ids = [];

    cats.forEach((c) => {
      let ele = document.getElementById(c.id + "id");
      if (parseInt(ele.value) > 0) {
        ids.push(ele.value);
      }
    });

    let res = await updateOrderData({
      product_id: editData?.id,
      variation_ids: ids,
    });

    if (res.status) {
      // do something
    } else {
      console.log("update failed");
    }
  };

  const getDefault = (id) => {
    let val = false;
    if (order && order.variations && order.variations.length > 0) {
      order.variations.forEach((v) => {
        if (v.product_option_id === id) {
          val = true;
        }
      });
    }

    return val;
  };

  const onQuanityChange = (q, product) => {
    if (q >= 1) {
      if (itemExists(product)) {
        setOrder((prevCart) => ({
          ...prevCart,
          products: prevCart.products.map((p) => {
            if (p.product_id === product.id) {
              p.quantity = q;
            }
            return p;
          }),
        }));
      }
    } else {
      alert("quantity must be greater than 1");
    }
  };

  return !isLoading ? (
    <>
      <Cart order={order} onUpdateQuantity={onQuanityChange} />
      <div className="main-container productDetail">
        <HeaderPage />
        <div className="productDetailWrapper">
          <div className="productDetailWrapper_left">
            <Slider items={getInfoByType("gallery")} selected={1} />
          </div>
          <div className="productDetailWrapper_right">
            <div className="title p-2">
              <span> {editData?.name}</span>
            </div>

            <div className="priceInfo p-2">
              <span>{getT(translationKeys.price)}</span>
              <span className="info">
                <span>{currencyType}</span>
                <span className="full_price"> {editData?.fullprice}</span>
                <span>{currencyType + editData?.price}</span>
              </span>
            </div>

            <div className="infoOptions p-2">
              {cats &&
                cats.map((c, index) => {
                  return (
                    <>
                      <div className="infoOption" key={index}>
                        <select
                          name={c.name}
                          id={c.id + "id"}
                          onChange={(e) => {
                            //setDynamicVal(e.target.value, c.name);
                            onVariationChange(e.target.value);
                          }}
                          className="form-select"
                        >
                          <option value={0}>{"choose " + c.name}</option>
                          {getVariationByType(c.id).map((v) => {
                            return (
                              <option selected={getDefault(v.id)} value={v.id}>
                                {v.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </>
                  );
                })}
            </div>
            <div className="infoQuantity">
              <input
                type="number"
                onChange={(event) => {
                  onQuanityChange(event.target.value, editData);
                }}
                id={"product_" + editData?.id}
                className="form-control m-2"
                defaultValue={1}
              />
              <span
                onClick={() => {
                  onAdd(editData);
                }}
                className="btn btn-danger cursor m-2"
              >
                {itemExists(editData) ? getT("remove") : getT("add")}
              </span>
            </div>
          </div>
        </div>

        <div className="block-list">
          {getInfoByType("block").map((b, index) => {
            if (b.file_id != null) {
              return (
                <div className="block-list-item" key={index}>
                  <img
                    src={getFolderPath + b?.file?.name}
                    alt={b?.file?.original_name}
                  />
                  <div className="des">{b.description}</div>
                </div>
              );
            } else {
              return (
                <div
                  className={
                    "block-list-item info " + b.bold_type + " " + b.align_type
                  }
                  key={index}
                >
                  <div className="des">{b.description}</div>
                </div>
              );
            }
          })}
        </div>

        {<Footer noTopBar={false} />}
      </div>
    </>
  ) : (
    <Loading />
  );
}
