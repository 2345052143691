import React, { useEffect } from "react";
import HeaderPage from "../Header";
import { updateOrderData } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import { getT, translationKeys } from "../../admin/utils";
import MainWrapper from "../MainWrapper";

export default function Success() {
  const navigate = useNavigate();

  const onCheckOut = async () => {
    let res = await updateOrderData({
      status: 3,
      payment_status: "paid",
    });

    if (res.status) {
      // do something
      localStorage.removeItem("orderId");
    } else {
      console.log("update failed");
    }
  };

  useEffect(() => {
    onCheckOut();
  }, []);

  return (
    <MainWrapper mainClass="dynamicPage" topBar={false}>
      <div className="infoWrapper">
        <p className="mt-5 center">
          {getT(translationKeys.paymentSuccessMessage)}
        </p>
      </div>
    </MainWrapper>
  );
}
