import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { usePageInfoQuery } from "../queries/pages";
import HeaderComponent from "../components/page/HeaderComponent";
import ImageWithTextComponent from "../components/page/ImageWithTextComponent";
import GalleryComponent from "../components/page/GalleryComponent";
import AccordionComponent from "../components/page/AccordionComponent";
import FormComponent from "../components/page/FormComponent";
import CustomFormComponent from "../components/page/CustomFormComponent";
import MainWrapper from "./MainWrapper";
import { getT, translationKeys } from "../admin/utils";
import useScreenSize from "../components/useScreenSize";
import { useAuth } from "../authContext";
import Navigation from "../components/Navigation";

export default function PageInfo() {
  const { lang, name } = useParams();
  const isMobile = useScreenSize(768);
  const { isCustomerAuthenticated, customerInfo } = useAuth();
  const [pageData, setPageData] = useState({});
  console.log(lang);
  const { data, isIdle, isLoading, isError, error } = usePageInfoQuery(
    name,
    name ? true : false,
    {
      lng: localStorage.getItem("lng"),
    }
  );

  useEffect(() => {
    if (data && !isLoading && data.item) {
      setPageData(data.item);
    } else {
      setPageData({});
    }
  }, [isLoading, data]);

  const getStyleData = () => {
    console.log(pageData);
    return pageData?.size_type === "pixels"
      ? pageData?.size > 0
        ? pageData?.size + "px"
        : "1100px"
      : "auto";
  };

  return (
    <>
      <MainWrapper
        mainClass="dynamicPage"
        showFooter={isMobile ? false : true}
        showChatBtn={true}
      >
        {pageData && pageData.status && !isLoading ? (
          pageData &&
          pageData.components &&
          pageData.components.length > 0 && (
            <div
              className="front_component_list"
              style={{ maxWidth: getStyleData() }}
            >
              {pageData.components.map((c, index) => {
                if (c.status) {
                  return (
                    <div
                      className={
                        "front_component_list_item " +
                        c.type +
                        "_component " +
                        "content_" +
                        c?.content?.contentPosition +
                        " " +
                        "image_" +
                        c?.content?.imagePosition +
                        " grid_size_" +
                        c?.content?.gridSize
                      }
                      key={index}
                    >
                      {c.type === "header" && <HeaderComponent info={c} />}
                      {c.type === "imagewithtext" && (
                        <ImageWithTextComponent info={c} />
                      )}
                      {c.type === "gallery" && <GalleryComponent info={c} />}
                      {c.type === "accordion" && (
                        <AccordionComponent info={c} />
                      )}
                      {c.type === "form" && <FormComponent info={c} />}
                      {c.type === "customform" && (
                        <CustomFormComponent info={c} />
                      )}
                    </div>
                  );
                }
              })}
            </div>
          )
        ) : pageData && !pageData.status && !isLoading ? (
          <div className="infoWrapper">{getT(translationKeys.notfound)}</div>
        ) : (
          <div className="infoWrapper">{getT(translationKeys.pleaseWait)}</div>
        )}
        {isMobile && isCustomerAuthenticated && <Navigation />}
      </MainWrapper>
    </>
  );
}
