import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths, { apiUrl } from "../../config/paths";
import Loading from "../../components/Loading";
import { useAuth } from "../../authContext";
import {
  getT,
  parallaxTypes,
  subVariationTypes,
  translationKeys,
  variationTypes,
} from "../utils";
import CustomInput from "./utils/CustomInput";

import CustomSelect from "./utils/CustomSelect";
import { useProductInfoQuery } from "../../queries/productInfo";
import CustomTextArea from "./utils/CustomTextArea";
import axios from "axios";
import { getFromLocalStorage } from "../../utils/helpers";
import { useQueryClient } from "@tanstack/react-query";
import { useCategoryQuery } from "../../queries/categories";
import CustomSelect2 from "./utils/CustomSelect2";

const ProductInfoForm = ({
  onEdit = false,
  editId = null,
  pid = null,
  onClose = () => {},
}) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useProductInfoQuery(
    editId,
    editId ? true : false
  );

  const { TData } = useAuth();
  const queryClient = useQueryClient();

  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);

  const formSchema = Yup.object().shape({
    //name: Yup.string().required(getT(translationKeys.nameRequired)),
    /*description: Yup.string().required(
      getT(translationKeys.descriptionRequired)
    ),*/
  });

  useEffect(() => {
    if (data && !isLoading && data?.item) {
      setEditData(data?.item);
    }
    console.log(pid);
  }, [isLoading, data]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    //console.log(values);
    const finalData = {
      name: values.name,
      product_id: pid,
      type: values.type.value,
      description: values.description,
      bold_type: values.bold_type,
      align_type: values.align_type,
      active: values.active ? 1 : 0,
    };

    let formData = new FormData();
    formData.append("file", file);

    // Append additional form fields
    Object.keys(finalData).forEach((key) => {
      formData.append(key, finalData[key]);
    });

    toast.warning(getT(translationKeys.pleaseWait));

    try {
      let response = {};
      if (editId > 0) {
        response = await axios.post(
          apiUrl + "/api/productInfo/updateData/" + editId,
          formData,
          {
            headers: {
              Authorization: getFromLocalStorage("userToken"),
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        response = await axios.post(apiUrl + "/api/productInfo", formData, {
          headers: {
            Authorization: getFromLocalStorage("userToken"),
            "Content-Type": "multipart/form-data",
          },
        });
      }

      if (response.data && response.data.item && response.data.item.id > 0) {
        toast.dismiss();
        resetForm();

        // Reset the file input
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
        toast.success(getT(translationKeys.updated));
        queryClient.invalidateQueries("productInfoOptions");
        onClose();
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("try again later");
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    navigate(paths.productsPath);
  };

  const getInitialInfo = (id) => {
    let output = {};
    let inputArray = parallaxTypes;
    inputArray.forEach((q) => {
      if (q.value === id) {
        output = {
          value: q.value,
          label: q.name,
        };
      }
    });

    return output;
  };

  if (isLoading) {
    return <Loading />;
  }

  const boldOptions = [
    {
      name: "bold",
      value: "bold",
    },
    {
      name: "italic",
      value: "italic",
    },
    {
      name: "normal",
      value: "normal",
    },
  ];

  const alignOptions = [
    {
      name: "center",
      value: "center",
    },
    {
      name: "left",
      value: "left",
    },
    {
      name: "right",
      value: "right",
    },
  ];

  if (isError) {
    return <div className="leadForm">{getT(translationKeys.generalError)}</div>;
  }

  return (
    <Container fluid className="leadForm">
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              name: editData?.name ?? "",
              type: getInitialInfo(editData?.type) ?? {
                label: "block",
                value: "block",
              },
              description: editData?.description ?? "",
              align_type: editData?.align_type ?? "",
              bold_type: editData?.bold_type ?? "",
              active: editData?.active && editData.active ? true : false,
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, formik }) => (
              <Form>
                <Row>
                  <Col md={12}>
                    <div>
                      <Field
                        type="checkbox"
                        className="form-check-input me-2"
                        name="active"
                      />
                      <label className="me-2">
                        {getT(translationKeys.active)}{" "}
                      </label>
                    </div>
                  </Col>
                  <Col sm={12} md={12}>
                    <CustomSelect
                      label={getT(translationKeys.type)}
                      name="type"
                      defaultValue={values.type}
                      options={
                        parallaxTypes &&
                        parallaxTypes?.map((option, index) => ({
                          label: option.name,
                          value: option.value,
                        }))
                      }
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <CustomSelect2
                      label={getT(translationKeys.boldLabel)}
                      name="bold_type"
                      options={boldOptions}
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <CustomSelect2
                      label={getT(translationKeys.alignmentLabel)}
                      name="align_type"
                      options={alignOptions}
                    />
                  </Col>
                  {/* <Col sm={12} md={12}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.name}
                      name="name"
                      label={getT(translationKeys.name)}
                    />
                    </Col> */}
                </Row>
                <Row className="mt-2">
                  <Col sm={12} md={12}>
                    <label>File</label>
                    <input
                      type="file"
                      name="file"
                      ref={fileInputRef}
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                      className="form-control my-2"
                    />
                  </Col>
                </Row>

                {values.type.value === "block" && (
                  <Row>
                    <Col sm={12} md={12}>
                      <CustomTextArea
                        name="description"
                        label={getT(translationKeys.description)}
                        defaultValue={values.description}
                        placeholder=""
                      />
                    </Col>
                  </Row>
                )}

                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      {getT(translationKeys.submit)}
                    </button>
                    {/* <button
                      type="button"
                      onClick={() => handleClear(formik)}
                      className="btn btn-secondary"
                    >
                      {getT(translationKeys.back)}
                    </button> */}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default ProductInfoForm;
