import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths, { apiUrl } from "../../config/paths";
import Loading from "../../components/Loading";
import CustomInput from "./utils/CustomInput";
import CustomSelect2 from "./utils/CustomSelect2";
import { MdLandslide, MdOutlineLandslide } from "react-icons/md";
import {
  airConditionerOptions,
  electric_car_Options,
  getT,
  heatingOptions,
  inductionOptions,
  infoOptions,
  translationKeys,
} from "../utils";

import {
  floorsOptions,
  getCustomerToken,
  homeTypes,
  inclinationTypes,
  personTypes,
  riscaldamentoOptions,
  updateQuoteData,
} from "../../utils";
import { FaBuilding, FaHome } from "react-icons/fa";
import {
  BsBuildingsFill,
  BsChevronDoubleLeft,
  BsChevronDoubleRight,
  BsFillPersonFill,
} from "react-icons/bs";
import { Button } from "react-bootstrap";
import axios from "axios";
import WrapperModal from "../../components/ModalWrapper";
import AuthenticationModal from "../../pages/AuthenticationModal";
import { useAuth } from "../../authContext";

const FrontForm = ({
  onEdit = false,
  editId = null,
  step = 0,
  onPrev = () => {},
  onNext = () => {},
  idParam,
  initData = {},
  area = null,
  prevAddress = "",
  onUpdateId = () => {},
  address = {},
  location = {},
  refCode = "",
}) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const { customerInfo, toggleLoginModal } = useAuth();

  useEffect(() => {
    setTimeout(() => {
      if (formikRef && formikRef.current && area > 0) {
        formikRef.current.setFieldValue("area", area);
        formikRef.current.setFieldValue("full_area", area);
      }
    }, 50);
  }, [area]);

  useEffect(() => {
    setTimeout(() => {
      if (formikRef && formikRef.current && address && address.address) {
        formikRef.current.setFieldValue("address", address.address);
        formikRef.current.setFieldValue("city", address.city);
        formikRef.current.setFieldValue(
          "prov",
          address.country_state_code
            ? address.country_state_code
            : address.province
        );
        formikRef.current.setFieldValue(
          "cap",
          address.zip ? address.zip : address.cap
        );
      }
    }, 50);
  }, [address]);

  useEffect(() => {
    if (customerInfo?.email) {
      if (formikRef && formikRef.current) {
        formikRef.current.setFieldValue("email", customerInfo?.email);
        formikRef.current.setFieldValue("user_id", customerInfo?.id);
      }
    }
  }, [customerInfo]);

  const newSchema = {
    home_type: Yup.string().required(getT(translationKeys.home_type_required)),
    person_type: Yup.string().required(
      getT(translationKeys.person_type_required)
    ),
    area: Yup.string().required(getT(translationKeys.mqt_required)),
    full_area: Yup.string().required(getT(translationKeys.mqi_required)),
    //f1: Yup.string().required(getT(translationKeys.f1_required)),
    //f2: Yup.string().required(getT(translationKeys.f2_required)),
    //f3: Yup.string().required(getT(translationKeys.f3_required)),
    electric_car: Yup.string().required(
      getT(translationKeys.electric_car_required)
    ),
    induction_hub: Yup.string().required(
      getT(translationKeys.induction_hub_required)
    ),
    air_conditioners_pump: Yup.string().required(
      getT(translationKeys.air_conditioners_pump_required)
    ),
    heat_pump: Yup.string().required(getT(translationKeys.heat_pump_required)),
    people_count: Yup.string().required(
      getT(translationKeys.people_count_required)
    ),
    address: Yup.string().required(getT(translationKeys.address_required)),
    cap: Yup.string().required(getT(translationKeys.zip_required)),
    city: Yup.string().required(getT(translationKeys.cityRequired)),
    prov: Yup.string().required(getT(translationKeys.prov_required)),
    //email: Yup.string().required(getT(translationKeys.emailRequired)),
    //phone: Yup.string().required(getT(translationKeys.phone_required)),
    country: Yup.string().required(getT(translationKeys.countryRequired)),
  };

  const formSchema = Yup.object().shape(newSchema);

  const handleSubmit = async (values, { setSubmitting }) => {
    let finalValues = {
      //step1
      is_owner: values.is_owner,
      home_type: values.home_type,
      //energy_price: values?.energy_price,
      //energy_increase: values?.energy_increase,
      inclination_type: values.inclination_type,
      floor_status: values.inclination_status,
      floor_count_status: values?.floor_count_status,
      floor_count: values?.floor_count,
      person_type: values.person_type,
      //step2
      area: values.area,
      full_area: values.full_area,
      f1: values?.f1,
      f2: values?.f2,
      f3: values?.f3,
      f_status: values.fidontknow,
      //step3
      electric_car: values?.electric_car,
      induction_hub: values?.induction_hub,
      air_conditioners_pump: values?.air_conditioners_pump,
      heat_pump: values?.heat_pump,
      people_count: values?.people_count,
      //extra
      email: values.email,
      mobile: values.phone,
      address: values.address,
      cap: values.cap,
      country: values.country,
      city: values.city,
      province: values.prov,
      status: 2,
      name: values.name,
      col: "",
      tilt: values?.mqi,
      inclination: values?.mqt,
      company_name: values.company_name,
      sanitary_water: values.sanitary_water,
      is_air_conditioners_pump: values.is_air_conditioners_pump,
      user_id: values.user_id,
    };

    if (!idParam) {
      finalValues["ref_code"] = refCode;
    }

    let upateId = idParam ? idParam : localStorage.getItem("quoteId");

    const customerToken = getCustomerToken();
    if (!customerToken) {
      toggleLoginModal(true);
      //toast.error(getT(translationKeys.loginError));
      return;
    }

    if (upateId > 0) {
      let url = "/api/cusQuote/" + upateId;
      if (!idParam) {
        url = "/api/cusQuote/" + upateId + "/complete";
      }
      try {
        // Make Axios POST request to save data
        const response = await axios.put(apiUrl + url, finalValues);
        if (
          response.status === 200 &&
          response.data &&
          response.data.quote &&
          response.data.quote.id > 0
        ) {
          if (!idParam) {
            localStorage.removeItem("quoteId");
            toast.success(getT(translationKeys.request_sent_success_label), {
              position: "top-right",
            });
            navigate(paths.homePath);
            window.location.reload();
          } else if (idParam && step === 3) {
            toast.success(getT(translationKeys.request_update_success_label), {
              position: "top-right",
            });
          }
        } else {
          console.log(response.status, response);
          toast.error(getT(translationKeys.generalError), {
            position: "top-right",
          });
        }
        setSubmitting(false);
      } catch (error) {
        console.error("Error saving data:", error);
        setSubmitting(false);
      }
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    navigate(paths.translationsPath);
  };

  const createRowAndFetchId = async () => {
    toast.loading(getT(translationKeys.pleaseWait));
    try {
      const response = await axios.post(apiUrl + "/api/cusQuote", {
        status: 0,
        lat: location.lat,
        lng: location.lng,
        address: address?.address ?? "",
        city: address?.city ?? "",
        cap: address?.zip ?? "",
        country: address?.country,
        province: address?.country_state_code
          ? address?.country_state_code
          : address?.province,
      });
      if (
        response.status === 201 &&
        response.data &&
        response.data.quote &&
        response.data.quote.id > 0
      ) {
        localStorage.setItem("quoteId", response.data.quote.id);
        toast.dismiss();
        return {
          id: response.data.quote.id,
          status: true,
        };
      } else {
        console.log(response.status, response.statusText);
        toast.dismiss();
        return {
          id: -1,
          status: false,
        };
      }
    } catch (error) {
      console.error("Error creating row:", error);
      toast.error(getT(translationKeys.generalError));
      return {
        id: -1,
        status: false,
      };
    }
  };

  const getIconByType = (key, val = false) => {
    let final = "";
    switch (key) {
      case "single":
        final = <FaHome />;
        break;
      case "shed":
        final = <FaBuilding />;
        break;
      case "condominium":
        final = <BsBuildingsFill />;
        break;
      case "ground":
        final = <MdLandslide />;
        break;
      case 1:
        final = !val ? (
          <img src={"/assets/piano.png"} alt="1" />
        ) : (
          <img src={"/assets/piano_hov.png"} alt="1" />
        );
        break;
      case 2:
        final = !val ? (
          <img src={"/assets/1falda.png"} alt="1" />
        ) : (
          <img src={"/assets/1falda_hov.png"} alt="1" />
        );
        break;
      case 3:
        final = !val ? (
          <img src={"/assets/2falde.png"} alt="1" />
        ) : (
          <img src={"/assets/2falde_hov.png"} alt="1" />
        );
        break;
      case 4:
        final = !val ? (
          <img src={"/assets/2falde_b.png"} alt="1" />
        ) : (
          <img src={"/assets/2falde_b_hov.png"} alt="1" />
        );
        break;
      default:
        break;
    }
    return final;
  };

  return (
    <>
      <Container fluid className="frontForm">
        <div className="frontFormInner">
          <div className="frontFormInnerInfo">
            <Formik
              initialValues={{
                // step1
                home_type: initData?.home_type ? initData.home_type : "",
                is_owner:
                  initData?.is_owner && initData?.is_owner ? true : false,
                person_type: initData?.person_type ?? "",
                inclination_type: initData?.inclination_type ?? "",
                inclination_status: editData?.floor_status ? true : false,
                floor_count: initData?.floor_count ?? "",
                floor_count_status:
                  initData?.floor_count_status && initData?.floor_count_status
                    ? true
                    : false,
                // step2
                area: initData?.area ? initData.area : initData.inclination,
                full_area: initData?.full_area
                  ? initData.full_area
                  : initData?.tilt,
                f1: initData?.f1 ?? "",
                f2: initData?.f2 ?? "",
                f3: initData?.f3 ?? "",
                fidontknow:
                  initData?.f_status && initData?.f_status ? true : false,
                // step 3
                electric_car: initData?.electric_car ?? "",
                induction_hub: initData?.induction_hub ?? "",
                air_conditioners_pump: initData?.air_conditioners_pump ?? "",
                heat_pump: initData?.heat_pump ?? "",
                people_count: initData?.people_count ?? "",

                //extra
                sanitary_water: initData?.sanitary_water ?? "",
                name: initData?.name ? initData.name : customerInfo?.name,
                company_name: initData?.company_name ?? "",
                address: address?.address ? address.address : prevAddress,
                cap: address?.cap ? address.cap : address?.zip,
                prov: address?.country_state_code
                  ? address.province
                  : address?.country_state_code,
                city: address?.city ? address.city : address?.city,
                phone: initData?.mobile ?? "",
                email: customerInfo?.email
                  ? customerInfo?.email
                  : initData?.email,
                user_id:
                  customerInfo?.id && customerInfo.id > 0
                    ? customerInfo?.id
                    : null,
                country: initData?.country
                  ? initData.country
                  : address?.country,
                is_air_conditioners_pump: initData?.is_air_conditioners_pump
                  ? true
                  : false,
              }}
              validationSchema={formSchema}
              onSubmit={handleSubmit}
              innerRef={formikRef}
            >
              {({
                values,
                setFieldValue,
                getFieldValue,
                formik,
                isSubmitting,
                handleChange,
              }) => (
                <Form>
                  <div className="frontFormInnerInfoSteps">
                    {step === 1 && (
                      <Row>
                        <Col sm={12} md={12} className="mt-2">
                          <label className="form-label b-title">
                            {getT(translationKeys.constructionType)}
                          </label>
                          <div className="home-types">
                            {homeTypes &&
                              homeTypes.map((h) => {
                                return (
                                  <div
                                    onClick={() => {
                                      if (h.value === values.home_type) {
                                        setFieldValue("home_type", "");
                                      } else {
                                        setFieldValue("home_type", h.value);
                                      }
                                    }}
                                    className={
                                      values.home_type === h.value
                                        ? "home-type active"
                                        : "home-type "
                                    }
                                  >
                                    <span>{getIconByType(h.value)}</span>
                                    <span>
                                      {" "}
                                      {getT(translationKeys[h.name])}
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="d-flex checkBox">
                            <Field
                              type="checkbox"
                              className="form-check-input me-2"
                              name="is_owner"
                            />
                            <label>{getT(translationKeys.is_owner)}</label>
                          </div>
                        </Col>
                        <Col sm={12} md={12} className="my-3">
                          <label className="form-label b-title">
                            {getT(translationKeys.personType)}
                          </label>
                          <div className="home-types">
                            {personTypes &&
                              personTypes.map((h) => {
                                return (
                                  <div
                                    onClick={() => {
                                      if (h.value === values.person_type) {
                                        setFieldValue("person_type", "");
                                      } else {
                                        setFieldValue("person_type", h.value);
                                      }
                                    }}
                                    className={
                                      values.person_type === h.value
                                        ? "home-type active"
                                        : "home-type "
                                    }
                                  >
                                    <span>
                                      {h.value === "company" ? (
                                        <BsBuildingsFill />
                                      ) : (
                                        <BsFillPersonFill />
                                      )}
                                    </span>
                                    <span>{getT(translationKeys[h.name])}</span>
                                  </div>
                                );
                              })}
                          </div>
                        </Col>
                        <Col sm={12} md={12}>
                          <label className="form-label b-title">
                            {getT(translationKeys.inclinationFormLabel)}
                          </label>
                          <div
                            className={
                              values.floor_status
                                ? "home-roof-types disabled"
                                : "home-roof-types"
                            }
                          >
                            {inclinationTypes.map((i) => {
                              return (
                                <div
                                  onClick={() => {
                                    if (!values.floor_status) {
                                      if (i.value === values.inclination_type) {
                                        setFieldValue("inclination_type", "");
                                      } else {
                                        setFieldValue(
                                          "inclination_type",
                                          i.value
                                        );
                                      }
                                    }
                                  }}
                                  className={
                                    values.inclination_type === i.value
                                      ? "home-roof-type active"
                                      : "home-roof-type "
                                  }
                                >
                                  {getIconByType(
                                    i.value,
                                    values.inclination_type === i.value
                                  )}
                                </div>
                              );
                            })}
                          </div>
                          <div className="d-flex  checkBox mb-2 mt-2">
                            <Field
                              type="checkbox"
                              id="floor_status"
                              className="form-check-input me-2"
                              name="floor_status"
                              onChange={(event) => {
                                handleChange(event);
                                if (event.target.value) {
                                  setFieldValue("inclination_type", 0);
                                }
                              }}
                            />
                            <label className="me-2">
                              {getT(translationKeys.idontknow)}
                            </label>
                          </div>
                        </Col>
                        <Col sm={12} md={12} className="mt-2 customSelect">
                          <CustomSelect2
                            label={getT(translationKeys.floorslabel)}
                            name="floor_count"
                            options={floorsOptions}
                            defaultValue={values.floor_count}
                            defaultSelectVal={getT(
                              translationKeys.selectionLabel
                            )}
                            boxVisible={true}
                            boxIcon={"stairs"}
                            disabled={values.floor_count_status ? true : false}
                          />

                          <div className="d-flex  checkBox mt-2">
                            <Field
                              type="checkbox"
                              id="floor_count_status"
                              className="form-check-input me-2"
                              name="floor_count_status"
                              onChange={(event) => {
                                handleChange(event);
                                if (event.target.value) {
                                  setFieldValue("floor_count", "");
                                }
                              }}
                            />
                            <label className="me-2">
                              {getT(translationKeys.idontknowTerraLabel)}
                            </label>
                          </div>
                        </Col>

                        {/* <Col sm={12} md={6}>
                            <CustomInput
                              onEdit={false}
                              defaultValue={values.area}
                              name="area"
                              label={getT(translationKeys.squareMeter)}
                            />
                      </Col> */}
                      </Row>
                    )}
                    {step === 2 && (
                      <>
                        <Row>
                          <Col md={12}>
                            <label className="b-title step2">
                              {getT(translationKeys.selection_roof_label)}
                            </label>
                            <p>
                              {getT(translationKeys.selection_roof_sub_label)}
                            </p>
                            <div className="polygon_field">
                              <img
                                src={"/assets/panel.png"}
                                alt=""
                                className="panelImg"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <div className="meters">
                              <div className="mqt meter">
                                <CustomInput
                                  onEdit={false}
                                  defaultValue={values.area}
                                  name="area"
                                  classList="form-control placeholder_center placeholder_light"
                                  label={
                                    getT(translationKeys.mqt) +
                                    "( " +
                                    area +
                                    " )"
                                  }
                                  placeholder="0"
                                />
                              </div>
                              <div className="mqi meter">
                                <CustomInput
                                  onEdit={false}
                                  defaultValue={values.full_area}
                                  classList="form-control placeholder_center placeholder_light"
                                  name="full_area"
                                  label={getT(translationKeys.mqi)}
                                  placeholder="0"
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <label className="b-title mt-3 step2">
                              {getT(translationKeys.energy_title_label)}
                            </label>
                            <p>{getT(translationKeys.energy_sub_label)}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <div className="fvalues">
                              <CustomInput
                                onEdit={false}
                                placeholder={"F1"}
                                defaultValue={values.f1}
                                name="f1"
                                disabled={values.fidontknow ? true : false}
                                classList="form-control placeholder_center placeholder_light fval"
                                labelStatus={false}
                              />
                              <CustomInput
                                onEdit={false}
                                placeholder={"F2"}
                                defaultValue={values.f2}
                                name="f2"
                                disabled={values.fidontknow ? true : false}
                                classList="form-control placeholder_center placeholder_light fval"
                                labelStatus={false}
                              />
                              <CustomInput
                                onEdit={false}
                                placeholder={"F3"}
                                defaultValue={values.f3}
                                name="f3"
                                disabled={values.fidontknow ? true : false}
                                classList="form-control placeholder_center placeholder_light fval"
                                labelStatus={false}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <div className="d-flex checkBox">
                              <Field
                                type="checkbox"
                                className="form-check-input me-2"
                                name="fidontknow"
                                checked={values.fidontknow}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                              <label className="me-2">
                                {getT(translationKeys.fidontknow)}
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Row className="my-2">
                          {values.fidontknow && (
                            <>
                              <div className="energyInfo">
                                <div className="energyInfoTop">
                                  <p>
                                    {getT(translationKeys.energy_note_label)}
                                  </p>
                                </div>
                                {/*<div className="engeryInfoBottom">
                                  <CustomSelect2
                                    label={getT(translationKeys.personsLabel)}
                                    name="people_count"
                                    options={riscaldamentoOptions}
                                    labelStatus={false}
                                    defaultSelectVal={getT(
                                      translationKeys.selectionLabel_conviventi
                                    )}
                                  />
                                  <CustomSelect2
                                    label={getT(translationKeys.personsLabel)}
                                    name="heat_pump"
                                    labelStatus={false}
                                    options={floorsOptions}
                                    defaultSelectVal={getT(
                                      translationKeys.selectionLabel_riscaldamento
                                    )}
                                  />
                                </div>*/}
                              </div>
                            </>
                          )}
                        </Row>
                      </>
                    )}
                    {step === 3 && (
                      <>
                        <Row>
                          <Col md={12} className="mb-2">
                            <label className="b-title">
                              {getT(translationKeys.profile_consumption_title)}
                            </label>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} className="mb-2">
                            <CustomSelect2
                              label={getT(translationKeys.electricCarLabel)}
                              name="electric_car"
                              options={electric_car_Options}
                              defaultValue={values.electric_car}
                              defaultSelectVal={getT(
                                translationKeys.selection_electric_placeholder
                              )}
                              labelStatus={false}
                              boxVisible={true}
                              boxIcon={"car"}
                            />
                          </Col>
                          <Col md={12} className="mb-2">
                            <CustomSelect2
                              label={getT(translationKeys.kichenLabel)}
                              name="induction_hub"
                              options={inductionOptions}
                              defaultValue={values.induction_hub}
                              labelStatus={false}
                              defaultSelectVal={getT(
                                translationKeys.selection_induction_placeholder
                              )}
                              boxVisible={true}
                              boxIcon={"kichen"}
                            />
                          </Col>
                          <Col md={12} className="mb-2">
                            {/*<CustomSelect2
                              label={getT(translationKeys.acLabel)}
                              name="is_air_conditioners_pump"
                              options={infoOptions}
                              defaultValue={values.is_air_conditioners_pump}
                              defaultSelectVal={getT(
                                translationKeys.selection_cold_placeholder
                              )}
                              boxVisible={true}
                              onUpdate={(data) => {
                                console.log(data, "test");
                              }}
                              labelStatus={false}
                              boxIcon={"cold"}
                            />*/}
                            <CustomSelect2
                              label={getT(translationKeys.acLabel2)}
                              name="air_conditioners_pump"
                              options={airConditionerOptions}
                              defaultValue={values.air_conditioners_pump}
                              defaultSelectVal={getT(
                                translationKeys.selection_cold_placeholder
                              )}
                              labelStatus={false}
                              boxVisible={true}
                              boxIcon={"cold"}
                            />
                          </Col>
                          <Col>
                            {/* <CustomSelect2
                              label={getT(translationKeys.acLabel2)}
                              name="air_conditioners_pump"
                              options={airConditionerOptions}
                              defaultValue={values.air_conditioners_pump}
                              defaultSelectVal={getT(
                                translationKeys.selection_heat_placeholder
                              )}
                              labelStatus={false}
                              boxVisible={true}
                              boxIcon={"flakes"}
                            /> */}
                          </Col>
                          <Col md={12} className="mb-2">
                            <CustomSelect2
                              label={getT(translationKeys.personsLabel)}
                              name="heat_pump"
                              labelStatus={false}
                              options={riscaldamentoOptions}
                              defaultValue={values.air_conditioners_pump}
                              defaultSelectVal={getT(
                                translationKeys.selectionLabel_riscaldamento
                              )}
                              boxVisible={true}
                              boxIcon={"flakes"}
                            />
                          </Col>

                          <Col md={12} className="mb-2">
                            <CustomSelect2
                              label={getT(translationKeys.personsLabel)}
                              name="people_count"
                              options={riscaldamentoOptions}
                              labelStatus={false}
                              defaultValue={values.people_count}
                              defaultSelectVal={getT(
                                translationKeys.selection_people_placeholder
                              )}
                              boxIcon={"people"}
                              boxVisible={true}
                            />
                          </Col>
                        </Row>
                        {/*<Row className="my-2">
                          <Col md={6}>
                            <CustomSelect2
                              label={getT(translationKeys.sanitary_water_label)}
                              name="sanitary_water"
                              options={infoOptions}
                              defaultValue={values.sanitary_water}
                              defaultSelectVal={getT(
                                translationKeys.selectionLabel
                              )}
                              boxVisible={true}
                            />
                          </Col> 
                        </Row>*/}
                        <Row className="mt-4">
                          <Col md={12}>
                            <label className="form-label b-title">
                              Intestatario del preventivo
                            </label>
                            {values.person_type === "company" && (
                              <div className="mb-2">
                                <CustomInput
                                  onEdit={false}
                                  defaultValue={values.company_name}
                                  name="company_name"
                                  labelStatus={false}
                                  placeholder={getT(
                                    translationKeys.company_name
                                  )}
                                  label={getT(translationKeys.company_name)}
                                />
                              </div>
                            )}
                          </Col>
                          {/* <Col md={12} className="mb-2">
                            <CustomInput
                              onEdit={false}
                              defaultValue={values.name}
                              name="name"
                              labelStatus={false}
                              placeholder={getT(translationKeys.name)}
                              label={getT(translationKeys.name)}
                            />
                          </Col> */}
                          <Col md={12} className="mb-2">
                            <CustomInput
                              onEdit={false}
                              defaultValue={values.address}
                              name="address"
                              placeholder={getT(translationKeys.address)}
                              labelStatus={false}
                              fieldType="auto"
                              isIcon={true}
                            />
                          </Col>
                          {/*<Col md={4} className="mb-2">
                            <CustomInput
                              onEdit={false}
                              defaultValue={values.zip}
                              name="zip"
                              placeholder={getT(translationKeys.zip)}
                              labelStatus={false}
                              label={getT(translationKeys.zip)}
                            />
                          </Col>*/}
                          <Col md={8} className="mb-2">
                            <CustomInput
                              onEdit={false}
                              defaultValue={values.city}
                              name="city"
                              placeholder={getT(translationKeys.comune)}
                              labelStatus={false}
                            />
                          </Col>
                          <Col md={4} className="mb-2">
                            <CustomInput
                              onEdit={false}
                              defaultValue={values.prov}
                              name="prov"
                              placeholder={getT(translationKeys.province)}
                              labelStatus={false}
                            />
                          </Col>
                          {/* <Col md={12} className="mb-2">
                            <CustomInput
                              onEdit={false}
                              defaultValue={values.country}
                              name="country"
                              placeholder={getT(translationKeys.country)}
                              labelStatus={false}
                            />
                          </Col>
                          <Col md={12} className="mb-2">
                          <label>{getT(translationKeys.loginLabel)}</label>
                          <p>{getT(translationKeys.loginSubLabel)}</p>
                        </Col> */}
                          {/* <Col md={12} className="mb-2">
                            <CustomInput
                              onEdit={false}
                              defaultValue={values.email}
                              name="email"
                              placeholder={getT(translationKeys.email)}
                              labelStatus={false}
                              label={getT(translationKeys.email)}
                            />
                          </Col>
                          <Col md={12} className="mb-4">
                            <CustomInput
                              onEdit={false}
                              defaultValue={values.phone}
                              name="phone"
                              placeholder={getT(translationKeys.phone)}
                              labelStatus={false}
                              label={getT(translationKeys.phone)}
                            />
                          </Col> */}
                        </Row>
                      </>
                    )}
                  </div>
                  <div className="btns">
                    <Button
                      style={{ marginRight: step === 3 ? "5px" : "10px" }}
                      onClick={() => {
                        onPrev();
                      }}
                      className={step === 3 ? "last" : ""}
                      variant={step === 0 ? "secondary" : "primary"}
                      type="button"
                    >
                      <BsChevronDoubleLeft />
                      <span>
                        {step !== 3 && getT(translationKeys.previous)}
                      </span>
                    </Button>
                    {step < 3 && (
                      <Button
                        onClick={async () => {
                          if (step === 1) {
                            // type and person type
                            if (values.home_type === "" || !values.home_type) {
                              toast.error(
                                getT(
                                  translationKeys.fill_construction_type_error
                                )
                              );
                              return;
                            }

                            if (!values.person_type) {
                              toast.error(
                                getT(translationKeys.fill_person_type_error)
                              );
                              return;
                            }

                            // inclination
                            if (
                              !values.floor_status &&
                              values.inclination_type === ""
                            ) {
                              toast.error(
                                getT(translationKeys.fillInclinationlabel)
                              );
                              return;
                            }

                            // energy details

                            if (
                              !values.floor_count_status &&
                              values.floor_count == ""
                            ) {
                              toast.error(
                                getT(translationKeys.fillPianifouriterralabel)
                              );
                              return;
                            }

                            if (
                              !values.floor_count == "" &&
                              values.floor_count_status
                            ) {
                              toast.error(
                                getT(translationKeys.fillPianifouriterralabel)
                              );
                              return;
                            }

                            let updateId = localStorage.getItem("quoteId");
                            if (!localStorage.getItem("quoteId")) {
                              console.log();
                              const { id, status } =
                                await createRowAndFetchId();
                              if (!status) {
                                return;
                              }
                              onUpdateId(id);
                              updateId = id;
                            }

                            console.log(updateId, "test");

                            // update
                            let res1 = await updateQuoteData(
                              {
                                is_owner: values.is_owner,
                                home_type: values.home_type,
                                area: area,
                                floor_count_status: values?.floor_count_status,
                                floor_count: values?.floor_count,
                                person_type: values.person_type,
                                inclination_type: values.inclination_type,
                                floor_status: values.floor_status,
                              },
                              updateId
                            );
                            if (res1.status) {
                              toast.dismiss();

                              onNext();
                            } else {
                              console.log("update failed");
                            }
                          }

                          if (
                            (!values.area && step === 2) ||
                            (!values.full_area && step === 2)
                          ) {
                            toast.error(getT(translationKeys.fillAllLabel));
                            return;
                          }

                          if (
                            (!values.f1 && step === 2 && !values.fidontknow) ||
                            (!values.f2 && step === 2 && !values.fidontknow) ||
                            (!values.f3 && step === 2 && !values.fidontknow)
                          ) {
                            toast.error(getT(translationKeys.fillAllLabel));
                            return;
                          }

                          /*if (
                            (values.fidontknow &&
                              values.people_count <= 0 &&
                              step === 2) ||
                            (values.fidontknow &&
                              values.heat_pump === "" &&
                              step === 2) ||
                            (values.mqi <= 0 && step === 2) ||
                            (values.mqt <= 0 && step === 2)
                          ) {
                            toast.error(getT(translationKeys.fillAllLabel));
                            return;
                          }*/

                          //
                          if (step === 2) {
                            let res = await updateQuoteData(
                              {
                                area: values.area,
                                full_area: values.full_area,
                                f1: values?.f1 > 0 ? values.f1 : 1,
                                f2: values?.f2 > 0 ? values.f2 : 1,
                                f3: values.f3 > 0 ? values.f3 : 1,
                                f_status: values.fidontknow,
                              },
                              localStorage.getItem("quoteId")
                            );
                            if (res.status) {
                              onNext();
                            } else {
                              console.log("update failed");
                            }
                          }
                        }}
                        variant={"primary"}
                        type="button"
                      >
                        <span>{getT(translationKeys.next)}</span>
                        <BsChevronDoubleRight />
                      </Button>
                    )}
                    {step === 3 && (
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant={"success"}
                      >
                        {!idParam
                          ? getT(translationKeys.quoteBtn)
                          : getT(translationKeys.update)}
                      </Button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </>
  );
};

export default FrontForm;
