import React, { useEffect, useRef, useState } from "react";
import {
  formatDateInfo,
  formatMysqlDate,
  formatMysqlDateToNormal,
  getT,
  translationKeys,
} from "../utils";
import { BsDownload, BsFillTrash3Fill, BsPencilSquare } from "react-icons/bs";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useDeleteMessageMutation } from "../../queries/messages";
import { useAuth } from "../../authContext";
import WrapperModal from "../../components/ModalWrapper";
import MessageModalForm from "../forms/MessageModalForm";
import axios from "axios";
import { apiUrl } from "../../config/paths";
import { getFromLocalStorage } from "../../utils/helpers";

export default function Messages({ list = [], fromId = 0 }) {
  const mutation = useDeleteMessageMutation();
  const { authUserId } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditMoal] = useState(false);
  const [editId, setEditId] = useState(null);
  const chatContainerRef = useRef(null);
  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (mutation.data.data.status) {
        toast.success("deleted");
      } else {
        toast.error("try again later");
      }
      mutation.reset();
    }
  }, [mutation]);

  useEffect(() => {
    scrollToBottom();
  });

  const onDelete = (id) => {
    if (id > 0) {
      Swal.fire({
        title: "Are you sure ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          toast.loading("Deleting");
          mutation.mutate({ id: id, user_id: authUserId });
        }
      });
    }
  };

  const scrollToBottom = () => {
    document.querySelector("#messageListId").scrollTo({
      top: document.querySelector("#messageListId").scrollHeight,
      behavior: "smooth",
    });
  };

  const downloadFile = async (id) => {
    window.open(apiUrl + `/api/message/attachment/${id}`, "_self");
  };

  return (
    <>
      <WrapperModal
        show={showModal}
        title={
          editModal
            ? getT(translationKeys.update)
            : getT(translationKeys.addLabel)
        }
        handleClose={() => {
          setShowModal(!showModal);
        }}
      >
        <MessageModalForm
          onEdit={editModal}
          editId={editId}
          onClose={() => {
            setShowModal(false);
            setEditMoal(false);
            setEditId(null);
          }}
        />
      </WrapperModal>
      <div className="messageList" id="messageListId" ref={chatContainerRef}>
        {list &&
          list.map((l, index) => {
            return (
              <div
                key={index}
                className={
                  fromId == l.user_id
                    ? "messageListItem right"
                    : "messageListItem"
                }
              >
                <div className="messageListItemInner">
                  <div className="msg">
                    <span>{l?.message}</span>
                    <span className="actions">
                      {l?.file_id && (
                        <BsDownload
                          onClick={() =>
                            downloadFile(l?.file_id, l?.file?.name)
                          }
                        />
                      )}
                      {/*  l.user_id == authUserId && (
                      <>
                        {l?.message && (
                          <BsPencilSquare
                            onClick={() => {
                              setEditId(l.id);
                              setEditMoal(true);
                              setShowModal(true);
                            }}
                          />
                        )}
                        <BsFillTrash3Fill onClick={() => onDelete(l.id)} />
                      </>
                          ) */}
                    </span>
                  </div>
                  <div className="note">
                    <span>{formatDateInfo(l?.created_at)}</span>
                    <span>
                      {" - "}
                      {l?.user && l?.user?.name
                        ? l?.user?.name + " " + l?.user?.lastName
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}
