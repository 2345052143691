import React, { useEffect, useState } from "react";
import CustomListTable from "../../components/CustomListTable";
import paths, { apiUrl } from "../../config/paths";
import { getCustomerToken } from "../../utils";
import Loading from "../../components/Loading";
import {
  currencyType,
  formatMysqlDate,
  getPercentageByType,
  getT,
  translationKeys,
} from "../../admin/utils";
import moment from "moment";
import { useAuth } from "../../authContext";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { IoMdEye } from "react-icons/io";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { FaFilePdf } from "react-icons/fa6";
import { RiMoneyEuroBoxFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

export default function QuotesWidget({ userId = -1, isMobile = false }) {
  const [userInfo, setUserInfo] = useState({});
  const [list, setList] = useState([]);
  const { panelSettings } = useAuth();
  const [loading, setLoading] = useState(true);
  const token = getCustomerToken();
  const navigate = useNavigate();

  const getFtvData = (r) => {
    let f4 = 0;
    let f1 = r.f1 ? parseFloat(r.f1) : 1;
    let f2 = r.f2 ? parseFloat(r.f2) : 1;
    let f3 = r.f3 ? parseFloat(r.f3) : 1;
    let total = f1 + f2 + f3;
    let subTotal = total - total * 0.2;
    f4 = subTotal / 365;
    return f4 > 0 ? f4.toFixed(3) : 0;
  };

  const getFinalPrice = (r) => {
    let f4 = getFtvData(r);
    let bat = getBatteryCount(r);
    let price = 0;
    let price2 = 0;
    if (panelSettings && panelSettings.ftv_price && panelSettings.bat_price) {
      let ftvPrice = parseFloat(panelSettings.ftv_price);
      let batPrice = parseFloat(panelSettings.bat_price);
      if (f4 > 0 && ftvPrice > 0) {
        price = f4 * ftvPrice;
      }
      if (batPrice > 0) {
        price2 = price + batPrice * bat;
      }
    }

    // extra price
    let extraPrice = price2;

    let items = [
      {
        name: "peopleCount",
        val: r?.people_count,
      },
      {
        name: "heatPump",
        val: r?.heat_pump,
      },
      {
        name: "inductionHub",
        val: r?.induction_hub,
      },
      {
        name: "ac",
        val: r?.air_conditioners_pump,
      },
      {
        name: "electricCar",
        val: r?.electric_car,
      },
      {
        name: "col",
        val: r?.col,
      },
    ];

    items.forEach((item) => {
      let subPrice = getPercentageByType(item.val, item.name);

      if (subPrice > 1) {
        extraPrice = extraPrice + extraPrice * (subPrice / 100);
      }
    });

    // global discount
    if (r?.discount && parseFloat(r?.discount) > 0) {
      let disCount = parseFloat(r?.discount) / 100;
      extraPrice = extraPrice - extraPrice * disCount;
    } else {
      extraPrice = extraPrice - extraPrice * 0.1;
    }

    return Math.round(extraPrice).toFixed(2);
  };

  const getBatteryCount = (r) => {
    let f4 = getFtvData(r);
    let bat = 0;
    if (panelSettings && panelSettings.bat_capacity) {
      let batCapacity = parseFloat(panelSettings.bat_capacity);
      if (f4 >= batCapacity) {
        bat = f4 / batCapacity;
        if (bat < 1) {
          bat = 0;
        }
      }
    }

    // manuvally set the battery size
    if (r?.bat && parseFloat(r?.bat) > 0) {
      bat = parseFloat(r?.bat);
    }

    return bat;
  };

  const getBatteryKw = (r) => {
    let bat = getBatteryCount(r);
    let batkw = 0;
    if (panelSettings && panelSettings.bat_capacity) {
      let bc = parseFloat(panelSettings.bat_capacity);
      if (bc > 0 && bat > 0) {
        batkw = batkw + bat * bc;
      }
    }
    return batkw;
  };

  useEffect(() => {
    if (userId > 0) {
      if (token) {
        fetch(apiUrl + "/api/getQuotes/" + userId, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((res) => {
            if (res && res.items && res.items.length > 0) {
              setList(
                res.items.map((i) => {
                  i.created_at = formatMysqlDate(i.created_at);
                  i["ftv"] = getFtvData(i) + "KW";
                  i["bat"] = getBatteryKw(i) + "kw";
                  i["col"] = i?.col && i.col ? "si" : "no";
                  i["price"] = currencyType + " " + getFinalPrice(i);
                  return i;
                })
              );
            }
          })
          .catch((error) => {
            console.error("Error checking token status:", error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [userId]);

  const getBottomLeftInfo = () => {
    return (
      <>
        <div
          className="helpIcons d-flex"
          style={{ fontSize: "12px", color: "grey" }}
        >
          <div className="helpIcon">
            <IoMdEye fontSize={18} onClick={() => {}} className=" me-2" />
            <span className=" me-2">
              {getT(translationKeys.quotes_table_eye_label)}
            </span>
          </div>
          <div className="helpIcon">
            <HiOutlinePencilAlt fontSize={18} className=" me-2" />
            <span className=" me-2">
              {getT(translationKeys.quotes_table_pencil_label)}
            </span>
          </div>
          {/* <div className="helpIcon">
            <FaFilePdf fontSize={18} className=" me-2" />
            <span className=" me-2">
              {getT(translationKeys.quotes_table_pdf_label)}
            </span>
          </div> */}
          <div className="helpIcon">
            <RiMoneyEuroBoxFill fontSize={18} className=" me-2" />
            <span>{getT(translationKeys.quotes_table_euro_label)}</span>
          </div>
        </div>
      </>
    );
  };

  const getColumnsData = () => {
    return isMobile
      ? [
          { name: getT(translationKeys.quotes_table_id), value: "id" },
          {
            name: getT(translationKeys.quotes_table_ftv),
            value: "ftv",
          },
          {
            name: getT(translationKeys.quotes_table_accumuliation),
            value: "bat",
          },
          {
            name: getT(translationKeys.quotes_table_col),
            value: "col",
          },
          {
            name: getT(translationKeys.quotes_table_price),
            value: "price",
          },
          {
            name: "",
            value: "",
            type: "actions",
          },
        ]
      : [
          { name: getT(translationKeys.quotes_table_id), value: "id" },
          {
            name: getT(translationKeys.quotes_table_date),
            value: "created_at",
          },
          {
            name: getT(translationKeys.quotes_table_ftv),
            value: "ftv",
          },
          {
            name: getT(translationKeys.quotes_table_accumuliation),
            value: "bat",
          },
          {
            name: getT(translationKeys.quotes_table_col),
            value: "col",
          },
          {
            name: getT(translationKeys.quotes_table_price),
            value: "price",
          },
          {
            name: "",
            value: "",
            type: "actions",
          },
        ];
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <CustomListTable
        title={getT(translationKeys.quotes_table_header_title)}
        btnTitle={getT(translationKeys.quotes_table_add_new_button_title)}
        lengendOptions={getColumnsData()}
        bottomLeftData={getBottomLeftInfo()}
        listItems={list}
        onNew={() => {
          localStorage.removeItem("quoteId");
          navigate(paths.homePath);
        }}
      />
    </>
  );
}
