import React, { useEffect, useState } from "react";
import Filters from "../Filters";
import CustomTable from "../CustomTable";
import { BsEyeFill, BsFillTrash3Fill, BsPencilSquare } from "react-icons/bs";
import moment from "moment";
import {
  getT,
  isDateBetween,
  leadColumns,
  pageColumns,
  translationKeys,
} from "../utils";
import { useNavigate } from "react-router-dom";
import paths, { adminWord, apiUrl } from "../../config/paths";
import { BsCopy } from "react-icons/bs";
import axios from "axios";
import { getFromLocalStorage } from "../../utils/helpers";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useAuth } from "../../authContext";
import { useDeletePageMutation, usePagesQuery } from "../../queries/pages";
import { useQueryClient } from "@tanstack/react-query";

export default function Pages() {
  const [list, setList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { TData } = useAuth();
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { data, isIdle, isLoading, isError, error } = usePagesQuery();

  const mutation = useDeletePageMutation();

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (mutation.data.data.status) {
        toast.success("deleted");
      } else {
        toast.error("try again later");
      }
      mutation.reset();
    }
  }, [mutation]);

  const onClone = async (id) => {
    if (id && id > 1) {
      try {
        const response = await axios.post(apiUrl + `/api/page/clone/${id}`);
        if (response.data && response.data.item && response.data.item.id > 1) {
          queryClient.invalidateQueries("pages");
        }
      } catch (error) {
        console.error("Error posting data:", error.message);
      }
    }
  };

  useEffect(() => {
    if (data && !isLoading && data.items) {
      let apiList = data.items.map((r) => {
        return {
          id: r?.id,
          date: moment(r?.created_at, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY"
          ),
          status:
            r?.status && r.status
              ? getT(translationKeys.yes)
              : getT(translationKeys.no),
          parent: r?.parent ? r?.parent.name : "",
          name: r?.name,
          permalink: r.parent_id && r?.permalink ? r?.permalink : "",
          add: r?.description,
          lang: r.parent_id && r?.lang && r.lang ? r.lang : "",
          action: (
            <>
              <span
                className="cursor mx-2"
                data-link={r.permalink}
                data-lang={r.lang}
                onClick={(event) => {
                  let link =
                    event.target.parentNode.getAttribute("data-link") ||
                    event.target.getAttribute("data-link");
                  let lang =
                    event.target.parentNode.getAttribute("data-lang") ||
                    event.target.getAttribute("data-lang");
                  if (link !== null && lang != null) {
                    window.open("/#/" + lang + "/" + link, "_blank");
                  }
                }}
              >
                <BsEyeFill
                  fontSize={25}
                  data-link={r.permalink}
                  data-lang={r.lang}
                />
              </span>
              <span
                className="cursor me-2"
                data-id={r.id}
                onClick={(event) => {
                  let id =
                    event.target.parentNode.getAttribute("data-id") ||
                    event.target.getAttribute("data-id");
                  if (id > 0) {
                    navigate(adminWord + "/pages/edit/" + id);
                  }
                }}
              >
                <BsPencilSquare data-id={r.id} fontSize={25} />
              </span>
              <span
                data-id={r.id}
                className="cursor me-2"
                onClick={(event) => {
                  let id =
                    event.target.parentNode.getAttribute("data-id") ||
                    event.target.getAttribute("data-id");
                  if (id > 0) {
                    Swal.fire({
                      title: getT("are_you_sure_to_clone_this_page_label"),
                      text: "",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: getT(translationKeys.yes),
                      cancelButtonText: getT(translationKeys.no),
                    }).then((result) => {
                      if (result.isConfirmed) {
                        onClone(id);
                      }
                    });
                  }
                }}
              >
                <BsCopy data-id={r.id} fontSize={25} />
              </span>

              <span
                data-id={r.id}
                className="cursor me-2"
                onClick={(event) => {
                  let id =
                    event.target.parentNode.getAttribute("data-id") ||
                    event.target.getAttribute("data-id");
                  if (id > 0) {
                    Swal.fire({
                      title: getT(translationKeys.are_you_sure_label),
                      text: "",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: getT(translationKeys.yes),
                      cancelButtonText: getT(translationKeys.no),
                    }).then((result) => {
                      if (result.isConfirmed) {
                        toast.loading("Deleting");
                        mutation.mutate({ id: id });
                      }
                    });
                  }
                }}
              >
                <BsFillTrash3Fill
                  data-id={r.id}
                  fontSize={25}
                  className="danger"
                />
              </span>
            </>
          ),
        };
      });

      setFilteredData(apiList);
      setList(apiList);
    }
  }, [isLoading, data]);

  const onSearch = (values) => {
    var options = list;

    if (options && options.length > 0) {
      if (values.name !== "") {
        options = options.filter((s) => {
          let cString = s.name.toLowerCase();
          return cString.includes(values.name.toLowerCase());
        });
      }

      if (values.address !== "") {
        options = options.filter((s) => {
          let cString = s.add.toLowerCase();
          return cString.includes(values.address.toLowerCase());
        });
      }

      if (values.comune !== "") {
        options = options.filter((s) => {
          let cString = s.comune.toLowerCase();
          return cString.includes(values.comune.toLowerCase());
        });
      }

      if (values.type !== "") {
        options = options.filter((s) => {
          return s.type === values.type;
        });
      }

      if (values.from !== "" && values.to !== "") {
        options = options.filter((s) => {
          let date = moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD");
          return isDateBetween(values.from, values.to, date);
        });
      }
    }

    setFilteredData(options);
  };

  const onClear = () => {
    setFilteredData(list);
  };

  return (
    <>
      <Filters
        type="pages"
        onAdd={() => navigate(paths.newPagePath)}
        onFilter={onSearch}
        onClear={onClear}
      />
      <div className="customTable">
        {isIdle || isLoading ? (
          getT(translationKeys.pleaseWait)
        ) : (
          <CustomTable
            columns={pageColumns}
            tData={TData}
            data={filteredData}
          />
        )}
      </div>
    </>
  );
}
