import { ErrorMessage, Field, useFormikContext } from "formik";
import React from "react";
import { getT, translationKeys } from "../../utils";
import { FaCar } from "react-icons/fa";
import { MdSoupKitchen } from "react-icons/md";
import { PiThermometerColdBold } from "react-icons/pi";
import { LiaSnowflakeSolid } from "react-icons/lia";
import { FaPeopleRoof } from "react-icons/fa6";
import { SiLevelsdotfyi } from "react-icons/si";

export default function CustomSelect2({
  label = "title",
  name = "info_field",
  type = "text",
  placeholder = "",
  onEdit,
  options = [],
  labelStatus = true,
  defaultSelectVal = "",
  boxVisible = false,
  disabled = false,
  onUpdate = () => {},
  boxIcon = "",
  ...rest
}) {
  const { setFieldValue } = useFormikContext();

  const getIconType = (key) => {
    let icon = "";
    switch (key) {
      case "car":
        icon = <FaCar />;
        break;
      case "kichen":
        icon = <MdSoupKitchen />;
        break;
      case "cold":
        icon = <PiThermometerColdBold />;
        break;
      case "flakes":
        icon = <LiaSnowflakeSolid />;
        break;
      case "people":
        icon = <FaPeopleRoof />;
        break;
      case "stairs":
        icon = <SiLevelsdotfyi />;

      default:
        break;
    }
    return icon;
  };

  return (
    <>
      {labelStatus && <label className="form-label b-title">{label}</label>}
      <div className="customSelect_grid">
        {boxVisible && <div className="box">{getIconType(boxIcon)}</div>}
        <Field
          as="select"
          className="form-select"
          placeholder={placeholder}
          name={name}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
            onUpdate(e.target.value);
          }}
          disabled={disabled}
        >
          <option value="" disabled className="placeHolderLight">
            {defaultSelectVal != ""
              ? defaultSelectVal
              : getT(translationKeys.choose)}
          </option>
          {options.map((option, index) => (
            <option key={index} value={option.value} label={option.name} />
          ))}
        </Field>
      </div>

      <ErrorMessage className="danger" name={name} component="div" />
    </>
  );
}
