import React, { useEffect, useState } from "react";
import Filters from "../Filters";
import CustomTable from "../CustomTable";
import { BsPencilSquare } from "react-icons/bs";
import moment from "moment";
import {
  clientsColumns,
  getT,
  isDateBetween,
  rolesColumns,
  translationKeys,
} from "../utils";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import { useRoleQuery } from "../../queries/roles";

export default function Roles() {
  const [list, setList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();

  const { data, isIdle, isLoading, isError, error } = useRoleQuery();

  useEffect(() => {
    if (data && !isLoading && data.roles) {
      let apiList = data.roles.map((r) => {
        return {
          id: "CL" + r?.id,
          date: moment(r?.created_at, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY"
          ),
          name: r?.name,
          description: r?.description,
          status: r?.status,
          action:
            r?.id && r?.id > 0 ? (
              <BsPencilSquare
                data-id={r.id}
                fontSize={25}
                className="cursor"
                onClick={(event) => {
                  let id = event.target.getAttribute("data-id");
                  if (id > 0) {
                    navigate("/roles/edit/" + id);
                  }
                }}
              />
            ) : (
              ""
            ),
        };
      });

      setFilteredData(apiList);
      setList(apiList);
    }
  }, [isLoading, data]);

  const onSearch = (values) => {
    var options = list;

    if (options && options.length > 0) {
      if (values.name !== "") {
        options = options.filter((s) => {
          let cString = s.name.toLowerCase();
          return cString.includes(values.name.toLowerCase());
        });
      }

      if (values.address !== "") {
        options = options.filter((s) => {
          let cString = s.add.toLowerCase();
          return cString.includes(values.address.toLowerCase());
        });
      }

      if (values.comune !== "") {
        options = options.filter((s) => {
          let cString = s.comune.toLowerCase();
          return cString.includes(values.comune.toLowerCase());
        });
      }

      if (values.type !== "") {
        options = options.filter((s) => {
          return s.type === values.type;
        });
      }

      if (values.from !== "" && values.to !== "") {
        options = options.filter((s) => {
          let date = moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD");
          return isDateBetween(values.from, values.to, date);
        });
      }

      console.log(values);
    }

    setFilteredData(options);
  };

  const onClear = () => {
    setFilteredData(list);
  };

  return (
    <>
      <Filters
        type="roles"
        onAdd={() => navigate(paths.newRolePath)}
        onFilter={onSearch}
        onClear={onClear}
      />
      <div className="customTable">
        {isIdle || isLoading ? (
          getT(translationKeys.pleaseWait)
        ) : (
          <CustomTable columns={rolesColumns} data={filteredData} />
        )}
      </div>
    </>
  );
}
