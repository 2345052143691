import React from "react";

import MainWrapper from "./MainWrapper";
import useScreenSize from "../components/useScreenSize";
import { getT, translationKeys } from "../admin/utils";
import Navigation from "../components/Navigation";
import { useAuth } from "../authContext";

export default function NotFound() {
  const { isCustomerAuthenticated } = useAuth();
  const isMobile = useScreenSize(768);

  return (
    <>
      <MainWrapper
        mainClass="dynamicPage quotes_page"
        showFooter={isMobile && isCustomerAuthenticated ? false : true}
        showChatBtn={isMobile ? false : true}
      >
        <div className="infoWrapper d-flex justify-content-center p-2">
          {getT(translationKeys.page_not_found_label)}
        </div>
        {isMobile && isCustomerAuthenticated && <Navigation />}
      </MainWrapper>
    </>
  );
}
