import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";
import CustomTextArea from "./utils/CustomTextArea";
import { getT, translationKeys } from "../utils";
import {
  useCreateMessageMutation,
  useMessageQuery,
  useUpdateMessageMutation,
} from "../../queries/messages";

const MessageModalForm = ({
  onEdit = false,
  editId = null,
  onClose = () => {},
}) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useMessageQuery(
    editId,
    editId ? true : false
  );

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdateMessageMutation)
    : (mutationHook = useCreateMessageMutation);
  const mutation = mutationHook();

  const editSchema = {};

  const formSchema = Yup.object().shape(editSchema);

  useEffect(() => {
    if (data && !isLoading && data?.item) {
      setEditData(data?.item);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.item &&
        mutation.data.data.item.id > 0
      ) {
        onEdit ? toast.success("updated") : toast.success("created");
        onClose();
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error("try again later");
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const finalData = {
      message: values.message,
    };

    toast.warning("please wait..");
    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    //navigate(paths.rolesPath);
    onClose();
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">{getT(translationKeys.generalError)}</div>;
  }

  return (
    <Container fluid>
      {(editData && onEdit) || !onEdit ? (
        <Formik
          initialValues={{
            message: editData?.message ? editData?.message : "",
          }}
          validationSchema={formSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <Row>
                <Col sm={12} md={12}>
                  <CustomTextArea
                    name={"message"}
                    label={getT(translationKeys.message)}
                    placeholder=""
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <button
                    type="submit"
                    disabled={submittable}
                    className="btn btn-success me-2"
                  >
                    {getT(translationKeys.submit)}
                  </button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      ) : (
        <Loading />
      )}
    </Container>
  );
};

export default MessageModalForm;
