// AuthProvider.js
import React, { createContext, useContext, useState, useEffect } from "react";
import paths, { apiUrl } from "./config/paths";
import Loading from "./components/Loading";
import { toast } from "react-toastify";
import { useFetchers, useLocation, useNavigate } from "react-router-dom";
import { loadTranslations, updateTranslationList } from "./admin/utils";
import { getFromLocalStorage } from "./utils/helpers";
import { useTanslationsQuery } from "./queries/translations";
import { getCustomerToken } from "./utils";
import { useGeneralSettingsQuery } from "./queries/Settings";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isCustomerAuthenticated, setCustomerAuthenticated] = useState(false);
  const [userRoleName, setUserRoleName] = useState("");
  const [customerInfo, setCustomerInfo] = useState({});
  const [authUserId, setAuthUserId] = useState(null);
  const [panelSettings, setPanelSettings] = useState({});
  const [generalSettings, setGeneralSettings] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [fmodules, setfModules] = useState([]);
  const [showMobileModal, setShowMobileModal] = useState(false);
  const [appointmentModalStatus, setAppointmentModalStatus] = useState(true);
  const [ticketModalStatus, setTicketModalStatus] = useState(true);
  const getInitalMenuPath = (pathname) => {
    let value = "";
    switch (pathname) {
      case "/profile":
        value = "profile";
        break;

      case "/appointments":
        value = "appointments";
        break;

      case "/quotes":
        value = "quotes";
        break;

      case "/myTickets":
        value = "tickets";
        break;

      case "/preferences":
        value = "settings";
        break;

      default:
        value = "";
        break;
    }
    return value;
  };
  const [mobileActiveMenu, setMobileActiveMenu] = useState(
    getInitalMenuPath(location.pathname)
  );

  const { data, isIdle, isLoading, isError, error } = useTanslationsQuery();

  const {
    data: settingData,
    isIdle: isSettingIdle,
    isLoading: isSettingsLoading,
  } = useGeneralSettingsQuery();

  useEffect(() => {
    if (settingData && !isSettingsLoading && settingData.settings) {
      setfModules(settingData.settings);
    }
    if (settingData && settingData?.general) {
      setGeneralSettings(settingData.general);
    }
  }, [isSettingsLoading, settingData]);

  useEffect(() => {
    // Check for the token in local storage
    const token = localStorage.getItem("userToken");

    if (token) {
      // If the token exists, call your API to verify its status
      // This is a simplified example; replace the URL and headers with your actual API details
      fetch(apiUrl + "/api/me", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            setAuthenticated(false);
          }
        })
        .then((res) => {
          if (res && res.user && res.user.role_id >= 0) {
            setAuthenticated(true);
            setUserRoleName(res.user?.role?.name);
            setAuthUserId(res.user.id);
            if (res.settings && res.settings.id) {
              setPanelSettings(res.settings);
            }
            if (location.pathname === "/login") {
              if (res.user.role_id === 0) {
                navigate(paths.leadsPath);
              } else {
                navigate(paths.requestsPath);
              }
            }
          } else {
            setAuthenticated(false);
          }
        })
        .catch((error) => {
          console.error("Error checking token status:", error);
          setAuthenticated(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const token = getCustomerToken();

    // Check for the token in local storage
    if (token) {
      fetch(apiUrl + "/api/me", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((res) => {
          if (res && res.user && res.user.role_id >= 0) {
            //console.log(res.user, "test");

            setCustomerInfo((prev) => ({ ...prev, ...res.user }));
            setCustomerAuthenticated(true);
            setPanelSettings(res.settings);
          }
        })
        .catch((error) => {
          console.error("Error checking token status:", error);
        })
        .finally(() => {});
    }
  }, [isCustomerAuthenticated]);

  useEffect(() => {
    if (data && !isLoading && data.items) {
      updateTranslationList(data.items);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (!getFromLocalStorage("lng")) {
      localStorage.setItem("lng", "it");
    }

    //loadTranslations();
  }, []);

  const authLogin = (token) => {
    // Save the token to local storage
    localStorage.setItem("userToken", token);
    setAuthenticated(true);
  };

  const toggleLoginModal = (val) => {
    setShowLoginModal(val);
  };

  const toggleMobileModal = (val) => {
    setShowMobileModal(val);
  };

  const authCustomerLogin = (token) => {
    // Save the token to local storage
    localStorage.setItem("customerToken", token);
    setCustomerAuthenticated(true);
  };

  const authCustomerLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("customerToken");
    localStorage.removeItem("quoteId");
    setCustomerAuthenticated(false);
  };

  const authLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("userToken");
    setAuthenticated(false);
  };

  if (loading || isLoading || isSettingsLoading) {
    return <Loading />;
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authUserId,
        loading,
        authLogin,
        authLogout,
        userRoleName,
        panelSettings,
        isCustomerAuthenticated,
        authCustomerLogin,
        authCustomerLogout,
        toggleLoginModal,
        showLoginModal,
        customerInfo,
        fmodules,
        generalSettings,
        showMobileModal,
        toggleMobileModal,
        appointmentModalStatus,
        setAppointmentModalStatus,
        ticketModalStatus,
        setTicketModalStatus,
        mobileActiveMenu,
        setMobileActiveMenu,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
