import React from "react";
import EmailTemplateForm from "../forms/EmailTemplateForm";

export default function NewEmailTemplate() {
  return (
    <>
      <EmailTemplateForm />
    </>
  );
}
