import axios from "axios";
import paths, { apiUrl } from "../config/paths";
import { getFromLocalStorage } from "./helpers";

export const FrontMenuList = [
  {
    name: "Login",
    value: "login",
    routeVal: "login",
    path: paths.requestsPath,
  },
  {
    name: "info",
    value: "info",
    routeVal: "info",
    path: paths.infoPath,
  },
  {
    name: "whoweare",
    value: "about",
    routeVal: "about",
    path: paths.aboutPath,
  },

  {
    name: "gallery",
    value: "gallery",
    routeVal: "gallery",
    path: paths.galleryPath,
  },
  {
    name: "Garantee",
    value: "garantee",
    routeVal: "garantee",
    path: paths.garanteePath,
  },
  /* {
    name: "photovoltaicsystems",
    value: "plants",
    routeVal: "plants",
    path: paths.plantsPath,
  },
 {
    name: "gsepractices",
    value: "gse",
    routeVal: "gse",
    path: paths.gsePath,
  }, */

  {
    name: "faq",
    value: "faq",
    routeVal: "faq",
    path: paths.faqPath,
  },
  {
    name: "contacts",
    value: "contact",
    routeVal: "contact",
    path: paths.contactPath,
  },
  /*{
    name: "Privacy",
    value: "privacy",
    routeVal: "privacy",
    path: paths.privacyPath,
  },*/
];

export const footerLogos = [
  {
    name: "solaredge",
    img: "./assets/edgeLogo.svg",
    type: "svg",
  },
  {
    name: "goodwe",
    img: "./assets/goodweLogo.svg",
    type: "png",
  },
  {
    name: "sungrow",
    img: "./assets/sungrowLogo.png",
    type: "png",
  },
  {
    name: "sma",
    img: "./assets/SMALogo.svg",
    type: "svg",
  },
  {
    name: "huawei",
    img: "./assets/huaweiLogo.webp",
    type: "webp",
  },
  {
    name: "trina",
    img: "./assets/trinarLogo.png",
    type: "png",
  },
  {
    name: "jinko",
    img: "./assets/jinkoLogo.png",
    type: "png",
  },
];

export const homeTypes = [
  {
    name: "single",
    value: "single",
  },
  {
    name: "shed",
    value: "shed",
  },
  {
    name: "condominium",
    value: "condominium",
  },
  {
    name: "ground",
    value: "ground",
  },
];

export const personTypes = [
  {
    name: "private",
    value: "private",
  },
  {
    name: "company",
    value: "company",
  },
];

export const inclinationTypes = [
  {
    name: "1",
    value: 1,
  },
  {
    name: "2",
    value: 2,
  },
  {
    name: "3",
    value: 3,
  },
  {
    name: "4",
    value: 4,
  },
];

export const riscaldamentoOptions = [
  {
    name: "1",
    value: "1",
  },
  {
    name: "2",
    value: "2",
  },
  {
    name: "3",
    value: "3",
  },
  {
    name: "4",
    value: "4",
  },
  {
    name: "5",
    value: "5",
  },
  {
    name: "select_5_and_more_label",
    value: "n",
  },
];

export const floorsOptions = [
  {
    name: "1",
    value: "1",
  },
  {
    name: "2",
    value: "2",
  },
  {
    name: "3",
    value: "3",
  },
  {
    name: "4",
    value: "4",
  },
  {
    name: "5",
    value: "5",
  },
  {
    name: "6",
    value: "6",
  },
  {
    name: "7",
    value: "7",
  },
  {
    name: "8",
    value: "8",
  },
  {
    name: "9",
    value: "9",
  },
  {
    name: "10",
    value: "10",
  },
  {
    name: "select_10_and_more_label",
    value: "n",
  },
];

// update data of form
export const updateQuoteData = async (data, id) => {
  let payload = data;
  if (payload) {
    try {
      const response = await axios.put(apiUrl + "/api/cusQuote/" + id, payload);
      if (
        response.status === 200 &&
        response.data &&
        response.data.quote &&
        response.data.quote.id > 0
      ) {
        return { status: true };
      } else {
        return { status: false };
      }
    } catch (error) {
      //console.error("Error creating row:", error);
      return { status: false };
    }
  }
};

// get updates
export const getCustomerToken = () => {
  return localStorage.getItem("customerToken");
};

export const getCommonToken = (type) => {
  if (type === "customer") {
    return getFromLocalStorage("customerToken");
  }

  return getFromLocalStorage("userToken");
};

export const getDynamicMenuLink = (m, type = "link") => {
  let info = "";

  if (m.page && m.page.children && m.page.children.length > 0) {
    m.page.children.forEach((i) => {
      if (i.lang === localStorage.getItem("lng") && type === "link") {
        info = `/${i?.lang}/${i?.permalink}`;
      } else if (i.lang === localStorage.getItem("lng") && type === "name") {
        info = `${i?.name}`;
      }
    });
  }

  return info;
};

export const getInfoOfListValue = (list, val) => {
  let final = { name: "" };
  list.forEach((el) => {
    if (el.value && val && el.value.toString() == val.toString()) {
      final = el;
    }
  });
  return final;
};
