import React from "react";
import { useParams } from "react-router-dom";
import RoleForm from "../forms/RoleForm";

export default function EditRole() {
  const { id } = useParams();

  return (
    <>
      <RoleForm onEdit editId={id} />
    </>
  );
}
