import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";
import { useAuth } from "../../authContext";
import {
  getT,
  subVariationTypes,
  translationKeys,
  variationTypes,
} from "../utils";
import CustomInput from "./utils/CustomInput";
import {
  useCreateProductVariationMutation,
  useProductVariationQuery,
  useUpdateProductVariationMutation,
} from "../../queries/productVariations";
import CustomSelect from "./utils/CustomSelect";
import { useCategoryQuery } from "../../queries/categories";
import {
  useProductCategoryByIdQuery,
  useProductCategoryQuery,
} from "../../queries/productCategory";

const ProductVariationsForm = ({
  onEdit = false,
  editId = null,
  pid = null,
  onClose = () => {},
}) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const [catInfoData, setCatInfoData] = useState([]);
  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useProductVariationQuery(
    editId,
    editId ? true : false
  );
  const { TData } = useAuth();

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdateProductVariationMutation)
    : (mutationHook = useCreateProductVariationMutation);
  const mutation = mutationHook();

  const formSchema = Yup.object().shape({
    name: Yup.string().required(getT(translationKeys.nameRequired)),
    additionalprice: Yup.string().required(
      getT(translationKeys.additionalpricerequired)
    ),
    additionalunitprice: Yup.string().required(
      getT(translationKeys.additionalunitpricerequired)
    ),
    value: Yup.string().required(getT(translationKeys.valuerequired)),
  });

  const {
    data: catData,
    isIdle: catIdle,
    isLoading: catLoading,
    isError: catError,
  } = useProductCategoryQuery(null, pid ? true : false, { pid: pid });

  useEffect(() => {
    if (catData && !catLoading && catData?.items) {
      setCatInfoData(catData?.items);
    }
  }, [catLoading, catData]);

  useEffect(() => {
    if (data && !isLoading && data?.item) {
      setEditData(data?.item);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.item &&
        mutation.data.data.item.id > 0
      ) {
        onEdit
          ? toast.success(getT(translationKeys.updated))
          : toast.success(getT(translationKeys.created));
        onClose();
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error(getT(translationKeys.generalError));
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    //console.log(values);
    const finalData = {
      name: values.name,
      product_id: pid,
      //type: values.type.value,
      //sub_type: values.sub_type.value,
      product_category_id: values.category_id.value,
      value: values.value,
      additionalprice: values.additionalprice,
      additionalunitprice: values.additionalunitprice,
    };

    toast.warning(getT(translationKeys.pleaseWait));

    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    navigate(paths.productsPath);
  };

  const getInitialInfo = (id, type) => {
    let output = {};
    let inputArray = type === "type" ? variationTypes : subVariationTypes;
    inputArray.forEach((q) => {
      if (q.value === id) {
        output = {
          value: q.value,
          label: q.name,
        };
      }
    });

    return output;
  };

  const getInitialCat = (data) => {
    let output = {};

    if (data?.category && data.category?.id) {
      output = {
        value: data.category?.id,
        label: data.category?.name,
      };
    }
    return output;
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">{getT(translationKeys.generalError)}</div>;
  }

  return (
    <Container fluid className="leadForm">
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              name: editData?.name ?? "",
              /* type: getInitialInfo(editData?.type, "type") ?? {
                label: "variation",
                value: "variation",
              },
              sub_type: getInitialInfo(editData?.sub_type, "sub-type") ?? {
                label: "color",
                value: "color",
              },*/
              category_id: getInitialCat(editData),
              value: editData?.value ?? "",
              additionalprice: editData?.additionalprice ?? 0,
              additionalunitprice: editData?.additionalunitprice ?? 0,
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, formik }) => (
              <Form>
                <Row>
                  <Col sm={12} md={6}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.name}
                      name="name"
                      label={getT(translationKeys.name)}
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.value}
                      name="value"
                      label={getT(translationKeys.value)}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  {/* <Col sm={12} md={6}>
                    <CustomSelect
                      label={getT(translationKeys.type)}
                      name="type"
                      defaultValue={values.type}
                      options={
                        variationTypes &&
                        variationTypes?.map((option, index) => ({
                          label: option.name,
                          value: option.value,
                        }))
                      }
                    />
                    </Col> */}
                  <Col sm={12} md={12}>
                    <CustomSelect
                      label={getT(translationKeys.variation)}
                      name="category_id"
                      defaultValue={values.category_id}
                      options={
                        catInfoData &&
                        catInfoData?.map((option, index) => ({
                          label: option.name,
                          value: option.id,
                        }))
                      }
                    />
                  </Col>
                  {/* <Col sm={12} md={4}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.additionalprice}
                      name="additionalprice"
                      type="number"
                      label={getT(translationKeys.additionalpricelabel)}
                    />
                  </Col>
                  <Col sm={12} md={4}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.additionalunitprice}
                      name="additionalunitprice"
                      type="number"
                      label={getT(translationKeys.additionalunitprice)}
                    />
                    </Col> */}
                </Row>

                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      {getT(translationKeys.submit)}
                    </button>
                    {/* <button
                      type="button"
                      onClick={() => handleClear(formik)}
                      className="btn btn-secondary"
                    >
                      {getT(translationKeys.back)}
                    </button> */}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default ProductVariationsForm;
