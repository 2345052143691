import React from "react";
import MainWrapper from "../MainWrapper";
import useScreenSize from "../../components/useScreenSize";
import { Col, Container, Row } from "react-bootstrap";
import { getT, translationKeys } from "../../admin/utils";
import Navigation from "../../components/Navigation";
import TicketsWidget from "../widgets/ticketswidget";
import { useAuth } from "../../authContext";

export default function MyTickets() {
  const { isCustomerAuthenticated, customerInfo } = useAuth();
  const isMobile = useScreenSize(768);

  return (
    <>
      <MainWrapper
        mainClass="dynamicPage quotes_page"
        showFooter={isMobile && isCustomerAuthenticated ? false : true}
        showChatBtn={true}
      >
        {isCustomerAuthenticated && customerInfo && customerInfo?.id > 0 ? (
          <div className="infoWrapper">
            <Container fluid>
              <Row>
                <Col md={6} lg={8}>
                  <div className="widgets_container">
                    <div className="widget_item">
                      <TicketsWidget userId={customerInfo.id} />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        ) : (
          <div className="infoWrapper">
            {getT(translationKeys.notAutherized)}
          </div>
        )}
        {isCustomerAuthenticated && <Navigation />}
      </MainWrapper>
    </>
  );
}
