import React, { useEffect, useState } from "react";
import Filters from "../Filters";
import CustomTable from "../CustomTable";
import { BsPencilSquare } from "react-icons/bs";
import { useLeadQuery } from "../../queries/leads";
import moment from "moment";
import { getT, isDateBetween, leadColumns, translationKeys } from "../utils";
import { useNavigate } from "react-router-dom";
import paths, { adminWord, apiUrl } from "../../config/paths";
import { BsCopy } from "react-icons/bs";
import axios from "axios";
import { getFromLocalStorage } from "../../utils/helpers";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useAuth } from "../../authContext";

export default function Leads() {
  const [list, setList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [copyDisabled, setCopyDisabled] = useState(false);
  const { TData } = useAuth();

  const navigate = useNavigate();

  const { data, isIdle, isLoading, isError, error } = useLeadQuery();

  useEffect(() => {
    if (data && !isLoading && data.leads) {
      let apiList = data.leads.map((r) => {
        return {
          id: "LD" + r?.id,
          date: moment(r?.created_at, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY"
          ),
          name: r?.name + " " + r?.surname,
          add: r?.address,
          comune: r?.city,
          phone: r?.mobile,
          email: r?.email,
          type: r?.referal_type,
          action: (
            <>
              <span
                data-id={r.id}
                className="cursor me-2"
                onClick={(event) => {
                  let id =
                    event.target.parentNode.getAttribute("data-id") ||
                    event.target.getAttribute("data-id");
                  if (id > 0) {
                    navigate(adminWord + "/leads/edit/" + id);
                  }
                }}
              >
                <BsPencilSquare data-id={r.id} fontSize={25} />
              </span>

              <span
                data-id={r.id}
                className="cursor"
                onClick={(event) => {
                  let id =
                    event.target.parentNode.getAttribute("data-id") ||
                    event.target.getAttribute("data-id");
                  if (id > 0) {
                    setCopyDisabled(true);

                    Swal.fire({
                      title: getT(
                        "are_you_sure_you_want_start_creating_quote_from_this_lead_label"
                      ),
                      text: "",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: getT(translationKeys.yes),
                      cancelButtonText: getT(translationKeys.no),
                    }).then((result) => {
                      /* Read more about isConfirmed, isDenied below */
                      if (result.isConfirmed) {
                        toast.warning("please wait..");

                        axios
                          .post(
                            apiUrl + "/api/leadCopy",
                            { id: id },
                            {
                              headers: {
                                Authorization: getFromLocalStorage("userToken"),
                              },
                            }
                          )
                          .then((response) => {
                            if (
                              response.data &&
                              response.data.quote &&
                              response.data.quote.id > 0
                            ) {
                              toast.success(
                                "successfully created quote from lead"
                              );
                              navigate(
                                "/preventivi/edit/" + response.data.quote.id
                              );
                            } else {
                              toast.error("try again later");
                            }
                            setCopyDisabled(false);
                            // Handle the success response
                            console.log("Response:", response.data);
                          })
                          .catch((error) => {
                            // Handle errors
                            console.error("Error:", error);
                            toast.error("try again later");
                            setCopyDisabled(false);
                          });
                      }
                    });
                  }
                }}
              >
                <BsCopy data-id={r.id} fontSize={25} disabled={copyDisabled} />
              </span>
            </>
          ),
        };
      });

      setFilteredData(apiList);
      setList(apiList);
    }
  }, [isLoading, data]);

  const onSearch = (values) => {
    var options = list;

    if (options && options.length > 0) {
      if (values.name !== "") {
        options = options.filter((s) => {
          let cString = s.name.toLowerCase();
          return cString.includes(values.name.toLowerCase());
        });
      }

      if (values.address !== "") {
        options = options.filter((s) => {
          let cString = s.add.toLowerCase();
          return cString.includes(values.address.toLowerCase());
        });
      }

      if (values.comune !== "") {
        options = options.filter((s) => {
          let cString = s.comune.toLowerCase();
          return cString.includes(values.comune.toLowerCase());
        });
      }

      if (values.type !== "") {
        options = options.filter((s) => {
          return s.type === values.type;
        });
      }

      if (values.from !== "" && values.to !== "") {
        options = options.filter((s) => {
          let date = moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD");
          return isDateBetween(values.from, values.to, date);
        });
      }
    }

    setFilteredData(options);
  };

  const onClear = () => {
    setFilteredData(list);
  };

  return (
    <>
      <Filters
        onAdd={() => navigate(paths.newLeadPath)}
        onFilter={onSearch}
        onClear={onClear}
      />
      <div className="customTable">
        {isIdle || isLoading ? (
          getT(translationKeys.pleaseWait)
        ) : (
          <CustomTable
            columns={leadColumns}
            tData={TData}
            data={filteredData}
          />
        )}
      </div>
    </>
  );
}
