import axios from "axios";
import { apiUrl } from "../config/paths";

// layers
export const dataLayerOptions = [
  {
    name: "No layer",
    value: "none",
    type: "none",
  },
  {
    name: "Roof mask",
    value: "mask",
    type: "mask",
  },
  {
    name: "Digital Surface Model",
    value: "dsm",
    type: "dsm",
  },
  {
    name: "Aerial image",
    value: "rgb",
    type: "rgb",
  },
  /*{
    name: "Annual sunshine",
    value: "annualFlux",
    type: "annualFlux",
  },
  {
    name: "Monthly sunshine",
    value: "monthlyFlux",
    type: "monthlyFlux",
  },
  {
    name: "Hourly shade",
    value: "hourlyShade",
    type: "hourlyShade",
  },*/
];
// colors
export const binaryPalette = ["212121", "B3E5FC"];
export const rainbowPalette = [
  "3949AB",
  "81D4FA",
  "66BB6A",
  "FFE082",
  "E53935",
];
export const ironPalette = ["00000A", "91009C", "E64616", "FEB400", "FFFFF6"];
export const sunlightPalette = ["212121", "FFCA28"];
export const panelsPalette = ["E8EAF6", "1A237E"];

export function renderRGB(rgb, mask) {
  // https://www.w3schools.com/tags/canvas_createimagedata.asp
  const canvas = document.createElement("canvas");
  canvas.width = mask ? mask.width : rgb.width;
  canvas.height = mask ? mask.height : rgb.height;

  const dw = rgb.width / canvas.width;
  const dh = rgb.height / canvas.height;

  const ctx = canvas.getContext("2d");
  const img = ctx.getImageData(0, 0, canvas.width, canvas.height);
  for (let y = 0; y < canvas.height; y++) {
    for (let x = 0; x < canvas.width; x++) {
      const imgIdx = y * canvas.width * 4 + x * 4;
      const rgbIdx = Math.floor(y * dh) * rgb.width + Math.floor(x * dw);
      const maskIdx = y * canvas.width + x;
      img.data[imgIdx + 0] = rgb.rasters[0][rgbIdx]; // Red
      img.data[imgIdx + 1] = rgb.rasters[1][rgbIdx]; // Green
      img.data[imgIdx + 2] = rgb.rasters[2][rgbIdx]; // Blue
      img.data[imgIdx + 3] = mask // Alpha
        ? mask.rasters[0][maskIdx] * 255
        : 255;
    }
  }
  ctx.putImageData(img, 0, 0);
  return canvas;
}

export function renderPalette({ data, mask, colors, min, max, index }) {
  const n = 256;
  const palette = createPalette(colors ?? ["000000", "ffffff"], n);
  const indices = data.rasters[index ?? 0]
    .map((x) => normalize(x, max ?? 1, min ?? 0))
    .map((x) => Math.round(x * (n - 1)));
  return renderRGB(
    {
      ...data,
      rasters: [
        indices.map((i) => palette[i].r),
        indices.map((i) => palette[i].g),
        indices.map((i) => palette[i].b),
      ],
    },
    mask
  );
}

export function createPalette(hexColors, size = 256) {
  const rgb = hexColors.map(colorToRGB);
  const step = (rgb.length - 1) / (size - 1);
  return Array(size)
    .fill(0)
    .map((_, i) => {
      const index = i * step;
      const j = Math.floor(index);
      const k = Math.ceil(index);
      return {
        r: lerp(rgb[j].r, rgb[k].r, index - j),
        g: lerp(rgb[j].g, rgb[k].g, index - j),
        b: lerp(rgb[j].b, rgb[k].b, index - j),
      };
    });
}
export function colorToRGB(color) {
  const hex = color.startsWith("#") ? color.slice(1) : color;
  return {
    r: parseInt(hex.substring(0, 2), 16),
    g: parseInt(hex.substring(2, 4), 16),
    b: parseInt(hex.substring(4, 6), 16),
  };
}

export function normalize(x, max = 1, min = 0) {
  const y = (x - min) / (max - min);
  return clamp(y, 0, 1);
}

export function rgbToColor({ r, g, b }) {
  const f = (x) => {
    const hex = Math.round(x).toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  };
  return `#${f(r)}${f(g)}${f(b)}`;
}

export function lerp(x, y, t) {
  return x + t * (y - x);
}

export function clamp(x, min, max) {
  return Math.min(Math.max(x, min), max);
}

export function showNumber(x) {
  return x?.toLocaleString(undefined, { maximumFractionDigits: 1 });
}

export function createImage(rasters) {
  // Process rasters and create an image (you may need additional processing)
  // This is a basic example, you might need to adapt it based on your specific use case
  var width = rasters.width;
  var height = rasters.height;
  var data = rasters[0]; // Assuming the first band of data

  var canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;

  var context = canvas.getContext("2d");
  var imageData = context.createImageData(width, height);

  for (var i = 0; i < width * height; i++) {
    var value = data[i];
    imageData.data[i * 4] = value;
    imageData.data[i * 4 + 1] = value;
    imageData.data[i * 4 + 2] = value;
    imageData.data[i * 4 + 3] = 255; // Alpha channel
  }

  context.putImageData(imageData, 0, 0);
  return canvas.toDataURL("image/png");
}

export function arrayBufferToBase64(arrayBuffer) {
  var binary = "";
  var bytes = new Uint8Array(arrayBuffer);
  for (var i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}

// some data
// Function to display solar panels within grid tiles
/*
 // Extract coordinates from the polygon
 var aoiCoordinates = aoiPolygon.getPath().getArray();


function displaySolarPanelsInGrid(map, aoiCoordinates, rows, cols) {
  // eslint-disable-next-line no-undef
  var bounds = new google.maps.LatLngBounds();

  // Calculate grid size based on rows and columns
  var gridSize = {
    lat: (aoiCoordinates[2].lat() - aoiCoordinates[0].lat()) / rows,
    lng: (aoiCoordinates[2].lng() - aoiCoordinates[0].lng()) / cols,
  };

  // Loop through grid rows
  for (var i = 0; i < rows; i++) {
    // Loop through grid columns
    for (var j = 0; j < cols; j++) {
      // Calculate grid tile boundaries
      var tileBounds = {
        north: aoiCoordinates[0].lat() + (i + 1) * gridSize.lat,
        south: aoiCoordinates[0].lat() + i * gridSize.lat,
        east: aoiCoordinates[0].lng() + (j + 1) * gridSize.lng,
        west: aoiCoordinates[0].lng() + j * gridSize.lng,
      };

      // Place solar panel marker at the center of the grid tile
      // eslint-disable-next-line no-undef
      var panelMarker = new google.maps.Marker({
        position: {
          lat: (tileBounds.north + tileBounds.south) / 2,
          lng: (tileBounds.east + tileBounds.west) / 2,
        },
        map: map,
        title: "Solar Panel",
      });

      // Extend the bounds to include the current grid tile
      bounds.extend(panelMarker.getPosition());

      // Draw vertical grid line
      // eslint-disable-next-line no-undef
      var verticalGridLine = new google.maps.Polyline({
        path: [
          {
            lat: tileBounds.north,
            lng: (tileBounds.east + tileBounds.west) / 2,
          },
          {
            lat: tileBounds.south,
            lng: (tileBounds.east + tileBounds.west) / 2,
          },
        ],
        strokeColor: "#0000FF",
        strokeOpacity: 0.5,
        strokeWeight: 2,
        map: map,
      });

      // Draw horizontal grid line
      // eslint-disable-next-line no-undef
      var horizontalGridLine = new google.maps.Polyline({
        path: [
          {
            lat: (tileBounds.north + tileBounds.south) / 2,
            lng: tileBounds.west,
          },
          {
            lat: (tileBounds.north + tileBounds.south) / 2,
            lng: tileBounds.east,
          },
        ],
        strokeColor: "#0000FF",
        strokeOpacity: 0.5,
        strokeWeight: 2,
        map: map,
      });
    }
  }

  // Optionally, fit the map to the bounds of the grid tiles
  map.fitBounds(bounds);
}

 // Call the function with the desired number of rows and columns
 displaySolarPanelsInGrid(map, aoiCoordinates, 5, 5); 
 

    // Listen for click events on grid lines
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(map, "click", function (event) {
        handleGridLineClick(event.latLng);
      });

 function handleGridLineClick(latLng) {
        // Check if the clicked point is near any grid line
        for (const line of gridLines) {
          const path = line.getPath().getArray();
          for (const point of path) {
            const distance =
              window.google.maps.geometry.spherical.computeDistanceBetween(
                point,
                latLng
              );
            if (distance < 10) {
              // Adjust the distance threshold as needed
              // Fill the clicked grid line with color
              line.setOptions({
                strokeColor: "#00FF00",
                strokeOpacity: 0.8,
                strokeWeight: 2,
              });
              return;
            }
          }
        }
      }
 
 */

export function getAddressFromLatlng(apiKey, lat, lng) {
  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

  fetch(apiUrl)
    .then((response) => response.json())
    .then((data) => {
      if (data.status === "OK") {
        const results = data.results;
        if (results.length > 0) {
          return { status: true, results: results };
        } else {
          return { status: false, message: "No results found" };
        }
      } else {
        return { status: false, message: "Error in geocoding request" };
      }
    })
    .catch((error) => {
      return { status: false, message: error };
    });
}

export const getFromLocalStorage = (key) => {
  try {
    if (typeof window !== "undefined") {
      const serializedData = localStorage.getItem(key);
      if (serializedData === null) {
        return undefined;
      }
      return serializedData;
    }
    return undefined;
  } catch (err) {
    console.error("Error getting data from local storage:", err);
    return undefined;
  }
};

export function calculateQueryString(query) {
  let queryStrArr = [];
  Object.keys(query).forEach((k) => {
    const value = query[k];
    if (Array.isArray(value)) {
      for (var subvalue of value) {
        queryStrArr.push(`${k}=${subvalue}`);
      }
    } else {
      queryStrArr.push(`${k}=${value}`);
    }
  });
  return queryStrArr.length > 0 ? "?" + queryStrArr.join("&") : "";
}

// update data of form
export const updateOrderData = async (data) => {
  let payload = data;
  if (payload) {
    try {
      const response = await axios.put(
        apiUrl + "/api/cusOrder/" + localStorage.getItem("orderId"),
        payload
      );
      if (
        response.status === 200 &&
        response.data &&
        response.data.order &&
        response.data.order.id > 0
      ) {
        return { status: true };
      } else {
        return { status: false };
      }
    } catch (error) {
      //console.error("Error creating row:", error);
      return { status: false };
    }
  }
};

export const updateOrderProductsData = async (data) => {
  let payload = data;
  if (payload) {
    try {
      const response = await axios.put(
        apiUrl + "/api/cusOrderProducts/" + localStorage.getItem("orderId"),
        payload
      );
      if (
        response.status === 200 &&
        response.data &&
        response.data.order &&
        response.data.order.id > 0
      ) {
        return { status: true };
      } else {
        return { status: false };
      }
    } catch (error) {
      //console.error("Error creating row:", error);
      return { status: false };
    }
  }
};

export const geocodeLatLng = (latLng) => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line new-parens, no-undef
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, function (results, status) {
      if (status === "OK") {
        if (results[0]) {
          resolve({
            address: results[0].formatted_address,
            lat: latLng.lat,
            lng: latLng.lng,
          });
        } else {
          reject({
            address: "",
            lat: latLng.lat,
            lng: latLng.lng,
          });
        }
      } else {
        reject({
          address: "",
          lat: latLng.lat,
          lng: latLng.lng,
        });
      }
    });
  });
};
