import React, { useEffect } from "react";
import { getT, translationKeys } from "../../admin/utils";
import MainWrapper from "../MainWrapper";
import { updateQuoteData } from "../../utils";
import useScreenSize from "../../components/useScreenSize";
import Navigation from "../../components/Navigation";

export default function QuoteFailed() {
  const isMobile = useScreenSize(768);

  const onCheckOut = async () => {
    let res = await updateQuoteData(
      {
        status: 3,
        payment_status: "not_paid",
      },
      localStorage.getItem("quotePaymentId")
    );

    if (res.status) {
      // do something
    } else {
      console.log("update failed");
    }
  };

  useEffect(() => {
    onCheckOut();
  }, []);

  return (
    <MainWrapper
      mainClass="dynamicPage"
      topBar={false}
      showFooter={isMobile ? false : true}
      showChatBtn={isMobile ? false : true}
    >
      <div className="infoWrapper">
        <p className="mt-5 d-flex justify-content-center">
          {getT(translationKeys.paymentFailedMessage)}
        </p>
      </div>
      {isMobile && <Navigation />}
    </MainWrapper>
  );
}
