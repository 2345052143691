import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";
import {
  useCreateQuoteMutation,
  useQuoteQuery,
  useUpdateQuoteMutation,
} from "../../queries/quotes";

import {
  abitiOptions,
  infoOptions,
  airConditionerOptions,
  paymentStatusOptions,
  getT,
  translationKeys,
  electric_car_Options,
  inductionOptions,
} from "../utils";
import Accordion from "react-bootstrap/Accordion";
import App from "../../App";
import CustomInput from "./utils/CustomInput";
import CustomSelect2 from "./utils/CustomSelect2";
import { riscaldamentoOptions } from "../../utils";

const QuoteForm = ({ onEdit = false, editId = null }) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(true);
  const { data, isIdle, isLoading, isError, error } = useQuoteQuery(
    editId,
    editId ? true : false
  );

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdateQuoteMutation)
    : (mutationHook = useCreateQuoteMutation);
  const mutation = mutationHook();

  const formSchema = Yup.object().shape({
    /*name: Yup.string().required(getT(translationKeys.nameRequired)),
    surname: Yup.string().required(getT(translationKeys.surnameRequired)),
    email: Yup.string()
      .email(getT(translationKeys.emailError))
      .required(getT(translationKeys.emailRequired)),
    mobile: Yup.string().required(getT(translationKeys.mobileRequired)),*/
    add: Yup.string().required(getT(translationKeys.addressRequired)),
    cap: Yup.number().required(getT(translationKeys.zipRequired)),
    country: Yup.string().required(getT(translationKeys.countryRequired)),
    city: Yup.string().required(getT(translationKeys.cityRequired)),
    province: Yup.string().required(getT(translationKeys.stateRequired)),
  });

  useEffect(() => {
    if (data && !isLoading) {
      setEditData(data?.quote);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.quote &&
        mutation.data.data.quote.id > 0
      ) {
        onEdit
          ? toast.success(getT(translationKeys.updated))
          : toast.success(getT(translationKeys.created));
        navigate(paths.quotesPath);
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error(getT(translationKeys.generalError));
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    //console.log(values);
    const finalData = {
      /*email: values.email,
      name: values.name,
      surname: values.surname,*/
      mobile: values.mobile,
      address: values.add,
      city: values.city,
      cap: values.cap,
      country: values.country,
      province: values.province,
      induction_hub: values?.induction_hub ?? "",
      people_count: values?.people_count ?? "",
      heat_pump: values?.heat_pump ?? "",
      air_conditioners_pump: values?.air_conditioners_pump ?? "",
      full_area: values?.full_area ?? "",
      area: values?.area ?? "",
      energy_price: values?.energy_price ?? "",
      energy_consumption: values?.energy_consumption ?? "",
      source: values?.referal_type ?? "",
      //ftv: values.ftv,
      //bat: values.bat,
      col: values.col,
      electric_car: values.electric_car,
      f1: values.f1,
      f2: values.f2,
      f3: values.f3,
      discount: values.discount,
      //price: values.price,
      install: values.install,
      gse: values.gse,
      payment: values.payment,
      bat: values.bat,
      inverter_status: values.inverter_status,
      battery_status: values.battery_status,
      charging_status: values.charging_status,
      testing_status: values.testing_status,
      final_status: values.final_status,
    };

    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const fonteOptions = [
    { value: 1, label: "Facebook" },
    { value: 2, label: "Passaparola" },
    { value: 3, label: "Dipendente" },
    { value: 4, label: "Sito" },
  ];

  const handleClear = (formik) => {
    //formik.resetForm();
    navigate(paths.quotesPath);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">{getT(translationKeys.generalError)}</div>;
  }

  return (
    <Container fluid className="QuoteForm">
      <button
        onClick={() => {
          navigate(paths.quotesPath);
        }}
        className="btn btn-secondary "
      >
        {getT(translationKeys.back)}
      </button>
      <h4 className="my-2">
        {onEdit ? getT(translationKeys.update) : getT(translationKeys.create)}
      </h4>

      <button
        onClick={() => {
          setShowForm(!showForm);
        }}
        className={
          !showForm
            ? "btn btn-success  my-2 mapToggle"
            : "btn btn-success  my-2"
        }
      >
        {!showForm
          ? getT(translationKeys.showform)
          : getT(translationKeys.showmap)}
      </button>

      {!showForm && <App showHeader={false} editId={editId} />}
      {showForm && (
        <>
          {(editData && onEdit) || !onEdit ? (
            <Formik
              initialValues={{
                name: editData?.name ?? "",
                surname: editData?.surname ?? "",
                email: editData?.email ?? "",
                mobile: editData?.mobile ?? "",
                add: editData?.address ?? "",
                referal_type: editData?.source ?? "",
                province: editData?.province ?? "",
                city: editData?.city ?? "",
                country: editData?.country ?? "",
                cap: editData?.cap ?? "",
                full_area: editData?.full_area ?? "",
                area: editData?.area ?? "",
                energy_price: editData?.energy_price ?? "",
                energy_consumption: editData?.energy_consumption ?? "",
                ftv: editData?.ftv ?? "",
                bat: editData?.bat ?? "",
                discount: editData?.discount ?? "",
                price: editData?.price ?? "",
                install: editData?.install ?? "",
                gse: editData?.gse ?? "",
                payment: editData?.payment ?? "",
                f1: editData?.f1 ?? 1,
                f2: editData?.f2 ?? 1,
                f3: editData?.f3 ?? 1,
                col: editData?.col ?? "",
                electric_car: editData?.electric_car ?? "",
                induction_hub: editData?.induction_hub ?? "",
                people_count: editData?.people_count ?? "",
                heat_pump: editData?.heat_pump ?? "",
                air_conditioners_pump: editData?.air_conditioners_pump ?? "",
                inverter_status: editData?.inverter_status ?? "",
                battery_status: editData?.battery_status ?? "",
                charging_status: editData?.charging_status ?? "",
                testing_status: editData?.testing_status ?? "",
                final_status: editData?.final_status ?? "",
              }}
              validationSchema={formSchema}
              onSubmit={handleSubmit}
            >
              {({ formik, values, setFieldValue }) => (
                <Form>
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        {getT(translationKeys.customerInformation)}
                      </Accordion.Header>
                      <Accordion.Body>
                        {/*<Row>
                          <Col>
                            <CustomInput
                              label={getT(translationKeys.name)}
                              name="name"
                              defaultValue={values.name}
                            />
                          </Col>
                          <Col>
                            <CustomInput
                              label={getT(translationKeys.surname)}
                              name="surname"
                              defaultValue={values.surname}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col>
                            <CustomInput
                              label={getT(translationKeys.email)}
                              name="email"
                              type="email"
                              defaultValue={values.email}
                            />
                          </Col>
                          <Col>
                            <CustomInput
                              label={getT(translationKeys.mobile)}
                              name="mobile"
                              defaultValue={values.mobile}
                            />
                          </Col>
                        </Row> */}
                        <Row className="mt-2">
                          <Col sm={12} md={4}>
                            <CustomInput
                              label={getT(translationKeys.address)}
                              name="add"
                              defaultValue={values.add}
                            />
                          </Col>
                          <Col sm={12} md={4}>
                            <CustomInput
                              label={getT(translationKeys.country)}
                              name="country"
                              defaultValue={values.country}
                            />
                          </Col>
                          <Col sm={12} md={4}>
                            <CustomInput
                              label={getT(translationKeys.zip)}
                              name="cap"
                              defaultValue={values.cap}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col sm={12} md={4}>
                            <CustomInput
                              label={getT(translationKeys.city)}
                              name="city"
                              defaultValue={values.city}
                            />
                          </Col>
                          <Col sm={12} md={4}>
                            <CustomInput
                              label={getT(translationKeys.state)}
                              name="province"
                              defaultValue={values.province}
                            />
                          </Col>
                          <Col sm={12} md={4}>
                            <label className="form-label" htmlFor="city">
                              {getT(translationKeys.source)}
                            </label>

                            <Field
                              as="select"
                              className="form-select"
                              placeholder="Fonte"
                              id="referal_type"
                              name="referal_type"
                            >
                              <option value="">Fonte</option>
                              {fonteOptions.map((option, index) => (
                                <option
                                  key={index}
                                  value={option.value}
                                  label={option.label}
                                />
                              ))}
                              <ErrorMessage
                                className="danger"
                                name="referal_type"
                                component="div"
                              />
                            </Field>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        {getT(translationKeys.selection_roof_sub_label)}
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row className="mt-2">
                          <Col sm={12} md={6}>
                            <CustomInput
                              label={getT(translationKeys.mqt)}
                              name="area"
                              defaultValue={values.area}
                            />
                          </Col>
                          <Col sm={12} md={6}>
                            <CustomInput
                              label={getT(translationKeys.mqi)}
                              name="full_area"
                              defaultValue={values.full_area}
                            />
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        {getT(translationKeys.consumption)}
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row className="mt-2">
                          {/* <Col sm={12} md={3}>
                            <CustomInput
                              label="area"
                              name="area"
                              defaultValue={values.area}
                            />
                              </Col> */}
                          <Col sm={12} md={3}>
                            <CustomInput
                              label={getT(translationKeys.f1)}
                              name="f1"
                              defaultValue={values.f1}
                            />
                          </Col>
                          <Col sm={12} md={3}>
                            <CustomInput
                              label={getT(translationKeys.f2)}
                              name="f2"
                              defaultValue={values.f2}
                            />
                          </Col>
                          <Col sm={12} md={3}>
                            <CustomInput
                              label={getT(translationKeys.f3)}
                              name="f3"
                              defaultValue={values.f3}
                            />
                          </Col>
                          <Col sm={12} md={3}>
                            <CustomInput
                              label={
                                getT(translationKeys.batteryCount) +
                                " " +
                                values.bat
                              }
                              name="bat"
                              type="range"
                              classList={"form-range"}
                              min={0}
                              max={10}
                              defaultValue={values.bat}
                            />
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        {getT(translationKeys.energyRequirements)}
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row className="mt-2">
                          <Col sm={12} md={6}>
                            <CustomSelect2
                              label={getT(translationKeys.electricCarLabel)}
                              name="electric_car"
                              options={electric_car_Options}
                              defaultValue={values.electric_car}
                              defaultSelectVal={getT(
                                translationKeys.selection_electric_placeholder
                              )}
                              labelStatus={false}
                              boxVisible={true}
                              boxIcon={"car"}
                            />
                          </Col>
                          <Col sm={12} md={6}>
                            <CustomSelect2
                              label={getT(translationKeys.kichenLabel)}
                              name="induction_hub"
                              options={inductionOptions}
                              defaultValue={values.induction_hub}
                              labelStatus={false}
                              defaultSelectVal={getT(
                                translationKeys.selection_induction_placeholder
                              )}
                              boxVisible={true}
                              boxIcon={"kichen"}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col sm={12} md={6}>
                            <CustomSelect2
                              label={getT(translationKeys.acLabel2)}
                              name="air_conditioners_pump"
                              options={airConditionerOptions}
                              defaultValue={values.air_conditioners_pump}
                              defaultSelectVal={getT(
                                translationKeys.selection_cold_placeholder
                              )}
                              labelStatus={false}
                              boxVisible={true}
                              boxIcon={"cold"}
                            />
                          </Col>
                          <Col sm={12} md={6}>
                            <CustomSelect2
                              label={getT(translationKeys.personsLabel)}
                              name="heat_pump"
                              labelStatus={false}
                              options={riscaldamentoOptions}
                              defaultValue={values.air_conditioners_pump}
                              defaultSelectVal={getT(
                                translationKeys.selectionLabel_riscaldamento
                              )}
                              boxVisible={true}
                              boxIcon={"flakes"}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col sm={12} md={6}>
                            <CustomSelect2
                              label={getT(translationKeys.personsLabel)}
                              name="people_count"
                              options={riscaldamentoOptions}
                              labelStatus={false}
                              defaultValue={values.people_count}
                              defaultSelectVal={getT(
                                translationKeys.selection_people_placeholder
                              )}
                              boxIcon={"people"}
                              boxVisible={true}
                            />
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        {getT(translationKeys.payment)}
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row className="mt-2">
                          <Col sm={12} md={3}>
                            <CustomSelect2
                              label={getT(translationKeys.quote_payment_label)}
                              name="payment"
                              options={paymentStatusOptions}
                            />
                          </Col>
                          <Col sm={12} md={3}>
                            <CustomSelect2
                              label={getT(
                                translationKeys.quote_administrative_label
                              )}
                              name="gse"
                              options={paymentStatusOptions}
                            />
                          </Col>
                          <Col sm={12} md={3}>
                            <CustomSelect2
                              label={getT(
                                translationKeys.quote_fotovoltaic_module_label
                              )}
                              name="install"
                              options={paymentStatusOptions}
                            />
                          </Col>
                          <Col sm={12} md={3}>
                            <CustomSelect2
                              label={getT(translationKeys.quote_inverter_label)}
                              name="inverter_status"
                              options={paymentStatusOptions}
                            />
                          </Col>
                          <Col sm={12} md={3}>
                            <CustomSelect2
                              label={getT(translationKeys.quote_battery_label)}
                              name="battery_status"
                              options={paymentStatusOptions}
                            />
                          </Col>
                          <Col sm={12} md={3}>
                            <CustomSelect2
                              label={getT(translationKeys.quote_col_label)}
                              name="charging_status"
                              options={paymentStatusOptions}
                            />
                          </Col>
                          <Col sm={12} md={3}>
                            <CustomSelect2
                              label={getT(
                                translationKeys.quote_certification_label
                              )}
                              name="testing_status"
                              options={paymentStatusOptions}
                            />
                          </Col>
                          <Col sm={12} md={3}>
                            <CustomSelect2
                              label={getT(
                                translationKeys.quote_configuration_label
                              )}
                              name="final_status"
                              options={paymentStatusOptions}
                            />
                          </Col>

                          <Col sm={12} md={12}>
                            <CustomInput
                              label={
                                getT(translationKeys.discount) + values.discount
                              }
                              name="discount"
                              type="range"
                              classList={"form-range"}
                              min={10}
                              max={100}
                              defaultValue={values.discount}
                            />
                          </Col>
                        </Row>
                        {/* <Row className="mt-2">
                          <Col sm={12} md={4}>
                            <CustomInput
                              label="FTV"
                              name="ftv"
                              defaultValue={values.ftv}
                            />
                          </Col>
                          <Col sm={12} md={4}>
                            <CustomInput
                              label="BAT"
                              name="bat"
                              defaultValue={values.bat}
                            />
                          </Col>
                          
                        </Row>
                        <Row className="mt-2">
                          <Col sm={12} md={12}>
                            <CustomInput
                              label="Prezzo finale"
                              name="price"
                              defaultValue={values.price}
                            />
                          </Col>
                              </Row> */}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <Row className="mt-2">
                    <Col>
                      <button
                        type="submit"
                        disabled={submittable}
                        className="btn btn-success me-2"
                      >
                        {getT("submit")}
                      </button>
                      <button
                        type="button"
                        onClick={() => handleClear(formik)}
                        className="btn btn-secondary"
                      >
                        {getT("back")}
                      </button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          ) : (
            <Loading />
          )}
        </>
      )}
    </Container>
  );
};

export default QuoteForm;
