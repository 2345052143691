import React from "react";
import { useParams } from "react-router-dom";
import TranslationForm from "../forms/TranslationForm";

export default function EditTranslation() {
  const { id } = useParams();

  return (
    <>
      <TranslationForm onEdit editId={id} />
    </>
  );
}
