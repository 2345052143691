import React from "react";
import { useParams } from "react-router-dom";
import ContactForm from "../forms/ContactForm";

export default function EditContact() {
  const { id } = useParams();

  return (
    <>
      <ContactForm onEdit editId={id} />
    </>
  );
}
