import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { toast } from "react-toastify";
import CustomGoogleLogin from "./CustomGoogleLogin";
import { useAuth } from "../authContext";
import { getT, translationKeys } from "../admin/utils";

const MobileVerifyModal = ({ onLogin = () => {} }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [pageType, setPageType] = useState("message");
  const { authCustomerLogin, toggleLoginModal, toggleMobileModal } = useAuth();
  const [timeLeft, setTimeLeft] = useState(120);

  const validationSchema = Yup.object().shape({
    phone: Yup.string().required(getT(translationKeys.phoneRequired)),
  });

  const validationSchema2 = Yup.object().shape({
    code: Yup.string().required(getT(translationKeys.codeRequired)),
    phone: Yup.string().required(getT(translationKeys.phoneRequired)),
  });

  useEffect(() => {
    if (pageType === "message") return;

    const interval = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft <= 1) {
          clearInterval(interval);
          // Close the modal when the countdown reaches zero
          toggleLoginModal(false);
          toggleMobileModal(false);
          return 0;
        }
        return prevTimeLeft - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [pageType]);

  const getValidationSchema = () => {
    let finalVal = {};
    if (pageType === "message") {
      finalVal = validationSchema;
    }
    if (pageType === "code") {
      finalVal = validationSchema2;
    }
    return finalVal;
  };

  const getInitialValues = () => {
    let finalVal = {};
    if (pageType === "message") {
      finalVal = {
        phone: "",
        phone_country_code: "39",
      };
    }

    if (pageType === "code") {
      finalVal = {
        phone: "",
        code: "",
        phone_country_code: "39",
      };
    }

    return finalVal;
  };

  const getApiLocalUrl = () => {
    let finalUrl = "";
    if (pageType === "message") {
      finalUrl = "/api/mobile/sendCode";
    }
    if (pageType === "code") {
      finalUrl = "/api/mobile/verify";
    }
    console.log(finalUrl);
    return apiUrl + finalUrl;
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    // let's call the api to save the request of user
    let finalValues = {
      code: values.code,
      phone: values.phone,
      mobile_code: localStorage.getItem("mobile_token"),
      phone_country_code: values.phone_country_code,
    };

    try {
      // Make Axios POST request to save data
      const response = await axios.post(getApiLocalUrl(), finalValues);

      if (
        response.status === 200 &&
        pageType === "message" &&
        response.data &&
        response.data.status
      ) {
        // check if user verified his phone number or not
        console.log(response.data.user);
        setPageType("code");
      } else if (
        response.status === 200 &&
        pageType === "code" &&
        response.data &&
        response.data.user &&
        response.data.user.id > 0
      ) {
        // check if user verified his phone number or not
        authCustomerLogin(response.data.token);
        toggleLoginModal(false);
        toggleMobileModal(false);
      } else {
        console.log(response.status, response);
        //toggleLoginModal(false);
        if (!response.data.status && pageType === "message") {
          toast.error(getT(translationKeys.mobile_number_not_valid), {
            position: "top-right",
          });
        }
      }
      setSubmitting(false);
    } catch (error) {
      //console.error("Error saving data:", error);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message, {
          position: "top-right",
        });
      }
      onLogin(false);
      setSubmitting(false);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <Container>
      <Formik
        initialValues={getInitialValues()}
        validationSchema={getValidationSchema()}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, formik, isSubmitting }) => (
          <Form>
            <Row>
              {pageType === "code" && (
                <Col sm={12}>
                  <div>
                    {getT(translationKeys.mobile_verify_counter_help_label)}
                  </div>
                  <p
                    style={{
                      width: "100%",
                      maxWidth: "100px",
                      height: "auto",
                      borderRadius: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "grey",
                      color: "#fff",
                      margin: "0 auto",
                    }}
                  >
                    {formatTime(timeLeft)}
                  </p>
                </Col>
              )}
              <Col sm={2}>
                <div className="mb-3">
                  <label className="form-label" htmlFor="phone_country_code">
                    {getT(translationKeys.phone_country_code)}
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    name="phone_country_code"
                    disabled
                  />
                  <ErrorMessage
                    className="danger"
                    name="phone_country_code"
                    component="div"
                  />
                </div>
              </Col>
              <Col sm={10}>
                <div className="mb-3">
                  <label className="form-label" htmlFor="phone">
                    {getT(translationKeys.phone)}
                  </label>
                  <Field type="text" className="form-control" name="phone" />
                  <ErrorMessage
                    className="danger"
                    name="phone"
                    component="div"
                  />
                </div>
              </Col>
              {pageType === "code" && (
                <Col md={12}>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="code">
                      {getT(translationKeys.code)}
                    </label>
                    <Field type="text" className="form-control" name="code" />
                    <ErrorMessage
                      className="danger"
                      name="code"
                      component="div"
                    />
                  </div>
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="btn btn-success me-2"
                >
                  {getT(translationKeys.mobile_verify_submit_label)}
                </button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default MobileVerifyModal;
