import React from "react";
import TranslationForm from "../forms/TranslationForm";

export default function NewTranslation() {
  return (
    <>
      <TranslationForm />
    </>
  );
}
