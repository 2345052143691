import React, { useEffect, useState } from "react";
import { getT, isDateBetween, productColumns, translationKeys } from "../utils";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../authContext";
import WrapperModal from "../../components/ModalWrapper";
import { BsFillTrash3Fill, BsPencilSquare } from "react-icons/bs";

import Swal from "sweetalert2";
import { toast } from "react-toastify";
import {
  useDeleteProductCategoryMutation,
  useProductCategoryQuery,
} from "../../queries/productCategory";
import ProductCategoryForm from "../forms/ProductCategoryForm";

export default function ProductCategory({ pId = null }) {
  const [list, setList] = useState([]);
  const [editModal, setEditMoal] = useState(false);
  const [editId, setEditId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { TData } = useAuth();

  const navigate = useNavigate();

  const { data, isIdle, isLoading, isError, error } = useProductCategoryQuery(
    null,
    editId ? true : false,
    {
      pid: pId,
    }
  );

  const mutation = useDeleteProductCategoryMutation();

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (mutation.data.data.status) {
        toast.success("deleted");
      } else {
        toast.error("try again later");
      }
      mutation.reset();
    }
  }, [mutation]);

  useEffect(() => {
    if (data && !isLoading && data.items) {
      setList(data.items);
    }
  }, [isLoading, data]);

  return (
    <>
      <WrapperModal
        show={showModal}
        title={
          editModal
            ? getT(translationKeys.update)
            : getT(translationKeys.create)
        }
        handleClose={() => {
          setShowModal(!showModal);
        }}
      >
        <ProductCategoryForm
          onEdit={editModal}
          editId={editId}
          onClose={() => {
            setShowModal(false);
            setEditMoal(false);
            setEditId(null);
          }}
          pid={pId}
        />
      </WrapperModal>
      <div className="m-2">
        <div
          className="btn btn-success"
          onClick={() => {
            setShowModal(true);
          }}
        >
          {getT("add")}
        </div>
        <div>
          {isIdle || isLoading ? (
            getT(translationKeys.pleaseWait)
          ) : (
            <div className="p-list">
              {list.map((l) => {
                return (
                  <div className="p-list-item">
                    <span>{l.name}</span>
                    <span>
                      <BsPencilSquare
                        className="cursor"
                        fontSize={18}
                        onClick={() => {
                          console.log(l.id);
                          let id = l.id;
                          if (id > 0) {
                            setShowModal(true);
                            setEditMoal(true);
                            setEditId(id);
                          }
                        }}
                      />
                      <BsFillTrash3Fill
                        className="mx-2 cursor"
                        fontSize={18}
                        onClick={() => {
                          let id = l.id;
                          if (id > 0) {
                            Swal.fire({
                              title: "Are you sure ?",
                              text: "",
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                              confirmButtonText: "Yes",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                toast.loading("Deleting");
                                mutation.mutate({ id: id });
                              }
                            });
                          }
                        }}
                      />
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
