import React, { useEffect, useState } from "react";
import MessageForm from "../../admin/forms/MessageForm";
import { apiUrl } from "../../config/paths";
import WrapperModal from "../../components/ModalWrapper";
import { getT, translationKeys } from "../../admin/utils";
import Loading from "../../components/Loading";

export default function TicketWidgetModal({
  id = -1,
  showModal,
  setShowModal,
}) {
  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    // Check for the token in local storage
    const token = localStorage.getItem("customerToken");
    if (token) {
      fetch(apiUrl + "/api/me", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((res) => {
          if (res && res.user) {
            setUserInfo(res.user);
          }
        })
        .catch((error) => {
          console.error("Error checking token status:", error);
        })
        .finally(() => {});
    }
  }, []);

  return (
    <>
      <WrapperModal
        show={showModal}
        title={getT(translationKeys.ticket_modal_label)}
        handleClose={() => {
          setShowModal(!showModal);
        }}
        modalType={"xl"}
      >
        {id > 0 && userInfo?.id > 0 ? (
          <MessageForm
            onEdit
            editId={id}
            authId={userInfo?.id}
            location="profile"
            onClose={() => {
              setShowModal(!showModal);
            }}
          />
        ) : userInfo?.id > 0 ? (
          <MessageForm
            authId={userInfo?.id}
            location="profile"
            onClose={() => {
              setShowModal(!showModal);
            }}
          />
        ) : (
          <Loading />
        )}
      </WrapperModal>
    </>
  );
}
