import React, { useState } from "react";
import WrapperModal from "../../components/ModalWrapper";
import { eventStatusList, getT, translationKeys } from "../../admin/utils";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, Toast } from "react-bootstrap";
import { apiUrl } from "../../config/paths";
import axios from "axios";
import { getFromLocalStorage } from "../../utils/helpers";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { useEventQuery } from "../../queries/events";
import Swal from "sweetalert2";
import { useAuth } from "../../authContext";

export default function AppointmentModal({
  selectedAppointment,
  showModal,
  setShowModal = () => {},
  showEditModal = () => {},
}) {
  const queryClient = useQueryClient();
  const { customerInfo } = useAuth();

  const updateAppointment = async (id) => {
    toast.loading(getT(translationKeys.pleaseWait));
    try {
      const res = await axios
        .put(
          apiUrl + `/api/event/${id}`,
          {
            status: 3,
            user_id: customerInfo?.id,
          },
          {
            headers: {
              Authorization: getFromLocalStorage("customerToken"),
            },
          }
        )
        .then((res) => res);
      toast.dismiss();
      if (res.data.event && res.data.event.id > 1) {
        toast.success(getT(translationKeys.updated));
        setShowModal(!showModal, "update");
      }
      console.log(res, "response");
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const cancelAppointment = (id) => {
    Swal.fire({
      title: getT(translationKeys.are_you_sure_cancel_appointment_label),
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: getT(translationKeys.yes),
      cancelButtonText: getT(translationKeys.cancel),
    }).then((result) => {
      if (result.isConfirmed) {
        updateAppointment(id);
      }
    });
  };

  const getColor = (type) => {
    let clr = "";
    eventStatusList.forEach((s) => {
      if (s.value === type) {
        clr = s.color;
      }
    });
    return clr;
  };

  const getStatusName = (type) => {
    let clr = "";
    eventStatusList.forEach((s) => {
      if (s.value === type) {
        clr = s.name;
      }
    });
    return clr;
  };

  return (
    <>
      <WrapperModal
        show={showModal}
        title={getT(translationKeys.appointment_label)}
        handleClose={() => {
          setShowModal(!showModal);
        }}
      >
        <Container fluid className="appointmentsList">
          <Row>
            <Col md={6}>
              <label>{getT(translationKeys.from)}</label>
              <div>
                {selectedAppointment?.from_user?.name +
                  " (" +
                  selectedAppointment?.from_user?.email +
                  ")"}
              </div>
            </Col>
            <Col md={6}>
              <label>{getT(translationKeys.to)}</label>
              <div>
                {selectedAppointment?.to_user?.name +
                  " (" +
                  selectedAppointment?.from_user?.email +
                  ")"}
              </div>
            </Col>
            <Col md={6}>
              <label>{getT(translationKeys.startDate)}</label>
              <div>{selectedAppointment?.start_date}</div>
            </Col>
            <Col md={6}>
              <label>{getT(translationKeys.endDate)}</label>
              <div>{selectedAppointment?.end_date}</div>
            </Col>
            <Col md={6}>
              <label>{getT(translationKeys.description)}</label>
              <div>{selectedAppointment?.description}</div>
            </Col>
            <Col md={12}>
              <label>{getT(translationKeys.notes)}</label>
              <div>{selectedAppointment?.customer_notes}</div>
              <div
                style={{
                  backgroundColor: getColor(selectedAppointment?.status),
                  color: "#fff",
                  padding: "5px",
                  borderRadius: "10px",
                  maxWidth: "120px",
                  textAlign: "center",
                }}
                className="helpIcon me-1"
              >
                {getStatusName(selectedAppointment?.status)}
              </div>
            </Col>

            <Col md={12} className="mt-2 d-flex">
              <Button
                onClick={() => {
                  cancelAppointment(selectedAppointment.id);
                }}
                className="btn btn-danger me-1"
              >
                {getT(translationKeys.cancel)}
              </Button>
              <Button
                onClick={() => {
                  showEditModal();
                }}
                className="btn btn-info"
              >
                {getT(translationKeys.calender_date_modify)}
              </Button>
            </Col>
          </Row>
        </Container>
      </WrapperModal>
    </>
  );
}
