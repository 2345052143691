import React from "react";
import OrderForm from "../forms/OrderForm";

export default function NewOrder() {
  return (
    <>
      <OrderForm />
    </>
  );
}
