import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";
import { useAuth } from "../../authContext";
import { getT, translationKeys } from "../utils";
import CustomInput from "./utils/CustomInput";
import {
  useCreateDynamicSelectMutation,
  useDynamicSelectQuery,
  useUpdateDynamicSelectMutation,
} from "../../queries/dynamicselect";

const DynamicSelectForm = ({
  onEdit = false,
  editId = null,
  position = "",
}) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useDynamicSelectQuery(
    editId,
    editId ? true : false
  );
  const { TData } = useAuth();
  const formikRef = useRef();

  let mutationHook;

  const dynamicTypes = [
    { value: "floors", label: "piani fouri terra" },
    { value: "people", label: "conviventi" },
    { value: "heating", label: "riscaldomento" },
    { value: "electric", label: "electrica" },
    { value: "stove", label: "induction" },
    { value: "ac", label: "climazione" },
    { value: "ac_type", label: "riscaldomento" },
  ];

  const langTypes = [
    { value: "it", label: "italian" },
    { value: "en", label: "english" },
  ];

  onEdit
    ? (mutationHook = useUpdateDynamicSelectMutation)
    : (mutationHook = useCreateDynamicSelectMutation);
  const mutation = mutationHook();

  const formSchema = Yup.object().shape({
    name: Yup.string().required(getT(translationKeys.nameRequired)),
    value: Yup.string().required(getT(translationKeys.valuerequired)),
    type: Yup.string().required(getT(translationKeys.typeRequired)),
    lang: Yup.string().required(getT(translationKeys.langRequired)),
  });

  useEffect(() => {
    if (data && !isLoading && data?.item) {
      setEditData(data.item);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.item &&
        mutation.data.data.item.id > 0
      ) {
        if (position === "front") {
          handleClear();
          toast.success("soon we will contact you");
        } else {
          onEdit
            ? toast.success(getT(translationKeys.updated))
            : toast.success(getT(translationKeys.created));
          navigate(paths.dynamicSelectsPath);
        }
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error(getT(translationKeys.generalError));
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    //console.log(values);
    const finalData = {
      name: values.name,
      value: values.value,
      lang: values.lang,
      type: values.type,
      z_order: values.z_order,
      status: values.status,
    };

    console.log(finalData, "test");

    toast.warning(getT(translationKeys.pleaseWait));

    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const handleClear = () => {
    const { current } = formikRef;
    if (current) {
      current.resetForm();
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">{getT(translationKeys.generalError)}</div>;
  }

  return (
    <Container fluid>
      {position === "" && (
        <button
          onClick={() => {
            navigate(paths.dynamicSelectsPath);
          }}
          className="btn btn-secondary my-2"
        >
          {getT(translationKeys.back)}
        </button>
      )}
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              name: editData?.name ?? "",
              value: editData?.value ?? "",
              lang: editData?.lang ?? "",
              status: editData?.status && editData.status ? true : false,
              z_order: editData?.z_order,
              type: editData?.type ?? "",
            }}
            innerRef={formikRef}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, formik, resetForm }) => (
              <Form>
                <Row className="mt-2">
                  <Col md={3} className="mb-2">
                    <div className="d-flex">
                      <Field
                        type="checkbox"
                        id="status"
                        className="form-check-input me-2"
                        name="status"
                      />
                      <label className="me-2">
                        {getT(translationKeys.status)}
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.name}
                      name="name"
                      label={getT(translationKeys.name)}
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.value}
                      name="value"
                      label={getT(translationKeys.value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12}>
                    <label className="form-label" htmlFor="firstName">
                      {getT(translationKeys.lang)}{" "}
                    </label>

                    <Field
                      as="select"
                      className="form-select"
                      placeholder={getT(translationKeys.lang)}
                      id="lang"
                      name="lang"
                    >
                      {langTypes.map((option, index) => (
                        <option
                          key={index}
                          value={option.value}
                          label={option.label}
                        />
                      ))}
                      <ErrorMessage
                        className="danger"
                        name="lang"
                        component="div"
                      />
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12}>
                    <label className="form-label" htmlFor="firstName">
                      {getT(translationKeys.type)}{" "}
                    </label>

                    <Field
                      as="select"
                      className="form-select"
                      placeholder={getT(translationKeys.type)}
                      id="type"
                      name="type"
                    >
                      {dynamicTypes.map((option, index) => (
                        <option
                          key={index}
                          value={option.value}
                          label={option.label}
                        />
                      ))}
                      <ErrorMessage
                        className="danger"
                        name="type"
                        component="div"
                      />
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.z_order}
                      name="z_order"
                      label={getT(translationKeys.z_order)}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      {getT(translationKeys.submit)}
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default DynamicSelectForm;
