import React, { useEffect, useState } from "react";
import Filters from "../Filters";
import CustomTable from "../CustomTable";
import { BsFillTrash3Fill, BsPencilSquare } from "react-icons/bs";
import moment from "moment";
import {
  clientsColumns,
  getT,
  isDateBetween,
  menuColumns,
  rolesColumns,
  swalContext,
  translationKeys,
} from "../utils";
import { useNavigate } from "react-router-dom";
import paths, { adminWord } from "../../config/paths";
import { useDeleteMenuMutation, useMenuQuery } from "../../queries/Menus";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

export default function Menus() {
  const [list, setList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();

  const { data, isIdle, isLoading, isError, error } = useMenuQuery(
    null,
    false,
    {
      type: "all",
    }
  );
  const mutation = useDeleteMenuMutation();

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (mutation.data.data.status) {
        toast.success("deleted");
      } else {
        toast.error("try again later");
      }
      mutation.reset();
    }
  }, [mutation]);

  useEffect(() => {
    if (data && !isLoading && data.items) {
      let apiList = data.items.map((r) => {
        return {
          id: r?.id,
          date: moment(r?.created_at, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY"
          ),
          name: r?.page?.name,
          alias: r?.name,
          description: r?.description,
          status: r?.status,
          type: r?.type,
          linkType: r?.link_type,
          action: (
            <>
              <span
                data-id={r.id}
                className="cursor mx-2"
                onClick={(event) => {
                  let id =
                    event.target.parentNode.getAttribute("data-id") ||
                    event.target.getAttribute("data-id");
                  if (id > 0) {
                    navigate(adminWord + "/menus/edit/" + id);
                  }
                }}
              >
                <BsPencilSquare data-id={r.id} fontSize={25} />
              </span>
              <span
                data-id={r.id}
                className="cursor me-2 danger"
                onClick={(event) => {
                  let id =
                    event.target.parentNode.getAttribute("data-id") ||
                    event.target.getAttribute("data-id");
                  if (id > 0) {
                    Swal.fire({
                      title: getT(translationKeys.are_you_sure_label),
                      text: "",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: getT(translationKeys.yes),
                      cancelButtonText: getT(translationKeys.no),
                    }).then((result) => {
                      if (result.isConfirmed) {
                        toast.loading("Deleting");
                        mutation.mutate({ id: id });
                      }
                    });
                  }
                }}
              >
                <BsFillTrash3Fill data-id={r.id} fontSize={25} />
              </span>
            </>
          ),
        };
      });

      setFilteredData(apiList);
      setList(apiList);
    }
  }, [isLoading, data]);

  const onSearch = (values) => {
    var options = list;

    if (options && options.length > 0) {
      if (values.name !== "") {
        options = options.filter((s) => {
          let cString = s.name.toLowerCase();
          return cString.includes(values.name.toLowerCase());
        });
      }

      if (values.address !== "") {
        options = options.filter((s) => {
          let cString = s.add.toLowerCase();
          return cString.includes(values.address.toLowerCase());
        });
      }

      if (values.comune !== "") {
        options = options.filter((s) => {
          let cString = s.comune.toLowerCase();
          return cString.includes(values.comune.toLowerCase());
        });
      }

      if (values.type !== "") {
        options = options.filter((s) => {
          return s.type === values.type;
        });
      }

      if (values.from !== "" && values.to !== "") {
        options = options.filter((s) => {
          let date = moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD");
          return isDateBetween(values.from, values.to, date);
        });
      }

      console.log(values);
    }

    setFilteredData(options);
  };

  const onClear = () => {
    setFilteredData(list);
  };

  return (
    <>
      <Filters
        type="menus"
        onAdd={() => navigate(paths.newMenuPath)}
        onFilter={onSearch}
        onClear={onClear}
      />
      <div className="customTable">
        {isIdle || isLoading ? (
          getT(translationKeys.pleaseWait)
        ) : (
          <CustomTable columns={menuColumns} data={filteredData} />
        )}
      </div>
    </>
  );
}
