import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { toast } from "react-toastify";
import CustomGoogleLogin from "./CustomGoogleLogin";
import { useAuth } from "../authContext";
import { getT, translationKeys } from "../admin/utils";
import CustomInput from "../admin/forms/utils/CustomInput";
import { BiSolidLock } from "react-icons/bi";
import { IoMdEyeOff } from "react-icons/io";
import { IoMdEye } from "react-icons/io";
import { MdOutlineMailOutline } from "react-icons/md";

const AuthenticationModal = ({ onLogin = () => {} }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [pageType, setPageType] = useState("login");
  const [pwd, setPwd] = useState("password");
  const { authCustomerLogin, toggleLoginModal, toggleMobileModal } = useAuth();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(getT(translationKeys.nameRequired)),
    lastName: Yup.string().required(getT(translationKeys.surnameRequired)),
    email: Yup.string()
      .email("Invalid email")
      .required(getT(translationKeys.emailRequired)),
    password: Yup.string()
      .min(6)
      .required(getT(translationKeys.passwordRequired)),
  });

  const validationSchema2 = Yup.object().shape({
    email: Yup.string()
      .email(getT(translationKeys.emailError))
      .required(getT(translationKeys.emailRequired)),
    password: Yup.string()
      .min(6)
      .required(getT(translationKeys.passwordRequired)),
  });

  const validationSchema3 = Yup.object().shape({
    email: Yup.string()
      .email(getT(translationKeys.emailError))
      .required(getT(translationKeys.emailRequired)),
  });

  const validationSchema4 = Yup.object().shape({
    email: Yup.string()
      .email(getT(translationKeys.emailError))
      .required(getT(translationKeys.emailRequired)),
    token: Yup.string().required(getT(translationKeys.codeRequired)),
    password: Yup.string()
      .min(6)
      .required(getT(translationKeys.passwordRequired)),
  });

  const getValidationSchema = () => {
    let finalVal = {};
    if (pageType === "signup") {
      finalVal = validationSchema;
    }
    if (pageType === "login") {
      finalVal = validationSchema2;
    }

    if (pageType === "forget") {
      finalVal = validationSchema3;
    }
    if (pageType === "reset") {
      finalVal = validationSchema4;
    }
    return finalVal;
  };

  const getInitialValues = () => {
    let finalVal = {};
    if (pageType === "login") {
      finalVal = {
        email: "",
        password: "",
      };
    }

    if (pageType === "signup") {
      finalVal = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
      };
    }

    if (pageType === "forget") {
      finalVal = finalVal = {
        email: "",
      };
    }
    if (pageType === "reset") {
      finalVal = finalVal = {
        email: "",
        token: "",
        password: "",
      };
    }
    return finalVal;
  };

  const getApiLocalUrl = () => {
    let finalUrl = "";
    if (pageType === "login") {
      finalUrl = "/api/login";
    }
    if (pageType === "signup") {
      finalUrl = "/api/signup";
    }
    if (pageType === "forget") {
      finalUrl = "/api/forget";
    }
    if (pageType === "reset") {
      finalUrl = "/api/reset";
    }
    console.log(finalUrl);
    return apiUrl + finalUrl;
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    // let's call the api to save the request of user
    let finalValues = {
      name: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      token: values.token,
    };

    try {
      // Make Axios POST request to save data
      const response = await axios.post(getApiLocalUrl(), finalValues);

      if (
        response.status === 201 &&
        pageType === "signup" &&
        response.data &&
        response.data.user &&
        response.data.user.id > 0
      ) {
        /*toast.success("your profile created", {
          position: "top-right",
        });*/
        // check if user verified his phone number or not
        //console.log(response.data.user);

        //authCustomerLogin(response.data.token);
        //window.location.reload();
        //toggleLoginModal(false);
        if (!response.data.user.phone) {
          localStorage.setItem("mobile_token", response.data.user.mobile_code);
          toggleLoginModal(false);
          toggleMobileModal(true);
        } else {
          authCustomerLogin(response.data.token);
          //window.location.reload();
          toggleLoginModal(false);
        }
      } else if (
        response.status === 200 &&
        pageType === "login" &&
        response.data &&
        response.data.user &&
        response.data.user.id > 0
      ) {
        // check if user verified his phone number or not
        console.log(response.data.user);

        //authCustomerLogin(response.data.token);
        // window.location.reload();
        //toggleLoginModal(false);
        if (!response.data.user.phone) {
          localStorage.setItem("mobile_token", response.data.user.mobile_code);
          toggleLoginModal(false);
          toggleMobileModal(true);
        } else {
          authCustomerLogin(response.data.token);
          //window.location.reload();
          toggleLoginModal(false);
        }
      } else if (
        response.status === 200 &&
        pageType === "forget" &&
        response.data &&
        response.data.token
      ) {
        toast.success(getT(translationKeys.check_email_for_code_label), {
          position: "top-right",
        });
        setPageType("reset");
      } else if (response.status === 200 && pageType === "reset") {
        toast.success(getT(translationKeys.updated_password_now_login_label), {
          position: "top-right",
        });
        setPageType("login");
      } else {
        console.log(response.status, response);
        //toggleLoginModal(false);
      }
      setSubmitting(false);
    } catch (error) {
      //console.error("Error saving data:", error);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message, {
          position: "top-right",
        });
      }
      onLogin(false);
      setSubmitting(false);
    }
  };

  return (
    <Container>
      <Formik
        initialValues={getInitialValues()}
        validationSchema={getValidationSchema()}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, formik, isSubmitting }) => (
          <Form>
            <Row className="loginModal">
              <Col lg={6} md={4}>
                <div className="side-image ">
                  <img
                    src="https://img.freepik.com/free-photo/smiling-young-businesswoman-typing-laptop-office_231208-5887.jpg"
                    alt="login"
                  />
                </div>
              </Col>
              <Col lg={6} md={8}>
                <div className="button-container">
                  <CustomGoogleLogin pageType={pageType} />
                  <div className="my-3">OR</div>
                  <Row>
                    <Col>
                      <div
                        className={
                          pageType === "login"
                            ? "btn btn-success me-2 btn-full mb-2"
                            : "btn btn-secondary me-2 btn-full mb-2"
                        }
                        onClick={() => setPageType("login")}
                      >
                        {getT(translationKeys.login)}
                      </div>
                    </Col>
                    <Col>
                      <div
                        className={
                          pageType === "signup"
                            ? "btn btn-success btn-full mb-2 me-2"
                            : "btn btn-secondary  btn-full mb-2 me-2"
                        }
                        onClick={() => setPageType("signup")}
                      >
                        {getT(translationKeys.signup)}
                      </div>
                    </Col>
                  </Row>
                </div>{" "}
                <Row>
                  {pageType === "signup" && (
                    <>
                      {" "}
                      <Col md={6}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="firstName">
                            {getT(translationKeys.name)}
                          </label>
                          <Field
                            type="text"
                            id="firstName"
                            className="form-control"
                            name="firstName"
                          />
                          <ErrorMessage
                            className="danger"
                            name="firstName"
                            component="div"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="lastName">
                            {getT(translationKeys.surname)}
                          </label>
                          <Field
                            type="text"
                            id="lastName"
                            className="form-control"
                            name="lastName"
                          />
                          <ErrorMessage
                            className="danger"
                            name="lastName"
                            component="div"
                          />
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
                <Row>
                  <Col>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.email}
                      name="email"
                      label={getT(translationKeys.email)}
                      placeholder={getT(translationKeys.email)}
                      isIcon={true}
                      iconData={<MdOutlineMailOutline />}
                    />
                  </Col>
                </Row>
                {pageType === "reset" && (
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                          {getT(translationKeys.code)}
                        </label>
                        <Field
                          className="form-control"
                          id="token"
                          name="token"
                        />
                        <ErrorMessage
                          className="danger"
                          name="token"
                          component="div"
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                {pageType !== "forget" && (
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <CustomInput
                          onEdit={false}
                          defaultValue={values.password}
                          name="password"
                          label={
                            pageType === "reset"
                              ? getT(translationKeys.new_password)
                              : getT(translationKeys.password)
                          }
                          placeholder="Password"
                          isIcon={true}
                          type={pwd}
                          extraIcon={true}
                          extranIconData={
                            pwd === "password" ? (
                              <IoMdEyeOff
                                onClick={() => {
                                  setPwd("text");
                                }}
                                className="pwdIcon cursor"
                              />
                            ) : (
                              <IoMdEye
                                onClick={() => {
                                  setPwd("password");
                                }}
                                className="pwdIcon cursor"
                              />
                            )
                          }
                          iconData={<BiSolidLock />}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                {pageType === "login" && (
                  <Row>
                    <Col>
                      <p
                        className="cursor reset"
                        onClick={() => {
                          setPageType("forget");
                        }}
                      >
                        {getT(translationKeys.forgetpwd_label)}
                      </p>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="btn btn-success me-2"
                    >
                      {getT(translationKeys.submit)}
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default AuthenticationModal;
