import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";
import { useAuth } from "../../authContext";
import { getT, translationKeys } from "../utils";
import CustomInput from "./utils/CustomInput";
import { usePagesQuery } from "../../queries/pages";
import CustomSelect2 from "./utils/CustomSelect2";
import {
  useCreateNotificationMutation,
  useNotificationQuery,
  useUpdateNotificationMutation,
} from "../../queries/notifications";
import CustomTextArea from "./utils/CustomTextArea";
import CustomSelect from "./utils/CustomSelect";
import { useUserQuery } from "../../queries/users";

const NotificationForm = ({ onEdit = false, editId = null }) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const [usersData, setUsersData] = useState([]);
  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useNotificationQuery(
    editId,
    editId ? true : false
  );

  const [pages, setPages] = useState([]);

  const {
    data: pagesData,
    isIdle: pagesIdle,
    isLoading: pagesLoading,
    isError: pagesError,
  } = usePagesQuery(null, true);

  const { TData } = useAuth();

  const {
    data: userData,
    isIdle: userIdle,
    isLoading: userLoading,
    isError: userError,
  } = useUserQuery();

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdateNotificationMutation)
    : (mutationHook = useCreateNotificationMutation);
  const mutation = mutationHook();

  const formSchema = Yup.object().shape({
    name: Yup.string().required(getT(translationKeys.nameRequired)),
    description: Yup.string().required(
      getT(translationKeys.descriptionRequired)
    ),
    to_id: Yup.object().required(getT(translationKeys.fromRequired)),
    from_id: Yup.object().required(getT(translationKeys.toRequired)),
  });

  useEffect(() => {
    if (pagesData && !pagesLoading && pagesData?.items) {
      let items = pagesData.items.filter((p) => !p.parent_id && p);
      setPages(items);
    }
  }, [pagesLoading, pagesData]);

  useEffect(() => {
    if (userData && !userLoading && userData.users) {
      setUsersData(userData?.users);
    }
  }, [userLoading, userData]);

  useEffect(() => {
    if (data && !isLoading && data?.item) {
      setEditData(data.item);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.item &&
        mutation.data.data.item.id > 0
      ) {
        onEdit
          ? toast.success(getT(translationKeys.updated))
          : toast.success(getT(translationKeys.created));
        navigate(paths.notificationsPath);
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error(getT(translationKeys.generalError));
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    //console.log(values);
    const finalData = {
      name: values.name,
      description: values.description,
      status: values.status,
      type: values.type,
      to_id: values.to_id.value,
      from_id: values.from_id.value,
    };

    toast.warning(getT(translationKeys.pleaseWait));

    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    navigate(paths.notificationsPath);
  };

  const getPages = () => {
    let items = [];
    pages.forEach((p) => {
      items.push({
        name: p?.name,
        value: p?.id,
      });
    });
    return items;
  };

  const getUsersByType = (type) => {
    let users = [];
    usersData.forEach((u) => {
      if ((u.role && u.role.name === type) || type === "all") {
        users.push(u);
      }
    });
    return users && users.length > 0
      ? users.map((u) => {
          return {
            value: u.id,
            label: u.name,
          };
        })
      : [];
  };

  const getInitialUserInfo = (id) => {
    let user = {};
    usersData.forEach((u) => {
      if (u.id === parseInt(id)) {
        user = {
          value: u.id,
          label: u.name,
        };
      }
    });
    return user;
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">{getT(translationKeys.generalError)}</div>;
  }

  return (
    <Container fluid className="leadForm">
      <button
        onClick={() => {
          navigate(paths.notificationsPath);
        }}
        className="btn btn-secondary "
      >
        {getT(translationKeys.back)}
      </button>
      <h4 className="my-2">
        {onEdit ? getT(translationKeys.update) : getT(translationKeys.create)}
      </h4>
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              name: editData?.name ?? "",
              description: editData?.description ?? "",
              type: editData?.type ? editData.type : "general",
              to_id: getInitialUserInfo(editData?.to_id),
              from_id: getInitialUserInfo(editData?.from_id),
              status: editData?.status && editData.status ? true : false,
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, formik }) => (
              <Form>
                <Row>
                  <Col md={4}>
                    <CustomSelect
                      label={getT(translationKeys.from)}
                      name="from_id"
                      isMulti={false}
                      defaultValue={values.from_id}
                      options={getUsersByType("all")}
                    />
                  </Col>
                  <Col md={4}>
                    <CustomSelect
                      label={getT(translationKeys.to)}
                      name="to_id"
                      isMulti={false}
                      defaultValue={values.to_id}
                      options={getUsersByType("customer")}
                    />
                  </Col>
                  <Col md={4}>
                    <CustomSelect2
                      label={getT(translationKeys.type)}
                      name="type"
                      options={[
                        {
                          name: "Ticket",
                          value: "ticket",
                        },
                        {
                          name: "Appointment",
                          value: "appointment",
                        },
                        {
                          name: "Quote",
                          value: "quote",
                        },
                        {
                          name: "General",
                          value: "general",
                        },
                      ]}
                      defaultValue={values.type}
                    />
                  </Col>

                  <Col md={12}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.name}
                      name="name"
                      label={getT(translationKeys.name)}
                    />
                  </Col>
                  <Col md={12}>
                    <CustomTextArea
                      name="description"
                      label={getT(translationKeys.description)}
                      defaultValue={values.description}
                      placeholder=""
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      {getT(translationKeys.submit)}
                    </button>
                    <button
                      type="button"
                      onClick={() => handleClear(formik)}
                      className="btn btn-secondary"
                    >
                      {getT(translationKeys.back)}
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default NotificationForm;
