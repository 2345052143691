// Home.js
import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { apiUrl } from "../config/paths";
import axios from "axios";
import { useAuth } from "../authContext";

const CustomGoogleLogin = ({ pageType = "" }) => {
  const { authCustomerLogin, toggleLoginModal, toggleMobileModal } = useAuth();

  const updateUserInfo = async (user) => {
    try {
      // update or get user info based on user login
      const response = await axios.post(apiUrl + "/api/users/sync", {
        name: user?.name,
        email: user?.email,
        sub: user?.sub + "__google",
        family_name: user?.family_name,
      });
      if (response.data && response.data.user && response.data.user.id > 0) {
        // check if user verified his phone number or not
        //console.log(response.data.user);
        if (!response.data.user.phone) {
          localStorage.setItem("mobile_token", response.data.user.mobile_code);
          toggleLoginModal(false);
          toggleMobileModal(true);
        } else {
          authCustomerLogin(response.data.token);
          toggleLoginModal(false);
        }
      } else {
        console.log(response.status, response);
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };
  return (
    <div className="googleLogin">
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          const decodedRes = jwtDecode(credentialResponse?.credential);
          updateUserInfo(decodedRes);
        }}
        className="google-login-button"
        onError={() => {
          console.log("Login Failed");
        }}
        width="270"
        text={pageType === "login" ? "signin_with" : "signup_with"}
      />
    </div>
  );
};

export default CustomGoogleLogin;
