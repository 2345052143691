import React from "react";
import NotificationForm from "../forms/NotificationForm";

export default function NewNotification() {
  return (
    <>
      <NotificationForm />
    </>
  );
}
