import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { apiUrl } from "../../config/paths";
import { getFolderPath } from "../../utils/api";

export default function Slider({ items = [], selected = 0 }) {
  const [index, setIndex] = useState(selected);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div className="sliderContainer">
      <Carousel controls={false} activeIndex={index} onSelect={handleSelect}>
        {items.map((g, index) => {
          return (
            <Carousel.Item key={index}>
              <img
                src={getFolderPath + g?.file?.name}
                alt={g?.file?.original_name}
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}
