import React, { useEffect, useRef, useState } from "react";
import { BsDashLg, BsFillGridFill, BsPlusLg } from "react-icons/bs";
import { FaPencilAlt } from "react-icons/fa";
import {
  FaHandPointUp,
  FaHandPointer,
  FaRotate,
  FaTrash,
} from "react-icons/fa6";
import { getT, translateColumns, translationKeys } from "../admin/utils";
import Swal from "sweetalert2";
import CustomOverLay from "../components/CustomOverLay";

export default function DrawingMap({ zoom, loc, onDraw, coordinates }) {
  let drawnPolygons = [];
  let rotation = 0;
  const mapRef = useRef(null);
  const infoWindowRef = useRef(null);
  let debounceTimeout;
  let marker;

  let vertexMarkers = [];
  const polyRef = useRef(null);
  const markerRef = useRef(null);
  const areaLabelRef = useRef(null);
  var drawingModeActive = true;
  var visible = true;

  const [isDrawing, setIsDrawing] = useState(true);
  useEffect(() => {
    if (window.google && window.google.maps) {
      loadMap();
    }
  }, [window.google]);
  const [drawingManager, setDrawingManager] = useState(null);

  const changeTilt = () => {
    //let val = map.getTilt() + 45;
    if (mapRef.current.getTilt() === 45) {
      mapRef.current.setTilt(0);
    } else {
      mapRef.current.setTilt(45);
    }
  };

  const changeHeading = () => {
    let val = rotation + 45;
    if (mapRef.current) {
      mapRef.current.setHeading((mapRef.current.getHeading() + 45) % 360);
      rotation = val;
    }
    /*if (infoWindowRef.current) {
      infoWindowRef.current.close();
    }*/
  };

  const changeDrawingMode = () => {
    console.log(drawingManager);
    let drawingPoly = window.google.maps.drawing.OverlayType.POLYGON;
    drawingManager.setDrawingMode(drawingPoly);
  };

  const loadMap = async () => {
    const myLatLng = loc;
    const mapEle = document.getElementById("customMap");
    if (mapEle) {
      const map = new window.google.maps.Map(mapEle, {
        zoom: zoom ? parseInt(zoom) : 20,
        center: myLatLng,
        minZoom: 18,
        tilt: 0,
        disableDefaultUI: true,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        mapId: "b9ce3553b919f3bc",
      });
      map.setMapTypeId("satellite");
      map.setHeading(0);

      mapRef.current = map;

      // adding drawing manager
      const drawingManagerInstance =
        new window.google.maps.drawing.DrawingManager({
          drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
          drawingControl: false,
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
          },
          polygonOptions: {
            editable: true,
            draggable: true,
            fillColor: "green",
            strokeColor: "green",
            strokeOpacity: 0.8,
            strokeWeight: 3,
            fillOpacity: 0.35,
            geodesic: true,
          },
        });

      drawingManagerInstance.setMap(map);
      setDrawingManager(drawingManagerInstance);

      let infoWindow = new window.google.maps.InfoWindow({
        content: `<div>${getT(translationKeys.drawing_help_label)}</div>`,
      });

      infoWindowRef.current = infoWindow;

      // Custom overlay for tracking mouse position
      var mouseTrackingOverlay = new window.google.maps.OverlayView();
      mouseTrackingOverlay.draw = function () {}; // Required, but we don't need to draw anything

      // Listen for mousemove event on the map
      mouseTrackingOverlay.onAdd = function () {
        var container = this.getPanes().overlayMouseTarget;
        var that = this;

        container.addEventListener("mousemove", function (event) {
          var latLng = that
            .getProjection()
            .fromContainerPixelToLatLng(
              new window.google.maps.Point(event.clientX, event.clientY - 80)
            );
          infoWindow.setPosition(latLng);
          console.log(visible, "visible");
          if (visible) {
            infoWindow.open(map);
          }
        });
      };
      mouseTrackingOverlay.setMap(map);

      window.google.maps.event.addListener(map, "mousedown", () => {
        if (drawingModeActive) {
          markerRef.current.setVisible(false);
        }

        infoWindow.close(map);
        visible = false;
      });

      window.google.maps.event.addListener(
        drawingManagerInstance,
        "drawingmode_changed",
        function () {
          drawingModeActive =
            drawingManagerInstance.getDrawingMode() ===
            window.google.maps.drawing.OverlayType.POLYGON;
          if (drawingModeActive) {
            visible = true;
          }
        }
      );

      window.google.maps.event.addListener(map, "mousemove", (event) => {
        infoWindow.setPosition(event.latLng);
      });

      const icon = {
        url: "/assets/pin_google.png",
        // eslint-disable-next-line no-undef
        //scaledSize: new google.maps.Size(30, 30),
      };
      const marker = new window.google.maps.Marker({
        position: myLatLng,
        map,
        draggable: false,
        customName: "myMarkerInfo",
        icon: icon,
        title: "",
      });

      markerRef.current = marker;

      // overlaycomplete
      window.google.maps.event.addListener(
        drawingManagerInstance,
        "overlaycomplete",
        function (event) {
          if (event.type === window.google.maps.drawing.OverlayType.POLYGON) {
            const polygon = event.overlay;

            markerRef.current.setVisible(true);

            let path = polygon.getPath();
            // Calculate area
            let area = window.google.maps.geometry.spherical.computeArea(path);

            let fixedCoordinates = polygon
              .getPath()
              .getArray()
              .map((point) => ({
                lat: point.lat(),
                lng: point.lng(),
              }));

            onDraw({
              dynamic: fixedCoordinates,
              fixed: fixedCoordinates,
              area: area.toFixed(2),
            });

            removeAllPolygons();
            drawingManagerInstance.setDrawingMode(null);
            drawnPolygons.push(polygon);
            polyRef.current = polygon;
            // Add event listeners for changes in polygon
            window.google.maps.event.addListener(
              path,
              "insert_at",
              debounceCalculateArea,
              { passive: true }
            );
            window.google.maps.event.addListener(
              path,
              "set_at",
              debounceCalculateArea,
              { passive: true }
            );

            window.google.maps.event.addListener(
              path,
              "dragend",
              debounceCalculateArea,
              { passive: true }
            );

            debounceCalculateArea();
            setIsDrawing(false);
            infoWindow.close();
            //handlePolygonEvents(polygon);
          }
        }
      );

      // drawingmode_changed
      window.google.maps.event.addListener(
        drawingManagerInstance,
        "drawingmode_changed",
        function () {
          var mode = drawingManagerInstance.getDrawingMode();
          if (mode !== window.google.maps.drawing.OverlayType.POLYGON) {
            infoWindow.close();
          }
        }
      );

      // Add area label
      let areaLabel = new window.google.maps.InfoWindow();
      areaLabelRef.current = areaLabel;

      // adding drawing manager
      if (coordinates) {
        let polygonToShow = new window.google.maps.Polygon({
          paths: coordinates,
          editable: true,
          draggable: true,
          fillColor: "green",
          fillOpacity: 0.35,
          strokeColor: "green",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          geodesic: true,
        });
        removeAllPolygons();
        debounceCalculateArea();
        polygonToShow.setMap(map);
        polyRef.current = polygonToShow;
        drawnPolygons.push(polygonToShow);

        // Add event listeners for changes in polygon
        window.google.maps.event.addListener(
          polyRef.current.getPath(),
          "insert_at",
          debounceCalculateArea,
          { passive: true }
        );
        window.google.maps.event.addListener(
          polyRef.current.getPath(),
          "set_at",
          debounceCalculateArea,
          { passive: true }
        );
        window.google.maps.event.addListener(
          polyRef.current.getPath(),
          "dragend",
          debounceCalculateArea,
          { passive: true }
        );

        setIsDrawing(false);
        //handlePolygonEvents(polygon_field);
        //drawingManagerInstance.setDrawingMode(null);
        /*if (infoWindowRef.current) {
          infoWindowRef.current.close();
        }*/
      }
    }

    function handlePolygonEvents(poly) {
      window.google.maps.event.addListener(poly, "mouseover", function () {
        highlightPolygonVertices(poly);
      });

      window.google.maps.event.addListener(poly, "mouseout", function () {
        hideVertexMarkers();
      });
    }

    function highlightPolygonVertices(poly) {
      let path = poly.getPath();
      // Clear any existing vertex markers
      hideVertexMarkers();

      path.forEach(function (latlng, index) {
        let marker = new window.google.maps.Marker({
          position: latlng,
          map: mapRef.current,
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 10,
            fillColor: "green",
            fillOpacity: 1,
            strokeWeight: 1,
          },
        });
        vertexMarkers.push(marker);
      });
    }
    function hideVertexMarkers() {
      vertexMarkers.forEach(function (marker) {
        marker.setMap(null);
      });
      vertexMarkers = [];
    }

    function debounceCalculateArea() {
      infoWindowRef.current.close(mapRef.current);
      // Clear the previous timeout
      clearTimeout(debounceTimeout);
      // Set a new timeout
      debounceTimeout = setTimeout(calculateArea, 500); // Adjust the delay as needed
    }

    function calculateArea() {
      var area = window.google.maps.geometry.spherical.computeArea(
        polyRef.current.getPath()
      );
      let fixedCoordinates = polyRef.current
        .getPath()
        .getArray()
        .map((point) => ({
          lat: point.lat(),
          lng: point.lng(),
        }));
      // Assuming you have some UI element to display the area
      var contentString =
        '<div class="custom-infowindow">' + area.toFixed(2) + "</div>";
      areaLabelRef.current.setContent(contentString);
      areaLabelRef.current.setPosition(polyRef.current.getPath().getAt(0)); // Display area at first vertex
      //areaLabelRef.current.open(mapRef.current);
      onDraw({
        dynamic: fixedCoordinates,
        fixed: fixedCoordinates,
        area: area.toFixed(2),
      });
    }
  };

  // Function to remove all drawn polygons
  const removeAllPolygons = (val) => {
    // Iterate through the array of drawn polygons

    drawnPolygons.forEach((ele) => {
      ele.setMap(null);
    });

    /*if (infoWindowRef.current) {
      infoWindowRef.current.close();
    }*/

    if (val) {
      Swal.fire({
        title: getT(
          translationKeys.are_you_sure_to_delete_your_roof_panel_label
        ),
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: getT(translationKeys.yes),
        cancelButtonText: getT(translationKeys.cancel),
      }).then((result) => {
        if (result.isConfirmed) {
          //polygon_field = null;
          if (polyRef.current) {
            polyRef.current.setMap(null);
          }

          if (areaLabelRef.current) {
            areaLabelRef.current.close();
          }

          onDraw({
            dynamic: "",
            fixed: "",
            area: 0,
          });

          setIsDrawing(true);
        }
      });
    }
  };

  const increaseZoom = () => {
    mapRef.current.setZoom(mapRef.current.getZoom() + 1);
  };

  const decreaseZoom = () => {
    mapRef.current.setZoom(mapRef.current.getZoom() - 1);
  };

  const toggleDrawingMode = (val) => {
    if (val) {
      drawingManager.setDrawingMode(
        window.google.maps.drawing.OverlayType.POLYGON
      );
    } else {
      drawingManager.setDrawingMode(null);
    }
    setIsDrawing(val);
  };

  return (
    <>
      <div className="rotationBtn large">
        <CustomOverLay
          placement="left"
          message={getT(translationKeys.draw_info_label)}
          iconElement={
            <FaPencilAlt
              cursor={"pointer"}
              onClick={() => toggleDrawingMode(true)}
            />
          }
        ></CustomOverLay>
        <CustomOverLay
          placement="left"
          message={getT(translationKeys.pointer_info_label)}
          iconElement={
            <FaHandPointer
              cursor={"pointer"}
              onClick={() => toggleDrawingMode(false)}
            />
          }
        ></CustomOverLay>
        <CustomOverLay
          placement="left"
          message={getT(translationKeys.delete_polygon_info_label)}
          iconElement={
            <FaTrash
              cursor={"pointer"}
              onClick={() => removeAllPolygons(true)}
            />
          }
        ></CustomOverLay>
        <CustomOverLay
          placement="left"
          message={getT(translationKeys.rotate_info_label)}
          iconElement={
            <FaRotate
              fontSize={25}
              cursor={"pointer"}
              onClick={() => changeHeading()}
            />
          }
        />
        <CustomOverLay
          placement="left"
          message={getT(translationKeys.plus_zoom_info_label)}
          iconElement={
            <BsPlusLg cursor={"pointer"} onClick={() => increaseZoom()} />
          }
        ></CustomOverLay>
        <CustomOverLay
          placement="left"
          message={getT(translationKeys.minus_zoom_info_label)}
          iconElement={
            <BsDashLg cursor={"pointer"} onClick={() => decreaseZoom()} />
          }
        ></CustomOverLay>
        {/* <BsFillGridFill
          fontSize={25}
          cursor={"pointer"}
          onClick={() => changeTilt()}
          color="white"
  /> */}
      </div>
      <div id="customMap"></div>;
    </>
  );
}
