import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import paths from "../../config/paths";
import { useContactQuery } from "../../queries/contacts";
import { getT } from "../utils";

export default function ViewContact() {
  const { id } = useParams();
  const [contact, setContact] = useState();
  const { data, isIdle, isLoading, isError, error } = useContactQuery(
    id,
    id ? true : false
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (data && !isLoading && data?.lead) {
      setContact(data?.lead);
    }
  }, [isLoading, data]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="p-4">
        <button
          onClick={() => {
            navigate(paths.contactsPath);
          }}
          className="btn btn-secondary "
        >
          {getT("back")}
        </button>
        <p>
          <b> {getT("information_label")}</b>
        </p>
        <p>{contact?.name + " " + contact?.surname}</p>
        <p>
          {contact?.address +
            " " +
            contact?.city +
            " " +
            contact?.zip +
            " " +
            contact?.country}
        </p>
        <p>
          <b>{getT("source")}</b>
        </p>
        <p>{contact?.source}</p>
      </div>
    </>
  );
}
