import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getT, paymentStatusOptions, translationKeys } from "./utils";

const validationSchema = Yup.object().shape({});

const Filters = ({
  type = "leads",
  onAdd = () => {},
  onFilter = () => {},
  onClear = () => {},
  rolesData = [],
  roleType = "",
}) => {
  const handleSubmit = async (values, { setSubmitting }) => {
    onFilter(values);
  };

  const options = [
    { value: 1, label: "Facebook" },
    { value: 2, label: "Passaparola" },
    { value: 3, label: "Dipendente" },
    { value: 4, label: "Sito" },
  ];

  const statusOptions = [
    { value: 0, label: "draft", color: "grey_clr" },
    { value: 1, label: "copy", color: "grey_clr" },
    { value: 2, label: "complete", color: "green_clr" },
  ];

  const options2 = [
    { value: "milan", label: "milan" },
    { value: "others", label: "others" },
  ];

  const handleClear = (formik) => {
    formik.resetForm();
    onClear();
  };

  const getBtnTitle = () => {
    let val = "";
    switch (type) {
      case "leads":
        val = "Aggiungi lead";
        break;

      case "roles":
        val = "add role";
        break;

      case "tickets":
        val = "add ticket";
        break;

      case "translations":
        val = "add translation";
        break;

      case "pages":
        val = "add page";
        break;

      case "contacts":
        val = "add contact";
        break;
      case "dynamicSelects":
        val = "add new";
        break;
      case "emailTemplates":
        val = "add email template";
        break;
      case "notfications":
        val = "add notification";
        break;

      default:
        val = "Aggiungi Clienti";
        break;
    }
    return val;
  };

  const getTitle = () => {
    let val = "";
    switch (type) {
      case "leads":
        val = "leadSearchLabel";
        break;

      case "quotes":
        val = "quoteSearchLabel";
        break;

      case "roles":
        val = "roleSearchLabel";
        break;

      case "translations":
        val = "translationSearchLabel";
        break;

      case "products":
        val = "productsSearchLabel";
        break;

      case "categories":
        val = "categoriesSearchLabel";
        break;

      case "orders":
        val = "ordersSearchLabel";
        break;

      case "pages":
        val = "pageSearchLabel";
        break;

      case "requests":
        val = "requestSearchLabel";
        break;

      case "contacts":
        val = "contactSearchLabel";
        break;

      case "tickets":
        val = "ticketSearchLabel";
        break;

      case "menus":
        val = "menuSearchLabel";
        break;

      case "dynamicForms":
        val = "formsSearchLabel";
        break;

      case "dynamicSelects":
        val = "formsSearchLabel";
        break;

      case "emailTemplates":
        val = "emailtemplatesearchLabel";
        break;

      case "notfications":
        val = "notificationsSearchLabel";
        break;

      default:
        val = "clientsSearchLabel";
        break;
    }
    return val;
  };

  return (
    <Container fluid className="filters">
      <h3>
        {getT(getTitle())}{" "}
        {type !== "quotes" &&
          type !== "requests" &&
          type !== "contacts" &&
          type !== "dynamicForms" &&
          type !== "orders" && (
            <button onClick={() => onAdd()}>
              {getT(translationKeys.addLabel)}
            </button>
          )}
      </h3>
      <Formik
        initialValues={{
          from: "",
          to: "",
          type: "",
          address: "",
          name: "",
          comune: "",
          prov: "",
          status: "",
          statusType: "",
          role_id: -1,
          install: "",
          payment: "",
          gse: "",
          company: 0,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik, values) => (
          <Form>
            <Row>
              <Col md={3} className="mb-2">
                <Field
                  type="date"
                  id="from"
                  placeholder="Dal"
                  className="form-control"
                  name="from"
                />
              </Col>
              <Col md={3} className="mb-2">
                <Field
                  type="date"
                  id="to"
                  placeholder="AI"
                  className="form-control"
                  name="to"
                />
              </Col>
              {type === "clients" && (
                <>
                  <Col md={3} className="mb-2">
                    <Field
                      as="select"
                      className="form-select"
                      placeholder={getT(translationKeys.Installation)}
                      id="role_id"
                      name="role_id"
                    >
                      <option value="-1" label={getT(translationKeys.roles)} />
                      {rolesData.map((option) => (
                        <option
                          key={option.value}
                          value={option.id}
                          label={option.name}
                        />
                      ))}
                    </Field>
                  </Col>

                  <Col md={3} className="mb-2">
                    <div className="d-flex">
                      <label className="me-2">
                        {getT(translationKeys.company)}{" "}
                      </label>
                      <Field
                        type="radio"
                        value={1}
                        checked={values?.company}
                        className="form-check-input me-2"
                        name="company"
                      />
                      <label className="me-2">
                        {getT(translationKeys.yes)}{" "}
                      </label>
                      <Field
                        type="radio"
                        value={0}
                        checked={values?.company}
                        className="form-check-input me-2"
                        name="company"
                      />
                      <label>{getT(translationKeys.no)} </label>
                    </div>
                  </Col>
                </>
              )}

              {type === "quotes" && (
                <Col md={3} className="mb-2">
                  <Field
                    as="select"
                    className="form-select"
                    id="statusType"
                    name="statusType"
                  >
                    <option
                      value=""
                      disabled
                      label={getT(translationKeys.status)}
                    />
                    {statusOptions.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                        label={option.label}
                      />
                    ))}
                  </Field>
                </Col>
              )}

              {type === "leads" && (
                <>
                  <Col md={3} className="mb-2">
                    <Field
                      as="select"
                      className="form-select"
                      id="type"
                      name="type"
                    >
                      <option value="" label={getT(translationKeys.source)} />
                      {options.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                          label={option.label}
                        />
                      ))}
                    </Field>
                  </Col>
                  <Col md={3} className="mb-2">
                    <Field
                      type="text"
                      id="address"
                      placeholder={getT(translationKeys.address)}
                      className="form-control"
                      name="address"
                    />
                  </Col>
                </>
              )}
            </Row>
            {type === "quotes" && roleType !== "customer" && (
              <Row>
                <Col md={3} className="mb-2">
                  <Field
                    as="select"
                    className="form-select"
                    id="install"
                    name="install"
                  >
                    <option
                      value=""
                      label={getT(translationKeys.Installation)}
                    />
                    {paymentStatusOptions.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                        label={option.name}
                      />
                    ))}
                  </Field>
                </Col>
                <Col md={3} className="mb-2">
                  <Field
                    as="select"
                    className="form-select"
                    id="payment"
                    name="payment"
                  >
                    <option value="" label={getT(translationKeys.payment)} />
                    {paymentStatusOptions.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                        label={option.name}
                      />
                    ))}
                  </Field>
                </Col>
                <Col md={3} className="mb-2">
                  <Field
                    as="select"
                    className="form-select"
                    id="gse"
                    name="gse"
                  >
                    <option value="" label={getT(translationKeys.gse)} />
                    {paymentStatusOptions.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                        label={option.name}
                      />
                    ))}
                  </Field>
                </Col>
              </Row>
            )}
            <Row>
              <Col md={3} className="mb-2">
                <Field
                  type="input"
                  id="name"
                  placeholder={getT(translationKeys.name)}
                  className="form-control"
                  name="name"
                />
              </Col>
              {(type === "quotes" || type === "leads") && (
                <Col md={3} className="mb-2">
                  <Field
                    type="input"
                    id="comune"
                    placeholder={getT(translationKeys.province)}
                    className="form-control"
                    name="comune"
                  />
                </Col>
              )}

              {/* <Col md={3} className="mb-2">
                <Field
                  as="select"
                  className="form-select"
                  placeholder="Provincia"
                  id="prov"
                  name="prov"
                >
                  <option value="" label="Provincia" />
                  {options2.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                      label={option.label}
                    />
                  ))}
                </Field>
                  </Col> */}
              <Col md={3}>
                <button
                  type="button"
                  onClick={() => handleClear(formik)}
                  className="btn-red me-2"
                >
                  {getT(translationKeys.reset)}
                </button>
                <button type="submit" className="btn-green">
                  {getT(translationKeys.search)}
                </button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default Filters;
