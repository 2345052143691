import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";
import CustomInput from "./utils/CustomInput";
import CustomTextArea from "./utils/CustomTextArea";
import CustomSelect2 from "./utils/CustomSelect2";
import { getT, langOptions, translationKeys } from "../utils";
import {
  useCreateTranslationMutation,
  useTanslationsQuery,
  useUpdateTranslationMutation,
} from "../../queries/translations";

const TranslationForm = ({ onEdit = false, editId = null }) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useTanslationsQuery(
    editId,
    editId ? true : false
  );

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdateTranslationMutation)
    : (mutationHook = useCreateTranslationMutation);
  const mutation = mutationHook();

  const newSchema = {
    name: Yup.string().required(getT(translationKeys.nameRequired)),
  };

  const formSchema = Yup.object().shape(newSchema);

  useEffect(() => {
    if (data && !isLoading && data.item) {
      setEditData(data?.item);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.item &&
        mutation.data.data.item.id > 0
      ) {
        onEdit
          ? toast.success(getT(translationKeys.updated))
          : toast.success(getT(translationKeys.created));
        navigate(paths.translationsPath);
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error(getT(translationKeys.generalError));
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const finalData = {
      name: values.name,
      value: {
        it: values.it,
        en: values.en,
      },
    };

    console.log(finalData);

    if (onEdit) {
      delete finalData.name;
    }
    toast.warning(getT(translationKeys.pleaseWait));
    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    navigate(paths.translationsPath);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">{getT(translationKeys.generalError)}</div>;
  }

  return (
    <Container fluid className="leadForm">
      <button
        onClick={() => {
          navigate(paths.translationsPath);
        }}
        className="btn btn-secondary "
      >
        {getT(translationKeys.back)}
      </button>
      <h4 className="my-2">
        {onEdit ? getT(translationKeys.update) : getT(translationKeys.create)}
      </h4>
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              name: editData?.name ?? "",
              it: editData?.value?.it ?? "",
              en: editData?.value?.en ?? "",
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, formik }) => (
              <Form>
                <Row>
                  <Col sm={12} md={12}>
                    <CustomInput
                      onEdit={onEdit}
                      defaultValue={values.name}
                      name="name"
                      label={getT(translationKeys.name)}
                    />
                  </Col>
                  {langOptions.map((l) => {
                    return (
                      <div className="langOption">
                        <Col sm={12} md={12}>
                          <CustomTextArea
                            name={l.value}
                            label={getT(l.name)}
                            defaultValue={values.value}
                            placeholder=""
                          />
                        </Col>
                      </div>
                    );
                  })}
                </Row>

                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      {getT(translationKeys.submit)}
                    </button>
                    <button
                      type="button"
                      onClick={() => handleClear(formik)}
                      className="btn btn-secondary"
                    >
                      {getT(translationKeys.back)}
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default TranslationForm;
