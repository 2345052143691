import React, { useEffect, useState } from "react";
import Filters from "../Filters";
import CustomTable from "../CustomTable";
import { BsPencilSquare } from "react-icons/bs";
import moment from "moment";
import {
  getT,
  isDateBetween,
  ticketColumns,
  ticketStatusOptions,
  translationKeys,
} from "../utils";
import { useNavigate } from "react-router-dom";
import paths, { adminWord } from "../../config/paths";
import { useTicketQuery } from "../../queries/tickets";

export default function Tickets() {
  const [list, setList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();

  const { data, isIdle, isLoading, isError, error } = useTicketQuery();

  const getStatus = (id) => {
    let status = "";
    ticketStatusOptions.forEach((t) => {
      if (t.value == id) {
        status = t.name;
      }
    });
    return status;
  };

  useEffect(() => {
    if (data && !isLoading && data.items) {
      let apiList = data.items.map((r) => {
        return {
          id: +r?.id,
          date: moment(r?.created_at, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY"
          ),
          name: r?.name,
          description: r?.description,
          status: getStatus(r?.status),
          createdBy:
            r?.created_by?.name +
            " " +
            (r?.created_by?.lastName ? r?.created_by?.lastName : ""),
          action: (
            <>
              <span
                data-id={r.id}
                className="cursor"
                onClick={(event) => {
                  let id =
                    event.target.parentNode.getAttribute("data-id") ||
                    event.target.getAttribute("data-id");
                  if (id > 0) {
                    navigate(adminWord + "/tickets/edit/" + id);
                  }
                }}
              >
                <BsPencilSquare data-id={r.id} fontSize={25} />
              </span>
            </>
          ),
        };
      });

      setFilteredData(apiList);
      setList(apiList);
    }
  }, [isLoading, data]);

  const onSearch = (values) => {
    var options = list;

    if (options && options.length > 0) {
      if (values.name !== "") {
        options = options.filter((s) => {
          let cString = s.name.toLowerCase();
          return cString.includes(values.name.toLowerCase());
        });
      }

      if (values.from !== "" && values.to !== "") {
        options = options.filter((s) => {
          let date = moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD");
          return isDateBetween(values.from, values.to, date);
        });
      }
    }

    setFilteredData(options);
  };

  const onClear = () => {
    setFilteredData(list);
  };

  return (
    <>
      <Filters
        type="tickets"
        onAdd={() => navigate(paths.newTicketsPath)}
        onFilter={onSearch}
        onClear={onClear}
      />
      <div className="customTable">
        {isIdle || isLoading ? (
          getT(translationKeys.pleaseWait)
        ) : (
          <CustomTable columns={ticketColumns} data={filteredData} />
        )}
      </div>
    </>
  );
}
