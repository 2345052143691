import React, { useEffect } from "react";
import HeaderPage from "../Header";
import { updateOrderData } from "../../utils/helpers";
import { getT, translationKeys } from "../../admin/utils";
import MainWrapper from "../MainWrapper";

export default function Failed() {
  const onCheckOut = async () => {
    let res = await updateOrderData({
      status: 2,
      payment_status: "not_paid",
    });

    if (res.status) {
      // do something
    } else {
      console.log("update failed");
    }
  };

  useEffect(() => {
    onCheckOut();
  }, []);
  return (
    <MainWrapper mainClass="dynamicPage" topBar={false}>
      <div className="infoWrapper">
        <p className="mt-5 center">
          {getT(translationKeys.paymentFailedMessage)}
        </p>
      </div>
    </MainWrapper>
  );
}
