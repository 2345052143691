import React, { useEffect, useState } from "react";
import Filters from "../Filters";
import CustomTable from "../CustomTable";
import { BsFillTrash3Fill, BsPencilSquare } from "react-icons/bs";
import moment from "moment";
import {
  categoryColumns,
  getT,
  isDateBetween,
  translationKeys,
} from "../utils";
import { useNavigate } from "react-router-dom";
import paths, { adminWord } from "../../config/paths";
import {
  useCategoryQuery,
  useDeleteCategoryMutation,
} from "../../queries/categories";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

export default function Categories() {
  const [list, setList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();

  const { data, isIdle, isLoading, isError, error } = useCategoryQuery();

  const mutation = useDeleteCategoryMutation();

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (mutation.data.data.status) {
        toast.success("deleted");
      } else {
        toast.error(mutation.data.data.message);
      }
      mutation.reset();
    }
  }, [mutation]);

  useEffect(() => {
    if (data && !isLoading && data.items && data.items.length > 0) {
      let apiList = data.items.map((r) => {
        return {
          id: "CL" + r?.id,
          date: moment(r?.created_at, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY"
          ),
          name: r?.name,
          active: r?.active,
          z_order: r?.z_order,
          action: (
            <>
              <span
                className="cursor me-2"
                data-id={r.id}
                onClick={(event) => {
                  let id =
                    event.target.parentNode.getAttribute("data-id") ||
                    event.target.getAttribute("data-id");
                  if (id > 0) {
                    navigate(adminWord + "/categories/edit/" + id);
                  }
                }}
              >
                <BsPencilSquare data-id={r.id} fontSize={25} />
              </span>
              <span
                className="cursor me-2"
                data-id={r.id}
                onClick={(event) => {
                  let id =
                    event.target.parentNode.getAttribute("data-id") ||
                    event.target.getAttribute("data-id");
                  if (id > 0) {
                    Swal.fire({
                      title: getT(translationKeys.are_you_sure_label),
                      text: "",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: getT(translationKeys.yes),
                      cancelButtonText: getT(translationKeys.no),
                    }).then((result) => {
                      if (result.isConfirmed) {
                        toast.loading("Deleting");
                        mutation.mutate({ id: id });
                      }
                    });
                  }
                }}
              >
                <BsFillTrash3Fill
                  data-id={r.id}
                  fontSize={25}
                  className="danger"
                />
              </span>
            </>
          ),
        };
      });

      setFilteredData(apiList);
      setList(apiList);
    }
  }, [isLoading, data]);

  const onSearch = (values) => {
    var options = list;

    if (options && options.length > 0) {
      if (values.name !== "") {
        options = options.filter((s) => {
          let cString = s.name.toLowerCase();
          return cString.includes(values.name.toLowerCase());
        });
      }

      if (values.address !== "") {
        options = options.filter((s) => {
          let cString = s.add.toLowerCase();
          return cString.includes(values.address.toLowerCase());
        });
      }

      if (values.comune !== "") {
        options = options.filter((s) => {
          let cString = s.comune.toLowerCase();
          return cString.includes(values.comune.toLowerCase());
        });
      }

      if (values.from !== "" && values.to !== "") {
        options = options.filter((s) => {
          let date = moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD");
          return isDateBetween(values.from, values.to, date);
        });
      }

      if (parseInt(values.role_id) !== -1) {
        options = options.filter((s) => {
          return parseInt(s.role_id) === parseInt(values.role_id);
        });
      }

      options = options.filter((s) => {
        return parseInt(s.companyInfo) === parseInt(values.company);
      });
    }

    setFilteredData(options);
  };

  const onClear = () => {
    setFilteredData(list);
  };

  return (
    <>
      <Filters
        type="categories"
        onAdd={() => navigate(paths.newCategoriesPath)}
        onFilter={onSearch}
        onClear={onClear}
        rolesData={roles}
      />
      <div className="customTable">
        {isIdle || isLoading ? (
          getT(translationKeys.pleaseWait)
        ) : (
          <CustomTable columns={categoryColumns} data={filteredData} />
        )}
      </div>
    </>
  );
}
