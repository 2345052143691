import React, { useState } from "react";
import CartProduct from "./cartProduct";
import {
  currencyType,
  getT,
  getTotalPrice,
  getVariations,
} from "../../admin/utils";

export default function Order({ order = {}, onUpdateQuantity = () => {} }) {
  const [ivaVal, setIvaVal] = useState(0.22);

  const countryOptions = [
    {
      name: "italy",
      value: "italy",
    },
    {
      name: "others",
      value: "others",
    },
  ];

  const getInitialCountry = () => {
    return order?.billing?.country
      ? order.billing.country.toLowerCase() === "italy"
        ? "italy"
        : "others"
      : "";
  };

  return (
    <>
      <div className="cart-product-list">
        {order?.products &&
          order.products &&
          order.products.map((item, index) => (
            <CartProduct
              item={item}
              index={index}
              order={order}
              onUpdateQuantity={onUpdateQuantity}
            />
          ))}
      </div>
      <div className="cart-extra-info m-2">
        <label className="form-label">{getT("note")}</label>
        <textarea name="note" id="note" className="form-control "></textarea>
        <label className="form-label">{getT("country")}</label>
        <select
          className="form-select"
          id="taxCountry"
          defaultValue={getInitialCountry()}
          onChange={(e) => {
            if (e.target.value === "italy" && order?.company !== "") {
              setIvaVal(0.22);
            }
            if (e.target.value === "others" && order?.company !== "") {
              setIvaVal(0);
            }
            if (order?.company === "") {
              setIvaVal(0.22);
            }
          }}
        >
          <option value={""}>{getT("choose_tax_country")} </option>
          {countryOptions &&
            countryOptions?.map((option, index) => (
              <option value={option.value}>{option.name}</option>
            ))}
        </select>
      </div>
      <div className="cart-product-total">
        <span>{getT("total")}</span>
        <span>{getTotalPrice(false, order) + currencyType}</span>
      </div>
      <div className="cart-product-total">
        <span>{getT("total_with_tax")} </span>
        <span>{getTotalPrice(true, order, ivaVal) + currencyType}</span>
      </div>
    </>
  );
}
