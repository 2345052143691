import React from "react";
import TicketForm from "../forms/TicketForm";

export default function NewTicket() {
  return (
    <>
      <TicketForm />
    </>
  );
}
