/* eslint-disable no-undef */
import MapMarker from "./pages/MapMarker";
import Location from "./pages/Location";
import "./styles/App.scss";
import { useEffect, useState } from "react";
import DrawingMap from "./pages/DrawingMap";
import PolygonMap from "./pages/PolygonMap";
import SolarGrid from "./pages/SolarGrid";
import TechInfo from "./pages/TechInfo";
import CustomerInfo from "./pages/CustomerInfo";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { updateQuoteData } from "./utils";
import FrontForm from "./admin/forms/FrontForm";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { getT, translationKeys } from "./admin/utils";
import { FaHome } from "react-icons/fa";
import WrapperModal from "./components/ModalWrapper";
import MainWrapper from "./pages/MainWrapper";
import Loading from "./components/Loading";
import Marker from "./pages/Marker";
import CustomOverLay from "./components/CustomOverLay";

function App({ showHeader = true, editId = null }) {
  const [input, setInput] = useState();
  const [addressInfo, setAddressInfo] = useState();
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const apiUrl = process.env.REACT_APP_API_URL;
  const [location, setLocation] = useState(); //{ lat: 45.4303378, lng: 9.3227076 }
  const [polygon, setPolygon] = useState();
  const [fixedPolygon, setFixedPolygon] = useState();
  const [zoom, setZoom] = useState(20);
  const [step, setStep] = useState(0);
  const [slopeEdge, setSlopeEdge] = useState();
  const [fixedEdge, setFixedEdge] = useState();
  const [showSideBar, setShowSideBar] = useState(false);
  const [initData, setInitData] = useState({});
  const locationInfo = useLocation();
  const [area, setArea] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const queryParams = new URLSearchParams(locationInfo.search);
  const idParam = editId ? editId : queryParams.get("id");
  const stepId = queryParams.get("step");
  const ref_code = queryParams.get("ref");
  const [mapLoaded, setMapLoaded] = useState(true);

  const [initialId, setInitialId] = useState(
    idParam > 0
      ? idParam
      : localStorage.getItem("quoteId")
      ? localStorage.getItem("quoteId")
      : null
  );

  const [refCode] = useState(ref_code);

  useEffect(() => {
    if (idParam > 0) {
      localStorage.setItem("quoteId", idParam);
    }
  }, [idParam]);

  const [techInfo, setTechInfo] = useState({
    inclination: 15,
    tilt: 25,
    energy_consumption: 0,
    energy_price: 0,
    energy_increase: 0,
    people_count: 0,
    heat_pump: "",
    air_conditioners_pump: "",
    induction_hub: "",
    electric_car: "",
    col: "",
    f1: 0,
    f2: 0,
    f3: 0,
  });

  const [userInfo, setUserInfo] = useState({
    name: "",
    surname: "",
    email: "",
    mobile: "",
    address: "",
    country: "",
    city: "",
    cap: "",
    province: "",
  });

  const onLocChange = (data) => {
    setLocation({
      lat: data?.lat,
      lng: data?.lng,
    });
  };

  const onAddressChange = (data) => {
    if (data.lat && data.lng) {
      setLocation({
        lat: data?.lat,
        lng: data?.lng,
      });
    }

    setInput(data?.address);
    setAddressInfo(data);
  };

  // google initiation

  /*useEffect(() => {
    const onLoad = async () => {
      if (window.google && window.google.maps && window.google.maps.places) {
        // The Google Maps API has loaded
        // Global services instances
        window.googleAutocomplete =
          new window.google.maps.places.AutocompleteService();
        window.googlePlacesService =
          new window.google.maps.places.PlacesService(
            document.createElement("div")
          );
        setMapLoaded(true);
      } else if (!window.google.maps.places) {
        setTimeout(() => {
          setMapLoaded(true);
        }, 20);
        window.google.maps.importLibrary("places");
        window.google.maps.importLibrary("geometry");
        window.google.maps.importLibrary("drawing");
        window.google.maps.importLibrary("marker");
      }
    };

    const initMap = () => {};
    window.initMap = initMap;

    // Check if the Google Maps API has loaded
    if (window.google && window.google.maps) {
      onLoad();
    } else {
      // If the API hasn't loaded yet, add a callback function to the window object
      /*const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places,geometry,drawing&callback=initMap`;
      script.onload = () => {
        if (!window.google || !window.google.maps) {
          console.error("Google Maps API failed to load.");
        }
      };
      document.head.appendChild(script);
    }
  }, [window.google]);*/

  const createRowAndFetchId = async () => {
    try {
      const response = await axios.post(apiUrl + "/api/cusQuote", {
        status: 0,
      });
      if (
        response.status === 201 &&
        response.data &&
        response.data.quote &&
        response.data.quote.id > 0
      ) {
        localStorage.setItem("quoteId", response.data.quote.id);
        setInitialId(response.data.quote.id);
        return response.data.quote.id;
      } else {
        console.log(response.status, response.statusText);
        return -1;
      }
    } catch (error) {
      console.error("Error creating row:", error);
      toast.error("try again later");
      return -1;
    }
  };

  const readRequestData = async (id) => {
    try {
      const response = await axios.get(apiUrl + "/api/cusQuote/" + id);
      if (
        response.status === 200 &&
        response.data &&
        response.data.quote &&
        response.data.quote.id > 0
      ) {
        // do the initial setup
        let initialData = response.data.quote;
        updateInitialData(initialData);
        setInitData(initialData);

        // hiding email modal when its there
        /*if (response.data.email === "") {
          setShowModal(true);
        }*/
      } else {
        console.log(response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error creating row:", error);
      toast.error("try again later");
    }
  };

  // getting previous data
  useEffect(() => {
    // Function to create a row and get the ID
    if (initialId > 1) {
      readRequestData(initialId);
    }
    console.log(initialId, "test");
  }, [initialId]);

  const updateInitialData = async (data) => {
    if (data?.polygon) {
      setPolygon(data?.polygon);
      setFixedPolygon(data?.polygon);
    }
    if (data?.polygon) {
      setSlopeEdge(data?.inclination_edge);
      setFixedEdge(data?.inclination_edge);
    }

    onAddressChange(data);

    if (data?.inclination) {
      onTechInfoChange(data?.inclination, "inclination");
    }
    if (data?.tilt) {
      onTechInfoChange(data?.tilt, "tilt");
    }
    if (data?.energy_consumption) {
      onTechInfoChange(data?.energy_consumption, "energy_consumption");
    }
    if (data?.energy_price) {
      onTechInfoChange(data?.energy_price, "energy_price");
    }
    if (data?.people_count) {
      onTechInfoChange(data?.people_count, "people_count");
    }
    if (data?.heat_pump) {
      onTechInfoChange(data?.heat_pump, "heat_pump");
    }
    if (data?.air_conditioners_pump) {
      onTechInfoChange(data?.air_conditioners_pump, "air_conditioners_pump");
    }
    if (data?.induction_hub) {
      onTechInfoChange(data?.induction_hub, "induction_hub");
    }

    if (data?.electric_car) {
      onTechInfoChange(data?.electric_car, "electric_car");
    }
    if (data?.col) {
      onTechInfoChange(data?.col, "col");
    }

    if (data?.f1) {
      onTechInfoChange(data?.f1, "f1");
    }

    if (data?.f2) {
      onTechInfoChange(data?.f2, "f2");
    }

    if (data?.f3) {
      onTechInfoChange(data?.f3, "f3");
    }

    let items = [
      "name",
      "surname",
      "email",
      "mobile",
      "address",
      "country",
      "city",
      "cap",
      "province",
    ];

    items.forEach((item) => {
      if (data[item]) {
        setUserInfo((prevTechInfo) => ({
          ...prevTechInfo,
          [item]: data[item],
        }));
      }
    });

    if (data?.lat && data?.lng) {
      setLocation({
        lat: parseFloat(data?.lat),
        lng: parseFloat(data?.lng),
      });

      geocodeLatLng({
        lat: parseFloat(data?.lat),
        lng: parseFloat(data?.lng),
      })
        .then((resData) => {
          console.log(resData, "success");
          onLocChange(resData);
          setStep(1);
        })
        .catch((errorData) => {
          //onLocChange(errorData);
          setStep(1);
        });
    }
  };

  const onDrawPolygon = async (data) => {
    let res = await updateQuoteData({ polygon: data.fixed }, initialId);
    if (res.status) {
      setPolygon(data.dynamic);
      setFixedPolygon(data.fixed);
      //setStep(3);
      setArea(data.area);
    } else {
      console.log("update failed");
    }
  };

  const onEmailUpdate = async () => {
    let emailEle = document.getElementById("quoteEmail");
    let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailEle && emailEle.value !== "") {
      if (emailRegex.test(emailEle.value)) {
        let res = await updateQuoteData({ email: emailEle.value }, initialId);
        if (res.status) {
          setShowModal(!showModal);
        } else {
          console.log("failed to update");
        }
      } else {
        toast.error(getT(translationKeys.emailError));
      }
    } else {
      toast.error(getT(translationKeys.emailRequired));
    }
  };

  const onZoom = (val) => {
    setZoom(val);
  };

  const onCenterChange = (val) => {
    //setLocation(val);
  };

  const onSlopeChange = async (data) => {
    let res = await updateQuoteData(
      {
        inclination_edge: data.fixed,
        area: data.area,
      },
      initialId
    );
    if (res.status) {
      setSlopeEdge(data.dynamic);
      setFixedEdge(data.fixed);
    } else {
      console.log("update failed");
    }
  };

  const geocodeLatLng = (latLng) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line new-parens
      var geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: latLng }, function (results, status) {
        if (status === "OK") {
          if (results[0]) {
            resolve({
              address: results[0].formatted_address,
              lat: latLng.lat,
              lng: latLng.lng,
            });
          } else {
            reject({
              address: "",
              lat: latLng.lat,
              lng: latLng.lng,
            });
          }
        } else {
          reject({
            address: "",
            lat: latLng.lat,
            lng: latLng.lng,
          });
        }
      });
    });
  };

  const onNext = () => {
    let val = step + 1;

    if (val === 1 && location && location.lat && location.lng) {
      setStep(val);
    }

    if (val === 2 && location && location.lat && location.lng) {
      setStep(val);
    }

    if (val === 3 && location && location.lat && location.lng && polygon) {
      setStep(val);
    }

    if (
      val === 4 &&
      location &&
      location.lat &&
      location.lng &&
      polygon &&
      slopeEdge
    ) {
      setStep(val);
    }
    if (
      val === 5 &&
      location &&
      location.lat &&
      location.lng &&
      polygon &&
      slopeEdge
    ) {
      setStep(val);
    }

    if (
      val === 6 &&
      location &&
      location.lat &&
      location.lng &&
      polygon &&
      slopeEdge &&
      techInfo
    ) {
      setStep(val);
    }
  };

  const onPrev = () => {
    let val = step - 1;

    if (step > 0) setStep(val);
  };

  const onMarkerChange = async (data) => {
    if (!initialId) {
      setLocation({
        lat: data?.lat,
        lng: data?.lng,
      });
    }

    let res = await updateQuoteData(
      {
        lat: data?.lat,
        lng: data?.lng,
      },
      initialId
    );
    if (res.status) {
      setLocation({
        lat: data?.lat,
        lng: data?.lng,
      });
      setZoom(data?.zoom);
    } else {
      console.log("update failed");
    }
  };

  const onTechInfoChange = async (val, type) => {
    setTechInfo((prevTechInfo) => ({
      ...prevTechInfo,
      [type]: val,
    }));
  };

  const onDelete = async () => {
    let res = await updateQuoteData(
      {
        polygon: "",
      },
      initialId
    );
    if (res.status) {
      setPolygon("");
      setFixedPolygon("");
      setArea(0);
    } else {
      console.log("update failed");
    }
  };

  if (!window.google && mapLoaded) {
    return <Loading />;
  }

  return (
    <MainWrapper
      showHeader={showHeader}
      onBack={() => {
        setStep(0);
      }}
    >
      {step === 0 && mapLoaded && (
        <Location
          value={input}
          onSelection={async (data) => {
            if (data && data.address) {
              onAddressChange(data);
              setStep(1);
              /*
              let res = await updateQuoteData(
                  {
                    lat: data?.lat,
                    lng: data?.lng,
                  },
                  initialId
                );
                if (res.status) {
                  onLocChange(data);
                  setStep(1);
                  //console.log(data);
                } else {
                  console.log("update failed");
                } */
            }
          }}
        />
      )}
      <div className={!showSideBar ? "mainContent active" : "mainContent "}>
        {mapLoaded ? (
          <>
            {" "}
            {step === 1 && (
              <>
                <Marker
                  zoom={zoom}
                  loc={location}
                  onZoomChange={onZoom}
                  address={input}
                  onAddChange={async (data) => {
                    if (data && data.address) {
                      let res = await updateQuoteData(
                        {
                          lat: data?.lat,
                          lng: data?.lng,
                        },
                        initialId
                      );
                      if (res.status) {
                        onAddressChange(data);
                        setStep(1);
                      } else {
                        console.log("update failed");
                      }
                    }
                  }}
                  onMarkerDragged={onMarkerChange}
                  onCenterChanged={onCenterChange}
                />
              </>
            )}
            {step === 2 && (
              <DrawingMap
                zoom={zoom}
                loc={location}
                onDraw={onDrawPolygon}
                coordinates={polygon}
                onDelete={onDelete}
              />
            )}
            {step === 3 && (
              <PolygonMap
                zoom={zoom}
                loc={location}
                coordinates={polygon}
                slopeEdge={slopeEdge}
                onMove={onDrawPolygon}
                onChangeSlope={onSlopeChange}
              />
            )}
            {step === 4 && (
              <SolarGrid
                zoom={zoom}
                loc={location}
                coordinates={polygon}
                slopeEdge={slopeEdge}
              />
            )}
            {step === 5 && (
              <TechInfo
                techInfo={techInfo}
                onTechInfoChange={onTechInfoChange}
              />
            )}
            {step === 6 && (
              <CustomerInfo
                zoom={zoom}
                loc={location}
                techInfo={techInfo}
                coordinates={fixedPolygon}
                slopeEdge={fixedEdge}
                address={addressInfo}
                initialId={initialId}
                apiUrl={apiUrl}
                userInfo={userInfo}
                idParam={idParam}
              />
            )}
          </>
        ) : (
          <div>please wait...</div>
        )}
      </div>
      {step > 0 && (
        <>
          <div className={showSideBar ? "toggle_btn hide" : "toggle_btn show"}>
            <CustomOverLay
              placement="left"
              message={
                showSideBar
                  ? getT(translationKeys.toggle_info_open_label)
                  : getT(translationKeys.toggle_info_close_label)
              }
              iconElement={
                !showSideBar ? (
                  <BsChevronCompactRight
                    onClick={() => setShowSideBar(!showSideBar)}
                    fontSize={30}
                  />
                ) : (
                  <BsChevronCompactLeft
                    onClick={() => setShowSideBar(!showSideBar)}
                    fontSize={30}
                  />
                )
              }
            ></CustomOverLay>
          </div>

          <div
            className={
              !showSideBar ? "frontFormOuter show" : "frontFormOuter hide"
            }
          >
            <div className="title">
              {getT(translationKeys.preventivo_number_label) +
                ":2024/" +
                (initialId ? initialId : "")}
              {step > 1 && (
                <FaHome
                  fontSize={22}
                  onClick={() => {
                    setStep(1);
                  }}
                  className="cursor"
                />
              )}
            </div>

            <FrontForm
              step={step}
              idParam={idParam}
              onNext={onNext}
              onPrev={onPrev}
              area={area}
              initData={initData}
              prevAddress={input}
              location={location}
              address={addressInfo}
              onUpdateId={(id) => setInitialId(id)}
              refCode={refCode}
            />
          </div>
        </>
      )}
    </MainWrapper>
  );
}

export default App;
