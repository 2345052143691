import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";

import CustomInput from "./utils/CustomInput";
import {
  useCreateQuoteMutation,
  useQuoteQuery,
  useUpdateQuoteMutation,
} from "../../queries/quotes";

const SmallQuoteForm = ({
  onEdit = false,
  editId = null,
  onClose = () => {},
}) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useQuoteQuery(
    editId,
    editId ? true : false
  );

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdateQuoteMutation)
    : (mutationHook = useCreateQuoteMutation);
  const mutation = mutationHook();

  const editSchema = {};

  const formSchema = Yup.object().shape(editSchema);

  useEffect(() => {
    if (data && !isLoading) {
      setEditData(data?.quote);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.quote &&
        mutation.data.data.quote.id > 0
      ) {
        onEdit ? toast.success("updated") : toast.success("created");
        onClose();
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error("try again later");
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const finalData = {
      bat: values.bat,
      discount: values.discount,
    };
    if (onEdit) {
      delete finalData.name;
    }
    toast.warning("please wait..");
    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    //navigate(paths.rolesPath);
    onClose();
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">something went wrong try again later</div>;
  }

  return (
    <Container fluid className="leadForm">
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              discount: editData?.discount ? editData?.discount : 10,
              bat: editData?.bat ?? "",
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form>
                <Row>
                  <Col sm={12} md={6}>
                    <CustomInput label="Discount" name="discount" />
                  </Col>
                  <Col sm={12} md={6}>
                    <CustomInput label="Battery Count" name="bat" />
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      submit
                    </button>
                    {/* <button
                      type="button"
                      onClick={() => handleClear(formik)}
                      className="btn btn-secondary"
                    >
                      back
            </button> */}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default SmallQuoteForm;
