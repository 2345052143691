import React, { useEffect, useRef, useState } from "react";
import { FrontMenuList, getCustomerToken, getDynamicMenuLink } from "../utils";
import { BsFillPersonFill, BsList } from "react-icons/bs";
import { getFromLocalStorage } from "../utils/helpers";
import {
  formatMysqlDate,
  formatMysqlDateToNormal,
  getT,
  moduleOptions,
  translationKeys,
} from "../admin/utils";
import ReactCountryFlag from "react-country-flag";
import { json, useLocation, useNavigate } from "react-router-dom";
import paths, { apiUrl, auth0domain } from "../config/paths";
import { useMenuQuery } from "../queries/Menus";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useAuth } from "../authContext";
import Swal from "sweetalert2";
import { useGeneralSettingsQuery } from "../queries/Settings";
import { FaRegUserCircle } from "react-icons/fa";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { FaGlobe } from "react-icons/fa6";
import { IoInformationCircle, IoLogOutOutline } from "react-icons/io5";
import { IoIosLogIn } from "react-icons/io";
import CustomOverLay from "../components/CustomOverLay";
import { IoPerson } from "react-icons/io5";
import { swap } from "formik";
import { RxCross1 } from "react-icons/rx";
import useScreenSize from "../components/useScreenSize";

export default function HeaderPage({ onBack = () => {}, onGoBack = () => {} }) {
  const [menuStatus, setMenuStatus] = useState(false);
  const {
    isCustomerAuthenticated,
    toggleLoginModal,
    authCustomerLogout,
    customerInfo,
    setMobileActiveMenu,
  } = useAuth();
  const [userInfo, setUserInfo] = useState({});
  const {
    fmodules,
    generalSettings,
    setAppointmentModalStatus,
    setTicketModalStatus,
  } = useAuth();
  const [langStatus, setLangStatus] = useState(false);
  const [showNotify, setShowNotify] = useState(false);
  const [notificationsData, setNotificationsData] = useState([]);
  const [visibleNotificationId, setVisibleNotificationId] = useState(-1);
  const elementRef = useRef(null);
  const isMobile = useScreenSize(768);

  const {
    data,
    isIdle,
    isLoading: isDataLoading,
    isError,
    error,
  } = useMenuQuery();

  const {
    data: settingData,
    isIdle: isSettingIdle,
    isLoading: isSettingsLoading,
  } = useGeneralSettingsQuery();

  const navigate = useNavigate();
  const [menuList, setMenuList] = useState([]);
  const location = useLocation();
  const [pagesVisible, setPagesVisible] = useState(false);

  useEffect(() => {
    if (data && !isDataLoading && data.items) {
      let items = data.items.filter((t) => t.type === "header");
      setMenuList(items);
    }
  }, [isDataLoading, data]);

  const fetchNotifications = async () => {
    if (customerInfo && customerInfo?.id > 0 && isCustomerAuthenticated) {
      try {
        const response = await axios
          .get(apiUrl + "/api/getNotifications/" + customerInfo?.id)
          .then((res) => {
            return res.data;
          });
        if (
          response &&
          response.status &&
          response.items &&
          response.items.length > 0
        ) {
          setNotificationsData(response.items);
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    }
  };

  const updateNotification = async (id) => {
    try {
      const response = await axios
        .post(apiUrl + "/api/updateNotification/" + id, {
          status: 1,
        })
        .then((res) => {
          return res.data;
        });
      if (
        response &&
        response.status &&
        response.item &&
        response.item.id > 0
      ) {
        fetchNotifications();
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    if (isCustomerAuthenticated) {
      fetchNotifications();
    }

    // Set up interval to fetch notifications every 5 seconds
    const intervalId = setInterval(fetchNotifications, 10000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [isCustomerAuthenticated, customerInfo]);

  const handleOutsideClick = (event) => {
    if (elementRef.current && !elementRef.current.contains(event.target)) {
      setShowNotify(false);
      setLangStatus(false);
      setPagesVisible(false);
    }
  };

  useEffect(() => {
    if (location.pathname === paths.homePath) {
      onBack();
    }
  }, [location, paths.homePath]);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const getNotifyName = (n, type = "name") => {
    let final = "";
    const translateList = {
      appointment: [
        {
          name: "appointment_notification_create_label",
          des: "appointment_notification_create_description_label",
          value: "create",
        },
        {
          name: "appointment_notification_update_label",
          des: "appointment_notification_update_description_label",
          value: "update",
        },
      ],
      general: [
        {
          name: "general_notification_create_label",
          des: "general_notification_create_description_label",
          value: "create",
        },
        {
          name: "general_notification_update_label",
          des: "general_notification_update_description_label",
          value: "update",
        },
      ],
      quote: [
        {
          name: "quote_notification_create_label",
          des: "quote_notification_create_description_label",
          value: "create",
        },
        {
          name: "quote_notification_update_label",
          des: "quote_notification_update_description_label",
          value: "update",
        },
      ],
      ticket: [
        {
          name: "ticket_notification_create_label",
          des: "ticket_notification_create_description_label",
          value: "create",
        },
        {
          name: "ticket_notification_update_label",
          des: "ticket_notification_update_description_label",
          value: "update",
        },
      ],
      general: [
        {
          name: "general_notification_create_label",
          des: "general_notification_create_description_label",
          value: "create",
        },
        {
          name: "general_notification_update_label",
          des: "general_notification_update_description_label",
          value: "update",
        },
      ],
    };

    if (translateList[n?.type]) {
      translateList[n?.type].forEach((i) => {
        if (i.value === n?.sub_type) {
          final = i[type];
        }
      });
    }

    return final;
  };

  return (
    <div className="header">
      <img
        src={
          generalSettings && generalSettings.logo_val
            ? generalSettings?.logo_val
            : "./assets/logo.png"
        }
        onClick={() => {
          if (
            localStorage.getItem("quoteId") &&
            location.pathname === paths.homePath
          ) {
            Swal.fire({
              title: getT(
                translationKeys.are_you_sure_to_start_from_fresh_label
              ),
              text: "",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: getT(translationKeys.yes),
              cancelButtonText: getT(translationKeys.cancel),
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage.removeItem("quoteId");
                navigate(paths.homePath, { replace: true });
                window.location.reload();
              }
            });
          } else {
            navigate(paths.homePath, { replace: true });
          }
          setMobileActiveMenu("new");
        }}
        alt="logo"
        className="cursor logo-print"
      />

      <div
        className={
          menuStatus
            ? "menuList mobile_menu_list active no-print"
            : "menuList no-print"
        }
      >
        {/* isCustomerAuthenticated ? (
          <>
            {moduleOptions() &&
              moduleOptions().map((i, index) => {
                return (
                  <>
                    {fmodules[i.value] && i.value !== "shop" && (
                      <div
                        key={index}
                        className="menuListItem"
                        onClick={() => {
                          navigate(i?.path, { replace: true });
                        }}
                      >
                        {i?.name}
                      </div>
                    )}
                  </>
                );
              })}
          </>
        ) : (
          {
            /* <FaRegUserCircle
            className="me-3 cursor"
            onClick={() => {
              toggleLoginModal(true);
            }}
            fontSize={20}
          /> 
        )}*/}

        <CustomOverLay
          placement="bottom"
          message={getT(translationKeys.pages_menu_list_label)}
          iconElement={
            <BsList
              onClick={() => {
                setPagesVisible(!pagesVisible);
              }}
              fontSize={25}
              className="me-3 cursor"
            />
          }
        ></CustomOverLay>
        {menuList && menuList.length > 0 && pagesVisible && (
          <div className="pagesListWrapper" ref={elementRef}>
            {menuList &&
              menuList.map((m, index) => {
                return (
                  <>
                    <div
                      className="pagesListItem"
                      key={index}
                      onClick={() => {
                        if (m.link_type === "internal") {
                          navigate(getDynamicMenuLink(m), {
                            replace: true,
                          });
                        }
                        if (m.link_type === "external") {
                          window.open(m?.link, "_blank");
                        }
                      }}
                    >
                      {m?.name && m.name
                        ? m.name
                        : getDynamicMenuLink(m, "name")}
                    </div>
                  </>
                );
              })}
            {fmodules["shop"] && isMobile && (
              <div
                className="pagesListItem"
                onClick={() => {
                  navigate(paths.shopPath, { replace: true });
                }}
              >
                {getT(translationKeys.shop)}
              </div>
            )}
          </div>
        )}
        {!isCustomerAuthenticated ? (
          <>
            <CustomOverLay
              placement="bottom"
              message={getT(translationKeys.login_info_label)}
              iconElement={
                <IoPerson
                  fontSize={25}
                  onClick={() => {
                    toggleLoginModal(true);
                  }}
                  className="me-3 cursor"
                />
              }
            ></CustomOverLay>
          </>
        ) : (
          <>
            {!isMobile && (
              <CustomOverLay
                placement="bottom"
                message={getT(translationKeys.profile_info_label)}
                iconElement={
                  <FaRegUserCircle
                    className="me-3 cursor"
                    onClick={() => {
                      // open use profile

                      navigate(paths.profilePath);
                      setMenuStatus(!menuStatus);
                    }}
                    fontSize={20}
                  />
                }
              ></CustomOverLay>
            )}

            <CustomOverLay
              placement="bottom"
              message={getT(translationKeys.notification_info_label)}
              iconElement={
                <div
                  className="notification"
                  onClick={() => {
                    //toggleLoginModal(true);
                    setLangStatus((prev) => (prev = false));
                    setShowNotify((prev) => (prev = !showNotify));
                  }}
                  ref={elementRef}
                >
                  <div className="count">
                    {
                      notificationsData.filter((n) => {
                        if (!n.status) {
                          return n;
                        }
                      }).length
                    }
                  </div>

                  <MdOutlineNotificationsActive
                    className="me-3 cursor"
                    onClick={() => {
                      //toggleLoginModal(true);
                    }}
                    fontSize={25}
                  />
                </div>
              }
            ></CustomOverLay>
            <CustomOverLay
              placement="bottom"
              message={getT(translationKeys.logout_info_label)}
              iconElement={
                <IoLogOutOutline
                  className="me-3 cursor"
                  onClick={() => {
                    Swal.fire({
                      title: getT(translationKeys.are_you_sure_to_logout),
                      text: "",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: getT(translationKeys.yes),
                      cancelButtonText: getT(translationKeys.cancel),
                    }).then((result) => {
                      if (result.isConfirmed) {
                        authCustomerLogout();
                        navigate(paths.homePath, { replace: true });
                        window.location.reload();
                      }
                    });
                  }}
                  fontSize={25}
                />
              }
            ></CustomOverLay>
          </>
        )}

        <div className="langBtn">
          <FaGlobe
            className="me-3 cursor"
            onClick={() => {
              //toggleLoginModal(true);
              setShowNotify((prev) => (prev = false));
              setLangStatus((prev) => (prev = !langStatus));
            }}
            fontSize={20}
          />

          {langStatus && (
            <div className="lang_list_menu" ref={elementRef}>
              <div
                className={
                  getFromLocalStorage("lng") === "it"
                    ? "lang_list_menu_item active"
                    : "lang_list_menu_item"
                }
                onClick={() => {
                  if (getFromLocalStorage("lng") === "it") {
                    localStorage.setItem("lng", "en");
                  } else {
                    localStorage.setItem("lng", "it");
                  }
                  window.location.reload();
                }}
              >
                <ReactCountryFlag
                  countryCode="IT"
                  style={{
                    fontSize: "25px",
                    lineHeight: "25px",
                  }}
                  svg
                />
                <span>Italian</span>
              </div>
              <div
                className={
                  getFromLocalStorage("lng") === "en"
                    ? "lang_list_menu_item active"
                    : "lang_list_menu_item"
                }
                onClick={() => {
                  if (getFromLocalStorage("lng") === "it") {
                    localStorage.setItem("lng", "en");
                  } else {
                    localStorage.setItem("lng", "it");
                  }
                  window.location.reload();
                }}
              >
                <ReactCountryFlag
                  countryCode="US"
                  style={{
                    fontSize: "25px",
                    lineHeight: "25px",
                  }}
                  svg
                />
                <span>English</span>
              </div>
            </div>
          )}
        </div>

        {showNotify && (
          <>
            <div className="notificationsList" ref={elementRef}>
              <div className="intro">
                <h4>{getT(translationKeys.notifications_title)}</h4>
                <RxCross1
                  onClick={() => {
                    setShowNotify((prev) => (prev = !showNotify));
                  }}
                />
              </div>
              <div className="notificationsListWrapper">
                {notificationsData &&
                  notificationsData.map((n) => {
                    return (
                      <>
                        <div
                          className={
                            n.status
                              ? "notificationsList_item active"
                              : "notificationsList_item"
                          }
                        >
                          <div
                            className="title "
                            onClick={() => {
                              if (visibleNotificationId === n.id) {
                                setVisibleNotificationId(-1);
                              } else {
                                updateNotification(n.id);
                                setVisibleNotificationId(n.id);
                              }
                            }}
                          >
                            <span>
                              {n?.type === "general"
                                ? n?.name
                                : getNotifyName(n)}
                            </span>
                            <span className="small-title">
                              {formatMysqlDateToNormal(n?.created_at)}
                            </span>
                          </div>
                          {visibleNotificationId === n.id && (
                            <div className="des ">
                              <span>
                                {n?.type === "general"
                                  ? n?.description
                                  : getNotifyName(n, "des")}
                              </span>
                              {n?.type !== "general" && n?.content_id > 0 && (
                                <IoInformationCircle
                                  fontSize={25}
                                  onClick={() => {
                                    if (n.type === "quote") {
                                      navigate("/quoteInfo/" + n.content_id);
                                    }

                                    if (n.type === "appointment" && !isMobile) {
                                      setAppointmentModalStatus(true);
                                      navigate(
                                        paths.profilePath +
                                          "?appointment=" +
                                          n.content_id
                                      );
                                    }

                                    if (n.type === "appointment" && isMobile) {
                                      setAppointmentModalStatus(true);
                                      navigate(
                                        paths.appointments +
                                          "?appointment=" +
                                          n.content_id
                                      );
                                    }

                                    if (n.type === "ticket" && !isMobile) {
                                      setTicketModalStatus(true);
                                      navigate(
                                        paths.profilePath +
                                          "?ticket=" +
                                          n.content_id
                                      );
                                    }

                                    if (n.type === "ticket" && isMobile) {
                                      setTicketModalStatus(true);
                                      navigate(
                                        paths.myTicketsPath +
                                          "?ticket=" +
                                          n.content_id
                                      );
                                    }

                                    setVisibleNotificationId(-1);
                                    setShowNotify(
                                      (prev) => (prev = !showNotify)
                                    );
                                  }}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </>
        )}

        {fmodules["shop"] && !isMobile && (
          <div
            className="menuListItem"
            onClick={() => {
              navigate(paths.shopPath, { replace: true });
            }}
          >
            {getT(translationKeys.shop)}
          </div>
        )}
      </div>
    </div>
  );
}
