import React, { useEffect, useRef, useState } from "react";
import Location from "./Location";
import { getT, translationKeys } from "../admin/utils";
import { FaRotate } from "react-icons/fa6";
import { BsDashLg, BsFillGridFill, BsPlusLg } from "react-icons/bs";
import CustomOverLay from "../components/CustomOverLay";

export default function Marker({
  loc,
  zoom,
  onAddChange = () => {},
  address,
  onCenterChanged = () => {},
  onMarkerDragged = () => {},
}) {
  var rotation = 0;
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  useEffect(() => {
    if (window.google && window.google.maps) {
      initMap();
    }
  });

  const initMap = async () => {
    const myLatLng = loc;
    // Request needed libraries.
    const { Map } = await window.google.maps.importLibrary("maps");
    const { AdvancedMarkerElement } = await window.google.maps.importLibrary(
      "marker"
    );

    const map = new Map(document.getElementById("customMap"), {
      center: myLatLng,
      minZoom: 18,
      tilt: 0,
      zoom: zoom ? parseInt(zoom) : 20,
      disableDefaultUI: true,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      mapId: "b9ce3553b919f3bc",
      //isFractionalZoomEnabled: true,
    });

    map.setMapTypeId("satellite");
    map.setHeading(0);

    /* const canvas = document.createElement("canvas");
    let gl = canvas.getContext("webgl2");

    gl
      ? console.log("WebGL 2 is supported")
      : console.log("WebGL 2 is NOT supported");*/

    mapRef.current = map;

    const icon = {
      url: "/assets/pin_google.png",
      // eslint-disable-next-line no-undef
      //scaledSize: new google.maps.Size(30, 30),
    };

    /*const marker = new AdvancedMarkerElement({
      map,
      gmpDraggable: true,
      icon: icon,
      position: myLatLng,
    });*/

    let marker = new window.google.maps.Marker({
      position: myLatLng,
      map,
      draggable: true,
      customName: "myMarkerInfo",
      icon: icon,
      title: "",
    });

    let infoWindow = new window.google.maps.InfoWindow({
      content: getT(translationKeys.markerLabelContent),
    });

    infoWindow.open(map, marker);

    // Add event listeners for mouseover and mouseout to show/hide the InfoWindow
    marker.addListener("mouseover", () => {
      infoWindow.open(map, marker);
    });

    window.google.maps.event.addListener(map, "center_changed", function () {
      saveMapState(map);
    });

    function saveMapState(map) {
      let currentCenter = map.getCenter().toJSON();
      onCenterChanged(currentCenter);
    }
    // for move marker event
    window.google.maps.event.addListener(marker, "dragend", function (event) {
      let currentZoom = map.getZoom();
      infoWindow.close();
      infoWindow.open(map, marker);
      onMarkerDragged({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
        zoom: currentZoom.toString(),
      });
      console.log("test");
    });

    console.log(map.getRenderingType());
  };

  const changeTilt = () => {
    //let val = map.getTilt() + 45;
    if (mapRef.current.getTilt() === 45) {
      mapRef.current.setTilt(0);
    } else {
      mapRef.current.setTilt(45);
    }
  };

  const increaseZoom = () => {
    mapRef.current.setZoom(mapRef.current.getZoom() + 1);
  };

  const decreaseZoom = () => {
    mapRef.current.setZoom(mapRef.current.getZoom() - 1);
  };

  const changeHeading = () => {
    let val = rotation + 45;

    if (mapRef.current) {
      console.log(mapRef.current.getHeading());
      //mapRef.current.setHeading(val);
      mapRef.current.setHeading((mapRef.current.getHeading() + 45) % 360);
      rotation = val;
    }
  };
  return (
    <>
      <Location
        value={address}
        onSelection={onAddChange}
        position={"second"}
        type={"small"}
      />
      <div className="rotationBtn">
        <CustomOverLay
          placement="left"
          message={getT(translationKeys.rotate_info_label)}
          iconElement={
            <FaRotate
              fontSize={25}
              cursor={"pointer"}
              onClick={() => changeHeading()}
            />
          }
        />

        {/* <BsFillGridFill
          fontSize={25}
          cursor={"pointer"}
          onClick={() => changeTilt()}
          color="white"
  /> */}
        <CustomOverLay
          placement="left"
          message={getT(translationKeys.plus_zoom_info_label)}
          iconElement={
            <BsPlusLg cursor={"pointer"} onClick={() => increaseZoom()} />
          }
        ></CustomOverLay>
        <CustomOverLay
          placement="left"
          message={getT(translationKeys.minus_zoom_info_label)}
          iconElement={
            <BsDashLg cursor={"pointer"} onClick={() => decreaseZoom()} />
          }
        ></CustomOverLay>
      </div>
      <div id="customMap"></div>
    </>
  );
}
