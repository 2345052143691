import React from "react";

export default function ImageWithTextComponent({ info = {} }) {
  return (
    <>
      <div className="imagewithtext_component_inner">
        <img
          src={info?.content?.image}
          alt={
            info?.content?.title ? info?.content?.title : info?.content?.image
          }
        />
        <div className="innerInfo">
          <div className="title">{info?.content?.title}</div>
          <div className="des">{info?.content?.description}</div>
        </div>
      </div>
    </>
  );
}
