import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";
import {
  useCreateUserMutation,
  useUpdateUserMutation,
  useUserQuery,
} from "../../queries/users";
import CustomInput from "./utils/CustomInput";
import { getT, translationKeys } from "../utils";

const ClientForm = ({ onEdit = false, editId = null, rolesData = [] }) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useUserQuery(
    editId,
    editId ? true : false
  );

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdateUserMutation)
    : (mutationHook = useCreateUserMutation);
  const mutation = mutationHook();

  const newUserSchema = {
    name: Yup.string().required(getT(translationKeys.nameRequired)),
    surname: Yup.string().required(getT(translationKeys.surnameRequired)),
    email: Yup.string()
      .email(getT(translationKeys.emailError))
      .required(getT(translationKeys.emailRequired)),
    pwd: Yup.string().required(getT(translationKeys.passwordRequired)),
  };

  const editUserSchema = {
    name: Yup.string().required(getT(translationKeys.nameRequired)),
    surname: Yup.string().required(getT(translationKeys.surnameRequired)),
  };

  const formSchema = Yup.object().shape(
    onEdit ? editUserSchema : newUserSchema
  );

  useEffect(() => {
    if (data && !isLoading) {
      setEditData(data?.user);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.user &&
        mutation.data.data.user.id > 0
      ) {
        onEdit
          ? toast.success(getT(translationKeys.updated))
          : toast.success(getT(translationKeys.created));
        navigate(paths.clientsPath);
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error(getT(translationKeys.generalError));
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    //console.log(values);
    const finalData = {
      email: values.email,
      name: values.name,
      lastName: values.surname,
      password: values.pwd,
      company: values.company,
      role_id: values.role_id,
      status: values.status,
    };
    if (onEdit) {
      delete finalData.email;
    }

    toast.warning(getT(translationKeys.pleaseWait));
    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    navigate(paths.leadsPath);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">{getT(translationKeys.generalError)}</div>;
  }

  return (
    <Container fluid className="leadForm">
      <button
        onClick={() => {
          navigate(paths.clientsPath);
        }}
        className="btn btn-secondary "
      >
        {getT(translationKeys.back)}
      </button>
      <h4 className="my-2">
        {onEdit ? getT(translationKeys.update) : getT(translationKeys.create)}
      </h4>
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              name: editData?.name ?? "",
              surname: editData?.lastName ?? "",
              email: editData?.email ?? "",
              pwd: "",
              company: editData?.company && editData.company ? true : false,
              role_id: editData?.role_id ?? 1,
              status: editData?.status && editData.status ? true : false,
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, formik }) => (
              <Form>
                <Row className="mt-2">
                  <Col md={3} className="mb-2">
                    <div className="d-flex">
                      <label className="me-2">
                        {" "}
                        {getT(translationKeys.company)}
                      </label>
                      <Field
                        type="checkbox"
                        id="company"
                        className="form-check-input me-2"
                        name="company"
                      />
                      <label className="me-2">
                        {getT(translationKeys.yes)}{" "}
                      </label>
                    </div>
                  </Col>
                  <Col md={3} className="mb-2">
                    <div className="d-flex">
                      <label className="me-2">
                        {" "}
                        {getT(translationKeys.status)}
                      </label>
                      <Field
                        type="checkbox"
                        id="status"
                        className="form-check-input me-2"
                        name="status"
                      />
                      <label className="me-2">
                        {getT(translationKeys.yes)}{" "}
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.name}
                      name="name"
                      label={getT(translationKeys.name)}
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.surname}
                      name="surname"
                      label={getT(translationKeys.surname)}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={12} md={6}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.email}
                      name="email"
                      type="email"
                      label={getT(translationKeys.email)}
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.pwd}
                      name="pwd"
                      type="password"
                      label={getT(translationKeys.password)}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={12} md={4}>
                    <label className="form-label" htmlFor="firstName">
                      {getT("role_type_label")}
                    </label>

                    <Field
                      as="select"
                      className="form-select"
                      placeholder="Fonte"
                      id="role_id"
                      name="role_id"
                    >
                      {rolesData.map((option, index) => (
                        <option
                          key={index}
                          value={option.id}
                          label={option.name}
                        />
                      ))}
                      <ErrorMessage
                        className="danger"
                        name="role_id"
                        component="div"
                      />
                    </Field>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      {getT(translationKeys.submit)}
                    </button>
                    <button
                      type="button"
                      onClick={() => handleClear(formik)}
                      className="btn btn-secondary"
                    >
                      {getT(translationKeys.back)}
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default ClientForm;
