import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getT } from "../utils";

export default function AddressInfo({ info = {} }) {
  return (
    <>
      <Container fluid className="leadForm">
        <Row className="mt-2">
          <Col md={6} className="mb-2">
            <label>
              <b>{getT("name")}</b>
            </label>
            <p>{info?.name}</p>
          </Col>
          <Col md={6} className="mb-2">
            <label>
              <b>{getT("surname")}</b>
            </label>
            <p>{info?.surname}</p>
          </Col>
          <Col md={6} className="mb-2">
            <label>
              <b>{getT("city")}</b>
            </label>
            <p>{info?.city}</p>
          </Col>
          <Col md={6} className="mb-2">
            <label>
              <b>{getT("state")}</b>
            </label>
            <p>{info?.state}</p>
          </Col>
          <Col md={6} className="mb-2">
            <label>
              <b>{getT("country")}</b>
            </label>
            <p>{info?.country}</p>
          </Col>
          <Col md={6} className="mb-2">
            <label>
              <b>{getT("zip")}</b>
            </label>
            <p>{info?.zip}</p>
          </Col>
          <Col md={6} className="mb-2">
            <label>
              <b>{getT("phone")}</b>
            </label>
            <p>{info?.phone}</p>
          </Col>
          <Col md={6} className="mb-2">
            <label>
              <b>{getT("company")}</b>
            </label>
            <p>{info?.company}</p>
          </Col>
          <Col md={6} className="mb-2">
            <label>
              <b>{getT("social_security_label")}</b>
            </label>
            <p>{info?.socialSecurity}</p>
          </Col>
        </Row>
      </Container>
    </>
  );
}
