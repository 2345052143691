import React, { useEffect, useState } from "react";
import Filters from "../Filters";
import CustomTable from "../CustomTable";
import { BsPencilSquare } from "react-icons/bs";
import moment from "moment";
import {
  getPanelsCount,
  getPercentageByType,
  getT,
  isDateBetween,
  leadColumns,
  paymentStatusOptions,
  quoteColumns,
  translationKeys,
} from "../utils";
import { useQuoteQuery } from "../../queries/quotes";
import { useNavigate } from "react-router-dom";
import { BsEyeFill } from "react-icons/bs";

import Loading from "../../components/Loading";
import { useAuth } from "../../authContext";
import SmallQuoteForm from "../forms/SmallQuoteForm";
import WrapperModal from "../../components/ModalWrapper";
import { adminWord } from "../../config/paths";

export default function Quotes({ roleType = "", cusId = null }) {
  const [list, setList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { panelSettings } = useAuth();
  const navigate = useNavigate();
  const [editModal, setEditMoal] = useState(false);
  const [editId, setEditId] = useState(null);

  const getCustomQuery = () => {
    let item = { customerId: cusId };
    if (roleType === "customer") {
      item["type"] = roleType;
    }
    return item;
  };

  const { data, isIdle, isLoading, isError, error } = useQuoteQuery(
    null,
    true,
    getCustomQuery()
  );

  const getStatusClr = (val) => {
    let clr = "btn_yash_clr";
    paymentStatusOptions.forEach((ele) => {
      if (ele.value === parseInt(val)) {
        clr = "btn_" + ele.color;
      }
    });
    return clr;
  };

  const getFinalPrice = (r) => {
    let f4 = getFtvData(r);
    let bat = getBatteryCount(r);
    let price = 0;
    let price2 = 0;
    if (panelSettings && panelSettings.ftv_price && panelSettings.bat_price) {
      let ftvPrice = parseFloat(panelSettings.ftv_price);
      let batPrice = parseFloat(panelSettings.bat_price);
      if (f4 > 0 && ftvPrice > 0) {
        price = f4 * ftvPrice;
      }
      if (batPrice > 0) {
        price2 = price + batPrice * bat;
      }
    }

    // extra price
    let extraPrice = price2;

    let items = [
      {
        name: "peopleCount",
        val: r?.people_count,
      },
      {
        name: "heatPump",
        val: r?.heat_pump,
      },
      {
        name: "inductionHub",
        val: r?.induction_hub,
      },
      {
        name: "ac",
        val: r?.air_conditioners_pump,
      },
      {
        name: "electricCar",
        val: r?.electric_car,
      },
      {
        name: "col",
        val: r?.col,
      },
    ];

    items.forEach((item) => {
      let subPrice = getPercentageByType(item.val, item.name);

      if (subPrice > 1) {
        extraPrice = extraPrice + extraPrice * (subPrice / 100);
      }
    });

    // global discount
    if (r?.discount && parseFloat(r?.discount) > 0) {
      let disCount = parseFloat(r?.discount) / 100;
      extraPrice = extraPrice - extraPrice * disCount;
    } else {
      extraPrice = extraPrice - extraPrice * 0.1;
    }

    return Math.round(extraPrice).toFixed(2);
  };

  const getBatteryCount = (r) => {
    let f4 = getFtvData(r);
    let bat = 0;
    if (panelSettings && panelSettings.bat_capacity) {
      let batCapacity = parseFloat(panelSettings.bat_capacity);
      if (f4 >= batCapacity) {
        bat = f4 / batCapacity;
        if (bat < 1) {
          bat = 0;
        }
      }
    }

    // manuvally set the battery size
    if (r?.bat && parseFloat(r?.bat) > 0) {
      bat = parseFloat(r?.bat);
    }

    return bat;
  };

  const getFtvData = (r) => {
    let f4 = 0;
    let f1 = r.f1 ? parseFloat(r.f1) : 1;
    let f2 = r.f2 ? parseFloat(r.f2) : 1;
    let f3 = r.f3 ? parseFloat(r.f3) : 1;
    let total = f1 + f2 + f3;
    let subTotal = total - total * 0.2;
    f4 = subTotal / 365;
    return f4 > 0 ? f4.toFixed(3) : 0;
  };

  const getBatteryKw = (r) => {
    let bat = getBatteryCount(r);
    let batkw = 0;
    if (panelSettings && panelSettings.bat_capacity) {
      let bc = parseFloat(panelSettings.bat_capacity);
      if (bc > 0 && bat > 0) {
        batkw = batkw + bat * bc;
      }
    }
    return batkw;
  };

  useEffect(() => {
    if (data && !isLoading && data.quotes) {
      let apiData = data.quotes.map((r) => {
        return {
          id: "PV" + r?.id,
          date: moment(r?.created_at, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY"
          ),
          name: (r.name ? r.name : "") + "" + (r?.surname ? r.surname : ""),
          add: r?.address,
          comune: r?.city,
          prov: r?.province,
          phone: r?.mobile,
          email: r?.email,
          status: r?.status,
          ftv: <span className="danger">{getFtvData(r)}KW</span>,
          bat: (
            <span
              data-id={r.id}
              onClick={(event, r) => {
                let id = event.target.getAttribute("data-id");
                if (id > 0) {
                  setShowModal(true);
                  setEditMoal(true);
                  setEditId(id);
                }
              }}
              className="danger cursor"
            >
              {getBatteryKw(r)} KW
            </span>
          ),
          col: r?.col && r.col ? "si" : "no",
          discount: (
            <span
              data-id={r.id}
              className="cursor"
              onClick={(event, r) => {
                if (roleType !== "customer") {
                  let id = event.target.getAttribute("data-id");
                  if (id > 0) {
                    setShowModal(true);
                    setEditMoal(true);
                    setEditId(id);
                  }
                }
              }}
            >
              {r?.discount && parseFloat(r.discount) > 0
                ? "-" + parseFloat(r.discount) + "%"
                : "-10%"}
            </span>
          ),
          price: <span className="green"> €{getFinalPrice(r)}</span>,
          gse: <div className={"c_btn " + getStatusClr(r?.gse)}></div>,
          install: <div className={"c_btn " + getStatusClr(r?.install)}></div>,
          payment: <div className={"c_btn " + getStatusClr(r?.payment)}></div>,
          installInfo: r?.install,
          gseInfo: r?.gse,
          paymentInfo: r?.payment,
          action: (
            <>
              {roleType !== "customer" && (
                <span
                  className="cursor me-2"
                  data-id={r.id}
                  onClick={(event) => {
                    let id =
                      event.target.parentNode.getAttribute("data-id") ||
                      event.target.getAttribute("data-id");
                    if (id > 0) {
                      navigate(adminWord + "/preventivi/edit/" + id);
                    }
                  }}
                >
                  <BsPencilSquare data-id={r.id} fontSize={25} />
                </span>
              )}
              <span
                data-id={r.id}
                className="cursor "
                onClick={(event) => {
                  let id =
                    event.target.parentNode.getAttribute("data-id") ||
                    event.target.getAttribute("data-id");
                  if (id > 0) {
                    window.open("/#?id=" + id, "_blank");
                  }
                }}
              >
                {" "}
                <BsEyeFill data-id={r.id} fontSize={25} />
              </span>
            </>
          ),
        };
      });
      setList(apiData);
      setFilteredData(apiData);
    }
  }, [isLoading, data]);

  const onSearch = (values) => {
    var options = list;

    if (options && options.length > 0) {
      if (values.name !== "") {
        options = options.filter((s) => {
          let cString = s.name.toLowerCase();
          return cString.includes(values.name.toLowerCase());
        });
      }

      if (values.comune !== "") {
        options = options.filter((s) => {
          let cString = s.comune.toLowerCase();
          return cString.includes(values.comune.toLowerCase());
        });
      }

      if (values.statusType !== "") {
        options = options.filter((s) => {
          return s.status === values.statusType;
        });
      }

      if (values.prov !== "") {
        options = options.filter((s) => {
          let cString = s.prov.toLowerCase();
          return cString.includes(values.prov.toLowerCase());
        });
      }

      if (values.type !== "") {
        options = options.filter((s) => {
          return s.type === values.type;
        });
      }

      if (values.install !== "") {
        options = options.filter((s) => {
          return parseInt(s.installInfo) === parseInt(values.install);
        });
      }

      if (values.payment !== "") {
        options = options.filter((s) => {
          return parseInt(s.paymentInfo) === parseInt(values.payment);
        });
      }

      if (values.gse !== "") {
        options = options.filter((s) => {
          return parseInt(s.gseInfo) === parseInt(values.gse);
        });
      }

      if (values.from !== "" && values.to !== "") {
        options = options.filter((s) => {
          let date = moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD");
          return isDateBetween(values.from, values.to, date);
        });
      }
    }

    setFilteredData(options);
  };

  if (isLoading) {
    return <Loading />;
  }

  const onClear = () => {
    setFilteredData(list);
  };

  return (
    <>
      <WrapperModal
        show={showModal}
        title={!editModal ? "New Appointment" : "Aggiornamento preventivi"}
        handleClose={() => {
          setShowModal(!showModal);
        }}
      >
        <SmallQuoteForm
          onEdit={editModal}
          editId={editId}
          onClose={() => {
            setShowModal(false);
            setEditMoal(false);
            setEditId(null);
          }}
        />
      </WrapperModal>
      <Filters
        type="quotes"
        onAdd={() => setShowModal(true)}
        onFilter={onSearch}
        onClear={onClear}
        roleType={roleType}
      />

      <div className="customTable">
        {isIdle || isLoading ? (
          getT(translationKeys.pleaseWait)
        ) : (
          <CustomTable
            legend={true}
            columns={quoteColumns}
            data={filteredData}
          />
        )}
      </div>
    </>
  );
}
