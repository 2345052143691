import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";
import CustomInput from "./utils/CustomInput";
import { getT, translationKeys } from "../utils";
import { FaUser } from "react-icons/fa";
import { BsBuildingsFill, BsFillPersonFill } from "react-icons/bs";
import {
  useCreateUserMutation,
  useUpdateUserMutation,
  useUserQuery,
} from "../../queries/users";
import { FaSquarePhone } from "react-icons/fa6";

const ProfileForm = ({ onEdit = false, editId = null }) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useUserQuery(
    editId,
    editId ? true : false
  );

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdateUserMutation)
    : (mutationHook = useCreateUserMutation);
  const mutation = mutationHook();

  const newSchema = {
    name: Yup.string().required(getT(translationKeys.nameRequired)),
  };

  const editSchema = {
    name: Yup.string().required(getT(translationKeys.nameRequired)),
  };

  const formSchema = Yup.object().shape(onEdit ? editSchema : newSchema);

  useEffect(() => {
    if (data && !isLoading && data?.user) {
      setEditData(data?.user);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.user &&
        mutation.data.data.user.id > 0
      ) {
        onEdit
          ? toast.success(getT(translationKeys.updated))
          : toast.success(getT(translationKeys.created));
        //navigate(paths.rolesPath);
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error("try again later");
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const finalData = {
      name: values?.name,
      address: values?.address,
      city: values?.city,
      state: values?.state,
      company: values?.company,
      phone: values?.phone,
      company_name: values.company_name,
      codice_fiscale: values.codice_fiscale,
    };

    toast.warning("please wait..");
    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    navigate(paths.rolesPath);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">something went wrong try again later</div>;
  }

  return (
    <Container fluid className="leadForm">
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              name: editData?.name ?? "",
              address: editData?.address ?? "",
              city: editData?.city ?? "",
              state: editData?.state ?? "",
              company: editData?.company && editData.company ? true : false,
              phone: editData?.phone ?? "",
              company_name: editData?.company_name ?? "",
              codice_fiscale: editData?.codice_fiscale ?? "",
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ formik, values, setFieldValue }) => (
              <Form>
                <h5>{getT(translationKeys.my_account_label)}</h5>
                <div class="home-types">
                  <div
                    className={
                      !values.company ? "home-type active" : "home-type"
                    }
                    onClick={() => {
                      setFieldValue("company", false);
                    }}
                  >
                    <BsFillPersonFill />
                    <span>Privati</span>
                  </div>
                  <div
                    className={
                      values.company ? "home-type active" : "home-type"
                    }
                    onClick={() => {
                      setFieldValue("company", true);
                    }}
                  >
                    <BsBuildingsFill />
                    <span>Azienda</span>
                  </div>
                </div>
                <Row>
                  {values.company && (
                    <>
                      {" "}
                      <Col sm={12} md={12} className="mb-1">
                        <CustomInput
                          onEdit={false}
                          defaultValue={values.company_name}
                          name="company_name"
                          label={getT(
                            translationKeys.profile_company_name_label
                          )}
                          labelStatus={false}
                          placeholder={getT(
                            translationKeys.profile_company_name_label
                          )}
                        />
                      </Col>
                      <Col sm={12} md={12} className="mb-1">
                        <CustomInput
                          onEdit={false}
                          defaultValue={values.codice_fiscale}
                          name="codice_fiscale"
                          label={getT(
                            translationKeys.profile_codice_fiscale_label
                          )}
                          labelStatus={false}
                          placeholder={getT(
                            translationKeys.profile_codice_fiscale_label
                          )}
                        />
                      </Col>{" "}
                    </>
                  )}
                  <Col sm={12} md={12}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.name}
                      name="name"
                      label={getT(translationKeys.name)}
                      labelStatus={false}
                      placeholder={getT(translationKeys.nameesurname)}
                    />
                  </Col>
                  <Col sm={12} md={12} className="mt-2">
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.address}
                      name="address"
                      label={getT(translationKeys.address)}
                      labelStatus={false}
                      placeholder={getT(translationKeys.address)}
                      fieldType="auto"
                      isIcon={true}
                    />
                  </Col>
                  <Col sm={12} md={8} className="mt-2">
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.city}
                      name="city"
                      label={getT(translationKeys.city)}
                      labelStatus={false}
                      placeholder={getT(translationKeys.city)}
                    />
                  </Col>
                  <Col sm={12} md={4} className="mt-2">
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.state}
                      name="state"
                      label={getT(translationKeys.prov)}
                      labelStatus={false}
                      placeholder={getT(translationKeys.prov)}
                    />
                  </Col>
                  <Col sm={12} md={12} className="mt-2">
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.phone}
                      name="phone"
                      label={getT(translationKeys.phone)}
                      labelStatus={false}
                      placeholder="Cellulare"
                      isIcon={true}
                      iconData={<FaSquarePhone size={18} />}
                    />
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      {getT(translationKeys.update_label)}
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default ProfileForm;
