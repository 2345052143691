import React, { useEffect, useRef, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaRotate } from "react-icons/fa6";
export default function CustomOverLay({
  placement = "bottom",
  message = "hi",
  iconElement = <FaRotate fontSize={25} cursor={"pointer"} />,
  children,
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {message}
    </Tooltip>
  );
  return (
    <div ref={tooltipRef}>
      <OverlayTrigger
        trigger={["hover", "focus"]}
        show={showTooltip}
        placement={placement}
        overlay={renderTooltip}
      >
        <div className="inner_tooltip">
          {React.cloneElement(iconElement, {
            onMouseEnter: () => setShowTooltip(true),
            onMouseLeave: () => setShowTooltip(false),
            onClick: (event) => {
              setShowTooltip(false);
              // Check if the original element has an onClick handler and call it
              if (iconElement.props.onClick) {
                iconElement.props.onClick(event);
              }
            },
          })}
        </div>
      </OverlayTrigger>
    </div>
  );
}
