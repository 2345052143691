import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths, { apiUrl } from "../../config/paths";
import Loading from "../../components/Loading";
import { useAuth } from "../../authContext";
import { getT, parallaxTypes, translationKeys } from "../utils";
import CustomInput from "./utils/CustomInput";
import axios from "axios";
import { getFromLocalStorage } from "../../utils/helpers";
import { useQueryClient } from "@tanstack/react-query";
import {
  useCreateProductCategoryMutation,
  useProductCategoryQuery,
  useUpdateProductCategoryMutation,
} from "../../queries/productCategory";

const ProductCategoryForm = ({
  onEdit = false,
  editId = null,
  pid = null,
  onClose = () => {},
}) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useProductCategoryQuery(
    editId,
    editId ? true : false
  );

  const { TData } = useAuth();
  const queryClient = useQueryClient();

  const fileInputRef = useRef(null);

  const formSchema = Yup.object().shape({
    name: Yup.string().required(getT(translationKeys.nameRequired)),
  });

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdateProductCategoryMutation)
    : (mutationHook = useCreateProductCategoryMutation);
  const mutation = mutationHook();

  useEffect(() => {
    if (data && !isLoading && data?.item) {
      setEditData(data?.item);
    }
    console.log(pid);
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.item &&
        mutation.data.data.item.id > 0
      ) {
        onEdit
          ? toast.success(getT(translationKeys.updated))
          : toast.success(getT(translationKeys.created));
        onClose();
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error(getT(translationKeys.generalError));
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    //console.log(values);
    const finalData = {
      name: values.name,
      active: values.active,
      z_order: values.z_order,
      product_id: pid,
    };
    toast.warning(getT(translationKeys.pleaseWait));

    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    navigate(paths.productsPath);
  };

  const getInitialInfo = (id) => {
    let output = {};
    let inputArray = parallaxTypes;
    inputArray.forEach((q) => {
      if (q.value === id) {
        output = {
          value: q.value,
          label: q.name,
        };
      }
    });

    return output;
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">{getT(translationKeys.generalError)}</div>;
  }

  return (
    <Container fluid className="leadForm">
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              name: editData?.name ?? "",
              active: editData?.active && editData.active ? true : false,
              z_order: editData?.z_order ?? 0,
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, formik }) => (
              <Form>
                <Row>
                  <Col md={12}>
                    <div>
                      <Field
                        type="checkbox"
                        className="form-check-input me-2"
                        name="active"
                      />
                      <label className="me-2">
                        {getT(translationKeys.active)}{" "}
                      </label>
                    </div>
                  </Col>
                  <Col sm={12} md={6}>
                    <CustomInput
                      onEdit={editId > 0 ? true : false}
                      defaultValue={values.name}
                      name="name"
                      label={getT(translationKeys.name)}
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.z_order}
                      name="z_order"
                      label={getT(translationKeys.z_order)}
                    />
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      {getT(translationKeys.submit)}
                    </button>
                    {/* <button
                      type="button"
                      onClick={() => handleClear(formik)}
                      className="btn btn-secondary"
                    >
                      {getT(translationKeys.back)}
                    </button> */}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default ProductCategoryForm;
