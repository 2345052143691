import React, { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths, { apiUrl } from "../../config/paths";
import Loading from "../../components/Loading";
import CustomInput from "./utils/CustomInput";
import CustomTextArea from "./utils/CustomTextArea";
import {
  getT,
  inductionOptions,
  langOptions,
  ticketCategoryOptions,
  ticketStatusOptions,
  translationKeys,
} from "../utils";

import { useAuth } from "../../authContext";
import { useTicketQuery } from "../../queries/tickets";
import CustomSelect2 from "./utils/CustomSelect2";
import Messages from "../Tickets/Messages";
import { BsUpload } from "react-icons/bs";
import {
  useCreateMessageMutation,
  useGetMessageByTicketId,
  useUpdateMessageMutation,
} from "../../queries/messages";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { getFromLocalStorage } from "../../utils/helpers";
import { MdOutlineAttachFile } from "react-icons/md";
import { RiDeleteBack2Fill } from "react-icons/ri";
const MessageForm = ({
  onEdit = false,
  editId = null,
  location = "",
  authId = -1,
  onClose = () => {},
}) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const [messageList, setMessageList] = useState([]);
  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useTicketQuery(
    editId,
    false
  );

  const [file, setFile] = useState(null);
  const { authUserId, customerInfo, isCustomerAuthenticated } = useAuth();
  const queryClient = useQueryClient();
  const fileInputRef = useRef(null);

  const newSchema = {
    //message: Yup.string().required(getT(translationKeys.messageRequired)),
  };

  const formSchema = Yup.object().shape(newSchema);

  let formInitVal = {
    name: editData?.name ?? "",
    status: editData?.status ?? "1",
    user_id: editData?.create_user_id ? editData?.create_user_id : authUserId,
    message: "",
    file: file,
    category: editData?.category ?? "practices",
  };

  const {
    data: msgData,
    isIdle: msgIdle,
    isLoading: msgLoading,
    isError: msgError,
  } = useGetMessageByTicketId(editId, false);

  useEffect(() => {
    if (msgData && !msgLoading && msgData?.items) {
      setMessageList(msgData?.items);
    }
  }, [msgLoading, msgData]);

  useEffect(() => {
    if (data && !isLoading && data.item && !submittable) {
      setEditData(data?.item);
    }
  }, [isLoading, data, submittable]);

  useEffect(() => {
    //queryClient.invalidateQueries(["messageList"]); // Fetch data on component mount

    const intervalId = setInterval(() => {
      queryClient.invalidateQueries(["messageList"]);
    }, 10000); // Set up interval to fetch data every 5 seconds

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, []);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const finalData = {
      name: values.name,
      status: values.status,
      user_id: location === "" ? authUserId : authId,
      ticket_id: editId,
      customer_id: authId,
      category: values.category,
      message: values.message,
    };

    let formData = new FormData();
    formData.append("file", file);

    // Append additional form fields
    Object.keys(finalData).forEach((key) => {
      formData.append(key, finalData[key]);
    });

    toast.warning(getT(translationKeys.pleaseWait));

    try {
      const response = await axios.post(apiUrl + "/api/message", formData, {
        headers: {
          Authorization: getFromLocalStorage("userToken")
            ? getFromLocalStorage("userToken")
            : getFromLocalStorage("customerToken"),
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data && response.data.status) {
        toast.dismiss();
        resetForm();
        // Reset the file input
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
        toast.success(
          !onEdit
            ? getT(translationKeys.created)
            : getT(translationKeys.updated)
        );
        if (!onEdit) {
          onClose();
        }
        setFile(null);
        queryClient.invalidateQueries("tickets");
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("try again later");
      }
    } finally {
      setSubmitting(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">{getT(translationKeys.generalError)}</div>;
  }

  return (
    <Container fluid className="leadForm messageForm">
      {location !== "profile" && (
        <>
          <button
            onClick={() => {
              location == ""
                ? navigate(paths.ticketsPath)
                : navigate(paths.profilePath);
            }}
            className="btn btn-secondary "
          >
            {getT(translationKeys.back)}
          </button>

          <h4 className="my-2">
            {onEdit
              ? getT(translationKeys.history)
              : getT(translationKeys.create)}
          </h4>
        </>
      )}
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={formInitVal}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, formik }) => (
              <Form>
                <Row>
                  <Col sm={12} md={onEdit ? 4 : 6}>
                    <CustomInput
                      //onEdit={onEdit}
                      defaultValue={values.name}
                      name="name"
                      label={getT(translationKeys.ticket_name)}
                    />
                  </Col>
                  <Col sm={12} md={onEdit ? 4 : 6}>
                    <CustomSelect2
                      label={getT(translationKeys.ticket_category_label)}
                      name="category"
                      options={ticketCategoryOptions}
                      defaultValue={values.category}
                      defaultSelectVal={getT(
                        translationKeys.ticket_select_category_placeholder_label
                      )}
                    />
                  </Col>
                  {onEdit && (
                    <Col sm={12} md={4}>
                      <CustomSelect2
                        label={getT(translationKeys.ticketStatusLabel)}
                        name="status"
                        options={ticketStatusOptions}
                        defaultValue={values.status}
                      />
                    </Col>
                  )}
                  <Col md={12}>
                    <Messages
                      list={messageList}
                      fromId={editData?.create_user_id}
                    />
                  </Col>
                  <Col sm={12} md={12}>
                    {/* <BsUpload fontSize={20} /> */}
                    <div className="fileWrapper">
                      <input
                        type="file"
                        name="file"
                        ref={fileInputRef}
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                        id="file_upload_msg"
                        className="form-control"
                      />
                      <div className="fileData">
                        {file ? (
                          <div className="d-flex justify-content-between">
                            <span>{file?.name}</span>
                            <RiDeleteBack2Fill
                              onClick={() => {
                                setFile(null);
                              }}
                            />
                          </div>
                        ) : (
                          <MdOutlineAttachFile
                            onClick={() => {
                              document
                                .querySelector("#file_upload_msg")
                                .click();
                            }}
                          />
                        )}
                      </div>

                      <div className="text-area">
                        <CustomTextArea
                          name={"message"}
                          label={""}
                          placeholder={getT(translationKeys.message)}
                          noLabel={false}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      {getT(translationKeys.send)}
                    </button>
                    {/* <button
                      type="button"
                      onClick={() => handleClear(formik)}
                      className="btn btn-secondary"
                    >
                      {getT(translationKeys.back)}
            </button> */}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default MessageForm;
