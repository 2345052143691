import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../authContext";
import { mobileMenuList } from "../utils/api";
import { getT, translationKeys } from "../admin/utils";

export default function Navigation() {
  const { mobileActiveMenu, setMobileActiveMenu, isCustomerAuthenticated } =
    useAuth();
  const navigate = useNavigate();

  return (
    <>
      <div className="actionsMenuList">
        {mobileMenuList.map((m) => {
          return (
            <>
              <div
                className={
                  "no-print actionsMenuListItem " +
                  (m.value === mobileActiveMenu ? "active " : "")
                }
                onClick={() => {
                  if (
                    (isCustomerAuthenticated && m.value !== "new") ||
                    isCustomerAuthenticated
                  ) {
                    setMobileActiveMenu(m.value);
                    navigate(m.path);
                  }
                }}
              >
                {m.icon}
                <span>{getT(translationKeys[m.name])}</span>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}
