import React, { useState } from "react";
import {
  installerMenuList,
  menuList,
  operatorMenuList,
  userMenuList,
} from "../config/api";
import { useAuth } from "../authContext";
import { useLocation, useNavigate } from "react-router-dom";
import { BsList } from "react-icons/bs";
import { FaAngleDoubleRight } from "react-icons/fa";
import { FaAngleDoubleLeft } from "react-icons/fa";
import {
  ADMINROLE,
  INSTALLERROLE,
  OPERATORROLE,
  getT,
  translationKeys,
} from "./utils";

export default function MenuInfo() {
  const [selected, setSelected] = useState("");
  const [menuStatus, setMenuStatus] = useState(false);
  const { userRoleName } = useAuth();
  const { authLogout } = useAuth();
  const navigate = useNavigate();
  const locationInfo = useLocation();
  const { generalSettings } = useAuth();
  const [noMenu, setNoMenu] = useState(true);

  const getRouteVal = () => {
    return locationInfo.pathname &&
      locationInfo.pathname.split("/") &&
      locationInfo.pathname.split("/")[2]
      ? locationInfo.pathname.split("/")[2]
      : "";
  };

  const getMenuList = () => {
    let val = [];
    switch (userRoleName) {
      case ADMINROLE:
        val = menuList;
        break;

      case OPERATORROLE:
        val = operatorMenuList;
        break;

      case INSTALLERROLE:
        val = installerMenuList;
        break;

      default:
        val = userMenuList;
        break;
    }

    return val;
  };

  return (
    <>
      {noMenu && (
        <div className="wrapper_side_left">
          <div className="header">
            <img
              className="logo_info"
              src={
                generalSettings && generalSettings.logo_val
                  ? generalSettings?.logo_val
                  : "/assets/logo.png"
              }
              alt="logo"
            />
            <BsList
              onClick={() => {
                setMenuStatus(!menuStatus);
              }}
              className="menuBtn cursor"
            />
          </div>

          <div className={menuStatus ? "menuList active" : "menuList"}>
            {getMenuList() &&
              getMenuList().map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      if (item.value === "logout") {
                        authLogout();
                      } else {
                        setSelected(item.value);
                        navigate(item.path);
                        setMenuStatus(false);
                      }
                    }}
                    className={
                      selected === item.value || getRouteVal() === item.routeVal
                        ? "menuListItem active"
                        : "menuListItem"
                    }
                  >
                    {getT(item.name)}
                  </div>
                );
              })}
            <div className="menuListItem">
              &#169;{" "}
              {generalSettings && generalSettings.copyRight
                ? getT(translationKeys[generalSettings.copyRight])
                : getT(translationKeys.copyRightLabel)}{" "}
            </div>
          </div>
        </div>
      )}
      {/* <div className="backendMenuToggleBtn">
        {noMenu && (
          <FaAngleDoubleLeft
            className="cursor"
            onClick={() => setNoMenu(!noMenu)}
            fontSize={25}
          />
        )}
        {!noMenu && (
          <FaAngleDoubleRight
            className="cursor active"
            onClick={() => setNoMenu(!noMenu)}
            fontSize={25}
          />
        )}
      </div> */}
    </>
  );
}
