import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../authContext";
import { getT, translationKeys } from "./utils";
import { useNavigate } from "react-router-dom";
import paths from "../config/paths";
import CustomInput from "./forms/utils/CustomInput";

const UserLogin = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [page, setPage] = useState(true);

  const { authLogin } = useAuth();

  const LoginvalidationSchema = Yup.object().shape({
    email: Yup.string().required(getT(translationKeys.emailRequired)),
    password: Yup.string()
      .min(6)
      .required(getT(translationKeys.passwordRequired)),
  });

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(getT(translationKeys.firstnamerequired)),
    lastName: Yup.string().required(getT(translationKeys.lastnamerequired)),
    email: Yup.string().required(getT(translationKeys.emailRequired)),
    password: Yup.string()
      .min(6)
      .required(getT(translationKeys.passwordRequired)),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    if (page) {
      let finalValues = {
        email: values.email,
        password: values.password,
      };
      try {
        toast.loading(getT(translationKeys.pleaseWait), {
          position: "top-right",
        });
        const response = await axios.post(apiUrl + "/api/login", finalValues);
        if (response.status === 200 && response.data && response.data.token) {
          authLogin(response.data.token);
          navigate(paths.dashboardPath);
        } else {
          console.log(response.status, response);

          toast.error(response?.data?.message, {
            position: "top-right",
          });
        }
        toast.dismiss();
        setSubmitting(false);
      } catch (error) {
        toast.dismiss();
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message, {
            position: "top-right",
          });
        }
        setSubmitting(false);
      }
    } else {
      let finalValues = {
        name: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
      };
      try {
        const response = await axios.post(apiUrl + "/api/signup", finalValues);
        if (
          response.status === 201 &&
          response.data &&
          response.data.user &&
          response.data.user.id > 0 &&
          response.data.token
        ) {
          authLogin(response.data.token);
        } else {
          //console.log(response.status, response);
          toast.error(response?.data?.message, {
            position: "top-right",
          });
        }
        setSubmitting(false);
      } catch (error) {
        console.error("Error saving data:", error);

        if (error?.response?.data?.message) {
          toast.error(error.response.data.message, {
            position: "top-right",
          });
        }

        setSubmitting(false);
      }
    }
  };

  return (
    <div className="loginContainer">
      <Container>
        <h3>
          {page ? getT(translationKeys.login) : getT(translationKeys.signup)}
        </h3>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            password: "",
          }}
          validationSchema={!page ? validationSchema : LoginvalidationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, resetForm }) => (
            <Form>
              {!page && (
                <Row>
                  <Col>
                    <CustomInput
                      label={getT(translationKeys.firstName)}
                      defaultValue={values.firstName}
                      name="firstName"
                    />
                  </Col>
                  <Col>
                    <CustomInput
                      label={getT(translationKeys.lastName)}
                      defaultValue={values.lastName}
                      name="lastName"
                    />
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <CustomInput
                    label={getT(translationKeys.email)}
                    defaultValue={values.email}
                    name="email"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomInput
                    label={getT(translationKeys.password)}
                    defaultValue={values.password}
                    name="password"
                    type="password"
                  />
                </Col>
              </Row>
              {/*<Row>
                  <Col>
                    <div
                      onClick={() => {
                        setPage(!page);
                      }}
                      className="infoBtn"
                    >
                      {!page ? "login" : " create a account ?"}
                    </div>
                  </Col>
                </Row>*/}
              <Row>
                <Col>
                  <div className="mt-2">
                    <button type="submit" className="btn btn-success me-2">
                      {getT(translationKeys.send)}
                    </button>
                    <button
                      type="button"
                      onClick={() => resetForm()}
                      className="btn btn-secondary me-2"
                    >
                      {getT(translationKeys.cancel)}
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default UserLogin;
