import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import itLocale from "@fullcalendar/core/locales/it";
import WrapperModal from "../../components/ModalWrapper";

import { useEventQuery } from "../../queries/events";
import { useAuth } from "../../authContext";
import { CUSTOMERROLE, eventStatusList, getT } from "../utils";
import moment from "moment";

import { useUserQuery } from "../../queries/users";
import Loading from "../../components/Loading";
import EventForm from "../forms/EventForm";

function Calender() {
  const [events, setEvents] = useState([]);
  const [FilterEvents, setFilterEvents] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditMoal] = useState(false);
  const [editId, setEditId] = useState(null);
  const { userRoleName } = useAuth();
  const [usersData, setUsersData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(-1);
  const [selectedDate, setSelectedDate] = useState(null);
  const { data, isIdle, isLoading, isError, error } = useEventQuery();

  const {
    data: userData,
    isIdle: userIdle,
    isLoading: userLoading,
    isError: userError,
  } = useUserQuery();

  useEffect(() => {
    if (userData && !userLoading && userData.users) {
      setUsersData(userData?.users);
    }
  }, [userLoading, userData]);

  useEffect(() => {
    if (data && !isLoading && data.events) {
      let events = [];
      if (data.events.length > 0) {
        events = data.events.map((e) => {
          return {
            title: e?.name,
            start: new Date(e?.start_date).toISOString(),
            end: new Date(e?.end_date).toISOString(),
            id: e?.id,
            customData: {
              status: e?.status,
              from_user_id: e?.from_user_id,
              to_user_id: e?.to_user_id,
            },
          };
        });
      }
      setEvents(events);
    }
  }, [isLoading, data]);

  const handleCellClick = (arg) => {
    setSelectedDate(arg.date);
    if (userRoleName !== CUSTOMERROLE) {
      setShowModal(true);
      setEditMoal(false);
      setEditId(null);
    }
  };

  const handleEventClick = (arg) => {
    if (arg.event.id && arg.event.id > 0) {
      setShowModal(true);
      setEditMoal(true);
      setEditId(arg.event.id);
    }
  };

  const getColor = (type) => {
    let clr = "";
    eventStatusList.forEach((s) => {
      if (s.value === type) {
        clr = s.color;
      }
    });
    return clr;
  };

  const eventContent = (arg) => {
    // Customize the rendering of each event here
    const startTime = moment(arg.event.start).format("h:mm a");
    const endTime = moment(arg.event.end).format("h:mm a");

    console.log(endTime);

    let type = arg.event.extendedProps?.customData?.status;
    return (
      <div
        style={{
          backgroundColor: getColor(type),
          borderColor: getColor(type),
          borderRadius: "5px",
          padding: "5px",
        }}
      >
        {startTime + "-" + endTime + " (" + arg.event.title + ")"}
      </div>
    );
  };

  const getUsers = () => {
    let list = [];
    usersData.forEach((u) => {
      list.push({
        value: u.id,
        label: u?.name + " " + u?.lastName,
      });
    });
    return list;
  };

  const getFilterEvents = (val) => {
    var options = events;
    if (options && options.length > 0) {
      if (parseInt(val) > -1) {
        options = options.filter((s) => {
          return (
            parseInt(s.customData.to_user_id) === parseInt(val) ||
            parseInt(s.customData.from_user_id) === parseInt(val)
          );
        });
      }
    }
    return options;
  };

  if (isLoading || userLoading || userIdle) {
    return <Loading />;
  }

  return (
    <>
      <div className="calenderForm">
        <WrapperModal
          show={showModal}
          title={!editModal ? "New Appointment" : "Update Appointment"}
          handleClose={() => {
            setShowModal(!showModal);
          }}
        >
          <EventForm
            onEdit={editModal}
            editId={editId}
            givenDate={selectedDate}
            onClose={() => {
              setShowModal(false);
              setEditMoal(false);
              setEditId(null);
            }}
          />
        </WrapperModal>

        {userRoleName !== CUSTOMERROLE && (
          <div className="selectOptions">
            <select
              value={selectedUserId}
              className="form-select my-2"
              onChange={(event) => {
                setSelectedUserId(event.target.value);
              }}
            >
              <option value={-1} label={getT("users_label")} />
              {getUsers() &&
                getUsers().map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    label={option.label}
                  />
                ))}
            </select>
          </div>
        )}
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin]}
          initialView="dayGridMonth"
          weekends={true}
          events={getFilterEvents(selectedUserId)}
          eventContent={eventContent}
          dayCellDidMount={(arg) => {
            arg.el.addEventListener("click", () => handleCellClick(arg));
          }}
          locale={localStorage.getItem("lng") === "it" ? itLocale : ""}
          eventClick={handleEventClick}
        />
      </div>
    </>
  );
}

export default Calender;
