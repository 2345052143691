import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getT, translationKeys } from "../../admin/utils";
import MainWrapper from "../MainWrapper";
import { updateQuoteData } from "../../utils";
import Navigation from "../../components/Navigation";
import useScreenSize from "../../components/useScreenSize";

export default function QuoteSuccess() {
  const navigate = useNavigate();
  const isMobile = useScreenSize(768);

  const onCheckOut = async () => {
    let res = await updateQuoteData(
      {
        status: 4,
        payment_status: "paid",
      },
      localStorage.getItem("quotePaymentId")
    );

    if (res.status) {
      // do something
      localStorage.removeItem("quotePaymentId");
    } else {
      console.log("update failed");
    }
  };

  useEffect(() => {
    onCheckOut();
  }, []);

  return (
    <MainWrapper
      mainClass="dynamicPage"
      topBar={false}
      showFooter={isMobile ? false : true}
      showChatBtn={isMobile ? false : true}
    >
      <div className="infoWrapper">
        <p className="mt-5  d-flex justify-content-center">
          {getT(translationKeys.paymentSuccessMessage)}
        </p>
      </div>
      {isMobile && <Navigation />}
    </MainWrapper>
  );
}
