import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { calculateQueryString, getFromLocalStorage } from "../utils/helpers";
import { apiUrl } from "../config/paths";

/*** Queries **********************/

const _fetcher = (baseUrl, Id = null, query = {}) => {
  const uri = Id ? `/${Id}` : "";
  let queryStr = calculateQueryString(query);
  return axios
    .get(`${baseUrl}${uri}${queryStr}`, {
      headers: {
        Authorization: getFromLocalStorage("userToken"),
      },
    })
    .then((res) => {
      return res.data;
    });
};

export const useContactQuery = (
  Id = null,
  enabled = true,
  query = {},
  fetcher = _fetcher
) => {
  return useQuery({
    queryKey: ["contacts", Id, query],
    queryFn: () => fetcher(apiUrl + "/api/contact", Id, query),
    option: {
      refetchInterval: 100,
      enabled: enabled,
    },
  });
};

/* mutations */

const createContactMutator = (params) => {
  return axios
    .post(apiUrl + `/api/contact`, params, {
      headers: {
        Authorization: getFromLocalStorage("userToken"),
      },
    })
    .then((res) => res);
};

export const useCreateContactMutation = (mutator = createContactMutator) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => mutator(params),
    onSuccess: () => queryClient.invalidateQueries("contacts"),
  });
};

export const updateContactMutation = (params) => {
  const { id, data } = params;
  return axios
    .put(apiUrl + `/api/contact/${id}`, data, {
      headers: {
        Authorization: getFromLocalStorage("userToken"),
      },
    })
    .then((res) => res);
};

export const useUpdateContactMutation = (mutator = updateContactMutation) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => mutator(params),
    onSuccess: () => queryClient.invalidateQueries("contacts"),
  });
};
