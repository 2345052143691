import React from "react";
import MenuForm from "../forms/MenuForm";

export default function NewMenu() {
  return (
    <>
      <MenuForm />
    </>
  );
}
