import React, { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { BsPlusCircle } from "react-icons/bs";
import ComponentForm from "./ComponentForm";
import { componentOptions, getT } from "../utils";
import { toast } from "react-toastify";

export default function ComponentList({
  pageId = -1,
  list = [],
  onNewItem = () => {},
  onDeleteItem = () => {},
  handleDynamicChange = () => {},
  handleStatusChange = () => {},
  onCreateInnerItem = () => {},
  onDeleteInnerItem = () => {},
  onCloneItem = () => {},
}) {
  return (
    <>
      <Row>
        <Col>
          <select className="form-select" id="componentId">
            <option value={""}>{getT("choose_component_label")}</option>
            {componentOptions.map((c) => {
              return <option value={c.value}>{c.name}</option>;
            })}
          </select>
        </Col>
        <Col>
          <BsPlusCircle
            className="cursor mb-3"
            fontSize={35}
            onClick={() => {
              let ele = document.getElementById("componentId");
              if (ele.value !== "") {
                onNewItem();
              } else {
                toast.error("choose one component");
              }
            }}
          />
        </Col>
      </Row>
      {list && list.length > 0 && (
        <div className="componentList">
          {list &&
            list.map((c, index) => {
              return (
                <ComponentForm
                  info={c}
                  key={index}
                  index={index}
                  onDelete={onDeleteItem}
                  cid={c?.id}
                  handleStatusChange={handleStatusChange}
                  handleDynamicChange={handleDynamicChange}
                  onCreateInnerItem={onCreateInnerItem}
                  onDeleteInnerItem={onDeleteInnerItem}
                  onCloneItem={onCloneItem}
                />
              );
            })}
        </div>
      )}
    </>
  );
}
