import React from "react";
import { ErrorMessage, Field } from "formik";
import Select from "react-select";

const CustomSelect = ({
  label,
  isMulti = false,
  defaultValue,
  name,
  options,
  ...rest
}) => (
  <>
    <label className="form-label">{label}</label>
    <Field name={name} className="form-select">
      {({ field, form }) => (
        <Select
          {...field}
          {...rest}
          isMulti={isMulti}
          options={options}
          onChange={(selectedOption) =>
            form.setFieldValue(name, selectedOption)
          }
          value={defaultValue}
        />
      )}
    </Field>
    <ErrorMessage className="danger" name={name} component="div" />
  </>
);

export default CustomSelect;
