import React from "react";
import RoleForm from "../forms/RoleForm";

export default function NewRole() {
  return (
    <>
      <RoleForm />
    </>
  );
}
