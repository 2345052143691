import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import paths from "../../config/paths";
import { useDynamicFormQuery } from "../../queries/dynamicform";
import { getT } from "../utils";

export default function ViewDynamicForm() {
  const { id } = useParams();
  const [contact, setContact] = useState();
  const { data, isIdle, isLoading, isError, error } = useDynamicFormQuery(
    id,
    id ? true : false
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (data && !isLoading && data?.item) {
      setContact(data?.item);
    }
  }, [isLoading, data]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="p-4">
        <button
          onClick={() => {
            navigate(paths.dynamicFormsPath);
          }}
          className="btn btn-secondary "
        >
          {getT("back")}
        </button>
        <p>
          <b> {getT("information_label")}</b>
        </p>
        {contact?.content &&
          Object.keys(contact?.content).map((i) => {
            return <p>{i + " : " + contact.content[i]}</p>;
          })}
        <p>
          <b>{getT("source")}</b>
        </p>
        <p>{contact?.source}</p>
      </div>
    </>
  );
}
