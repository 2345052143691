import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";

import {
  useCategoryQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
} from "../../queries/categories";
import { getT, translationKeys } from "../utils";
import CustomInput from "./utils/CustomInput";

const CategoryForm = ({ onEdit = false, editId = null }) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useCategoryQuery(
    editId,
    editId ? true : false
  );

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdateCategoryMutation)
    : (mutationHook = useCreateCategoryMutation);
  const mutation = mutationHook();

  const newSchema = {
    name: Yup.string().required(getT(translationKeys.nameRequired)),
  };

  const formSchema = Yup.object().shape(newSchema);

  useEffect(() => {
    if (data && !isLoading && data?.item) {
      setEditData(data?.item);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.item &&
        mutation.data.data.item.id > 0
      ) {
        onEdit
          ? toast.success(getT(translationKeys.updated))
          : toast.success(getT(translationKeys.created));
        navigate(paths.categoriesPath);
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error("try again later");
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const finalData = {
      name: values.name,
      active: values.active,
      z_order: values.z_order,
    };

    toast.warning("please wait..");
    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    navigate(paths.categoriesPath);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">{getT(translationKeys.generalError)}</div>;
  }

  return (
    <Container fluid className="leadForm">
      <button
        onClick={() => {
          navigate(paths.categoriesPath);
        }}
        className="btn btn-secondary "
      >
        {getT(translationKeys.back)}
      </button>
      <h4 className="my-2">
        {" "}
        {onEdit ? getT(translationKeys.update) : getT(translationKeys.create)}
      </h4>
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              name: editData?.name ?? "",
              active: editData?.active ? true : false,
              z_order: editData?.z_order ?? 0,
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, formik }) => (
              <Form>
                <Row className="mt-2">
                  <Col md={3} className="mb-2">
                    <div className="d-flex">
                      <label className="me-2">
                        {getT(translationKeys.active)}
                      </label>
                      <Field
                        type="checkbox"
                        id="active"
                        className="form-check-input me-2"
                        name="active"
                      />
                      <label className="me-2">
                        {getT(translationKeys.yes)}{" "}
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={8}>
                    <CustomInput
                      //onEdit={onEdit}
                      defaultValue={values.name}
                      name="name"
                      label={getT(translationKeys.name)}
                    />
                  </Col>
                  <Col sm={12} md={4}>
                    <CustomInput
                      defaultValue={values.z_order}
                      name="z_order"
                      label={getT(translationKeys.z_order)}
                    />
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      {getT(translationKeys.submit)}
                    </button>
                    <button
                      type="button"
                      onClick={() => handleClear(formik)}
                      className="btn btn-secondary"
                    >
                      {getT(translationKeys.back)}
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default CategoryForm;
