import { ErrorMessage, Field, useFormikContext } from "formik";
import React, { useState } from "react";

export default function CustomInput({
  label = "title",
  name = "info_field",
  type = "text",
  placeholder = "",
  classList = "form-control",
  onEdit,
  labelStatus = true,
  fieldType = "",
  isIcon = false,
  extraIcon = false,
  iconData = <img src="/assets/mappin.png" alt="map" />,
  extranIconData = "",
  onAutoFieldSelected = () => {},
  ...rest
}) {
  const { setFieldValue, values } = useFormikContext();
  const [predictions, setPredictions] = useState([]);
  const [show, setShow] = useState(false);

  const getResults = () => {
    if (window.googleAutocomplete) {
      window.googleAutocomplete.getPlacePredictions(
        {
          input: document.getElementById("dynamic_search_input_val_auto").value,
          componentRestrictions: { country: "IT" },
          language: "it",
        },
        (results) => {
          if (results && results.length > 0) {
            setPredictions(results);
            setShow(true);
            let ele = document.getElementById("predictionsInfo");
            if (ele) {
              ele.scrollIntoView({
                behavior: "smooth",
                block: "start", // Scrolls so the element is at the top of the view
                inline: "nearest",
              });
            }
          } else {
            setPredictions([]);
            setShow(false);
          }
        }
      );
    }
  };

  const handleSelection = (p) => {
    let finalAddress = {
      lat: null,
      lng: null,
      city: null,
      address: null,
      province: null,
      region: null,
      zip: null,
      street_number: null,
      country: null,
      country_state: null,
      country_state_code: null,
    };
    if (p.place_id !== -1) {
      let request = {
        placeId: p.place_id,
      };
      window.googlePlacesService.getDetails(request, function (place, status) {
        // eslint-disable-next-line no-undef
        if (status === google.maps.places.PlacesServiceStatus.OK && !place) {
          return;
        }
        finalAddress.lat = place.geometry.location.lat();
        finalAddress.lng = place.geometry.location.lng();
        for (let i = 0; i < place.address_components.length; i++) {
          for (let j = 0; j < place.address_components[i].types.length; j++) {
            if (place.address_components[i].types[j] === "route") {
              // address
              finalAddress.address = place.name;
            } else if (
              place.address_components[i].types[j] ===
              "administrative_area_level_2"
            ) {
              // state
              finalAddress.country_state =
                place.address_components[i].long_name;
              finalAddress.country_state_code =
                place.address_components[i].short_name;
            } else if (
              place.address_components[i].types[j] ===
              "administrative_area_level_2"
            ) {
              // prov
              finalAddress.province = place.address_components[i].short_name;
            } else if (
              place.address_components[i].types[j] ===
              "administrative_area_level_1"
            ) {
              // prov
              finalAddress.region = place.address_components[i].short_name;
            } else if (
              place.address_components[i].types[j] === "sublocality_level_1"
            ) {
              // prov
              finalAddress.province = place.address_components[i].short_name;
            } else if (
              place.address_components[i].types[j] ===
              "administrative_area_level_3"
            ) {
              // city
              finalAddress.city = place.address_components[i].short_name;
            } else if (place.address_components[i].types[j] === "locality") {
              // city : locality,postal_town
              finalAddress.city = place.address_components[i].long_name;
            } else if (place.address_components[i].types[j] === "postal_town") {
              finalAddress.city = place.address_components[i].long_name;
            } else if (place.address_components[i].types[j] === "country") {
              finalAddress.country = place.address_components[i].long_name;
              finalAddress.country_code =
                place.address_components[i].short_name;
            } else if (place.address_components[i].types[j] === "postal_code") {
              finalAddress.zip = place.address_components[i].long_name;
            } else if (
              place.address_components[i].types[j] === "street_number"
            ) {
              finalAddress.street_number =
                place.address_components[i].long_name;
            }
          }
        }

        if (finalAddress && finalAddress.address) {
          //console.log(finalAddress);
          onAutoFieldSelected(finalAddress);
          setShow(false);
          setFieldValue(name, finalAddress.address);
          // set for others
          if (finalAddress && finalAddress.city) {
            setFieldValue("city", finalAddress.city);
          }
          if (finalAddress && finalAddress.zip) {
            setFieldValue("zip", finalAddress.zip);
          }
          /*if (finalAddress && finalAddress.province) {
            setFieldValue("prov", finalAddress.province);
          }
          if (finalAddress && finalAddress.region) {
            setFieldValue("prov", finalAddress.region);
          }*/
          if (finalAddress && finalAddress.country_state_code) {
            setFieldValue("prov", finalAddress.country_state_code);
          }
          if (finalAddress && finalAddress.country_state_code) {
            setFieldValue(
              "country_state_code",
              finalAddress.country_state_code
            );
          }
          if (finalAddress && finalAddress.country_state_code) {
            setFieldValue("province", finalAddress.country_state_code);
          }
          if (finalAddress && finalAddress.country_state_code) {
            setFieldValue("state", finalAddress.country_state_code);
          }
          if (finalAddress && finalAddress.lat) {
            setFieldValue("lat", finalAddress.lat);
          }
          if (finalAddress && finalAddress.lng) {
            setFieldValue("lng", finalAddress.lng);
          }
          if (finalAddress && finalAddress.zip) {
            setFieldValue("cap", finalAddress.cap);
          }
          if (finalAddress && finalAddress.country) {
            setFieldValue("country", finalAddress.country);
          }
        }
      });
    }
    setPredictions([]);
    //onSelection({});
  };

  return (
    <>
      {labelStatus && <label className="form-label b-title">{label}</label>}
      <div
        className={`auto_complete_inner ${fieldType} ${isIcon ? "icon" : ""} ${
          extraIcon ? "extraicon" : ""
        }`}
      >
        {isIcon && iconData}
        {extraIcon && extranIconData}
        <Field
          type={type}
          placeholder={placeholder}
          className={classList}
          disabled={onEdit}
          name={name}
          id={
            "dynamic_search_input_val_" +
            (fieldType === "auto" ? fieldType : name)
          }
          onChange={(e) => {
            setFieldValue(name, e.target.value);
            if (fieldType === "auto") {
              getResults();
            }
            if (!e.target.value) {
              setPredictions([]);
              setShow(false);
            }
          }}
          onKeyDown={(e) => {
            setFieldValue(name, e.target.value);
            if (fieldType === "auto") {
              getResults();
            }
          }}
          onBlur={(e) => {
            setFieldValue(name, e.target.value);
            if (fieldType === "auto") {
              getResults();
            }
            if (!e.target.value) {
              setPredictions([]);
              setShow(false);
            }
          }}
          {...rest}
        />

        {fieldType === "auto" && show && (
          <div
            className={
              predictions && predictions.length > 0
                ? "predictions"
                : "predictions"
            }
            id="predictionsInfo"
          >
            {predictions.map((prediction) => (
              <div
                className="prediction"
                onClick={() => handleSelection(prediction)}
                key={prediction.place_id}
              >
                {prediction.description}
              </div>
            ))}
          </div>
        )}

        <ErrorMessage className="danger" name={name} component="div" />
      </div>
    </>
  );
}
