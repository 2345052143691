import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useRef } from "react";
import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";
import { tmc_editor_key } from "../../../config/api";

export default function CustomEditor({ name, label = "" }) {
  const editorRef = useRef(null);
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    extraClassName,
    setFieldValue,
  } = useFormikContext();
  const handleEditorChange = (data) => {
    setFieldValue(name, data);
  };

  useEffect(() => {
    document.addEventListener("focusin", (e) => {
      if (
        e.target.closest(
          ".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root"
        ) !== null
      ) {
        e.stopImmediatePropagation();
      }
    });
  }, []);

  return (
    <>
      <label>{label}</label>
      <Editor
        apiKey={tmc_editor_key}
        ref={editorRef}
        value={values[name]}
        onEditorChange={handleEditorChange}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            //"code",
            "help",
            "wordcount",
            "codesample",
            "powerpaste",
          ],
          toolbar:
            "undo redo | blocks | " +
            "link code  bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      <Form.Control.Feedback type="invalid">
        {errors[name]}
      </Form.Control.Feedback>
    </>
  );
}
