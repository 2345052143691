import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { getT, translationKeys } from "../../admin/utils";
export default function QuoteStatusWidget({ editData = {} }) {
  const list = [
    {
      name: "quote_payment_label",
      value: "payment",
      percentage: "34",
      color: "#008cd6",
      col: "payment",
      colorLabel: "blue",
    },
    {
      name: "quote_administrative_label",
      value: "administation",
      percentage: "70",
      color: "#ff73ff",
      col: "gse",
      colorLabel: "pink",
    },
    {
      name: "quote_fotovoltaic_module_label",
      value: "ftv",
      percentage: "100",
      color: "#000000",
      col: "install",
      colorLabel: "black",
    },
    {
      name: "quote_inverter_label",
      value: "inverter",
      percentage: "50",
      color: "#ff5c26",
      col: "inverter_status",
      colorLabel: "orange",
    },
    {
      name: "quote_battery_label",
      value: "battery",
      percentage: "34",
      color: "#2db200",
      col: "battery_status",
      colorLabel: "green",
    },
    {
      name: "quote_col_label",
      value: "col",
      percentage: "100",
      color: "#e8e800",
      col: "charging_status",
      colorLabel: "yellow",
    },
    {
      name: "quote_certification_label",
      value: "certification",
      percentage: "0",
      col: "testing_status",
      color: "secondary",
    },
    {
      name: "quote_configuration_label",
      value: "configuration",
      percentage: "0",
      col: "final_status",
      color: "light",
    },
  ];

  const getPercent = (data) => {
    console.log(editData, editData[data]);
    let final = 0;
    let val = editData[data];
    if (val == 1) {
      final = 30;
    }
    if (val == 2) {
      final = 50;
    }
    if (val == 3) {
      final = 100;
    }
    return final;
  };

  return (
    <div className="progress_levels_container">
      <div className="title mb-2">
        {getT(translationKeys.quote_status_title)}
      </div>
      <div className="progress_levels">
        <div>{getT(translationKeys.quote_status_sub_title)}</div>
        {list.map((l) => {
          return (
            <div className="progress_level d-flex justify-content-between">
              <span className="me-2 progress_level_left">
                {getT(translationKeys[l.name])}
              </span>
              <span className="progress_level_right d-flex align-items-center">
                <ProgressBar
                  className={l.colorLabel}
                  now={getPercent(l.col)}
                  style={{ flex: 1 }}
                />
                <span>{`${getPercent(l.col)}%`}</span>
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
