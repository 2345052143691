import React, { useRef } from "react";
import { getFromLocalStorage } from "../utils/helpers";
import ReactCountryFlag from "react-country-flag";
import Navigation from "../components/Navigation";
import useScreenSize from "../components/useScreenSize";
import MainWrapper from "./MainWrapper";
import { getT, translationKeys } from "../admin/utils";
import { useAuth } from "../authContext";

export default function Preferences() {
  const isMobile = useScreenSize(768);
  const { isCustomerAuthenticated } = useAuth();
  return (
    <>
      <MainWrapper
        mainClass="dynamicPage quotes_page"
        showFooter={isMobile && isCustomerAuthenticated ? false : true}
        showChatBtn={isMobile ? false : true}
      >
        <div className="preferences_page infoWrapper">
          <h4>{getT(translationKeys.language)}</h4>
          <div className="lang_list_menu">
            <div
              className={
                getFromLocalStorage("lng") === "it"
                  ? "lang_list_menu_item active"
                  : "lang_list_menu_item"
              }
              onClick={() => {
                if (getFromLocalStorage("lng") === "it") {
                  localStorage.setItem("lng", "en");
                } else {
                  localStorage.setItem("lng", "it");
                }
                window.location.reload();
              }}
            >
              <ReactCountryFlag
                countryCode="IT"
                style={{
                  fontSize: "25px",
                  lineHeight: "25px",
                }}
                svg
              />
              <span>Italian</span>
            </div>
            <div
              className={
                getFromLocalStorage("lng") === "en"
                  ? "lang_list_menu_item active"
                  : "lang_list_menu_item"
              }
              onClick={() => {
                if (getFromLocalStorage("lng") === "it") {
                  localStorage.setItem("lng", "en");
                } else {
                  localStorage.setItem("lng", "it");
                }
                window.location.reload();
              }}
            >
              <ReactCountryFlag
                countryCode="US"
                style={{
                  fontSize: "25px",
                  lineHeight: "25px",
                }}
                svg
              />
              <span>English</span>
            </div>
          </div>
        </div>
        {isCustomerAuthenticated && <Navigation />}
      </MainWrapper>
    </>
  );
}
