import React, { useEffect, useState } from "react";
import { useFileQuery } from "../queries/files";
import Loading from "../components/Loading";
import { getFolderPath } from "../utils/api";
import { BsFileArrowUpFill } from "react-icons/bs";
import axios from "axios";
import { getFromLocalStorage } from "../utils/helpers";
import { apiUrl } from "../config/paths";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { getT, translationKeys } from "./utils";

export default function MediaPicker({ onSelected = () => {}, sFile = "" }) {
  const { data, isIdle, isLoading, isError, error } = useFileQuery();
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [file, setFile] = useState(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data && !isLoading && data.items) {
      setItems(data.items);
      data.items.forEach((e) => {
        if (getFolderPath + e.name === sFile) {
          setSelected(e.name);
        }
      });
      setFilteredData(data.items);
    }
    console.log(sFile, "test");
  }, [isLoading, data]);

  const updateItem = (f) => {
    if (selected === f.name) {
      setSelected(null);
      onSelected("");
    } else {
      setSelected(f.name);
      onSelected(getFolderPath + f?.name);
    }
  };

  const fileUpload = async () => {
    let formData = new FormData();
    formData.append("file", file);
    toast.info("please wait...");
    try {
      let response = {};
      response = await axios.post(apiUrl + "/api/fileUpload", formData, {
        headers: {
          Authorization: getFromLocalStorage("userToken"),
          "Content-Type": "multipart/form-data",
        },
      });
      toast.dismiss();
      if (response.data && response.data.file && response.data.file.id > 0) {
        toast.success("file uploaded");
        document.getElementById("file_info").value = "";
        queryClient.invalidateQueries("files");
      }
    } catch (error) {
      toast.dismiss();
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("try again later");
      }
    } finally {
    }
  };

  const onSearch = (val) => {
    var options = items;

    if (options && options.length > 0) {
      if (val !== "") {
        options = options.filter((s) => {
          let cString =
            s.original_name.toLowerCase() + "_" + s.name.toLowerCase();
          return cString.includes(val.toLowerCase());
        });
      }
    }

    setFilteredData(options);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="media_picker">
        <div className="media_picker_header">
          <input
            placeholder={getT(translationKeys.search)}
            onChange={(event) => {
              onSearch(event.target.value);
            }}
            type="text"
            className="form-control"
          />
          <div className="upload">
            <input
              type="file"
              id="file_info"
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
            />
            <div onClick={() => fileUpload()} className="btn btn-success">
              {getT("upload_label")} <BsFileArrowUpFill fontSize={15} />
            </div>
          </div>
        </div>
        <div className="media_picker_boby">
          <div className="media_items">
            {filteredData &&
              filteredData.map((f) => {
                return (
                  <div
                    className={
                      "media_item " + (selected === f.name ? "active" : "")
                    }
                    onClick={() => updateItem(f)}
                  >
                    <img src={getFolderPath + f?.name} alt="test" />
                    <p>{f?.original_name}</p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}
