// Home.js
import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

const SolarMap = () => {
  return (
    <>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          const decodedRes = jwtDecode(credentialResponse?.credential);
          console.log(decodedRes);
        }}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    </>
  );
};

export default SolarMap;
