import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { getCustomerToken } from "../../utils";
import Loading from "../../components/Loading";
import { apiUrl } from "../../config/paths";
import { eventStatusList, getT, translationKeys } from "../../admin/utils";
import moment from "moment";
import AppointmentModal from "./appointmentModal";
import AppointmentUpdateModal from "./appointmentUpdateModal";

export default function CalenderWidget({ userId = -1 }) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = getCustomerToken();
  const [showModal, setShowModal] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [editId, setEditId] = useState(null);

  const getEvents = () => {
    fetch(apiUrl + "/api/getAppointments/" + userId, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((res) => {
        if (res && res.items && res.items.length > 0) {
          let events = [];
          if (res.items.length > 0) {
            events = res.items.map((e) => {
              return {
                title: e?.name,
                start: new Date(e?.start_date).toISOString(),
                end: new Date(e?.end_date).toISOString(),
                id: e?.id,
                customData: {
                  status: e?.status,
                  from_user_id: e?.from_user_id,
                  to_user_id: e?.to_user_id,
                  event: e,
                },
              };
            });
          }
          setList(events);
        }
      })
      .catch((error) => {
        console.error("Error checking token status:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (userId && userId > 0) {
      if (token) {
        getEvents();
      }
    }
  }, [userId]);

  const getColor = (type) => {
    let clr = "";
    eventStatusList.forEach((s) => {
      if (s.value === type) {
        clr = s.color;
      }
    });
    return clr;
  };

  const handleEventClick = (arg) => {
    if (
      arg.event.id &&
      arg.event.id > 0 &&
      arg.event.extendedProps?.customData?.event
    ) {
      //console.log(arg.event.customData);
      setSelectedAppointment(arg.event.extendedProps?.customData?.event);
      setShowModal(true);
    }
  };

  const eventContent = (arg) => {
    // Customize the rendering of each event here
    const startTime = moment(arg.event.start).format("h:mm a");
    const endTime = moment(arg.event.end).format("h:mm a");

    //console.log(endTime);

    let type = arg.event.extendedProps?.customData?.status;
    return (
      <div
        className="calender_dot"
        style={{
          backgroundColor: getColor(type),
          borderColor: getColor(type),
          borderRadius: "5px",
          padding: "5px",
        }}
      ></div>
    );
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <AppointmentModal
        showModal={showModal}
        setShowModal={(val, extra = "") => {
          if (extra === "update") {
            setList([]);
            getEvents();
          }
          setShowModal(val);
        }}
        showEditModal={() => {
          setShowModal(false);
          setShowEditModal(true);
          setEditId(selectedAppointment.id);
        }}
        selectedAppointment={selectedAppointment}
      />
      <AppointmentUpdateModal
        showModal={showEditModal}
        setShowModal={(val, extra = "") => {
          if (extra === "update") {
            setList([]);
            getEvents();
          }
          setShowEditModal(val);
        }}
        editId={editId}
      />
      <div className="calender_widget">
        <h3 className="title mt-1">
          {getT(translationKeys.calender_appointment_label)}
        </h3>
        <div className="customCalender">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin]}
            initialView="dayGridMonth"
            weekends={true}
            events={list}
            eventContent={eventContent}
            dayCellDidMount={(arg) => {
              arg.el.addEventListener("click", () => {});
            }}
            eventClick={handleEventClick}
          />
        </div>
      </div>
    </>
  );
}
