import React from "react";

export default function HeaderComponent({ info = {} }) {
  return (
    <>
      <div className=" f_main_title">{info?.content?.title}</div>
      <div className="f_sub_title">{info?.content?.description}</div>
    </>
  );
}
