import React, { useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import App from "./App";
import paths, { googleApiKey } from "./config/paths";
import Leads from "./admin/Leads/Leads";
import PrivateRoute from "./PrivateRoute";
import UserLogin from "./admin/UserLogin";
import Quotes from "./admin/Quotes/Quotes";
import Dashboard from "./admin/Dashbord";
import NewLead from "./admin/Leads/NewLead";
import EditLead from "./admin/Leads/EditLead";
import EditQuote from "./admin/Quotes/EditQuote";
import NewQuote from "./admin/Quotes/NewQuote";
import EditClient from "./admin/Clients/EditClient";
import NewClient from "./admin/Clients/NewClient";
import Clients from "./admin/Clients/Clients";
import Roles from "./admin/Roles/Roles";
import NewRole from "./admin/Roles/NewRole";
import EditRole from "./admin/Roles/EditRole";
import Requests from "./admin/Requests/Requests";
import EditRequest from "./admin/Requests/EditRequest";
import { useAuth } from "./authContext";
import { useEffect } from "react";
import Me from "./admin/Me";
import Calender from "./admin/Calender/Calender";
import { CUSTOMERROLE, INSTALLERROLE, OPERATORROLE } from "./admin/utils";
import CalculatorForm from "./admin/forms/CalculatorForm";
import Settings from "./admin/Settings";
import Translations from "./admin/Translations/Translations";
import NewTranslation from "./admin/Translations/NewTranslation";
import EditTranslation from "./admin/Translations/EditTranslation";
import Tickets from "./admin/Tickets/Tickets";
import EditTicket from "./admin/Tickets/EditTicket";
import NewTicket from "./admin/Tickets/NewTicket";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import Gurantee from "./pages/Gurantee";
import Info from "./pages/Info";
import Pages from "./admin/Pages/Pages";
import NewPage from "./admin/Pages/NewPage";
import EditPage from "./admin/Pages/EditPage";
import PageInfo from "./pages/PageInfo";
import Contacts from "./admin/Contacts/Contacts";
import EditContact from "./admin/Contacts/EditContact";
import NewContact from "./admin/Contacts/NewContact";
import Menus from "./admin/Menu/Menus";
import EditMenu from "./admin/Menu/EditMenu";
import NewMenu from "./admin/Menu/NewMenu";
import ViewContact from "./admin/Contacts/ViewContact";
import Products from "./admin/products/Products";
import NewProduct from "./admin/products/NewProduct";
import EditProduct from "./admin/products/EditProduct";
import Orders from "./admin/Orders/Orders";
import NewOrder from "./admin/Orders/NewOrder";
import ViewOrder from "./admin/Orders/ViewOrder";
import EditOrder from "./admin/Orders/EditOrder";
import Shop from "./pages/ecom/Shop";
import Categories from "./admin/Category/Categories";
import NewCategory from "./admin/Category/NewCategory";
import EditCategory from "./admin/Category/EditCategory";
import ProductDetail from "./pages/ecom/ProductDetail";
import DynamicForms from "./admin/DynamicForms/DynamicForms";
import ViewDynamicForm from "./admin/DynamicForms/ViewDynamicForm";
import Profile from "./pages/Profile";
import MyTickets from "./pages/Tickets/MyTickets";
import MyTicketDetail from "./pages/Tickets/MyTicketDetail";
import MyTicketNew from "./pages/Tickets/MyTicketNew";
import MyQuotes from "./pages/Quotes/MyQuotes";
import Appointments from "./pages/Appointments/Appointments";
import MyOrders from "./pages/Orders/MyOrders";
import Success from "./pages/ecom/Success";
import Failed from "./pages/ecom/failed";
import ViewMyOders from "./pages/Orders/ViewMyOders";
import SolarMap from "./pages/solarMap";
import EmailTemplates from "./admin/EmailTemplates/EmailTemplates";
import NewEmailTemplate from "./admin/EmailTemplates/NewEmailTemplate";
import EditEmailTemplate from "./admin/EmailTemplates/EditEmailTemplate";
import ModuleSettings from "./admin/ModuleSettings";
import PublicRoute from "./PublicRoute";
import { Loader } from "@googlemaps/js-api-loader";

import Loading from "./components/Loading";
import DynamicSelects from "./admin/DynamicSelects/DynamicSelects";
import EditDynamicSelect from "./admin/DynamicSelects/EditDynamicSelect";
import NewDynamicSelect from "./admin/DynamicSelects/NewDynamicSelect";
import Notifications from "./admin/Notifications/Notifications";
import EditNotification from "./admin/Notifications/EditNotification";
import NewNotification from "./admin/Notifications/NewNotification";
import QuoteInfo from "./pages/QuoteInfo";
import SwitchApp from "./SwitchApp";
import QuoteSuccess from "./pages/Payment/QuoteSuccess";
import QuoteFailed from "./pages/Payment/QuoteFailed";
import Preferences from "./pages/Preferences";
import NotFound from "./pages/NotFound";

export default function Router() {
  const { userRoleName } = useAuth();
  const locationInfo = useLocation();
  const navigate = useNavigate();
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    if (userRoleName === OPERATORROLE) {
      if (locationInfo.pathname.indexOf("roles") !== -1) {
        navigate(paths.dashboardPath);
      }
    }

    if (userRoleName === CUSTOMERROLE) {
      if (locationInfo.pathname.indexOf("requests") === -1) {
        navigate(paths.requestsPath);
      }
      if (locationInfo.pathname.indexOf("calender") === -1) {
        navigate(paths.calenderPath);
      }
      if (locationInfo.pathname.indexOf("me") === -1) {
        navigate(paths.profilePath);
      }
    }

    if (userRoleName === INSTALLERROLE) {
      if (locationInfo.pathname.indexOf("calender") === -1) {
        navigate(paths.calenderPath);
      }
    }
  }, [userRoleName]);

  // google initiation
  useEffect(() => {
    const loader = new Loader({
      apiKey: googleApiKey,
      version: "weekly",
      libraries: ["places", "marker", "drawing", "geometry"], // Load the Places library
    });

    loader
      .load()
      .then(() => {
        window.googleAutocomplete =
          new window.google.maps.places.AutocompleteService();
        window.googlePlacesService =
          new window.google.maps.places.PlacesService(
            document.createElement("div")
          );
        setMapLoaded(true);
      })
      .catch((e) => {
        console.error("Error loading Google Maps API", e);
      });
  }, []);

  if (!mapLoaded) {
    return <Loading />;
  }

  return (
    <Routes>
      {/* customer routes */}
      <Route path={paths.homePath} exact element={<SwitchApp />} />
      <Route path={paths.userLoginPath} exact element={<UserLogin />} />
      <Route
        path={paths.shopPath}
        exact
        element={
          <PublicRoute>
            <Shop />
          </PublicRoute>
        }
      />
      <Route path={paths.shopDetailPath} exact element={<ProductDetail />} />
      <Route
        path={paths.profilePath}
        exact
        element={
          <PublicRoute>
            <Profile />
          </PublicRoute>
        }
      />
      <Route
        path={paths.myOrders}
        exact
        element={
          <PublicRoute>
            <MyOrders />
          </PublicRoute>
        }
      />
      <Route path={paths.successPath} exact element={<Success />} />
      <Route path={paths.failedPath} exact element={<Failed />} />
      <Route path={paths.myQuotesPath} exact element={<MyQuotes />} />
      <Route path={paths.quoteSuccessPath} exact element={<QuoteSuccess />} />
      <Route path={paths.quoteFailedPath} exact element={<QuoteFailed />} />
      <Route path={paths.quoteInfoPath} exact element={<QuoteInfo />} />
      {/* <Route path={paths.newMyTicketsPath} exact element={<MyTicketNew />} /> 
        <Route
        path={paths.editMyTicketsPath}
        exact
        element={<MyTicketDetail />}
      />
      */}
      <Route path={paths.myTicketsPath} exact element={<MyTickets />} />
      <Route path={paths.appointments} exact element={<Appointments />} />
      <Route path={paths.preferencesPath} exact element={<Preferences />} />
      <Route path={paths.viewMyOrderPath} exact element={<ViewMyOders />} />

      <Route path={paths.dynamicPagePath} element={<PageInfo />} />

      {/* admin routes  */}

      <Route
        path={paths.notificationsPath}
        exact
        element={
          <PrivateRoute>
            <Notifications />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editNotificationPath}
        exact
        element={
          <PrivateRoute>
            <EditNotification />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.newNotificationPath}
        exact
        element={
          <PrivateRoute>
            <NewNotification />
          </PrivateRoute>
        }
      />
      <Route
        path={paths.moduleSettingsPath}
        element={
          <PrivateRoute>
            <ModuleSettings />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.dynamicFormsPath}
        element={
          <PrivateRoute>
            <DynamicForms />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.dynamicFormDetailPath}
        element={
          <PrivateRoute>
            <ViewDynamicForm />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.dynamicSelectsPath}
        element={
          <PrivateRoute>
            <DynamicSelects />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.newDynamicSelectPath}
        element={
          <PrivateRoute>
            <NewDynamicSelect />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editDynamicSelectPath}
        element={
          <PrivateRoute>
            <EditDynamicSelect />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.categoriesPath}
        element={
          <PrivateRoute>
            <Categories />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.newCategoriesPath}
        element={
          <PrivateRoute>
            <NewCategory />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editcategoriesPath}
        element={
          <PrivateRoute>
            <EditCategory />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.emailTemplatesPath}
        element={
          <PrivateRoute>
            <EmailTemplates />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.newEmailTemplatePath}
        element={
          <PrivateRoute>
            <NewEmailTemplate />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editEmailTemplatePath}
        element={
          <PrivateRoute>
            <EditEmailTemplate />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.dashboardPath}
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.settingsPath}
        element={
          <PrivateRoute>
            <Settings onEdit={true} editId={1} />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.pagesPath}
        element={
          <PrivateRoute>
            <Pages />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.newPagePath}
        element={
          <PrivateRoute>
            <NewPage />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editPagePath}
        element={
          <PrivateRoute>
            <EditPage />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.menusPath}
        element={
          <PrivateRoute>
            <Menus />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.newMenuPath}
        element={
          <PrivateRoute>
            <NewMenu />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editMenuPath}
        element={
          <PrivateRoute>
            <EditMenu />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.contactsPath}
        element={
          <PrivateRoute>
            <Contacts />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.newContactPath}
        element={
          <PrivateRoute>
            <NewContact />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editContactPath}
        element={
          <PrivateRoute>
            <EditContact />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.viewContactPath}
        element={
          <PrivateRoute>
            <ViewContact />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.leadsPath}
        element={
          <PrivateRoute>
            <Leads />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.newLeadPath}
        element={
          <PrivateRoute>
            <NewLead />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editLeadPath}
        element={
          <PrivateRoute>
            <EditLead />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.translationsPath}
        element={
          <PrivateRoute>
            <Translations />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.newTranslationsPath}
        element={
          <PrivateRoute>
            <NewTranslation />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editTranslationsPath}
        element={
          <PrivateRoute>
            <EditTranslation />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.quotesPath}
        element={
          <PrivateRoute>
            <Quotes />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.newQuotePath}
        element={
          <PrivateRoute>
            <NewQuote />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editQuotePath}
        element={
          <PrivateRoute>
            <EditQuote />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.ticketsPath}
        element={
          <PrivateRoute>
            <Tickets />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.newTicketsPath}
        element={
          <PrivateRoute>
            <NewTicket />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editTicketsPath}
        element={
          <PrivateRoute>
            <EditTicket />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.clientsPath}
        element={
          <PrivateRoute>
            <Clients />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.newClientPath}
        element={
          <PrivateRoute>
            <NewClient />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editClientPath}
        element={
          <PrivateRoute>
            <EditClient />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.rolesPath}
        element={
          <PrivateRoute>
            <Roles />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.newRolePath}
        element={
          <PrivateRoute>
            <NewRole />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editRolePath}
        element={
          <PrivateRoute>
            <EditRole />
          </PrivateRoute>
        }
      />
      <Route
        path={paths.requestsPath}
        element={
          <PrivateRoute>
            <Requests />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editRequestPath}
        element={
          <PrivateRoute>
            <EditRequest />
          </PrivateRoute>
        }
      />

      {/* <Route
        path={paths.mePath}
        element={
          <PrivateRoute>
            <Me />
          </PrivateRoute>
        }
      /> */}

      <Route
        path={paths.calenderPath}
        element={
          <PrivateRoute>
            <Calender />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.productsPath}
        element={
          <PrivateRoute>
            <Products />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.newProductsPath}
        element={
          <PrivateRoute>
            <NewProduct />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editProductsPath}
        element={
          <PrivateRoute>
            <EditProduct />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.ordersPath}
        element={
          <PrivateRoute>
            <Orders />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.newOrdersPath}
        element={
          <PrivateRoute>
            <NewOrder />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editOrdersPath}
        element={
          <PrivateRoute>
            <EditOrder />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.viewOrderPath}
        element={
          <PrivateRoute>
            <ViewOrder />
          </PrivateRoute>
        }
      />
      {/* Catch-all route */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
