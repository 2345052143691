import React, { useEffect, useState } from "react";
import CustomListTable from "../../components/CustomListTable";
import { apiUrl } from "../../config/paths";
import { getCustomerToken } from "../../utils";
import Loading from "../../components/Loading";
import {
  eventStatusList,
  formatMysqlDate,
  getT,
  translationKeys,
} from "../../admin/utils";
import moment from "moment";
import AppointmentModal from "./appointmentModal";
import AppointmentUpdateModal from "./appointmentUpdateModal";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../authContext";

export default function Appointmentswidget({ userId = -1, isMobile = false }) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [editId, setEditId] = useState(null);
  const token = getCustomerToken();
  const locationInfo = useLocation();
  const queryParams = new URLSearchParams(locationInfo.search);
  const idParam = queryParams.get("appointment");
  const { appointmentModalStatus, setAppointmentModalStatus } = useAuth();

  const getColor = (type) => {
    let clr = "";
    eventStatusList.forEach((s) => {
      if (s.value === type) {
        clr = s.color;
      }
    });
    return clr;
  };

  const getStatusName = (type) => {
    let clr = "";
    eventStatusList.forEach((s) => {
      if (s.value === type) {
        clr = s.name;
      }
    });
    return clr;
  };

  const getEvents = () => {
    fetch(apiUrl + "/api/getAppointments/" + userId, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((res) => {
        if (res && res.items && res.items.length > 0) {
          setList(
            res.items.map((i) => {
              //i.start_date = formatMysqlDate(i.start_date);
              i.f_start_date = isMobile ? (
                <span
                  style={{
                    color: getColor(i?.status),
                  }}
                >
                  {formatMysqlDate(i.start_date)}
                </span>
              ) : (
                formatMysqlDate(i.start_date)
              );

              i.f_end_date = isMobile ? (
                <span
                  style={{
                    color: getColor(i?.status),
                  }}
                >
                  {formatMysqlDate(i.f_end_date)}
                </span>
              ) : (
                formatMysqlDate(i.f_end_date)
              );
              i["f_status"] = (
                <span
                  style={{
                    backgroundColor: getColor(i?.status),
                    color: "#fff",
                    padding: "5px",
                    minWidth: "20px",
                    minHeight: "20px",
                    height: isMobile ? "20px" : "auto",
                    borderRadius: "20px",
                  }}
                >
                  {getStatusName(i?.status)}
                </span>
              );
              i["hour"] = moment(i.start_date, "YYYY-MM-DD HH:mm:ss").format(
                "hh:mm a"
              );
              return i;
            })
          );
        }
      })
      .catch((error) => {
        console.error("Error checking token status:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (userId && userId > 0) {
      if (token) {
        getEvents();
      }
    }
  }, [userId]);

  useEffect(() => {
    if (idParam > 0 && appointmentModalStatus) {
      list.forEach((l) => {
        if (l.id == idParam) {
          setSelectedAppointment(l);
          setShowModal(true);
          setAppointmentModalStatus(false);
        }
      });
    }
  }, [idParam, list, appointmentModalStatus]);

  const getColumnsData = () => {
    return isMobile
      ? [
          { name: getT(translationKeys.appointment_table_id), value: "id" },
          {
            name: getT(translationKeys.appointment_table_date),
            value: "f_start_date",
          },

          {
            name: getT(translationKeys.appointment_table_hour),
            value: "hour",
          },
          {
            name: getT(translationKeys.appointment_table_title),
            value: "name",
            link: true,
            extraClass: "extra_link",
          },
        ]
      : [
          { name: getT(translationKeys.appointment_table_id), value: "id" },
          {
            name: getT(translationKeys.appointment_table_date),
            value: "f_start_date",
          },
          {
            name: getT(translationKeys.appointment_table_status),
            value: "f_status",
          },
          {
            name: getT(translationKeys.appointment_table_hour),
            value: "hour",
          },
          {
            name: getT(translationKeys.appointment_table_title),
            value: "name",
            link: true,
            extraClass: "extra_link",
          },
        ];
  };

  const getBottomLeftInfo = () => {
    return (
      <>
        <div
          className="helpIcons d-flex"
          style={{ fontSize: "12px", color: "grey" }}
        >
          {eventStatusList &&
            eventStatusList.map((event) => {
              return (
                <>
                  <div
                    style={{
                      backgroundColor: event.color,
                      color: "#fff",
                      padding: "5px",
                      borderRadius: "10px",
                    }}
                    className="helpIcon me-1"
                  >
                    {event.name}
                  </div>
                </>
              );
            })}
        </div>
      </>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <AppointmentModal
        showModal={showModal}
        setShowModal={(val, extra = "") => {
          if (extra === "update") {
            setList([]);
            getEvents();
          }
          setShowModal(val);
        }}
        showEditModal={() => {
          setShowModal(false);
          setShowEditModal(true);
          setEditId(selectedAppointment.id);
        }}
        selectedAppointment={selectedAppointment}
      />
      <AppointmentUpdateModal
        showModal={showEditModal}
        setShowModal={(val, extra = "") => {
          if (extra === "update") {
            setList([]);
            getEvents();
          }
          setShowEditModal(val);
        }}
        editId={editId}
      />
      <CustomListTable
        title={getT(translationKeys.appointment_table_header_title)}
        btnTitle="new ticket"
        addBtn={false}
        lengendOptions={getColumnsData()}
        listItems={list}
        bottomLeftData={getBottomLeftInfo()}
        onEdit={(data) => {
          //console.log(data);
          setSelectedAppointment(data);
          setShowModal(true);
        }}
      />
    </>
  );
}
