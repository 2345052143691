import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";
import { useAuth } from "../../authContext";
import { getT, translationKeys } from "../utils";
import CustomInput from "./utils/CustomInput";
import {
  useContactQuery,
  useCreateContactMutation,
  useUpdateContactMutation,
} from "../../queries/contacts";

const ContactForm = ({ onEdit = false, editId = null, position = "" }) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useContactQuery(
    editId,
    editId ? true : false
  );
  const { TData } = useAuth();
  const formikRef = useRef();

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdateContactMutation)
    : (mutationHook = useCreateContactMutation);
  const mutation = mutationHook();

  const formSchema = Yup.object().shape({
    name: Yup.string().required(getT(translationKeys.nameRequired)),
    surname: Yup.string().required(getT(translationKeys.surnameRequired)),
    email: Yup.string()
      .email(getT(translationKeys.emailError))
      .required(getT(translationKeys.emailRequired)),
    mobile: Yup.string().required(getT(translationKeys.mobileRequired)),
    add: Yup.string().required(getT(translationKeys.addressRequired)),
    zip: Yup.number().required(getT(translationKeys.zipRequired)),
    country: Yup.string().required(getT(translationKeys.countryRequired)),
    city: Yup.string().required(getT(translationKeys.cityRequired)),
    state: Yup.string().required(getT(translationKeys.stateRequired)),
  });

  useEffect(() => {
    if (data && !isLoading) {
      setEditData(data?.lead);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.lead &&
        mutation.data.data.lead.id > 0
      ) {
        if (position === "front") {
          handleClear();
          toast.success("soon we will contact you");
        } else {
          onEdit
            ? toast.success(getT(translationKeys.updated))
            : toast.success(getT(translationKeys.created));
          navigate(paths.contactsPath);
        }
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error(getT(translationKeys.generalError));
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    //console.log(values);
    const finalData = {
      email: values.email,
      name: values.name,
      surname: values.surname,
      mobile: values.mobile,
      address: values.add,
      city: values.city,
      zip: values.zip,
      country: values.country,
      prov: values.state,
      referal_type: values.referal_type,
      source: values.source,
    };

    toast.warning(getT(translationKeys.pleaseWait));

    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const fonteOptions = [
    { value: 1, label: "Facebook" },
    { value: 2, label: "Passaparola" },
    { value: 3, label: "Dipendente" },
    { value: 4, label: "Sito" },
  ];

  const handleClear = () => {
    const { current } = formikRef;
    if (current) {
      current.resetForm();
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">{getT(translationKeys.generalError)}</div>;
  }

  return (
    <Container fluid>
      {position === "" && (
        <button
          onClick={() => {
            navigate(paths.contactsPath);
          }}
          className="btn btn-secondary my-2"
        >
          {getT(translationKeys.back)}
        </button>
      )}
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              name: editData?.name ?? "",
              surname: editData?.surname ?? "",
              email: editData?.email ?? "",
              mobile: editData?.mobile ?? "",
              add: editData?.address ?? "",
              referal_type: editData?.referal_type ?? "",
              state: editData?.prov ?? "",
              city: editData?.city ?? "",
              country: editData?.country ?? "",
              zip: editData?.zip ?? "",
              source: window.location.href,
            }}
            innerRef={formikRef}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, formik, resetForm }) => (
              <Form>
                <Row>
                  <Col sm={12} md={6}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.name}
                      name="name"
                      label={getT(translationKeys.name)}
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.surname}
                      name="surname"
                      label={getT(translationKeys.surname)}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={12} md={6}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.email}
                      name="email"
                      label={getT(translationKeys.email)}
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.mobile}
                      name="mobile"
                      label={getT(translationKeys.phone)}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={12} md={4}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.add}
                      name="add"
                      label={getT(translationKeys.address)}
                    />
                  </Col>
                  <Col sm={12} md={4}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.country}
                      name="country"
                      label={getT(translationKeys.country)}
                    />
                  </Col>
                  <Col sm={12} md={4}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.zip}
                      name="zip"
                      label={getT(translationKeys.zip)}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={12} md={4}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.city}
                      name="city"
                      label={getT(translationKeys.city)}
                    />
                  </Col>
                  <Col sm={12} md={4}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.state}
                      name="state"
                      label={getT(translationKeys.state)}
                    />
                  </Col>
                  <Col sm={12} md={4}>
                    <label className="form-label" htmlFor="firstName">
                      {getT(translationKeys.source)}{" "}
                    </label>

                    <Field
                      as="select"
                      className="form-select"
                      placeholder={getT(translationKeys.source)}
                      id="referal_type"
                      name="referal_type"
                    >
                      {fonteOptions.map((option, index) => (
                        <option
                          key={index}
                          value={option.value}
                          label={option.label}
                        />
                      ))}
                      <ErrorMessage
                        className="danger"
                        name="referal_type"
                        component="div"
                      />
                    </Field>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      {getT(translationKeys.submit)}
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default ContactForm;
