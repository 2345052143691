import React, { useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import CustomInput from "../../admin/forms/utils/CustomInput";
import { getT, translationKeys } from "../../admin/utils";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useCreateDynamicFormMutation } from "../../queries/dynamicform";
import { toast } from "react-toastify";
export default function CustomFormComponent({ info = {} }) {
  const [list, setList] = useState([]);
  const formikRef = useRef();

  const mutation = useCreateDynamicFormMutation();

  useEffect(() => {
    setList(info?.content?.items);
    console.log(info);
  }, [info]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.item &&
        mutation.data.data.item.id > 0
      ) {
        handleClear();
        window.location.reload();
        toast.success("soon we will contact you");
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error(getT(translationKeys.generalError));
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleClear = () => {
    const { current } = formikRef;
    if (current && typeof current.resetForm === "function") {
      try {
        current.resetForm();
      } catch (error) {
        console.error("Error resetting form:", error);
      }
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    let items = list && list.map((l, index) => l?.input_type + index);
    let obj = {};
    for (const item of items) {
      obj[item] = values[item] ? values[item] : "";
    }

    let finalData = {
      content: obj,
      source: window.location.href,
    };

    mutation.mutate(finalData);
  };

  const getDynamicOptions = (val) => {
    let options = val && val.length > 0 ? val : [];
    console.log(options);
    return options;
  };

  const getInitialValues = () => {
    let items = list && list.map((l, index) => l?.input_type + index);
    const obj = {};
    for (const item of items) {
      obj[item] = "";
    }
    return obj;
  };

  var getFormSchema = () => {
    let obj = {};
    if (list && list.length > 0) {
      for (let index = 0; index < list.length; index++) {
        if (list[index]?.important) {
          obj[list[index]?.input_type + index] = Yup.string().required(
            list[index]?.message
              ? list[index]?.message
              : getT(translationKeys.required)
          );
        }
      }
    }
    console.log(obj);
    return Yup.object().shape(obj);
  };

  return (
    <>
      <div className="custom_form_wrapper">
        <Container>
          <Formik
            initialValues={getInitialValues()}
            innerRef={formikRef}
            validationSchema={getFormSchema()}
            onSubmit={handleSubmit}
          >
            {({
              values,
              setFieldValue,
              formik,
              resetForm,
              errors,
              touched,
            }) => (
              <Form>
                {list &&
                  list.map((l, index) => {
                    return (
                      <Row className="custom_form_type">
                        {l?.input_type === "checkbox" && (
                          <Col sm={12} md={12}>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                name={"checkbox" + index}
                                className="form-check-input"
                                onChange={(e) =>
                                  setFieldValue(
                                    "checkbox" + index,
                                    e.target.value
                                  )
                                }
                              />
                              <label className="form-check-label">
                                {l?.title}
                              </label>
                              {errors["checkbox" + index] && (
                                <div className="danger">
                                  {errors["checkbox" + index]}
                                </div>
                              )}
                            </div>
                          </Col>
                        )}
                        {l?.input_type === "text_input" && (
                          <Col sm={12} md={12}>
                            <label className="form-label">{l?.title}</label>
                            <input
                              placeholder={l?.title}
                              type="text"
                              onChange={(e) =>
                                setFieldValue(
                                  "text_input" + index,
                                  e.target.value
                                )
                              }
                              name={"text_input" + index}
                              className="form-control"
                            />
                            {errors["text_input" + index] && (
                              <div className="danger">
                                {errors["text_input" + index]}
                              </div>
                            )}
                          </Col>
                        )}
                        {l?.input_type === "radio" &&
                          getDynamicOptions(l?.options_titles).length > 0 && (
                            <Col>
                              {getDynamicOptions(l?.options_titles).map(
                                (t, dIndex) => {
                                  return (
                                    <div className="form-check">
                                      <input
                                        name={"radio" + index}
                                        type="radio"
                                        value={
                                          getDynamicOptions(l?.options_values)[
                                            dIndex
                                          ].label
                                        }
                                        onChange={(e) =>
                                          setFieldValue(
                                            "radio" + index,
                                            e.target.value
                                          )
                                        }
                                        className="form-check-input"
                                      />
                                      <label className="form-check-label">
                                        {t?.label}
                                      </label>
                                    </div>
                                  );
                                }
                              )}
                              {errors["radio" + index] && (
                                <div className="danger">
                                  {errors["radio" + index]}
                                </div>
                              )}
                            </Col>
                          )}
                        {l?.input_type === "select" &&
                          getDynamicOptions(l?.options_titles).length > 0 && (
                            <>
                              <Col>
                                <label className="form-label">{l?.title}</label>
                                <select
                                  aria-label="Default select example"
                                  className="form-select"
                                  name={"select" + index}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "select" + index,
                                      e.target.value
                                    )
                                  }
                                >
                                  {getDynamicOptions(l?.options_titles).map(
                                    (t, sIndex) => {
                                      return (
                                        <option
                                          value={
                                            getDynamicOptions(
                                              l?.options_values
                                            )[sIndex].label
                                          }
                                        >
                                          {t.label}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                                {errors["select" + index] && (
                                  <div className="danger">
                                    {errors["select" + index]}
                                  </div>
                                )}
                              </Col>
                            </>
                          )}
                        {l?.input_type === "text_area" && (
                          <Col>
                            <label className="form-label">{l?.title}</label>
                            <textarea
                              name={"text_area" + index}
                              rows="3"
                              onChange={(e) =>
                                setFieldValue(
                                  "text_area" + index,
                                  e.target.value
                                )
                              }
                              className="form-control"
                            ></textarea>
                            {errors["text_area" + index] && (
                              <div className="danger">
                                {errors["text_area" + index]}
                              </div>
                            )}
                          </Col>
                        )}
                        {l?.input_type === "date" && (
                          <Col sm={12} md={12}>
                            <label className="form-label">{l?.title}</label>
                            <input
                              placeholder={l?.title}
                              type="date"
                              name={"date" + index}
                              onChange={(e) =>
                                setFieldValue("date" + index, e.target.value)
                              }
                              className="form-control"
                            />
                            {errors["date" + index] && (
                              <div className="danger">
                                {errors["date" + index]}
                              </div>
                            )}
                          </Col>
                        )}
                        {l?.input_type === "date_time" && (
                          <Col sm={12} md={12}>
                            <label className="form-label">{l?.title}</label>
                            <input
                              placeholder={l?.title}
                              type="datetime-local"
                              name={"date_time" + index}
                              onChange={(e) =>
                                setFieldValue(
                                  "date_time" + index,
                                  e.target.value
                                )
                              }
                              className="form-control"
                            />
                            {errors["date_time" + index] && (
                              <div className="danger">
                                {errors["date_time" + index]}
                              </div>
                            )}
                          </Col>
                        )}
                      </Row>
                    );
                  })}
                <Row className="mt-2">
                  <Col>
                    <button type="submit" className="btn btn-success me-2">
                      submit
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </div>
    </>
  );
}
