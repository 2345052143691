import React from "react";
import { apiUrl } from "../../config/paths";
import { updateOrderData } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { getFolderPath } from "../../utils/api";
import { currencyType, getT } from "../../admin/utils";

export default function Product({
  info = {},
  onAdd = () => {},
  itemExists = () => {},
  onQuanityChange = () => {},
  order = {},
  selectedCats = [],
}) {
  const navigate = useNavigate();

  const getImageInfo = () => {
    let final = "";
    if (
      info?.infos &&
      info?.infos.length > 0 &&
      info?.infos[0]?.type === "gallery"
    ) {
      final = getFolderPath + info?.infos[0]?.file?.name;
    }
    return final;
  };

  const getDefaultVariation = (id) => {
    let val = false;
    if (order && order.variations && order.variations.length > 0) {
      order.variations.forEach((v) => {
        if (v.product_option_id === id) {
          val = true;
        }
      });
    }
    return val;
  };

  const onVariationChange = async (id, productId) => {
    /*let ids = [];

    if (order && order.variations && order.variations.length > 0) {
      order.variations.forEach((v) => {
        
      });
    }

    let res = await updateOrderData({
      product_id: productId,
      variation_ids: ids,
    });

    if (res.status) {
      // do something
    } else {
      console.log("update failed");
    }*/
  };

  const checkValuesExist = (arr1, arr2) => {
    for (let i = 0; i < arr2.length; i++) {
      if (arr1.includes(arr2[i])) {
        return true;
      }
    }
    return false;
  };

  const getProdStatus = () => {
    if (selectedCats && selectedCats.length > 0) {
      if (info.categoryIds && info.categoryIds.length != null) {
        return checkValuesExist(info.categoryIds, selectedCats);
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <div className={"e_prod_list_item " + (getProdStatus() ? "show" : "hide")}>
      <img
        onClick={() => {
          navigate("/shop/" + info?.permalink);
        }}
        className="cursor"
        src={getImageInfo()}
        alt=""
      />
      {/* <div className="category_list">
        {info?.categories &&
          info?.categories.map((c) => {
            return (
              <div className="category_list_item">
                <div>{c?.name}</div>
                <select
                  onChange={(e) => {
                    //setDynamicVal(e.target.value, c.name);
                    onVariationChange(e.target.value, info.id);
                  }}
                >
                  <option value={-1}>choose</option>
                  {c?.variations &&
                    c.variations.map((v) => {
                      return (
                        <option
                          selected={getDefaultVariation(v.id)}
                          value={v.id}
                        >
                          {v.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            );
          })}
        </div> */}
      <div className="subInfo">
        <input
          type="number"
          style={{
            width: "auto",
            border: "1px solid #000",
            color: "#000",
            opacity: 0,
          }}
          defaultValue={1}
          onChange={(event) => {
            onQuanityChange(event.target.value, info);
          }}
          id={"product_" + info.id}
          className="form-control quantity"
        />
        <div
          onClick={() => {
            navigate("/shop/" + info?.permalink);
          }}
          className="title cursor"
        >
          {info?.name}
        </div>
        <div className="priceInfo">
          <span className="priceInfoInner">
            <span className="fullPrice">{info?.price + currencyType}</span>
            <span className="price">{info?.fullprice + currencyType}</span>
          </span>
          <span>
            <span
              onClick={() => {
                navigate("/shop/" + info?.permalink);
              }}
              className="btn btn-success cursor mx-2"
            >
              {getT("view")}
            </span>
            <span
              className="btn btn-danger cursor"
              onClick={() => {
                onAdd(info);
              }}
            >
              {itemExists(info) ? getT("remove") : getT("add")}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}
