import { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

export default function GalleryComponent({ info = {} }) {
  const [list, setList] = useState([]);
  const [index, setIndex] = useState(0);
  const [type, setType] = useState("grid");
  const [open, setOpen] = useState(false);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    setList(info?.content?.items);
    setType(info?.content?.contentType);
  }, [info]);

  const getImageList = () => {
    let items = list;
    let finalItems =
      items && items.length > 0
        ? items.map((i) => {
            return { src: i?.image };
          })
        : [];
    //console.log(finalItems);
    return finalItems;
  };

  return (
    <>
      {type === "slider" && (
        <Carousel activeIndex={index} onSelect={handleSelect} controls={true}>
          {list &&
            list.map((l, index) => {
              return (
                <Carousel.Item key={index}>
                  <img src={l.image} alt={l?.title ? l.title : l?.image} />
                </Carousel.Item>
              );
            })}
        </Carousel>
      )}

      {type === "line" && (
        <div className="exployeeList">
          {list &&
            list.map((l, index) => {
              return (
                <div class="exployeeListInner">
                  <img src={l.image} alt={l?.title ? l.title : l?.image} />
                  <p>{l?.title}</p>
                </div>
              );
            })}
        </div>
      )}

      {type === "grid" && (
        <div className="g_list">
          {list &&
            list.map((l, index) => {
              return (
                <div class="g_list_item">
                  <img src={l.image} alt={l?.title ? l.title : l?.image} />
                  <p>{l?.title}</p>
                </div>
              );
            })}
        </div>
      )}

      {type === "gallery" && (
        <div class="g_list_light_box">
          {list &&
            list.map((l, index) => {
              return (
                <div class="g_list_light_box_item">
                  <img
                    onClick={() => setOpen(true)}
                    src={l.image}
                    alt={l?.title ? l.title : l?.image}
                  />
                </div>
              );
            })}
        </div>
      )}

      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={getImageList()}
      />
    </>
  );
}
