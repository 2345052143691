import React, { useEffect, useRef, useState } from "react";
import Location from "./Location";
import { getT, translationKeys } from "../admin/utils";
import { FaRotate } from "react-icons/fa6";
import { BsDashLg, BsFillGridFill, BsPlusLg } from "react-icons/bs";
import CustomOverLay from "../components/CustomOverLay";
import { geocodeLatLng } from "../utils/helpers";
import { Button } from "react-bootstrap";
import { BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";
import Swal from "sweetalert2";
import { useAuth } from "../authContext";

export default function MobileMap({
  zoom = 18,
  onAddChange = () => {},
  onCenterChanged = () => {},
  onMarkerDragged = () => {},
  onNext = () => {},
  loc = {},
  setLoc = () => {},
  setAdd = () => {},
  address = {},
}) {
  var rotation = 0;
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const { isCustomerAuthenticated } = useAuth();

  const getGeoDetails = (lat, lng) => {
    geocodeLatLng({
      lat: lat,
      lng: lng,
    })
      .then((resData) => {
        if (resData && resData.address) {
          //console.log(resData, "success");
          setLoc({
            lat: lat,
            lng: lng,
          });
        }
      })
      .catch((errorData) => {
        //onLocChange(errorData);
      });
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // setting current location
          // get the human redable address
          getGeoDetails(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.log("User denied the request for Geolocation.");
              break;
            case error.POSITION_UNAVAILABLE:
              console.log("Location information is unavailable.");
              break;
            case error.TIMEOUT:
              console.log("The request to get user location timed out.");
              break;
            case error.UNKNOWN_ERROR:
              console.log("An unknown error occurred.");
              break;
            default:
              console.log("An unknown error occurred.");
          }
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    /* if (!loc) {
      Swal.fire({
        title: getT(translationKeys.do_like_to_share_your_location_label),
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: getT(translationKeys.yes),
        cancelButtonText: getT(translationKeys.cancel),
      }).then((result) => {
        if (result.isConfirmed) {
          if (window.google.maps) {
            getLocation();
          }
        }
      });
    }*/
  }, []);

  useEffect(() => {
    if (loc && loc.lat && loc.lng) {
      // get the human redable address
      //getGeoDetails(loc.lat, loc.lng);
    }
  }, [loc]);

  useEffect(() => {
    if (window.google && window.google.maps && loc && loc.lat && loc.lng) {
      initMap();
    }
  }, [loc, window.google]);

  const initMap = async () => {
    const myLatLng = loc;
    // Request needed libraries.
    const { Map } = await window.google.maps.importLibrary("maps");
    const { AdvancedMarkerElement } = await window.google.maps.importLibrary(
      "marker"
    );

    const map = new Map(document.getElementById("customMap"), {
      center: myLatLng,
      minZoom: 15,
      tilt: 0,
      zoom: zoom ? parseInt(zoom) : 20,
      disableDefaultUI: true,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      mapId: "b9ce3553b919f3bc",
      //isFractionalZoomEnabled: true,
    });

    map.setMapTypeId("satellite");
    map.setHeading(0);

    /* const canvas = document.createElement("canvas");
    let gl = canvas.getContext("webgl2");

    gl
      ? console.log("WebGL 2 is supported")
      : console.log("WebGL 2 is NOT supported");*/

    mapRef.current = map;

    const icon = {
      url: "/assets/pin_google.png",
      // eslint-disable-next-line no-undef
      //scaledSize: new google.maps.Size(30, 30),
    };

    /*const marker = new AdvancedMarkerElement({
      map,
      gmpDraggable: true,
      icon: icon,
      position: myLatLng,
    });*/

    let marker = new window.google.maps.Marker({
      position: myLatLng,
      map,
      draggable: true,
      customName: "myMarkerInfo",
      icon: icon,
      title: "",
    });

    let infoWindow = new window.google.maps.InfoWindow({
      content: getT(translationKeys.markerLabelContent),
    });

    infoWindow.open(map, marker);

    // Add event listeners for mouseover and mouseout to show/hide the InfoWindow
    marker.addListener("mouseover", () => {
      infoWindow.open(map, marker);
    });

    window.google.maps.event.addListener(map, "center_changed", function () {
      saveMapState(map);
    });

    function saveMapState(map) {
      let currentCenter = map.getCenter().toJSON();
      //onCenterChanged(currentCenter);
    }
    // for move marker event
    window.google.maps.event.addListener(marker, "dragend", function (event) {
      let currentZoom = map.getZoom();
      infoWindow.close();
      infoWindow.open(map, marker);
      /* onMarkerDragged({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
        zoom: currentZoom.toString(),
      });*/
      //getGeoDetails(event.latLng.lat(), event.latLng.lng());
      setLoc({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
        zoom: currentZoom.toString(),
      });
    });
  };

  const changeTilt = () => {
    //let val = map.getTilt() + 45;
    if (mapRef.current.getTilt() === 45) {
      mapRef.current.setTilt(0);
    } else {
      mapRef.current.setTilt(45);
    }
  };

  const increaseZoom = () => {
    mapRef.current.setZoom(mapRef.current.getZoom() + 1);
  };

  const decreaseZoom = () => {
    mapRef.current.setZoom(mapRef.current.getZoom() - 1);
  };

  const changeHeading = () => {
    let val = rotation + 45;

    if (mapRef.current) {
      console.log(mapRef.current.getHeading());
      //mapRef.current.setHeading(val);
      mapRef.current.setHeading((mapRef.current.getHeading() + 45) % 360);
      rotation = val;
    }
  };

  return (
    <>
      <div
        className={
          (loc && loc.lat && loc.lng ? "mobileMap " : "mobileMap noLoc ") +
          (isCustomerAuthenticated ? "logged" : "")
        }
        style={{ backgroundImage: 'url("/assets/background.png")' }}
      >
        <div className="mobileMap_mask">
          {!isUpdated && (
            <>
              {!loc && (
                <div className="location_outer">
                  <div className="b-title">
                    {getT(translationKeys.locationBigTitle)} <br />{" "}
                    <span>{getT(translationKeys.locationBigTitle2)}</span>
                  </div>
                  <div className="xs-title">
                    {getT(translationKeys.locationSmallTitle)}
                  </div>
                </div>
              )}
              <Location
                value={address?.address}
                onSelection={(data) => {
                  //console.log(data);
                  //console.log(data);

                  if (data.lat && data.lng) {
                    setIsUpdated(true);
                    setAdd(data);
                    setTimeout(() => {
                      setIsUpdated(false);
                    }, 10);
                  }
                }}
                position={"second"}
                type={"small"}
              />
            </>
          )}
          {loc && loc.lat && loc.lng && (
            <>
              <div className="rotationBtn">
                {/* <CustomOverLay
            placement="left"
            message={getT(translationKeys.rotate_info_label)}
            iconElement={
              <FaRotate
                fontSize={25}
                cursor={"pointer"}
                onClick={() => changeHeading()}
              />
            }
          /> */}

                {/* <BsFillGridFill
          fontSize={25}
          cursor={"pointer"}
          onClick={() => changeTilt()}
          color="white"
  /> */}
                <CustomOverLay
                  placement="left"
                  message={getT(translationKeys.plus_zoom_info_label)}
                  iconElement={
                    <BsPlusLg
                      cursor={"pointer"}
                      onClick={() => increaseZoom()}
                    />
                  }
                ></CustomOverLay>
                <CustomOverLay
                  placement="left"
                  message={getT(translationKeys.minus_zoom_info_label)}
                  iconElement={
                    <BsDashLg
                      cursor={"pointer"}
                      onClick={() => decreaseZoom()}
                    />
                  }
                ></CustomOverLay>
                <div onClick={onNext}>
                  <BsChevronDoubleRight />
                </div>
              </div>
              <div id="customMap"></div>{" "}
            </>
          )}
        </div>
      </div>
    </>
  );
}
