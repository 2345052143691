export const googleApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const apiUrl = process.env.REACT_APP_API_URL;
export const stripePublishableUrl = process.env.REACT_APP_Publishable_key;
export const appType = process.env.REACT_APP_TYPE;
export const auth0domain = process.env.REACT_APP_AUTH_DOMAIN;
export const auth0clientid = process.env.REACT_APP_AUTH_CLIENTID;

export const siteUrl = process.env.REACT_APP_URL;

export const homePath = "/";
//admin
export const adminWord = "/adminside";
export const userLoginPath = adminWord + "/login";
export const dashboardPath = adminWord + "/dashboard";

export const ordersPath = adminWord + "/orders";
export const newOrdersPath = adminWord + "/orders/new";
export const editOrdersPath = adminWord + "/orders/edit/:id";
export const viewOrderPath = adminWord + "/orders/view/:id";

export const productsPath = adminWord + "/products";
export const newProductsPath = adminWord + "/products/new";
export const editProductsPath = adminWord + "/products/edit/:id";

export const categoriesPath = adminWord + "/categories";
export const newCategoriesPath = adminWord + "/categories/new";
export const editcategoriesPath = adminWord + "/categories/edit/:id";

export const pagesPath = adminWord + "/pages";
export const newPagePath = adminWord + "/pages/new";
export const editPagePath = adminWord + "/pages/edit/:id";

export const leadsPath = adminWord + "/leads";
export const newLeadPath = adminWord + "/leads/new";
export const editLeadPath = adminWord + "/leads/edit/:id";

export const quotesPath = adminWord + "/preventivi";
export const newQuotePath = adminWord + "/preventivi/new";
export const editQuotePath = adminWord + "/preventivi/edit/:id";

export const calenderPath = adminWord + "/calender";

export const clientsPath = adminWord + "/clienti";
export const newClientPath = adminWord + "/clienti/new";
export const editClientPath = adminWord + "/clienti/edit/:id";

export const settingsPath = adminWord + "/settings";
export const moduleSettingsPath = adminWord + "/modeuleSettings";

export const translationsPath = adminWord + "/translations";
export const newTranslationsPath = adminWord + "/translations/new";
export const editTranslationsPath = adminWord + "/translations/edit/:id";

export const ticketsPath = adminWord + "/tickets";
export const newTicketsPath = adminWord + "/tickets/new";
export const editTicketsPath = adminWord + "/tickets/edit/:id";

export const emailTemplatesPath = adminWord + "/emailTemplates";
export const newEmailTemplatePath = adminWord + "/emailTemplates/new";
export const editEmailTemplatePath = adminWord + "/emailTemplates/edit/:id";

export const contactsPath = adminWord + "/contacts";
export const newContactPath = adminWord + "/contacts/new";
export const editContactPath = adminWord + "/contacts/edit/:id";
export const viewContactPath = adminWord + "/contacts/view/:id";

export const menusPath = adminWord + "/menus";
export const newMenuPath = adminWord + "/menus/new";
export const editMenuPath = adminWord + "/menus/edit/:id";

export const dynamicFormsPath = adminWord + "/dynamicForms";
export const dynamicFormDetailPath = adminWord + "/dynamicForms/view/:id";

export const rolesPath = adminWord + "/roles";
export const newRolePath = adminWord + "/roles/new";
export const editRolePath = adminWord + "/roles/edit/:id";

export const notificationsPath = adminWord + "/notifications";
export const newNotificationPath = adminWord + "/notifications/new";
export const editNotificationPath = adminWord + "/notifications/edit/:id";

export const dynamicSelectsPath = adminWord + "/dynamicSelects";
export const newDynamicSelectPath = adminWord + "/dynamicSelects/new";
export const editDynamicSelectPath = adminWord + "/dynamicSelects/edit/:id";

export const requestsPath = adminWord + "/requests";
export const editRequestPath = adminWord + "/requests/edit/:id";

//extras
export const calculatorPath = "/calculator";
export const aboutPath = "/about";
export const plantsPath = "/plants";
export const gsePath = "/gse";
export const privacyPath = "/privacy";
export const contactPath = "/contact";
export const galleryPath = "/gallery";
export const faqPath = "/faq";
export const garanteePath = "/garantee";
export const infoPath = "/info";
export const solarMap = "/solarMap";

// customer
export const profilePath = "/profile";
export const myTicketsPath = "/myTickets";
export const newMyTicketsPath = "/myTickets/new";
export const editMyTicketsPath = "/myTickets/edit/:id";
export const appointments = "/appointments";
export const myQuotesPath = "/quotes";
export const quoteSuccessPath = "/quoteInfo/success";
export const quoteFailedPath = "/quoteInfo/failed";
export const quoteInfoPath = "/quoteInfo/:id";
export const preferencesPath = "/preferences";

//customer extra
export const mePath = "/me";
export const shopPath = "/shop";
export const shopDetailPath = "/shop/:id";
export const successPath = "/shop/success";
export const failedPath = "/shop/failed";
export const myOrders = "/myOrders";
export const viewMyOrderPath = "/myOrders/view/:id";
export const dynamicPagePath = "/:lang/:name";

const paths = {
  homePath,
  leadsPath,
  newLeadPath,
  editLeadPath,
  userLoginPath,
  quotesPath,
  newQuotePath,
  editQuotePath,
  clientsPath,
  newClientPath,
  editClientPath,
  dashboardPath,
  rolesPath,
  newRolePath,
  editRolePath,
  requestsPath,
  editRequestPath,
  mePath,
  calenderPath,
  calculatorPath,
  settingsPath,
  aboutPath,
  plantsPath,
  gsePath,
  contactPath,
  privacyPath,
  translationsPath,
  newTranslationsPath,
  editTranslationsPath,
  ticketsPath,
  newTicketsPath,
  editTicketsPath,
  galleryPath,
  faqPath,
  garanteePath,
  infoPath,
  pagesPath,
  newPagePath,
  editPagePath,
  dynamicPagePath,
  contactsPath,
  newContactPath,
  editContactPath,
  viewContactPath,
  menusPath,
  newMenuPath,
  editMenuPath,
  productsPath,
  newProductsPath,
  editProductsPath,
  ordersPath,
  newOrdersPath,
  editOrdersPath,
  viewOrderPath,
  categoriesPath,
  newCategoriesPath,
  editcategoriesPath,
  shopPath,
  shopDetailPath,
  dynamicFormsPath,
  dynamicFormDetailPath,
  profilePath,
  myTicketsPath,
  newMyTicketsPath,
  editMyTicketsPath,
  myQuotesPath,
  appointments,
  myOrders,
  successPath,
  failedPath,
  viewMyOrderPath,
  solarMap,
  emailTemplatesPath,
  newEmailTemplatePath,
  editEmailTemplatePath,
  moduleSettingsPath,
  dynamicSelectsPath,
  newDynamicSelectPath,
  editDynamicSelectPath,
  notificationsPath,
  newNotificationPath,
  editNotificationPath,
  quoteInfoPath,
  quoteSuccessPath,
  quoteFailedPath,
  preferencesPath,
};

export default paths;
