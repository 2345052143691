import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
export default function AccordionComponent({ info = {} }) {
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(info?.content?.items);
  }, [info]);

  return (
    list &&
    list.map((l) => {
      return (
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>{l?.title}</Accordion.Header>
            <Accordion.Body>{l?.des}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      );
    })
  );
}
