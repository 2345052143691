import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";
import {
  useCreateRoleMutation,
  useRoleQuery,
  useUpdateRoleMutation,
} from "../../queries/roles";

const RoleForm = ({ onEdit = false, editId = null }) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useRoleQuery(
    editId,
    editId ? true : false
  );

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdateRoleMutation)
    : (mutationHook = useCreateRoleMutation);
  const mutation = mutationHook();

  const newSchema = {
    name: Yup.string().required("Nome is required"),
    description: Yup.string().required("description is required"),
  };

  const editSchema = {
    description: Yup.string().required("description is required"),
  };

  const formSchema = Yup.object().shape(onEdit ? editSchema : newSchema);

  useEffect(() => {
    if (data && !isLoading) {
      setEditData(data?.role);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.role &&
        mutation.data.data.role.id > 0
      ) {
        onEdit ? toast.success("updated") : toast.success("created");
        navigate(paths.rolesPath);
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error("try again later");
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const finalData = {
      name: values.name,
      description: values.description,
      status: values.status,
    };
    if (onEdit) {
      delete finalData.name;
    }
    toast.warning("please wait..");
    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    navigate(paths.rolesPath);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">something went wrong try again later</div>;
  }

  return (
    <Container fluid className="leadForm">
      <button
        onClick={() => {
          navigate(paths.rolesPath);
        }}
        className="btn btn-secondary "
      >
        back
      </button>
      <h4 className="my-2">{onEdit ? "Update" : "Create"} Role</h4>
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              name: editData?.name ?? "",
              description: editData?.description ?? "",
              status: editData?.status && editData.status ? true : false,
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form>
                {/* <Row className="mt-2">
                  <Col md={3} className="mb-2">
                    <div className="d-flex">
                      <label className="me-2">status</label>
                      <Field
                        type="checkbox"
                        id="status"
                        className="form-check-input me-2"
                        name="status"
                      />
                      <label className="me-2">Si </label>
                    </div>
                  </Col>
            </Row> */}
                <Row>
                  <Col sm={12} md={6}>
                    <label className="form-label" htmlFor="firstName">
                      Name:
                    </label>

                    <Field
                      type="text"
                      id="name"
                      placeholder="Nome"
                      className="form-control"
                      disabled={onEdit}
                      name="name"
                    />
                    <ErrorMessage
                      className="danger"
                      name="name"
                      component="div"
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <label className="form-label" htmlFor="firstName">
                      Description:
                    </label>

                    <Field
                      type="text"
                      id="description"
                      placeholder="Description"
                      className="form-control"
                      name="description"
                    />
                    <ErrorMessage
                      className="danger"
                      name="description"
                      component="div"
                    />
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      submit
                    </button>
                    <button
                      type="button"
                      onClick={() => handleClear(formik)}
                      className="btn btn-secondary"
                    >
                      back
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default RoleForm;
