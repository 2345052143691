import React, { useEffect, useState } from "react";
import {
  useSettingsQuery,
  useUpdateSettingMutation,
} from "../queries/Settings";
import Loading from "../components/Loading";
import { toast } from "react-toastify";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Col from "react-bootstrap/esm/Col";
import * as Yup from "yup";
import { getT, moduleOptions, translationKeys } from "./utils";
import { appointments } from "../config/paths";

export default function ModuleSettings() {
  const [editData, setEditData] = useState();
  const editId = 1;
  const { data, isIdle, isLoading, isError, error } = useSettingsQuery(
    editId,
    editId ? true : false
  );

  const mutation = useUpdateSettingMutation();

  const formSchema = Yup.object().shape({});

  useEffect(() => {
    if (data && !isLoading) {
      setEditData(data?.settings);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.settings &&
        mutation.data.data.settings.id > 0
      ) {
        toast.success("updated");
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error("try again later");
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const finalData = {
      modules: {
        quotes: values.quotes,
        appointments: values.appointments,
        orders: values.orders,
        profile: values.profile,
        tickets: values.tickets,
        shop: values.shop,
      },
    };

    try {
      mutation.mutate({ id: editData.id, data: finalData });
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    //navigate(paths.rolesPath);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <div className="EventForm">something went wrong try again later</div>
    );
  }

  return (
    <>
      <Container fluid>
        <div
          style={{
            margin: "10px",
            padding: "10px",
            backgroundColor: "#efefef",
          }}
        >
          {editData ? (
            <Formik
              initialValues={{
                quotes: editData.modules?.quotes
                  ? editData.modules?.quotes
                  : false,
                appointments: editData.modules?.appointments
                  ? editData.modules?.appointments
                  : false,
                orders: editData.modules?.orders
                  ? editData.modules?.orders
                  : false,
                profile: editData.modules?.profile
                  ? editData.modules?.profile
                  : false,
                tickets: editData.modules?.tickets
                  ? editData.tickets?.quotes
                  : false,
                shop: editData.modules?.shop ? editData.modules?.shop : false,
              }}
              validationSchema={formSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, isSubmitting }) => (
                <Form>
                  <Row>
                    <Col>
                      <h2>{getT("module_settings_label")}</h2>
                    </Col>
                  </Row>
                  <Row>
                    {moduleOptions() &&
                      moduleOptions().map((m) => {
                        return (
                          <>
                            <Col md={4} className="mb-2">
                              <div className="d-flex">
                                <Field
                                  type="checkbox"
                                  className="form-check-input me-2"
                                  name={m.value}
                                />
                                <label className="me-2">{m.name}</label>
                              </div>
                            </Col>
                          </>
                        );
                      })}
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="btn btn-success me-2"
                      >
                        {getT(translationKeys.submit)}
                      </button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          ) : (
            <Loading />
          )}
        </div>
      </Container>
    </>
  );
}
