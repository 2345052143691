import React, { useEffect, useRef, useState } from "react";
import OpenAI from "openai";
import { BsArrowDownCircle, BsChat } from "react-icons/bs";
import Loading from "../components/Loading";
import Swal from "sweetalert2";
import { apiUrl } from "../config/paths";
import axios from "axios";
import { getFromLocalStorage } from "../utils/helpers";
import { chat_gpt_assistant_key, chat_gpt_key } from "../config/api";
import { toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import { IoMdSend } from "react-icons/io";
import { BsThreeDots } from "react-icons/bs";
import { VscChromeMinimize } from "react-icons/vsc";
import { getT, translationKeys } from "../admin/utils";

export default function AiChatBox() {
  const [msgs, setMsgs] = useState([]);
  const [status, setStatus] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const divRef = useRef(null);
  const [threadId, setThreadId] = useState(localStorage.getItem("thread_id"));
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [afterCursor, setAfterCursor] = useState(null); // Cursor for fetching next messages
  const chatContainerRef = useRef(null);
  const [showScrollDownButton, setShowScrollDownButton] = useState(false);

  const openai = new OpenAI({
    apiKey: chat_gpt_key,
    dangerouslyAllowBrowser: true,
  });

  const createAssistant = async () => {
    const assistant = await openai.beta.assistants.create({
      name: "AI-TEST",
      instructions:
        "You are a customer support chatbot. Use your knowledge base to best respond to customer queries.",
      tools: [{ type: "retrieval" }],
      model: "gpt-4-turbo-preview",
    });
  };

  useEffect(() => {
    if (!threadId) {
      createThread();
    }
  }, [threadId]);

  const getAssistant = async () => {
    return await openai.beta.assistants.retrieve(chat_gpt_assistant_key);
  };

  const createThread = async () => {
    setStatus(true);
    try {
      const newThread = await openai.beta.threads.create();

      if (newThread.id) {
        try {
          const response = await axios.post(apiUrl + `/api/chatbox`, {
            thread_id: newThread.id,
            status: "active",
          });
          if (
            response.data &&
            response.data.item &&
            response.data.item.id > 1
          ) {
            localStorage.setItem("thread_id", newThread.id);
            setThreadId(newThread.id);
            setMsgs([]);
            setAfterCursor(null);
          }
        } catch (error) {
          console.error("Error", error.message);
        }
      }
      setStatus(false);
    } catch (error) {
      setStatus(false);
    }
  };

  const retrieveThread = async () => {
    const myThread = await openai.beta.threads.retrieve(threadId);
  };

  const addMessageToThread = async (
    msg = "I need to solve the equation `3x + 11 = 14`. Can you help me?"
  ) => {
    setStatus(true);
    try {
      const myThread = await openai.beta.threads.messages.create(threadId, {
        role: "user",
        content: msg,
      });
      setHasMoreMessages(true);
    } catch (error) {
      setStatus(false);
    }
  };

  const getThreadStatus = async (id) => {
    try {
      const runStatus = await openai.beta.threads.runs.retrieve(threadId, id);
      if (runStatus && runStatus.status === "completed") {
        setStatus(false);
        setHasMoreMessages(true);
      } else {
        setStatus(true);
        getThreadStatus(id);
      }
    } catch (error) {
      setStatus(false);
    }
  };

  const runThread = async () => {
    setStatus(true);
    try {
      const run = await openai.beta.threads.runs.create(threadId, {
        assistant_id: chat_gpt_assistant_key,
      });
      if (run && run.id) {
        getThreadStatus(run.id);
      }
    } catch (error) {
      setStatus(false);
    }
  };

  const getMessages = async () => {
    setStatus(true);
    try {
      const response = await openai.beta.threads.messages.list(threadId, {
        limit: 20,
        order: "asc",
        after: afterCursor,
      });

      const newMessages = response.data;

      if (newMessages && newMessages.length === 0) {
        // If no new messages were returned, there are no more messages to fetch
        setHasMoreMessages(false);
        scrollToBottom();
      } else {
        // Append new messages to existing messages
        setMsgs((prevMessages) => [...prevMessages, ...newMessages]);
        // Update cursor for fetching next messages
        setAfterCursor(response.body.last_id);
        scrollToBottom();
      }

      setStatus(false);
    } catch (error) {
      console.error("Error fetching messages:", error);
      setStatus(false);
    }
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
    console.log(" aiom ");
  };

  useEffect(() => {
    if (showChat) {
      getMessages();
    }
  }, [showChat, afterCursor, hasMoreMessages]);

  const closeStartThread = async () => {
    setStatus(true);
    try {
      if (threadId) {
        try {
          const response = await axios.post(
            apiUrl + `/api/chatbox/close/` + threadId,
            {
              thread_id: threadId,
              status: "closed",
            },
            {
              headers: {
                Authorization: getFromLocalStorage("userToken"),
              },
            }
          );
          if (
            response.data &&
            response.data.item &&
            response.data.item.id > 1
          ) {
            const updatedThread = await openai.beta.threads.update(threadId, {
              metadata: { closed: "true" },
            });
            console.log("test goes here");
            localStorage.removeItem("thread_id");
            setMsgs([]);
            setAfterCursor(null);
            createThread();
          }
        } catch (error) {
          console.error("Error", error.message);
        }
      }

      setStatus(false);
    } catch (error) {
      setStatus(false);
    }
  };

  return (
    <>
      <div className="no-print">
        {!showChat && (
          <div className={"chatBtn " + (showChat ? "active" : "")}>
            <span className="chatBtnIcon">
              <BsChat
                fontSize={40}
                onClick={() => {
                  setShowChat(!showChat);
                  setTimeout(() => {
                    scrollToBottom();
                  }, 10);
                }}
              />
            </span>
          </div>
        )}

        {showChat && (
          <div ref={divRef} className="chatBox">
            <>
              <div className="header_item">
                <span
                  onClick={() => {
                    setShowChat((prev) => {
                      prev = !showChat;
                    });
                  }}
                >
                  {getT(translationKeys.chat_title)}
                </span>
                <span className="d-flex">
                  <VscChromeMinimize
                    onClick={() => {
                      setShowChat((prev) => {
                        prev = !showChat;
                      });
                    }}
                    fontSize={25}
                    className="me-2"
                  />
                  <IoMdClose
                    fontSize={25}
                    onClick={() => {
                      Swal.fire({
                        title: getT(
                          translationKeys.are_you_sure_to_close_chat_label
                        ),
                        text: "",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: getT(translationKeys.yes),
                        cancelButtonText: getT(translationKeys.cancel),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          closeStartThread();
                        }
                      });
                    }}
                  />
                </span>
              </div>
              <div className="chatMessages" ref={chatContainerRef}>
                {msgs.map((msg) => {
                  return (
                    <div className={"chatMessage " + msg.role} key={msg.id}>
                      <div className="chatMessage_inner">
                        <span className="role">{msg.role + ":"}</span>
                        <span>
                          {msg.content &&
                            msg.content.map((c) => {
                              return (
                                <div>
                                  {c.text && c.text.value ? c.text.value : ""}
                                </div>
                              );
                            })}
                        </span>
                      </div>
                    </div>
                  );
                })}
                {status ? (
                  <div className="loader">
                    <Loading />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="msg_actions d-flex">
                <textarea className="form-control" id="msgInfo"></textarea>
                {status ? (
                  <button className="btn btn-success">
                    <BsThreeDots fontSize={25} />
                  </button>
                ) : (
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      let msg = document.getElementById("msgInfo").value;
                      if (msg !== "") {
                        addMessageToThread(msg);
                        runThread();
                      } else {
                        toast.error(getT(translationKeys.fill_message_label), {
                          position: "top-right",
                        });
                      }

                      document.getElementById("msgInfo").value = "";
                    }}
                  >
                    <IoMdSend fontSize={25} />
                  </button>
                )}
              </div>
            </>
          </div>
        )}
      </div>
    </>
  );
}
