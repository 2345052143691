import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ProductForm from "../forms/ProductForm";
import Nav from "react-bootstrap/Nav";
import { getT, translationKeys } from "../utils";
import ProductVariations from "./ProductVariations";
import ProductInfo from "./ProductInfo";
import ProductCategory from "./ProductCategory";

export default function EditProduct() {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(1);
  const handleSelect = (eventKey) => {
    setActiveTab(parseInt(eventKey));
  };

  return (
    <>
      <Nav
        variant="tabs"
        className="m-2"
        activeKey={activeTab}
        onSelect={handleSelect}
        defaultActiveKey={1}
      >
        <Nav.Item>
          <Nav.Link eventKey={1}>
            {getT(translationKeys.productinfotablabel)}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={2}>
            {getT(translationKeys.productcategorylabel)}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={3}>
            {getT(translationKeys.productvariationstablabel)}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={4}>
            {getT(translationKeys.productparallaxtablabel)}
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {activeTab === 1 && <ProductForm onEdit editId={id} />}
      {activeTab === 2 && <ProductCategory pId={id} />}
      {activeTab === 3 && <ProductVariations pId={id} />}
      {activeTab === 4 && <ProductInfo pId={id} />}
    </>
  );
}
