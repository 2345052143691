import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths, { apiUrl } from "../../config/paths";
import Loading from "../../components/Loading";
import {
  useCreatePageMutation,
  usePagesQuery,
  useUpdatePageMutation,
} from "../../queries/pages";
import { getT, translationKeys } from "../utils";
import CustomInput from "./utils/CustomInput";
import CustomTextArea from "./utils/CustomTextArea";
import Accordion from "react-bootstrap/Accordion";
import { BsPlusCircle } from "react-icons/bs";
import ComponentForm from "./ComponentForm";
import ComponentList from "./ComponentList";
import axios from "axios";
import CustomSelect2 from "./utils/CustomSelect2";
import { useQueryClient } from "@tanstack/react-query";

const PageForm = ({ onEdit = false, editId = null }) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = usePagesQuery(
    editId,
    editId ? true : false
  );

  const [pages, setPages] = useState([]);
  const {
    data: pagesData,
    isIdle: pagesIdle,
    isLoading: pagesLoading,
    isError: pagesError,
  } = usePagesQuery(null, true);

  const queryClient = useQueryClient();

  const [componentList, setComponentList] = useState([]);

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdatePageMutation)
    : (mutationHook = useCreatePageMutation);
  const mutation = mutationHook();

  const newSchema = {
    name: Yup.string().required(getT(translationKeys.nameRequired)),
    /*description: Yup.string().required(
      getT(translationKeys.descriptionRequired)
    ),*/
  };

  const editSchema = {
    /*description: Yup.string().required(
      getT(translationKeys.descriptionRequired)
    ),*/
  };

  const formSchema = Yup.object().shape(onEdit ? editSchema : newSchema);

  useEffect(() => {
    if (data && !isLoading && data.item) {
      setEditData(data?.item);
      setComponentList(data?.item?.components);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (pagesData && !pagesLoading && pagesData?.items) {
      setPages(pagesData?.items.filter((p) => p.parent_id === null));
    }
  }, [pagesLoading, pagesData]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.item &&
        mutation.data.data.item.id > 0
      ) {
        onEdit ? toast.success("updated") : toast.success("created");
        navigate(paths.pagesPath);
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error("try again later");
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const finalData = {
      name: values.name,
      description: values.description,
      parent_id: values.parent_id,
      status: values.status,
      components: componentList,
      lang: values.lang,
      permalink: values.permalink,
      size: values.size,
      size_type: values.size_type,
    };
    /* if (onEdit) {
      delete finalData.name;
    }*/

    //console.log(values);
    toast.warning("please wait..");
    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    navigate(paths.pagesPath);
  };

  const onNewItem = () => {
    let selectedEle = document.getElementById("componentId");
    let data = {
      type: selectedEle.value,
      content: {
        description: "",
        items: [],
        contentPosition: "left",
        contentType: "",
        title: "",
      },
      status: true,
      z_order: componentList.length + 1,
    };
    // eslint-disable-next-line default-case
    switch (selectedEle.value) {
      case "imagewithtext":
        data.content = {
          description: "",
          image: "",
          contentPosition: "left",
          imagePosition: "left",
          contentType: "",
          title: "",
        };
        break;
      case "gallery":
        data.content = {
          description: "",
          items: [],
          contentPosition: "left",
          contentType: "",
          title: "",
        };
        break;

      case "accordion":
        data.content = {
          description: "",
          items: [],
          contentPosition: "left",
          contentType: "",
          title: "",
        };
        break;

      case "form":
        data.content = {
          description: "",
          contentPosition: "left",
          contentType: "",
          title: "",
          subTitle: "",
          type: "",
        };
        break;

      case "customform":
        data.content = {
          description: "",
          contentPosition: "left",
          contentType: "",
          title: "",
          subTitle: "",
          type: "",
          items: [],
        };
        break;
    }
    const newList = [...componentList, data];
    setComponentList(newList);
  };

  const onDeleteItem = async (index, id) => {
    if (id && id > 1) {
      try {
        const response = await axios.delete(apiUrl + `/api/component/${id}`);
        if (response.data && response.data.status) {
          const newList = [...componentList];
          newList.splice(index, 1);
          setComponentList(newList);
        }
      } catch (error) {
        console.error("Error posting data:", error.message);
      }
    } else {
      const newList = [...componentList];
      newList.splice(index, 1);
      setComponentList(newList);
    }
  };

  const onCloneItem = async (index, id) => {
    if (id && id > 1) {
      try {
        const response = await axios.post(
          apiUrl + `/api/component/clone/${id}`
        );
        if (response.data && response.data.item && response.data.item.id > 1) {
          queryClient.invalidateQueries("pages");
        }
      } catch (error) {
        console.error("Error posting data:", error.message);
      }
    }
  };

  const handleDynamicChange = (index, key, value, innerIndex = -1) => {
    if (innerIndex >= 0) {
      const newList = [...componentList];
      newList[index]["content"].items[innerIndex][key] = value;
      setComponentList(newList);
      console.log(newList);
    } else {
      const newList = [...componentList];
      newList[index]["content"][key] = value;
      setComponentList(newList);
    }
  };

  const handleStatusChange = (index, key, value) => {
    const newList = [...componentList];
    newList[index][key] = value;
    setComponentList(newList);
  };

  const onCreateInnerItem = (index) => {
    const newList = [...componentList];
    newList[index].content.items.push({
      image: "",
      title: "",
      des: "",
    });
    setComponentList(newList);
  };

  const onDeleteInnerItem = (index, innnerIndex) => {
    const newList = [...componentList];
    newList[index].content.items.splice(innnerIndex, 1);
    setComponentList(newList);
  };

  const getLangTypes = () => {
    return [
      {
        name: "Italian",
        value: "it",
      },
      {
        name: "English",
        value: "en",
      },
    ];
  };

  const getGridSizeTypes = () => {
    return [
      {
        name: "full",
        value: "full",
      },
      {
        name: "max-width",
        value: "pixels",
      },
    ];
  };

  const getPages = () => {
    let items = [];
    pages.forEach((p) => {
      if (p.parent_id == null) {
        items.push({
          name: p.name,
          value: p.id,
        });
      }
    });
    return items;
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">something went wrong try again later</div>;
  }

  return (
    <Container fluid className="leadForm">
      <button
        onClick={() => {
          navigate(paths.pagesPath);
        }}
        className="btn btn-secondary "
      >
        {getT("back")}
      </button>
      <button
        onClick={() => {
          window.open(
            "#/" + editData?.lang + "/" + editData?.permalink,
            "_blank"
          );
        }}
        className="btn btn-success mx-2"
      >
        {getT("preview_label")}
      </button>
      <h4 className="my-2">
        {" "}
        {onEdit ? getT(translationKeys.update) : getT(translationKeys.create)}
      </h4>
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              name: editData?.name ?? "",
              description: editData?.description ?? "",
              status: editData?.status && editData.status ? true : false,
              components: editData?.components,
              lang: editData?.lang,
              permalink: editData?.permalink,
              size: editData?.size,
              size_type: editData?.size_type,
              parent_id: editData?.parent_id,
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, formik }) => (
              <Form>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      {getT("page_section_information_label")}
                    </Accordion.Header>
                    <Accordion.Body>
                      <Row className="mt-2">
                        <Col md={3} className="mb-2">
                          <div className="d-flex">
                            <label className="me-2">
                              {getT("page_status_label")}
                            </label>
                            <Field
                              type="checkbox"
                              id="status"
                              className="form-check-input me-2"
                              name="status"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={12}>
                          <CustomSelect2
                            label={getT("parent_page_label")}
                            name="parent_id"
                            options={getPages()}
                            defaultValue={values.parent_id}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={12}>
                          <CustomInput
                            //onEdit={onEdit}
                            defaultValue={values.name}
                            name="name"
                            label={getT("page_name_label")}
                          />
                        </Col>
                        <Col sm={12} md={12}>
                          <CustomInput
                            //onEdit={onEdit}
                            defaultValue={values.permalink}
                            name="permalink"
                            label={getT("page_permalink_label")}
                          />
                        </Col>

                        <Col md={4}>
                          <CustomSelect2
                            label={getT("choose_language_label")}
                            name="lang"
                            options={getLangTypes()}
                            defaultValue={values.lang}
                          />
                        </Col>
                        <Col md={4}>
                          <CustomSelect2
                            label={getT("choose_layout_type_label")}
                            name="size_type"
                            options={getGridSizeTypes()}
                            defaultValue={values.size_type}
                          />
                        </Col>
                        {values.size_type === "pixels" && (
                          <Col md={4}>
                            <CustomInput
                              defaultValue={values.size}
                              name="size"
                              label={getT("page_maximum_size_label")}
                            />
                          </Col>
                        )}
                        <Col sm={12} md={12}>
                          <CustomTextArea
                            name={"description"}
                            label={getT(translationKeys.description)}
                            placeholder=""
                          />
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>

                  {onEdit && values.parent_id && (
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        {getT("components_section_label")}
                      </Accordion.Header>
                      <Accordion.Body>
                        <ComponentList
                          list={componentList}
                          pageId={editData?.id}
                          onNewItem={onNewItem}
                          onDeleteItem={onDeleteItem}
                          handleDynamicChange={handleDynamicChange}
                          handleStatusChange={handleStatusChange}
                          onCreateInnerItem={onCreateInnerItem}
                          onDeleteInnerItem={onDeleteInnerItem}
                          onCloneItem={onCloneItem}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </Accordion>

                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      {getT("submit")}
                    </button>
                    <button
                      type="button"
                      onClick={() => handleClear(formik)}
                      className="btn btn-secondary"
                    >
                      {getT("back")}
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default PageForm;
