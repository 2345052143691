import React, { useEffect, useState } from "react";
import Filters from "../Filters";
import CustomTable from "../CustomTable";
import {
  BsFillInfoCircleFill,
  BsFillTrash3Fill,
  BsPencilSquare,
} from "react-icons/bs";
import moment from "moment";
import {
  emailTemplateColumns,
  getT,
  isDateBetween,
  templateOptions,
  translationKeys,
} from "../utils";
import { useNavigate } from "react-router-dom";
import paths, { adminWord, apiUrl } from "../../config/paths";
import { BsCopy } from "react-icons/bs";
import axios from "axios";
import { getFromLocalStorage } from "../../utils/helpers";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import { useAuth } from "../../authContext";

import {
  useDeleteEmailTemplateMutation,
  useEmailTemplateQuery,
} from "../../queries/emailTemplates";

export default function EmailTemplates() {
  const [list, setList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const { TData } = useAuth();

  const navigate = useNavigate();

  const { data, isIdle, isLoading, isError, error } = useEmailTemplateQuery();
  const mutation = useDeleteEmailTemplateMutation();

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (mutation.data.data.status) {
        toast.success("deleted");
      } else {
        toast.error("try again later");
      }
      mutation.reset();
    }
  }, [mutation]);

  useEffect(() => {
    if (data && !isLoading && data.items) {
      let apiList = data.items.map((r) => {
        return {
          id: r?.id,
          date: moment(r?.created_at, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY"
          ),
          name: templateOptions.map((t) => (t.value === r?.name ? t.name : "")),
          action: (
            <>
              <BsPencilSquare
                data-id={r.id}
                fontSize={25}
                className="cursor me-2"
                onClick={(event) => {
                  let id = event.target.getAttribute("data-id");
                  if (id > 0) {
                    navigate(adminWord + "/emailTemplates/edit/" + id);
                  }
                }}
              />
              <span
                data-id={r.id}
                className="cursor me-2 danger"
                onClick={(event) => {
                  let id =
                    event.target.parentNode.getAttribute("data-id") ||
                    event.target.getAttribute("data-id");
                  if (id > 0) {
                    Swal.fire({
                      title: getT(translationKeys.are_you_sure_label),
                      text: "",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: getT(translationKeys.yes),
                      cancelButtonText: getT(translationKeys.no),
                    }).then((result) => {
                      if (result.isConfirmed) {
                        toast.loading("Deleting");
                        mutation.mutate({ id: id });
                      }
                    });
                  }
                }}
              >
                <BsFillTrash3Fill data-id={r.id} fontSize={25} />
              </span>
            </>
          ),
        };
      });

      setFilteredData(apiList);
      setList(apiList);
    }
  }, [isLoading, data]);

  const onSearch = (values) => {
    var options = list;

    if (options && options.length > 0) {
      if (values.name !== "") {
        options = options.filter((s) => {
          let cString = s.name.toLowerCase();
          return cString.includes(values.name.toLowerCase());
        });
      }

      if (values.from !== "" && values.to !== "") {
        options = options.filter((s) => {
          let date = moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD");
          return isDateBetween(values.from, values.to, date);
        });
      }
    }

    setFilteredData(options);
  };

  const onClear = () => {
    setFilteredData(list);
  };

  return (
    <>
      <Filters
        onAdd={() => navigate(paths.newEmailTemplatePath)}
        onFilter={onSearch}
        onClear={onClear}
        type="emailTemplates"
      />
      <div className="customTable">
        {isIdle || isLoading ? (
          getT(translationKeys.pleaseWait)
        ) : (
          <CustomTable
            columns={emailTemplateColumns}
            tData={TData}
            data={filteredData}
          />
        )}
      </div>
    </>
  );
}
