import React, { useEffect, useRef } from "react";
import { BsDashLg, BsFillGridFill, BsPlusLg } from "react-icons/bs";
import { FaRotate } from "react-icons/fa6";
import CustomOverLay from "../components/CustomOverLay";
import { getT, translationKeys } from "../admin/utils";

export default function PolygonMap({
  zoom,
  loc,
  coordinates,
  onChangeSlope,
  slopeEdge,
  onMove,
}) {
  const mapRef = useRef(null);
  let rotation = 0;
  useEffect(() => {
    if (window.google) {
      loadMap();
    }
  }, [window.google]);

  const changeHeading = () => {
    let val = rotation + 45;
    if (mapRef.current) {
      mapRef.current.setHeading((mapRef.current.getHeading() + 45) % 360);
      rotation = val;
    }
    console.log(val);
  };
  const changeTilt = () => {
    //let val = map.getTilt() + 45;
    if (mapRef.current.getTilt() === 45) {
      mapRef.current.setTilt(0);
    } else {
      mapRef.current.setTilt(45);
    }
  };

  const loadMap = async () => {
    const myLatLng = loc;
    const mapEle = document.getElementById("customMap");
    if (mapEle) {
      // eslint-disable-next-line no-undef
      let map = new google.maps.Map(mapEle, {
        zoom: zoom ? parseInt(zoom) : 20,
        center: myLatLng,
        minZoom: 18,
        tilt: 0,
        disableDefaultUI: true,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        mapId: "b9ce3553b919f3bc",
      });
      map.setMapTypeId("satellite");
      map.setHeading(0);
      mapRef.current = map;

      // adding drawing manager
      // eslint-disable-next-line no-undef
      var polygonToShow = new google.maps.Polygon({
        paths: coordinates,
        editable: false,
        draggable: false,
        fillColor: "darkgreen",
        fillOpacity: 0.35,
        strokeColor: "green",
        strokeOpacity: 0.8,
        strokeWeight: 2,
      });
      polygonToShow.setMap(map);

      // area calculation
      // eslint-disable-next-line no-undef
      var polygonArea = google.maps.geometry.spherical.computeArea(
        polygonToShow.getPath()
      );

      // eslint-disable-next-line no-undef
      google.maps.event.addListener(
        polygonToShow.getPath(),
        "set_at",
        function (index) {
          console.log("Vertex at index " + index + " has been updated.");
          let path = polygonToShow.getPath();
          // eslint-disable-next-line no-undef
          let area = google.maps.geometry.spherical.computeArea(path);

          // eslint-disable-next-line no-undef
          let fixedCoordinates = polygonToShow
            .getPath()
            .getArray()
            .map((point) => ({
              lat: point.lat(),
              lng: point.lng(),
            }));
          // Use coordinates for your calculations (length, width, etc.)
          onMove({
            dynamic: fixedCoordinates,
            fixed: fixedCoordinates,
            area: Math.round(area, 2),
          });
        }
      );

      // Add event listener for new index
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(
        polygonToShow.getPath(),
        "insert_at",
        function (index) {
          // This function will be called whenever a new path point is added
          console.log("New path point added at index: " + index);
          console.log("Vertex at index " + index + " has been updated.");
          let path = polygonToShow.getPath();
          // eslint-disable-next-line no-undef
          let area = google.maps.geometry.spherical.computeArea(path);

          // eslint-disable-next-line no-undef
          let fixedCoordinates = polygonToShow
            .getPath()
            .getArray()
            .map((point) => ({
              lat: point.lat(),
              lng: point.lng(),
            }));
          // Use coordinates for your calculations (length, width, etc.)
          onMove({
            dynamic: fixedCoordinates,
            fixed: fixedCoordinates,
            area: Math.round(area, 2),
          });
        }
      );

      // adding event listener for its edge
      /* Add click event listener to the displayed polygon
      var polygons = [];
      var highlightedEdge = null;
      polygons.push(polygonToShow); // Store the polygon
      var selectedPathIndex = -1;
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(polygonToShow, "click", function (event) {
        // eslint-disable-next-line no-undef
        selectedPathIndex = findNearestPathIndex(event.latLng, coordinates);
        highlightSelectedEdge(selectedPathIndex, polygonToShow);
      });

      var prevIndex = null;

      if (slopeEdge) {
        // Draw a polyline for the selected edge
        // eslint-disable-next-line no-undef
        prevIndex = new google.maps.Polyline({
          path: slopeEdge,
          editable: true,
          draggable: false,
          fillColor: "green",
          strokeColor: "green", // Change to your desired color
          strokeOpacity: 0.8,
          strokeWeight: 4,
        });
        prevIndex.setMap(map);
      }

      function findNearestPathIndex(latLng, coordinates) {
        // Find the nearest path index based on the clicked point
        var minDistance = Number.MAX_VALUE;
        var nearestIndex = -1;

        coordinates.forEach(function (coord, index) {
          // eslint-disable-next-line no-undef
          var distance = google.maps.geometry.spherical.computeDistanceBetween(
            latLng,
            coord
          );

          if (distance < minDistance) {
            minDistance = distance;
            nearestIndex = index;
          }
        });

        return nearestIndex;
      }

      function highlightSelectedEdge(index, polygon) {
        // Remove the previous highlight
        prevIndex?.setMap(null);
        if (highlightedEdge) {
          highlightedEdge.setMap(null);
        }

        // Create a line segment for the selected edge
        var edgeCoordinates = [
          polygon.getPath().getAt(index),
          polygon.getPath().getAt((index + 1) % polygon.getPath().getLength()),
        ];

        // Draw a polyline for the selected edge
        // eslint-disable-next-line no-undef
        highlightedEdge = new google.maps.Polyline({
          path: edgeCoordinates,
          editable: false,
          draggable: false,
          fillColor: "green",
          strokeColor: "#00FF00", // Change to your desired color
          strokeOpacity: 0.8,
          strokeWeight: 4,
        });

        let fixedCoordinates = highlightedEdge
          .getPath()
          .getArray()
          .map((point) => ({
            lat: point.lat(),
            lng: point.lng(),
          }));

        onChangeSlope({
          dynamic: edgeCoordinates,
          fixed: fixedCoordinates,
          area: polygonArea,
        });

        highlightedEdge.setMap(map);
      } */
    }
  };

  const increaseZoom = () => {
    mapRef.current.setZoom(mapRef.current.getZoom() + 1);
  };

  const decreaseZoom = () => {
    mapRef.current.setZoom(mapRef.current.getZoom() - 1);
  };

  return (
    <>
      <div className="rotationBtn">
        <CustomOverLay
          placement="left"
          message={getT(translationKeys.rotate_info_label)}
          iconElement={
            <FaRotate
              fontSize={25}
              cursor={"pointer"}
              onClick={() => changeHeading()}
            />
          }
        ></CustomOverLay>
        {/* <BsFillGridFill
          fontSize={25}
          cursor={"pointer"}
          onClick={() => changeTilt()}
          color="white"
  /> */}
        <CustomOverLay
          placement="left"
          message={getT(translationKeys.plus_zoom_info_label)}
          iconElement={
            <BsPlusLg cursor={"pointer"} onClick={() => increaseZoom()} />
          }
        ></CustomOverLay>
        <CustomOverLay
          placement="left"
          message={getT(translationKeys.minus_zoom_info_label)}
          iconElement={
            <BsDashLg cursor={"pointer"} onClick={() => decreaseZoom()} />
          }
        ></CustomOverLay>
      </div>
      <div id="customMap"></div>{" "}
    </>
  );
}
