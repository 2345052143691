import React, { useEffect, useState } from "react";
import Filters from "../Filters";
import CustomTable from "../CustomTable";
import { BsPencilSquare } from "react-icons/bs";
import moment from "moment";
import {
  getT,
  isDateBetween,
  leadColumns,
  quoteColumns,
  translationKeys,
} from "../utils";
import { useQuoteQuery } from "../../queries/quotes";
import { useNavigate } from "react-router-dom";
import { BsFillInfoCircleFill } from "react-icons/bs";
import paths, { adminWord, apiUrl } from "../../config/paths";

import { toast } from "react-toastify";
import Swal from "sweetalert2";
import axios from "axios";
import { BsCopy } from "react-icons/bs";
import { getFromLocalStorage } from "../../utils/helpers";

export default function Requests() {
  const [list, setList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const { data, isIdle, isLoading, isError, error } = useQuoteQuery();

  useEffect(() => {
    if (data && !isLoading && data.quotes) {
      let apiData = data.quotes.map((r) => {
        return {
          id: "RQ" + r?.id,
          date: moment(r?.created_at, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY"
          ),
          name: (r.name ? r.name : "") + "" + (r?.surname ? r.surname : ""),
          add: r?.address,
          comune: r?.city,
          prov: r?.province,
          phone: r?.mobile,
          email: r?.email,
          status: r?.status,
          action: (
            <>
              <BsPencilSquare
                data-id={r.id}
                fontSize={25}
                className="cursor me-2"
                onClick={(event) => {
                  let id = event.target.getAttribute("data-id");
                  if (id > 0) {
                    navigate(adminWord + "/requests/edit/" + id);
                  }
                }}
              />
            </>
          ),
        };
      });
      setList(apiData);
      setFilteredData(apiData);
    }
  }, [isLoading, data]);

  const onSearch = (values) => {
    var options = list;

    if (options && options.length > 0) {
      if (values.name !== "") {
        options = options.filter((s) => {
          let cString = s.name.toLowerCase();
          return cString.includes(values.name.toLowerCase());
        });
      }

      if (values.comune !== "") {
        options = options.filter((s) => {
          let cString = s.comune.toLowerCase();
          return cString.includes(values.comune.toLowerCase());
        });
      }

      if (values.statusType !== "") {
        options = options.filter((s) => {
          return s.status === values.statusType;
        });
      }

      if (values.prov !== "") {
        options = options.filter((s) => {
          let cString = s.prov.toLowerCase();
          return cString.includes(values.prov.toLowerCase());
        });
      }

      if (values.type !== "") {
        options = options.filter((s) => {
          return s.type === values.type;
        });
      }

      if (values.from !== "" && values.to !== "") {
        options = options.filter((s) => {
          let date = moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD");
          return isDateBetween(values.from, values.to, date);
        });
      }
    }

    console.log(values);

    setFilteredData(options);
  };

  const onClear = () => {
    setFilteredData(list);
  };

  return (
    <>
      <Filters
        type="requests"
        onAdd={() => setShowModal(true)}
        onFilter={onSearch}
        onClear={onClear}
      />
      <div className="customTable">
        {isIdle || isLoading ? (
          getT(translationKeys.pleaseWait)
        ) : (
          <CustomTable columns={quoteColumns} data={filteredData} />
        )}
      </div>
    </>
  );
}
