import React, { useEffect, useState } from "react";
import { useAuth } from "./authContext";
import { Navigate, useLocation } from "react-router-dom";
import { moduleOptions } from "./admin/utils";
import paths from "./config/paths";

export default function PublicRoute({ children }) {
  const { fmodules } = useAuth();
  const location = useLocation();
  const [allowed, setAllowed] = useState(true);

  useEffect(() => {
    moduleOptions().forEach((m) => {
      //console.log(m.value, fmodules, location.pathname);
      if (m.path === location.pathname && !fmodules[m.value]) {
        setAllowed(false);
      }
    });
  });

  if (!allowed) {
    return <Navigate to={paths.homePath} />;
  }

  return <>{children}</>;
}
