import React from "react";
import { useParams } from "react-router-dom";
import MessageForm from "../forms/MessageForm";

export default function EditTicket() {
  const { id } = useParams();

  return (
    <>
      <MessageForm onEdit editId={id} />
    </>
  );
}
