import React from "react";
import ContactForm from "../forms/ContactForm";

export default function NewContact() {
  return (
    <>
      <ContactForm />
    </>
  );
}
