import React, { useEffect, useState } from "react";
import MainWrapper from "../MainWrapper";
import ViewOrder from "../../admin/Orders/ViewOrder";
import { apiUrl } from "../../config/paths";
import { getCustomerToken } from "../../utils";
import { getT, translationKeys } from "../../admin/utils";

export default function ViewMyOders() {
  const [userInfo, setUserInfo] = useState({});
  const token = getCustomerToken();

  useEffect(() => {
    // Check for the token in local storage

    if (token) {
      fetch(apiUrl + "/api/me", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((res) => {
          if (res && res.user) {
            setUserInfo(res.user);
          }
        })
        .catch((error) => {
          console.error("Error checking token status:", error);
        })
        .finally(() => {});
    }
  }, []);

  return (
    <>
      <MainWrapper mainClass="dynamicPage" topBar={false}>
        <div className="infoWrapper quotes_page">
          {userInfo && userInfo.id > 1 ? (
            <ViewOrder roleType={"customer"} cusId={userInfo?.id} />
          ) : (
            <div className="p-4">
              {!token
                ? getT(translationKeys.notAutherized)
                : getT(translationKeys.pleaseWait)}
            </div>
          )}
        </div>
      </MainWrapper>
    </>
  );
}
