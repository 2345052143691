import { ErrorMessage, Field, useFormikContext } from "formik";
import React from "react";

export default function CustomTextArea({
  name = "info_field",
  label = "",
  type = "text",
  placeholder = "",
  onEdit,
  noLabel = true,
  ...rest
}) {
  const { setFieldValue } = useFormikContext();

  return (
    <>
      {noLabel && <label className="form-label">{label}</label>}
      <Field
        as="textarea"
        placeholder={placeholder}
        className="form-control"
        disabled={onEdit}
        name={name}
        rows="4"
        onChange={(e) => setFieldValue(name, e.target.value)}
        {...rest}
      />
      <ErrorMessage className="danger" name={name} component="div" />
    </>
  );
}
